import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper } from '../../components';
import { CheckBoxField, RadioField } from '../../fields';
import {
    ConsultantConfirmationFormValues,
    AgreementsFormValues,
} from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import { Row, Col } from 'antd';
import InputField from '../../fields/Input';

interface Props {
    initialValues?: Partial<ConsultantConfirmationFormValues>;
    agreementsValues?: AgreementsFormValues;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: ConsultantConfirmationFormValues) => void;
}

export default function FConsultantConfirmationForm({
    onSubmit,
    initialValues: iV,
    bindSubmit,
    agreementsValues,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<ConsultantConfirmationFormValues>({
        a: Yup.object().shape({
            a: Yup.boolean().required(t('form.required')),
            b: Yup.boolean().required(t('form.required')),
            c: Yup.boolean().required(t('form.required')),
            d: Yup.boolean().required(t('form.required')),
        }),
        b: Yup.object().shape({
            place: Yup.string().required(t('form.required')),
            date: Yup.string().required(t('form.required')),
            interviewDuration: Yup.string().required(t('form.required')),
            attendants: Yup.string().required(t('form.required')),
            city: Yup.string().required(t('form.required')),
        }),
        c: Yup.object().shape({
            place: Yup.string().required(t('form.required')),
            date: Yup.string().required(t('form.required')),
            interviewDuration: Yup.string().required(t('form.required')),
            attendants: Yup.string().required(t('form.required')),
            time: Yup.string().required(t('form.required')),
            city: Yup.string().required(t('form.required')),
        }),
    });

    function handleOnSubmit(values: ConsultantConfirmationFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({
        submitForm,
    }: FormikProps<ConsultantConfirmationFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <CheckBoxField
                                name="a.a"
                                label={t(
                                    'consultantConfirmationForm.a.options.a'
                                )}
                            />
                            <CheckBoxField
                                name="a.b"
                                label={t(
                                    'consultantConfirmationForm.a.options.b'
                                )}
                            />
                            <CheckBoxField
                                name="a.c"
                                label={t(
                                    'consultantConfirmationForm.a.options.c'
                                )}
                            />
                            <CheckBoxField
                                name="a.d"
                                label={t(
                                    'consultantConfirmationForm.a.options.d'
                                )}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('consultantConfirmationForm.b.label')}
                            </QuestionWrapper.Title>
                            <Row>
                                <Col xs={24} md={10}>
                                    <RadioField
                                        name="b.place"
                                        options={[
                                            {
                                                label: t(
                                                    'consultantConfirmationForm.b.questions.place.a'
                                                ),
                                                value: 'a',
                                            },
                                            {
                                                label: t(
                                                    'consultantConfirmationForm.b.questions.place.b'
                                                ),
                                                value: 'b',
                                            },
                                            {
                                                label: t(
                                                    'consultantConfirmationForm.b.questions.place.c'
                                                ),
                                                value: 'c',
                                                custom: true,
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col xs={24} md={14}>
                                    <Row gutter={20}>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="b.date"
                                                label={t(
                                                    'consultantConfirmationForm.b.questions.date'
                                                )}
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="b.interviewDuration"
                                                label={t(
                                                    'consultantConfirmationForm.b.questions.interviewDuration'
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <InputField
                                        name="b.attendants"
                                        label={t(
                                            'consultantConfirmationForm.b.questions.attendants'
                                        )}
                                    />
                                    <InputField
                                        name="b.city"
                                        label={t(
                                            'consultantConfirmationForm.b.questions.city'
                                        )}
                                    />
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('consultantConfirmationForm.c.label')}
                            </QuestionWrapper.Title>
                            <Row>
                                <Col xs={24} md={10}>
                                    <RadioField
                                        name="c.place"
                                        options={[
                                            {
                                                label: t(
                                                    'consultantConfirmationForm.c.questions.place.a'
                                                ),
                                                value: 'a',
                                            },
                                            {
                                                label: t(
                                                    'consultantConfirmationForm.c.questions.place.b'
                                                ),
                                                value: 'b',
                                            },
                                            {
                                                label: t(
                                                    'consultantConfirmationForm.c.questions.place.c'
                                                ),
                                                value: 'c',
                                                custom: true,
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col xs={24} md={14}>
                                    <Row gutter={20}>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="c.date"
                                                label={t(
                                                    'consultantConfirmationForm.c.questions.date'
                                                )}
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="c.interviewDuration"
                                                label={t(
                                                    'consultantConfirmationForm.c.questions.interviewDuration'
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <InputField
                                        name="c.attendants"
                                        label={t(
                                            'consultantConfirmationForm.c.questions.attendants'
                                        )}
                                    />
                                    <InputField
                                        name="c.city"
                                        label={t(
                                            'consultantConfirmationForm.c.questions.city'
                                        )}
                                    />
                                    <InputField
                                        name="c.time"
                                        label={t(
                                            'consultantConfirmationForm.c.questions.time'
                                        )}
                                    />
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: {
                    a: iV?.a?.a,
                    b: iV?.a?.b,
                    c: iV?.a?.c,
                    d: iV?.a?.d,
                },
                b: {
                    place:
                        iV?.b?.place ??
                        agreementsValues?.placeOfSignature ??
                        '',
                    date:
                        agreementsValues?.dateOfSignature ?? iV?.b?.date ?? '',
                    interviewDuration:
                        iV?.b?.interviewDuration ??
                        agreementsValues?.interviewTime ??
                        '',
                    attendants: iV?.b?.attendants ?? '',
                    city: iV?.b?.city ?? '',
                },
                c: {
                    place: iV?.c?.place ?? '',
                    date: iV?.c?.date ?? '',
                    interviewDuration: iV?.c?.interviewDuration ?? '',
                    attendants: iV?.c?.attendants ?? '',
                    time: iV?.c?.time ?? '',
                    city: iV?.c?.city ?? '',
                },
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
