import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Button, Col, Icon, Row } from 'antd';
import * as Yup from 'yup';

import { I18nNameSpaces } from 'common/services/i18n';
import { ClientVerificationFormPhoneValues } from 'common/models/Forms';
import { InputSearchField } from 'common/fields';
import { ResultMessage } from 'common/components';

interface Props {
    readOnly?: boolean;
    signature?: string | null;
    success?: string | null;
    error?: ReactNode;
    loadingRequest?: boolean;
    disableRequest?: boolean;
    loadingVerify?: boolean;
    disableVerify?: boolean;

    onRequestCode: () => void;
    onVerifyCode: (code: string) => void;
}

export default function SignatureForm({
    signature,
    readOnly,
    success,
    error,
    loadingRequest,
    disableRequest,
    loadingVerify,
    disableVerify,
    onRequestCode,
    onVerifyCode,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);

    const codeSchema = Yup.object().shape<
        Pick<ClientVerificationFormPhoneValues, 'code'>
    >({
        code: Yup.string().required(t('form.required')),
    });

    function renderCodeVerifyForm({
        submitForm,
    }: FormikProps<Pick<ClientVerificationFormPhoneValues, 'code'>>) {
        return (
            <Form>
                <InputSearchField
                    name="code"
                    label={t('signatureForm.code.label')}
                    placeholder={t('signatureForm.code.placeholder')}
                    autoComplete="off"
                    enterButton={
                        loadingVerify ? (
                            <Icon type="loading" />
                        ) : (
                            t('signatureForm.sign.submit')
                        )
                    }
                    onSearch={submitForm}
                    disabled={readOnly || loadingVerify || disableVerify}
                />
            </Form>
        );
    }

    return (
        <div className="signature-form">
            <Button
                className="btn-request-code"
                type="primary"
                block
                disabled={readOnly || disableRequest || loadingRequest}
                onClick={onRequestCode}
            >
                {t('signatureForm.request.submit')}
                {loadingRequest ? (
                    <Icon type="loading" />
                ) : (
                    <Icon type="file-protect" />
                )}
            </Button>
            <Formik
                initialValues={{
                    code: '',
                }}
                render={renderCodeVerifyForm}
                onSubmit={({ code }) => onVerifyCode(code)}
                validationSchema={codeSchema}
                validateOnBlur={false}
                validateOnChange={false}
            />

            <Row type="flex" justify="space-between" gutter={30}>
                <Col span={24} md={16}>
                    {!!error && (
                        <ResultMessage type="error">{error}</ResultMessage>
                    )}
                    {!!success && !error && (
                        <ResultMessage type="success">{success}</ResultMessage>
                    )}
                </Col>
                <Col span={24} md={8} className="signature-area">
                    {readOnly || !signature ? (
                        <span className="signature-unknown">
                            {t('signatureForm.signature.placeholder')}
                        </span>
                    ) : (
                        <span>
                            <span className="signature-prefix">
                                {t('signatureForm.signature.prefix')}
                            </span>
                            <span className="signature">{signature}</span>
                        </span>
                    )}
                </Col>
            </Row>
        </div>
    );
}
