import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Row, Col, Divider } from 'antd';

import { Button } from '../../components';
import { InputField } from '../../fields';
import { AddressFormValues } from '../../models/User';

interface Props extends WithTranslation {
    type?: 'default' | 'edit';
    onSubmit: (values: AddressFormValues) => void;
    onRemove?: () => void;
    onCancel?: () => void;
    initialValues?: Partial<AddressFormValues>;
}

function AddressForm({
    t,
    onSubmit,
    initialValues,
    onCancel,
    onRemove,
    type,
}: Props) {
    const schema = Yup.object<AddressFormValues>().shape<AddressFormValues>({
        firstName: Yup.string().required(t('form.required')),
        lastName: Yup.string().required(t('form.required')),
        company: Yup.string(),
        phone: Yup.string().required(t('form.required')),
        address: Yup.string().required(t('form.required')),
        postalCode: Yup.string().required(t('form.required')),
        city: Yup.string().required(t('form.required')),
    });

    function handleOnSubmit(values: AddressFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm(formikBag: FormikProps<AddressFormValues>) {
        return (
            <div className="address-form">
                <Form onSubmitCapture={formikBag.submitForm}>
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <InputField
                                name="firstName"
                                label={t('addressForm.firstName.label')}
                                placeholder={t(
                                    'addressForm.firstName.placeholder'
                                )}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <InputField
                                name="lastName"
                                label={t('addressForm.lastName.label')}
                                placeholder={t(
                                    'addressForm.lastName.placeholder'
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputField
                                name="company"
                                label={t('addressForm.company.label')}
                                placeholder={t(
                                    'addressForm.company.placeholder'
                                )}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputField
                                name="phone"
                                label={t('addressForm.phone.label')}
                                placeholder={t('addressForm.phone.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputField
                                name="address"
                                label={t('addressForm.street.label')}
                                placeholder={t(
                                    'addressForm.street.placeholder'
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col xs={24} md={8}>
                            <InputField
                                name="postalCode"
                                label={t('addressForm.postcode.label')}
                                placeholder={t(
                                    'addressForm.postcode.placeholder'
                                )}
                            />
                        </Col>
                        <Col xs={24} md={16}>
                            <InputField
                                name="city"
                                label={t('addressForm.city.label')}
                                placeholder={t('addressForm.city.placeholder')}
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <Row type="flex">
                        {onRemove && (
                            <Col style={{ marginRight: '5px' }}>
                                <Button block onClick={onRemove} type="danger">
                                    {t('addressForm.remove')}
                                </Button>
                            </Col>
                        )}
                        <Col>
                            <Button block onClick={onCancel}>
                                {t('addressForm.cancel')}
                            </Button>
                        </Col>
                        <Col style={{ marginLeft: 'auto' }}>
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                onClick={formikBag.submitForm}
                            >
                                {type === 'edit'
                                    ? t('addressForm.submitEdit')
                                    : 'addressForm.submit'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

    return (
        <Formik<AddressFormValues>
            initialValues={{
                firstName: initialValues?.firstName ?? '',
                lastName: initialValues?.lastName ?? '',
                company: initialValues?.company ?? '',
                phone: initialValues?.phone ?? '',
                address: initialValues?.address ?? '',
                postalCode: initialValues?.postalCode ?? '',
                city: initialValues?.city ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}

export default withTranslation()(AddressForm);
