import { call, put, takeLatest } from 'redux-saga/effects';
import {
    createAsyncAction,
    createAction,
    createReducer,
    getType,
    ActionType,
} from 'typesafe-actions';

import api from 'common/api';
import { AppState } from 'common/models/AppState';
import {
    RegistrationFormValues,
    UserProfile,
    ProfileFormValues,
} from 'common/models/User';

import { selectAuth } from '../../ducks';

// Actions
export const registrationActions = createAsyncAction(
    '@@Registration/REQUEST',
    '@@Registration/SUCCESS',
    '@@Registration/FAILURE'
)<
    { auth: RegistrationFormValues; profile: ProfileFormValues },
    UserProfile,
    undefined
>();

export const activateRegistrationActions = createAsyncAction(
    '@@Registration_Activation/REQUEST',
    '@@Registration_Activation/SUCCESS',
    '@@Registration_Activation/FAILURE'
)<string, undefined, undefined>();

export const resetRegistrationActivationAction = createAction(
    '@@Registration_Activation/RESET'
);

export const registrationResetAction = createAction('@@Registration/RESET');

// Reducers
export interface RegistrationState {
    error: boolean;
    loading: boolean;
    success: boolean;
}

const initialState: RegistrationState = {
    error: false,
    loading: false,
    success: false,
} as const;

type RegistrationActions = ActionType<
    | typeof registrationActions
    | typeof registrationResetAction
    | typeof resetRegistrationActivationAction
    | typeof activateRegistrationActions
>;
export default createReducer<RegistrationState, RegistrationActions>(
    initialState
)
    .handleAction(registrationActions.failure, state => ({
        ...state,
        error: true,
    }))
    .handleAction(activateRegistrationActions.failure, state => ({
        ...state,
        error: true,
    }))
    .handleAction(registrationActions.success, state => ({
        ...state,
        success: true,
    }))
    .handleAction(activateRegistrationActions.success, state => ({
        ...state,
        success: true,
    }))
    .handleAction(registrationResetAction, () => initialState)
    .handleAction(resetRegistrationActivationAction, () => initialState);

// Selectors
export function selectRegistration(state: AppState) {
    return selectAuth(state).registration;
}

export function selectRegistrationError(state: AppState) {
    return selectRegistration(state).error;
}

export function selectRegistrationSuccess(state: AppState) {
    return selectRegistration(state).success;
}

// Sagas
function* watchRegistrationRequest(
    action: ReturnType<typeof registrationActions.request>
) {
    const resp = yield call(
        api.user.register,
        action.payload.auth as RegistrationFormValues,
        action.payload.profile
    );

    if (resp.ok) {
        yield put(registrationActions.success(resp.data));
    } else {
        yield put(registrationActions.failure());
    }
}

function* watchActivateRegistrationRequest(
    action: ReturnType<typeof activateRegistrationActions.request>
) {
    const resp = yield call(api.user.activateRegistration, action.payload);

    if (resp.ok) {
        yield put(activateRegistrationActions.success());
    } else {
        yield put(activateRegistrationActions.failure());
    }
}

export function* registrationSaga() {
    yield takeLatest(
        getType(activateRegistrationActions.request),
        watchActivateRegistrationRequest
    );
    yield takeLatest(
        getType(registrationActions.request),
        watchRegistrationRequest
    );
}
