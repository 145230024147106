import { SearchProps } from 'antd/lib/input';
import { Field, FieldProps, getIn } from 'formik';
import React from 'react';
import { ReactNode } from 'react';
import { useRegisteredRef } from 'react-register-nodes';

import { Input } from '../../components';

interface Props extends SearchProps {
    name: string;
    label?: ReactNode;
}
export default function InputField({ name, ...bag }: Props) {
    const ref = useRegisteredRef(name);

    return (
        <Field name={name}>
            {({ field, form: { errors } }: FieldProps<any>) => (
                <div ref={getIn(errors, name) ? ref : null}>
                    {
                        <Input.Search
                            error={getIn(errors, name)}
                            {...field}
                            {...bag}
                        />
                    }
                </div>
            )}
        </Field>
    );
}
