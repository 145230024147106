import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Control } from '../Control';

export interface SelectOption {
    label: React.ReactNode;
    value: string | number;
    disabled?: boolean;
}

interface Props extends SelectProps {
    options: SelectOption[];
    warning?: boolean;
    label?: ReactNode;
    error?: ReactNode;
}

export default function SelectComponent({
    options,
    warning,
    label,
    error,
    ...bag
}: Props) {
    function renderOptions() {
        return options.map((option, index) => (
            <Select.Option
                key={index}
                value={option.value}
                disabled={option.disabled ? true : false}
            >
                {option.label}
            </Select.Option>
        ));
    }

    function renderSelect() {
        return (
            <Select
                className={cn({
                    'ant-select-warning': warning,
                    'ant-select--with-label': label,
                })}
                {...bag}
            >
                {renderOptions()}
            </Select>
        );
    }

    return (
        <Control label={label} error={error}>
            {renderSelect()}
        </Control>
    );
}
