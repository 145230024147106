import { FormState } from '../../features/form/ducks';

export enum VersionFlag {
    OUTDATED = '1',
    CURRENT = '2',
}

export enum VerificationFlag {
    VERIFIED = 'verified',
    OUTDATED = 'outdated',
}

export enum SignatureFlag {
    SIGNED = 'signed',
    OUTDATED = 'outdated',
}

export enum SignatureScope {
    CUSTOMER_INFO_1 = 'customerInfo1',
    CUSTOMER_INFO_2 = 'customerInfo2',
    INVESTING = 'investing',
    GENERAL_AGREEMENT = 'generalAgreement',
    PROTOCOL = 'protocol',
    AGREEMENT = 'agreement',
    MEETING = 'meeting',
    MOVENTUM = 'bestOfTempleton',
}

interface Verification {
    customer1: {
        sms?: VerificationFlag;
        email?: VerificationFlag;
    };
}

interface Signature {
    customer1: {
        // e.g. { 'agreement': 'signed' }
        sms: { [value in SignatureScope]?: SignatureFlag };
    };
}

export interface Transaction {
    type: 'questionnaire';
    status: 'new';
    benefits: [];
    appliedBenefits: [];
    parents: [];
    children: [];
    journal: [];
    time: string;
    owner: string;
    entries: [{ meta: FormState }];
    price: { amount: 0; currency: 'EUR' };
    meta: {
        version: VersionFlag;
        verification?: Verification;
        signature?: Signature;
    };
    author: string;
    createdAt: string;
    updatedAt: string;
    id: string;
    _id: string;
}
