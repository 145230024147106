import { BasicProps } from 'antd/lib/layout/layout';
import cn from 'classnames';
import React from 'react';

interface Props extends BasicProps {
    fullHeight?: boolean;
}

export default function Container({
    fullHeight = false,
    className,
    ...bag
}: Props) {
    return (
        <div
            className={cn('container', className, {
                'container--fullheight': fullHeight,
            })}
            {...bag}
        />
    );
}
