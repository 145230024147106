import api from 'common/api';
import { takeLatest, call, put } from 'redux-saga/effects';
import {
    createAsyncAction,
    createReducer,
    createAction,
    ActionType,
    getType,
} from 'typesafe-actions';
import { selectAuth } from '../../ducks';
import { AppState } from '../../../../common/models/AppState';
import { SagaIterator } from 'redux-saga';
import { message } from 'antd';
import { push } from 'connected-react-router';

// Actions
export const requestPasswordRecoveryActions = createAsyncAction(
    '@@Auth/PasswordRecovery/REQUEST',
    '@@Auth/PasswordRecovery/SUCCESS',
    '@@Auth/PasswordRecovery/FAILURE'
)<string, undefined, undefined>();

export const changePasswordActions = createAsyncAction(
    '@@Auth/ChangePassword/REQUEST',
    '@@Auth/ChangePassword/SUCCESS',
    '@@Auth/ChangePassword/FAILURE'
)<{ password: string; token: string }, undefined, undefined>();

export const resetPasswordRecoveryAction = createAction(
    '@@Auth/PASSWORD_RECOVERY_RESET'
);

// Reducers
export interface ForgottenPasswordState {
    success: boolean;
    error: boolean;
}

export default createReducer<
    ForgottenPasswordState,
    ActionType<
        | typeof requestPasswordRecoveryActions
        | typeof resetPasswordRecoveryAction
    >
>({
    success: false,
    error: false,
})
    .handleAction(requestPasswordRecoveryActions.success, state => ({
        ...state,
        success: true,
    }))
    .handleAction(requestPasswordRecoveryActions.failure, state => ({
        ...state,
        error: true,
    }))
    .handleAction(resetPasswordRecoveryAction, () => ({
        success: false,
        error: false,
    }));

// Selectors
export function selectAuthForgottenPassword(state: AppState) {
    return selectAuth(state).forgottenPassword;
}

export function selectForgottenPasswordError(state: AppState) {
    return selectAuthForgottenPassword(state).error;
}

export function selectForgottenPasswordSuccess(state: AppState) {
    return selectAuthForgottenPassword(state).success;
}

// Sagas
function* watchPasswordResetRequest(
    action: ReturnType<typeof requestPasswordRecoveryActions.request>
) {
    const resp = yield call(
        api.user.forgotPassword,
        action.payload,
        `${window.location.origin}/auth/forgotten-password/recovery`
    );
    if (resp.ok) {
        yield put(requestPasswordRecoveryActions.success());
    } else {
        yield put(requestPasswordRecoveryActions.failure());
    }
}

function* watchChangePassword(
    action: ReturnType<typeof changePasswordActions.request>
): SagaIterator {
    const resp = yield call(
        api.user.changePassword,
        action.payload.token,
        action.payload.password
    );
    if (resp.ok) {
        yield put(changePasswordActions.success());
        message.success('Your password was successfully changed');
        yield put(push('/auth'));
    } else {
        yield put(changePasswordActions.failure());
    }
}

export function* forgottenPasswordSaga() {
    yield takeLatest(
        getType(requestPasswordRecoveryActions.request),
        watchPasswordResetRequest
    );
    yield takeLatest(
        getType(changePasswordActions.request),
        watchChangePassword
    );
}
