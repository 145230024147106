import React, { ReactNode } from 'react';

import { Typography, Card } from '../../components';

interface Props {
    title: ReactNode;
    value: ReactNode;
}

export default function InfoBlock({ title, value }: Props) {
    return (
        <Card className="info-block-card">
            <Typography.Paragraph>{title}</Typography.Paragraph>
            <Typography.Title level={2}>{value}</Typography.Title>
        </Card>
    );
}
