export const economicBackgroundCheckboxTree = [
    {
        label: 'investingGoalsForm.g.options.a.label',
        value: 'a',
    },
    {
        label: 'investingGoalsForm.g.options.b.label',
        value: 'b',
        children: [
            {
                label: 'investingGoalsForm.g.options.b.children.a',
                value: 'a',
                inputs: [
                    {
                        label: 'investingGoalsForm.g.options.b.gains',
                        name: 'gains',
                    },
                    {
                        label: 'investingGoalsForm.g.options.b.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.b.children.b',
                value: 'b',
                inputs: [
                    {
                        label: 'investingGoalsForm.g.options.b.gains',
                        name: 'gains',
                    },
                    {
                        label: 'investingGoalsForm.g.options.b.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.b.children.c',
                value: 'c',
                inputs: [
                    {
                        label: 'investingGoalsForm.g.options.b.gains',
                        name: 'gains',
                    },
                    {
                        label: 'investingGoalsForm.g.options.b.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.b.children.d',
                value: 'd',
                inputs: [
                    {
                        label: 'investingGoalsForm.g.options.b.gains',
                        name: 'gains',
                    },
                    {
                        label: 'investingGoalsForm.g.options.b.notes',
                        name: 'notes',
                    },
                ],
            },
        ],
    },
    {
        label: 'investingGoalsForm.g.options.c.label',
        value: 'c',
        children: [
            {
                label: 'investingGoalsForm.g.options.c.children.a.label',
                value: 'a',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.a.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.c.children.b.label',
                value: 'b',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.b.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.c.children.c.label',
                value: 'c',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.c.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.c.children.d.label',
                value: 'd',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.d.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.c.children.e.label',
                value: 'e',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.e.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.c.children.f.label',
                value: 'f',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.f.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.c.children.g.label',
                value: 'g',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.g.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.c.children.h.label',
                value: 'h',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.h.notes',
                        name: 'notes',
                    },
                ],
            },
            {
                label: 'investingGoalsForm.g.options.c.children.i.label',
                value: 'i',
                inputs: [
                    {
                        label:
                            'investingGoalsForm.g.options.c.children.i.notes',
                        name: 'notes',
                    },
                ],
            },
        ],
    },
];
