import React from 'react';

interface Props {
    title?: string;
}

export default function Spinner({ title = 'Loading' }: Props) {
    return (
        <>
            <div className="spinner">
                <div />
                <div />
                <div />
            </div>
            <p className="spinner__title">{title}</p>
        </>
    );
}
