import React, { Suspense, FunctionComponent } from 'react';
import { Utils } from '../../common/components';

export function WaitingComponent<Props>(
    Component: React.ComponentClass<Props> | FunctionComponent<Props>
) {
    return (props: Props) => (
        <Suspense fallback={<Utils.Loading />}>
            <Component {...props} />
        </Suspense>
    );
}
