import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import { FinanceAnalyseForm } from 'common/forms';

import { QuestionTitle, PageControls } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectInvestingAnalyse,
    setInvestingAnalyseValuesAction,
    selectInformationsCustomerInfo1,
} from '../../../../ducks';
import { FinanceAnalyseFormValues } from '../../../../../../common/models/Forms';
import { FormikProps } from 'formik';
import { FeatureProps } from '../../../../index';

export default function InvestingAnalyseFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch<{ id: string }>();
    const initialValues = useSelector(selectInvestingAnalyse);
    const customerValues = useSelector(selectInformationsCustomerInfo1);

    let submitForm: FormikProps<FinanceAnalyseFormValues>['submitForm'];

    function handleBackClick() {
        history.goBack();
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(
        cb: FormikProps<FinanceAnalyseFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(values: FinanceAnalyseFormValues) {
        if (!isPrivileged) {
            dispatch(setInvestingAnalyseValuesAction(values));
        }
        history.push(`/form/${match!.params.id}/investing/agreements`);
    }

    return (
        <>
            <QuestionTitle>{t('investingFeature.analyse')}</QuestionTitle>
            <FinanceAnalyseForm
                initialValues={initialValues || undefined}
                customerValues={customerValues || undefined}
                bindSubmit={bindSubmit}
                onSubmit={handleSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
