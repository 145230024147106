import React from 'react';
import { View } from '@react-pdf/renderer';
import { PDFCheckbox, PDFParagraphBlock } from './index';
import PdfParagraphBlock from './ParagraphBlock';
import { useTranslation } from 'react-i18next';
import { I18nNameSpaces } from '../../services/i18n';
import { CheckboxTreeOption } from '../../fields/CheckboxTree';
import { InvestingGoalsFormValues } from '../../models/Forms';

interface Props {
    treeTemplate: CheckboxTreeOption[];
    answers: InvestingGoalsFormValues['g'];
}

export default function PDFCheckboxTree({ treeTemplate, answers = {} }: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);

    return (
        <View>
            {treeTemplate.map(d => (
                <View key={d.value}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: '36%', paddingRight: '3%' }}>
                            <PDFCheckbox
                                checked={answers[d.value]?.checked ?? false}
                                label={t(d.label)}
                            />
                        </View>
                        {d.inputs && (
                            <View
                                style={{
                                    width: '50%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {d.inputs.map(input => (
                                    <View key={input.name}>
                                        <PDFParagraphBlock
                                            style={{ paddingRight: '8px' }}
                                        >
                                            <PDFParagraphBlock>{`${t(
                                                input.label
                                            )} `}</PDFParagraphBlock>
                                            <PdfParagraphBlock bold>
                                                {answers[d.value]?.[
                                                    input.name
                                                ] ?? ''}
                                            </PdfParagraphBlock>
                                        </PDFParagraphBlock>
                                    </View>
                                ))}
                            </View>
                        )}
                    </View>
                    {d.children && (
                        <View style={{ marginLeft: '10px' }}>
                            <PDFCheckboxTree
                                treeTemplate={d.children}
                                answers={answers[d.value] || {}}
                            />
                        </View>
                    )}
                </View>
            ))}
        </View>
    );
}
