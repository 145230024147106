import React, { useState, useEffect } from 'react';
import { Table, Icon, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import numbro from 'numbro';
import { ColumnProps } from 'antd/lib/table';

import { FondForm } from '../../forms';
import {
    FondDataFormValues,
    HazardProfileFormValues,
} from '../../models/Forms';

interface Props {
    columns: ColumnProps<{ key: string | number }>[];
    fondTypeOptions: { label: string; value: string }[];
    onChange: (values: FondDataFormValues[]) => void;
    strategy?: { name: string; type: string };
    hazard?: HazardProfileFormValues | null;
    initialData?: FondDataFormValues[];
    currency?: string;
}

function FondTable({
    initialData,
    onChange,
    strategy,
    hazard,
    columns,
    fondTypeOptions,
    currency,
}: Props) {
    const { t } = useTranslation();
    const [modalVisibility, setModalVisibility] = useState(false);
    const [data, setData] = useState<FondDataFormValues[]>(initialData || []);
    const [selectedRow, setSelectedRow] = useState<null | number>(null);

    /* eslint-disable */
    useEffect(() => {
        onChange && onChange(data);
    }, [data]);
    /* eslint-enable */

    function handleAddNew() {
        setModalVisibility(true);
    }

    function handleSubmit(values: FondDataFormValues) {
        if (selectedRow === null) {
            setData([...data!, { ...values }]);
        } else {
            const newData = data;
            newData[selectedRow] = values;
            setData(newData);
            setSelectedRow(null);
        }
        setModalVisibility(false);
    }

    function handleClose() {
        setModalVisibility(false);
        setSelectedRow(null);
    }

    function handleEdit(index: number) {
        return () => {
            setSelectedRow(index);
            setModalVisibility(true);
        };
    }

    function handleDelete(index: number) {
        return () => {
            setData(data.filter((_e, i) => i !== index));
        };
    }

    const actionsColumn = [
        {
            key: 'action',
            render: (_v: any, _r: any, index: number) => (
                <span className="fond-table-actions">
                    <Icon onClick={handleEdit(index)} type="edit" />
                    <Icon onClick={handleDelete(index)} type="close-circle" />
                </span>
            ),
        },
    ];

    return (
        <>
            <Modal
                centered
                closable
                title={t('fondTable.title')}
                visible={modalVisibility}
                footer={null}
                destroyOnClose
                onCancel={handleClose}
            >
                <FondForm
                    onSubmit={handleSubmit}
                    strategy={strategy}
                    hazard={hazard}
                    initialValues={
                        selectedRow === null ? undefined : data[selectedRow]
                    }
                    fondTypeOptions={fondTypeOptions}
                    currency={currency}
                />
            </Modal>
            <Table
                className="fond-table"
                bordered
                columns={columns.concat(actionsColumn)}
                dataSource={data.map((d, index) => ({ ...d, key: index }))}
                pagination={false}
                footer={() => (
                    <Button type="primary" onClick={handleAddNew}>
                        {t('fondTable.add')}
                    </Button>
                )}
            />
        </>
    );
}

type FondsTableProps = Omit<Props, 'columns' | 'fondTypeOptions'>;

export function NewFondsTable({ currency, ...tableProps }: FondsTableProps) {
    const { t } = useTranslation();

    return (
        <FondTable
            {...tableProps}
            currency={currency}
            columns={[
                {
                    title: t('fondTable.columns.code'),
                    dataIndex: 'code',
                    key: 'code',
                    align: 'left',
                },
                {
                    title: t('fondTable.columns.buy'),
                    dataIndex: 'type',
                    key: 'buy',
                    align: 'center',
                    render: (value: string) => {
                        return value === 'buy' ? (
                            <Icon type="check-circle" />
                        ) : (
                            <Icon type="dash" />
                        );
                    },
                },
                {
                    title: t('fondTable.columns.sell'),
                    dataIndex: 'type',
                    key: 'sell',
                    align: 'center',
                    render: (value: string) => {
                        return value === 'sell' ? (
                            <Icon type="check-circle" />
                        ) : (
                            <Icon type="dash" />
                        );
                    },
                },
                {
                    title: t('fondTable.columns.hold'),
                    dataIndex: 'type',
                    key: 'hold',
                    align: 'center',
                    render: (value: string) => {
                        return value === 'hold' ? (
                            <Icon type="check-circle" />
                        ) : (
                            <Icon type="dash" />
                        );
                    },
                },
                {
                    title: `${t(
                        'fondTable.columns.regularAmount'
                    )} ${currency}`,
                    dataIndex: 'regularAmount',
                    key: 'regularAmount',
                    align: 'center',
                    render: (value: any) => {
                        return numbro(value).formatCurrency({});
                    },
                },
                {
                    title: `${t(
                        'fondTable.columns.oneTimeAmount'
                    )} ${currency}`,
                    dataIndex: 'oneTimeAmount',
                    key: 'oneTimeAmount',
                    align: 'center',
                    render: (value: any) => {
                        return numbro(value).formatCurrency();
                    },
                },
                {
                    title: t('fondTable.columns.agio'),
                    dataIndex: 'agio',
                    key: 'agio',
                    align: 'center',
                },
                {
                    title: t('fondTable.columns.note'),
                    dataIndex: 'note',
                    key: 'note',
                    align: 'right',
                },
            ]}
            fondTypeOptions={[
                {
                    label: t('fondTable.columns.buy'),
                    value: 'buy',
                },
                {
                    label: t('fondTable.columns.sell'),
                    value: 'sell',
                },
                {
                    label: t('fondTable.columns.hold'),
                    value: 'hold',
                },
            ]}
        />
    );
}

export function HeldFondsTable({ currency, ...tableProps }: FondsTableProps) {
    const { t } = useTranslation();

    return (
        <FondTable
            {...tableProps}
            currency={currency}
            columns={[
                {
                    title: t('fondTable.columns.code'),
                    dataIndex: 'code',
                    key: 'code',
                    align: 'left',
                },
                {
                    title: t('fondTable.columns.sell'),
                    dataIndex: 'type',
                    key: 'sell',
                    align: 'center',
                    render: (value: string) => {
                        return value === 'sell' ? (
                            <Icon type="check-circle" />
                        ) : (
                            <Icon type="dash" />
                        );
                    },
                },
                {
                    title: t('fondTable.columns.hold'),
                    dataIndex: 'type',
                    key: 'hold',
                    align: 'center',
                    render: (value: string) => {
                        return value === 'hold' ? (
                            <Icon type="check-circle" />
                        ) : (
                            <Icon type="dash" />
                        );
                    },
                },
                {
                    title: `${t(
                        'fondTable.columns.regularAmount'
                    )} ${currency}`,
                    dataIndex: 'regularAmount',
                    key: 'regularAmount',
                    align: 'center',
                    render: (value: any) => {
                        return numbro(value).formatCurrency({});
                    },
                },
                {
                    title: `${t(
                        'fondTable.columns.oneTimeAmount'
                    )} ${currency}`,
                    dataIndex: 'oneTimeAmount',
                    key: 'oneTimeAmount',
                    align: 'center',
                    render: (value: any) => {
                        return numbro(value).formatCurrency();
                    },
                },
                {
                    title: t('fondTable.columns.agio'),
                    dataIndex: 'agio',
                    key: 'agio',
                    align: 'center',
                },
                {
                    title: t('fondTable.columns.note'),
                    dataIndex: 'note',
                    key: 'note',
                    align: 'right',
                },
            ]}
            fondTypeOptions={[
                {
                    label: t('fondTable.columns.sell'),
                    value: 'sell',
                },
                {
                    label: t('fondTable.columns.hold'),
                    value: 'hold',
                },
            ]}
        />
    );
}
