import React from 'react';
import { Collapse } from 'antd';
import { useSelector, useStore } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';
import { PDFDownloadLink } from '@react-pdf/renderer';

import { Button } from 'common/components';
import { I18nNameSpaces } from 'common/services/i18n';
import { PdfRenderer } from 'common/pdf/pdf-renderer';
import { SignatureScope } from 'common/models/Transaction';

import { PageControls } from '../../../../components';
import {
    selectInformationsCustomerInfo1,
    selectInformationsCustomerInfo2,
    selectInformationsCompanyInfo,
    selectInvestingAgreements,
    selectInvestingAnalyse,
    selectInvestingExperience,
    selectInvestingHazard,
    selectStrategy,
    selectInvestingFinances,
} from '../../../../ducks';

export default function InvestingPDFFeature() {
    const history = useHistory();
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const match = useRouteMatch<{ id: string }>();
    const appState = useStore().getState();
    const pdfRenderer = new PdfRenderer(appState);

    const companyInfo = useSelector(selectInformationsCompanyInfo);
    const clientValues1 = useSelector(selectInformationsCustomerInfo1);
    const clientValues2 = useSelector(selectInformationsCustomerInfo2);

    const analyse = useSelector(selectInvestingAnalyse);
    const agreement = useSelector(selectInvestingAgreements);
    const hazard = useSelector(selectInvestingHazard);
    const investingExperience = useSelector(selectInvestingExperience);
    const strategy = useSelector(selectStrategy);
    const finances = useSelector(selectInvestingFinances);

    function handleBackClick() {
        history.push(`/form/${match!.params.id}/investing/agreements`);
    }

    function handleContinueClick() {
        history.push(`/form/${match!.params.id}/protocol`);
    }

    return (
        <>
            <div className="transaction-feature" style={{ margin: '30px 0' }}>
                <Collapse accordion destroyInactivePanel>
                    <Collapse.Panel
                        key="client1"
                        header={t('signatureForm.fileTypes.customerInfo1')}
                    >
                        {clientValues1 && companyInfo && agreement ? (
                            <PDFDownloadLink
                                document={pdfRenderer.renderDocument(
                                    SignatureScope.CUSTOMER_INFO_1
                                )}
                                fileName={t(
                                    'signatureForm.fileNames.customerInfo1'
                                )}
                            >
                                {({ loading }) => (
                                    <Button
                                        block
                                        disabled={loading}
                                        loading={loading}
                                    >
                                        {t(
                                            'signatureForm.fileTypes.customerInfo1'
                                        )}
                                    </Button>
                                )}
                            </PDFDownloadLink>
                        ) : (
                            t('signatureForm.fileTypes.valuesMissing')
                        )}
                    </Collapse.Panel>
                    <Collapse.Panel
                        key="client2"
                        header={t('signatureForm.fileTypes.customerInfo2')}
                    >
                        {clientValues2 && companyInfo && agreement ? (
                            <PDFDownloadLink
                                document={pdfRenderer.renderDocument(
                                    SignatureScope.CUSTOMER_INFO_2
                                )}
                                fileName={t(
                                    'signatureForm.fileNames.customerInfo2'
                                )}
                            >
                                {({ loading }) => (
                                    <Button
                                        block
                                        disabled={loading}
                                        loading={loading}
                                    >
                                        {t(
                                            'signatureForm.fileTypes.customerInfo2'
                                        )}
                                    </Button>
                                )}
                            </PDFDownloadLink>
                        ) : (
                            t('signatureForm.fileTypes.valuesMissing')
                        )}
                    </Collapse.Panel>
                    <Collapse.Panel
                        key="client3"
                        header={t('signatureForm.fileTypes.investing')}
                    >
                        {analyse &&
                        investingExperience &&
                        clientValues1 &&
                        companyInfo &&
                        agreement &&
                        hazard &&
                        investingExperience &&
                        strategy &&
                        finances ? (
                            <PDFDownloadLink
                                document={pdfRenderer.renderDocument(
                                    SignatureScope.INVESTING
                                )}
                                fileName={t(
                                    'signatureForm.fileNames.investing'
                                )}
                            >
                                {({ loading }) => (
                                    <Button
                                        block
                                        disabled={loading}
                                        loading={loading}
                                    >
                                        {t('signatureForm.fileTypes.investing')}
                                    </Button>
                                )}
                            </PDFDownloadLink>
                        ) : (
                            t('signatureForm.fileTypes.valuesMissing')
                        )}
                    </Collapse.Panel>
                </Collapse>
            </div>
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
