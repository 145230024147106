import React from 'react';
import { useTranslation } from 'react-i18next';
import { View } from '@react-pdf/renderer';

import {
    ConsultantConfirmationFormValues,
    CompanyInfoFormValues,
    ClientFormValues,
    MeetingFormValues,
} from '../models/Forms';

import {
    PDFParagraphBlock,
    PDFDivider,
    PDFCheckbox,
    PDFSignatureBlock,
    PDFBrandedPageWithFooter,
    PDFListItem,
} from './components';

import { I18nNameSpaces } from '../services/i18n';

interface Props {
    clientValues1: ClientFormValues;
    client1Signature?: string;
    clientValues2?: ClientFormValues;
    companyInfo: CompanyInfoFormValues;
    meeting: MeetingFormValues;
    consultantConfirmation: ConsultantConfirmationFormValues;
}

export default function MeetingPage({
    companyInfo,
    clientValues1,
    client1Signature,
    clientValues2,
    consultantConfirmation,
    meeting,
}: Props) {
    const { t: formT } = useTranslation(I18nNameSpaces.Forms);
    const { t } = useTranslation(I18nNameSpaces.PDF);
    const footerTitle = (
        <PDFParagraphBlock>{t('meeting.title')}</PDFParagraphBlock>
    );

    return (
        <>
            <PDFBrandedPageWithFooter
                footerTitle={footerTitle}
                footerContent={
                    <PDFSignatureBlock
                        date={consultantConfirmation.c.date}
                        signature={client1Signature}
                        name={
                            clientValues1
                                ? `${clientValues1.title} ${clientValues1.firstName} ${clientValues1.lastName} (Zákazník 1)`
                                : undefined
                        }
                        name2={
                            clientValues2
                                ? `${clientValues2.title} ${clientValues2.firstName} ${clientValues2.lastName} (Zákazník 2)`
                                : undefined
                        }
                        signature3={`${companyInfo.firstName} ${companyInfo.lastName}`}
                        name3={
                            companyInfo
                                ? `${companyInfo.title} ${
                                      companyInfo.firstName
                                  } ${companyInfo.lastName} ${t(
                                      'signatureBlock.manager'
                                  )}`
                                : undefined
                        }
                        place={consultantConfirmation.c.city}
                        time={consultantConfirmation.c.interviewDuration}
                    />
                }
            >
                <View>
                    <PDFParagraphBlock.Title size="large" bold underline blue>
                        {t('meeting.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <View>
                        <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                            <View style={{ flexGrow: 2 }}>
                                <PDFDivider />
                                <PDFListItem
                                    label={t('meeting.q1.fields.a')}
                                    value={`${companyInfo.title} ${companyInfo.firstName} ${companyInfo.lastName}`}
                                />
                                <PDFDivider />
                                <PDFListItem
                                    label={t('meeting.q1.fields.b')}
                                    value={unescape(companyInfo.address)}
                                />
                                <PDFDivider />
                                <PDFListItem
                                    label={t('meeting.q1.fields.c')}
                                    value={companyInfo.partnerNumber}
                                />
                                <PDFDivider />
                            </View>
                            <View style={{ flexGrow: 2 }}>
                                <PDFDivider />
                                <PDFListItem
                                    label={t('meeting.q1.fields.d')}
                                    value={companyInfo.phone}
                                />
                                <PDFDivider />
                                <PDFListItem
                                    label={t('meeting.q1.fields.e')}
                                    value={companyInfo.email}
                                />
                                <PDFDivider />
                                <PDFListItem
                                    label={t('meeting.q1.fields.f')}
                                    value={companyInfo.ico}
                                />
                                <PDFDivider />
                            </View>
                        </View>
                    </View>
                    <PDFDivider />
                    <PDFDivider />
                    <PDFDivider />
                    <PDFDivider />
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexGrow: 2 }}>
                            <PDFParagraphBlock.Title
                                size="medium"
                                bold
                                underline
                                blue
                            >
                                {t('meeting.userBox.title', { number: 1 })}
                            </PDFParagraphBlock.Title>
                            <PDFListItem
                                label={t('meeting.userBox.fields.a')}
                                value={formT(
                                    `clientForm.gender.options.${clientValues1.gender}`
                                )}
                            />
                            <PDFListItem
                                label={t('meeting.userBox.fields.b')}
                                value={`${clientValues1.title} ${clientValues1.firstName} ${clientValues1.lastName}`}
                            />
                            <PDFParagraphBlock bold>
                                {t('meeting.userBox.fields.addressTitle')}
                            </PDFParagraphBlock>
                            <PDFListItem
                                label={t('meeting.userBox.fields.c')}
                                value={`${clientValues1.street} ${clientValues1.streetNumber}`}
                            />
                            <View
                                style={{
                                    flexDirection: 'row',
                                    height: 'auto',
                                }}
                            >
                                <View style={{ flexGrow: 3 }}>
                                    <PDFListItem
                                        label={t('meeting.userBox.fields.d')}
                                        value={clientValues1.city}
                                    />
                                </View>
                                <View style={{ flexGrow: 3 }}>
                                    <PDFListItem
                                        label={t('meeting.userBox.fields.e')}
                                        value={clientValues1.psc}
                                    />
                                </View>
                                <View style={{ flexGrow: 3 }}>
                                    <PDFListItem
                                        label={t('meeting.userBox.fields.f')}
                                        value={clientValues1.state}
                                    />
                                </View>
                            </View>
                            <PDFDivider />
                            <PDFListItem
                                label={t('meeting.userBox.fields.g')}
                                value={clientValues1?.birthday?.date}
                            />
                        </View>
                        <View style={{ flexGrow: 2 }}>
                            <PDFParagraphBlock.Title
                                size="medium"
                                bold
                                underline
                                blue
                            >
                                {t('meeting.userBox.title', { number: 2 })}
                            </PDFParagraphBlock.Title>
                            <PDFListItem
                                label={t('meeting.userBox.fields.a')}
                                value={
                                    clientValues2
                                        ? formT(
                                              `clientForm.gender.options.${clientValues2.gender}`
                                          )
                                        : ''
                                }
                            />
                            <PDFListItem
                                label={t('meeting.userBox.fields.b')}
                                value={
                                    clientValues2
                                        ? `${clientValues2.title} ${clientValues2.firstName} ${clientValues2.lastName}`
                                        : ''
                                }
                            />
                            <PDFParagraphBlock bold>
                                {t('meeting.userBox.fields.addressTitle')}
                            </PDFParagraphBlock>
                            <PDFListItem
                                label={t('meeting.userBox.fields.c')}
                                value={`${clientValues2?.street ?? ''} ${
                                    clientValues2?.streetNumber ?? ''
                                }`}
                            />
                            <View
                                style={{
                                    flexDirection: 'row',
                                    height: 'auto',
                                }}
                            >
                                <View style={{ flexGrow: 3 }}>
                                    <PDFListItem
                                        label={t('meeting.userBox.fields.d')}
                                        value={clientValues2?.city ?? ''}
                                    />
                                </View>
                                <View style={{ flexGrow: 3 }}>
                                    <PDFListItem
                                        label={t('meeting.userBox.fields.e')}
                                        value={clientValues2?.psc ?? ''}
                                    />
                                </View>
                                <View style={{ flexGrow: 3 }}>
                                    <PDFListItem
                                        label={t('meeting.userBox.fields.f')}
                                        value={clientValues2?.state ?? ''}
                                    />
                                </View>
                            </View>
                            <PDFDivider />
                            <PDFListItem
                                label={t('meeting.userBox.fields.g')}
                                value={clientValues2?.birthday?.date}
                            />
                        </View>
                    </View>
                    <PDFDivider />
                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock.Title size="medium" bold underline blue>
                        {t('meeting.q2.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <PDFCheckbox
                        checked={meeting.a}
                        label={t('meeting.q2.options.a')}
                    />
                    <PDFCheckbox
                        checked={meeting.b}
                        label={t('meeting.q2.options.b')}
                    />
                    <PDFDivider />
                    <PDFDivider />
                    <View
                        wrap={false}
                        style={{
                            border: '1 solid black',
                            padding: '10',
                            minHeight: 170,
                        }}
                    >
                        <PDFParagraphBlock>{meeting.note}</PDFParagraphBlock>
                    </View>
                    <PDFDivider />
                    <PDFDivider />
                </View>
            </PDFBrandedPageWithFooter>
        </>
    );
}
