import React from 'react';
import { Input } from 'antd';

interface Props {
    onSearchClicked: (value: string) => void;
    buttonText: string;
    placeholder?: string;
}

export default function SearchBox({
    placeholder = '',
    onSearchClicked,
    buttonText,
}: Props) {
    return (
        <Input.Search
            size="large"
            placeholder={placeholder}
            onSearch={onSearchClicked}
            enterButton={buttonText}
            allowClear
        />
    );
}
