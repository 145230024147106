import React from 'react';
import { WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps, Route, Switch } from 'react-router';

import PasswordResetRecovery from './features/recovery';
import PasswordResetRequest from './features/request';

type Props = RouteComponentProps & WithTranslation;

function ForgottenPasswordFeature({ match }: Props) {
    return (
        <Switch>
            <Route
                exact
                path={`${match.path}/`}
                component={PasswordResetRequest}
            />
            <Route
                exact
                path={`${match.path}/recovery`}
                component={PasswordResetRecovery}
            />
        </Switch>
    );
}

export default withRouter(ForgottenPasswordFeature);
