import React from 'react';
import { QuestionTitle, PageControls } from '../../components';
import { useTranslation } from 'react-i18next';
import { QuestionWrapper } from 'common/components';
import { BestOfTempletonAccountCreationForm } from 'common/forms';
import { FormikProps } from 'formik';
import { BestOfTempletonAccountFormValues } from 'common/models/Forms';
import { useHistory, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectStrategy,
    setBestOfTempletonAccountInfo,
    selectBestOfTempletonAccountInfo,
    selectInvestingExperience,
    selectInvestingHazard,
} from 'features/form/ducks';

export default function BestOfTempletonAccountFeature() {
    const { t } = useTranslation();
    const strategy = useSelector(selectStrategy);
    const history = useHistory();
    const params = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectBestOfTempletonAccountInfo);
    const investingExperienceValues = useSelector(selectInvestingExperience);
    const hazard = useSelector(selectInvestingHazard);

    let submitForm: FormikProps<BestOfTempletonAccountFormValues>['submitForm'];

    function bindSubmit(
        cb: FormikProps<BestOfTempletonAccountFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(values: BestOfTempletonAccountFormValues) {
        dispatch(setBestOfTempletonAccountInfo(values));
        history.push(`/form/${params.id}/transaction`);
    }

    function handleContinueClick() {
        submitForm();
    }

    function handleBackClick() {
        history.goBack();
    }

    return (
        <>
            <QuestionTitle>{t('bestOfTempletonFeature.title')}</QuestionTitle>
            <QuestionWrapper>
                <BestOfTempletonAccountCreationForm
                    initialValues={initialValues || undefined}
                    investingExperienceValues={
                        investingExperienceValues || undefined
                    }
                    onSubmit={handleSubmit}
                    bindSubmit={bindSubmit}
                    strategy={strategy}
                    hazard={hazard || undefined}
                />
            </QuestionWrapper>
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
