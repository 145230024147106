import React from 'react';
import { Tooltip } from 'antd';
import { TooltipProps } from 'antd/lib/tooltip';

type Props = TooltipProps;

export default function EnhancedTooltip({
    placement = 'bottom',
    ...rest
}: Props) {
    return <Tooltip placement={placement} {...rest} />;
}
