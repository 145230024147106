import React from 'react';
import {
    withTranslation,
    WithTranslation,
    useTranslation,
} from 'react-i18next';
import {
    Route,
    RouteComponentProps,
    Redirect,
    Switch,
    useHistory,
} from 'react-router';
import { connect, useSelector } from 'react-redux';
import useMount from 'react-use/lib/useMount';
import { Skeleton, Row, Col, Icon } from 'antd';

import { Layout, Header, Steps } from 'common/components';
import { AppState } from 'common/models/AppState';

import InformationsFeature from './features/informations';
import InvestingFeature from './features/investing';
import ProtocolFeature from './features/protocol';
import AgreementFeature from './features/agreement';
import TransactionFeature from './features/transaction';
import BestOfTempletonFeature from './features/BoT';
import VerificationFeature from './features/verification';
import {
    loadTransactionActions,
    FormState,
    selectFormTransaction,
    selectFormInformationsDone,
    selectInvestingDone,
    selectProtocolDone,
    selectAgreementDone,
    selectBestOfTempletonDone,
    selectStrategy,
    InvestingStrategy,
    selectIsFormVerified,
    selectInformationsCustomerInfo1,
    selectInformationsCustomer1FullName,
} from './ducks';

interface DispatchToProps {
    loadTransaction: typeof loadTransactionActions.request;
}

interface StateToProps {
    transaction: FormState['transaction'];
}

type Props = DispatchToProps &
    StateToProps &
    WithTranslation &
    RouteComponentProps<{ id: string }>;

export interface FeatureProps {
    isPrivileged?: boolean;
}

function FormFeature({ match, loadTransaction, transaction }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const { isVerifiedEmail, isVerifiedPhone } = useSelector(
        selectIsFormVerified
    );

    const verificationDone = isVerifiedEmail && isVerifiedPhone;
    const informationsDone = useSelector(selectFormInformationsDone);
    const investingDone = useSelector(selectInvestingDone);
    const protocolDone = useSelector(selectProtocolDone);
    const agreementDone = useSelector(selectAgreementDone);
    const bestOfTempletonDone = useSelector(selectBestOfTempletonDone);
    const strategy = useSelector(selectStrategy);
    const customerInfo = useSelector(selectInformationsCustomerInfo1);
    const customerFullName = useSelector(selectInformationsCustomer1FullName);

    useMount(() => {
        loadTransaction(match.params.id);
    });

    function handleStepClick(step: string) {
        return () => {
            if (step === 'verification') {
                history.push(`/form/${match!.params.id}/verification`);
            } else if (step === 'informations' && informationsDone) {
                history.push(`/form/${match!.params.id}/informations`);
            } else if (step === 'investing' && investingDone) {
                history.push(`/form/${match!.params.id}/investing`);
            } else if (step === 'protocol' && protocolDone) {
                history.push(`/form/${match!.params.id}/protocol`);
            } else if (step === 'agreement' && agreementDone) {
                history.push(`/form/${match!.params.id}/agreement`);
            } else if (
                step === 'BoT' &&
                bestOfTempletonDone &&
                strategy &&
                strategy.type !== InvestingStrategy.Conservative
            ) {
                history.push(`/form/${match!.params.id}/BoT`);
            }
        };
    }

    return (
        <Layout>
            <Header type="tall">
                <Row type="flex" align="top">
                    <Col span={21}>
                        <Steps size="small">
                            <Steps.Step
                                className="steps-item-skippable"
                                onClick={handleStepClick('verification')}
                                key="0"
                                description={t('tabs.verification')}
                                status={verificationDone ? 'finish' : 'process'}
                            />
                            <Steps.Step
                                onClick={handleStepClick('informations')}
                                key="1"
                                description={t('tabs.informations')}
                                status={informationsDone ? 'finish' : 'process'}
                            />
                            <Steps.Step
                                onClick={handleStepClick('investing')}
                                key="2"
                                description={t('tabs.investing')}
                                status={investingDone ? 'finish' : 'process'}
                            />
                            <Steps.Step
                                onClick={handleStepClick('protocol')}
                                key="3"
                                description={t('tabs.protocol')}
                                status={protocolDone ? 'finish' : 'process'}
                            />
                            <Steps.Step
                                onClick={handleStepClick('agreement')}
                                key="4"
                                description={t('tabs.agreement')}
                                status={agreementDone ? 'finish' : 'process'}
                            />
                            {strategy &&
                                strategy.type !==
                                    InvestingStrategy.Conservative && (
                                    <Steps.Step
                                        onClick={handleStepClick('BoT')}
                                        key="5"
                                        description={t('tabs.bestOfTempleton')}
                                        status={
                                            bestOfTempletonDone
                                                ? 'finish'
                                                : 'process'
                                        }
                                    />
                                )}
                        </Steps>
                    </Col>
                    {customerFullName && (
                        <Col
                            className="form-progress-client-info"
                            span={0}
                            md={3}
                        >
                            <p>
                                <Icon type="profile" /> {customerFullName}
                            </p>

                            {customerInfo?.birthday && (
                                <p>{customerInfo.birthday.date}</p>
                            )}
                        </Col>
                    )}
                </Row>
            </Header>
            <Layout.Content>
                {transaction ? (
                    <Layout.Container>
                        <Switch>
                            <Route
                                path={`${match.path}/verification`}
                                component={VerificationFeature}
                            />
                            <Route
                                path={`${match.path}/informations`}
                                component={InformationsFeature}
                            />
                            <Route
                                path={`${match.path}/investing`}
                                component={InvestingFeature}
                            />
                            <Route
                                path={`${match.path}/protocol`}
                                component={ProtocolFeature}
                            />
                            <Route
                                path={`${match.path}/agreement`}
                                component={AgreementFeature}
                            />
                            <Route
                                path={`${match.path}/transaction`}
                                component={TransactionFeature}
                            />
                            <Route
                                path={`${match.path}/BoT`}
                                component={BestOfTempletonFeature}
                            />
                            <Redirect to={`${match.path}/verification`} />
                        </Switch>
                    </Layout.Container>
                ) : (
                    <Skeleton />
                )}
            </Layout.Content>
        </Layout>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    transaction: selectFormTransaction(state),
});

const mapDispatchToProps: DispatchToProps = {
    loadTransaction: loadTransactionActions.request,
};

export default withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(FormFeature)
);
