import React, { ReactNode } from 'react';

import { QuestionWrapper, Card } from '../../components';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';
import numbro from 'numbro';

interface Props {
    monthlyInvestingPlan: ReactNode;
    oneTimeProvision: ReactNode;
    monthlyProvision: ReactNode;
    oneTimeInvesting: ReactNode;
}

export default function AnalyseConclusion(props: Props) {
    const { t } = useTranslation();
    return (
        <div className="analyse-conclusion">
            <QuestionWrapper>
                <QuestionWrapper.Divider>
                    <Card>
                        <Row gutter={30}>
                            <Col xs={12}>
                                <Typography.Paragraph>
                                    {t(
                                        'analyseConclusion.monthlyInvestingPlan'
                                    )}
                                </Typography.Paragraph>
                                <Typography.Title level={4}>
                                    {numbro(
                                        props.monthlyInvestingPlan
                                    ).formatCurrency()}
                                </Typography.Title>
                            </Col>
                            <Col xs={12}>
                                <Typography.Paragraph>
                                    {t('analyseConclusion.oneTimeInvesting')}
                                </Typography.Paragraph>
                                <Typography.Title level={4}>
                                    {numbro(
                                        props.oneTimeInvesting
                                    ).formatCurrency()}
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Card>
                    <QuestionWrapper.Title>
                        {t('analyseConclusion.forPartnerTitle')}
                    </QuestionWrapper.Title>
                    <Card>
                        <Row gutter={30}>
                            <Col xs={24} md={12}>
                                <Typography.Paragraph>
                                    {t('analyseConclusion.oneTimeProvision')}
                                </Typography.Paragraph>
                                <Typography.Title level={4}>
                                    {numbro(
                                        props.oneTimeProvision
                                    ).formatCurrency()}
                                </Typography.Title>
                            </Col>
                            <Col xs={24} md={12}>
                                <Typography.Paragraph>
                                    {t('analyseConclusion.monthlyProvision')}
                                </Typography.Paragraph>
                                <Typography.Title level={4}>
                                    {numbro(
                                        props.monthlyProvision
                                    ).formatCurrency()}
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Card>
                </QuestionWrapper.Divider>
            </QuestionWrapper>
        </div>
    );
}
