import React, { ReactNode } from 'react';
import cn from 'classnames';

interface Props {
    type?: 'success' | 'error';
    children: string | ReactNode;
}

export default function ResultMessage({ children, type, ...props }: Props) {
    return (
        <p
            className={cn('form-result-message', {
                error: type === 'error',
                success: type === 'success',
            })}
            {...props}
        >
            {children}
        </p>
    );
}
