import React from 'react';
import { Row, Col } from 'antd';

import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';

interface Props {
    disableContinue?: boolean;
    onBack?: () => void;
    onContinue?: () => void;
    onSkip?: () => void;
}

export default function PageControls({
    disableContinue,
    onBack,
    onContinue,
    onSkip,
}: Props) {
    const { t } = useTranslation();
    return (
        <Row type="flex" justify="end" gutter={10} style={{ margin: '40px 0' }}>
            {onSkip && (
                <Col>
                    <Button onClick={onSkip} size="large">
                        {t('informationsFeature.skip')}
                    </Button>
                </Col>
            )}
            <Col>
                <Button onClick={onBack} size="large">
                    {t('informationsFeature.back')}
                </Button>
            </Col>
            <Col>
                <Button
                    type="primary"
                    onClick={onContinue}
                    size="large"
                    disabled={disableContinue}
                >
                    {t('informationsFeature.continue')}
                </Button>
            </Col>
        </Row>
    );
}
