import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import cn from 'classnames';
import React, { ReactNode } from 'react';

import { Control } from '../Control';

interface Props extends TextAreaProps {
    label?: ReactNode;
    error?: ReactNode;
}

export default ({ label, error, ...bag }: Props) => (
    <Control label={label} error={error}>
        <Input.TextArea
            className={cn({
                'ant-input-warning': error,
            })}
            {...bag}
        />
    </Control>
);
