import {
    ActionType,
    createAction,
    createReducer,
    getType,
} from 'typesafe-actions';
import { call, delay, put, select, take, takeLatest } from 'redux-saga/effects';

import {
    ClientVerificationFormEmailValues,
    ClientVerificationFormPhoneValues,
} from 'common/models/Forms';
import { AppState } from 'common/models/AppState';
import { TokenDeliveryMethod, TokenType } from 'common/models/Token';
import {
    loadTransactionActions,
    selectForm,
    selectFormTransaction,
    selectInformationsCustomerInfo1,
    setInformationsClient1InfoValuesPartialAction,
} from 'features/form/ducks';
import api from 'common/api';
import { TokenState } from 'common/api/token';
import { selectIsPrivileged } from 'features/auth/ducks';

export function selectClientVerification(state: AppState) {
    return selectForm(state).verification;
}

export const resetClientVerificationAction = createAction(
    '@@Verification/RESET'
);

export const requestEmailVerificationCodeAction = createAction(
    '@@Verification/REQUEST_EMAIL_CODE',
    action => (values: ClientVerificationFormEmailValues['email']) =>
        action({ email: values })
);

export const requestPhoneVerificationCodeAction = createAction(
    '@@Verification/REQUEST_SMS_CODE',
    action => (values: ClientVerificationFormPhoneValues['phone']) =>
        action({ phone: values })
);

export const submitPhoneVerificationCodeAction = createAction(
    '@@Verification/SUBMIT_SMS_CODE',
    action => (values: ClientVerificationFormPhoneValues['code']) =>
        action(values)
);

export const submitEmailVerificationCodeAction = createAction(
    '@@Verification/SUBMIT_EMAIL_CODE',
    action => (values: ClientVerificationFormEmailValues['code']) =>
        action(values)
);

const changePhoneVerificationStateAction = createAction(
    '@@Verification/CHANGE_SMS_VERIFICATION_STATE',
    action => (values: TokenState) => action(values)
);

const changeEmailVerificationStateAction = createAction(
    '@@Verification/CHANGE_EMAIL_VERIFICATION_STATE',
    action => (values: TokenState) => action(values)
);

const updatePhoneVerificationStateAction = createAction(
    '@@Verification/UPDATE_SMS_VERIFICATION_STATE',
    action => (values: Partial<VerificationFormState['phone']>) =>
        action(values)
);

const updateEmailVerificationStateAction = createAction(
    '@@Verification/UPDATE_EMAIL_VERIFICATION_STATE',
    action => (values: Partial<VerificationFormState['email']>) =>
        action(values)
);

export const initialVerificationFormState = {
    phone: {
        status: TokenState.DEFAULT,
    },
    email: {
        status: TokenState.DEFAULT,
    },
};

export interface VerificationFormState {
    phone: {
        status: TokenState;
        attemptsLeft?: number;
        httpStatus?: number;
    };
    email: {
        status: TokenState;
        attemptsLeft?: number;
        httpStatus?: number;
    };
}

type VerificationFormActions = ActionType<
    | typeof updatePhoneVerificationStateAction
    | typeof updateEmailVerificationStateAction
    | typeof resetClientVerificationAction
    | typeof changeEmailVerificationStateAction
    | typeof changePhoneVerificationStateAction
>;

export default createReducer<VerificationFormState, VerificationFormActions>(
    initialVerificationFormState
)
    .handleAction(updatePhoneVerificationStateAction, (state, { payload }) => ({
        ...state,
        phone: {
            ...state.phone,
            ...payload,
        },
    }))
    .handleAction(updateEmailVerificationStateAction, (state, { payload }) => ({
        ...state,
        email: {
            ...state.email,
            ...payload,
        },
    }))
    .handleAction(changeEmailVerificationStateAction, (state, { payload }) => ({
        ...state,
        email: { status: payload },
    }))
    .handleAction(changePhoneVerificationStateAction, (state, { payload }) => ({
        ...state,
        phone: { status: payload },
    }))
    .handleAction(
        resetClientVerificationAction,
        () => initialVerificationFormState
    );

function* watchRequestVerificationCode(
    deliveryMethod: TokenDeliveryMethod,
    {
        payload,
    }: ReturnType<typeof setInformationsClient1InfoValuesPartialAction>
) {
    yield put(
        deliveryMethod === TokenDeliveryMethod.PHONE
            ? changePhoneVerificationStateAction(TokenState.REQUESTED)
            : changeEmailVerificationStateAction(TokenState.REQUESTED)
    );
    // Prevent accidental double-click submissions
    yield delay(250);

    // Update the user data first, and only then ask for the code to be sent
    const isPrivileged = yield select(selectIsPrivileged);

    if (!isPrivileged) {
        yield put(setInformationsClient1InfoValuesPartialAction(payload));
        yield take([
            loadTransactionActions.success,
            loadTransactionActions.failure,
        ]);
    }

    const transaction = yield select(selectFormTransaction);
    const { ok, status } = yield call(
        api.token.create,
        transaction._id,
        TokenType.VERIFICATION,
        deliveryMethod
    );
    const newState = {
        status: ok ? TokenState.DELIVERED : TokenState.DELIVERY_ERROR,
        httpStatus: status,
    };

    yield put(
        deliveryMethod === TokenDeliveryMethod.PHONE
            ? updatePhoneVerificationStateAction(newState)
            : updateEmailVerificationStateAction(newState)
    );
}

function* watchSubmitVerificationCode(
    deliveryMethod: TokenDeliveryMethod,
    { payload }: ReturnType<typeof submitPhoneVerificationCodeAction>
) {
    yield put(
        deliveryMethod === TokenDeliveryMethod.PHONE
            ? changePhoneVerificationStateAction(TokenState.SUBMITTED)
            : changeEmailVerificationStateAction(TokenState.SUBMITTED)
    );

    const transaction = yield select(selectFormTransaction);
    const { phone, email } = yield select(selectInformationsCustomerInfo1);
    const { ok, status, response } = yield call(
        api.token.verify,
        transaction._id,
        TokenType.VERIFICATION,
        deliveryMethod,
        deliveryMethod === TokenDeliveryMethod.PHONE
            ? `${phone.prefix}${phone.number}`
            : email,
        payload
    );
    const newState = {
        status: ok ? TokenState.VERIFIED : TokenState.SUBMISSION_ERROR,
        httpStatus: status,
        attemptsLeft: response?.data?.attemptsLeft,
    };

    yield put(
        deliveryMethod === TokenDeliveryMethod.PHONE
            ? updatePhoneVerificationStateAction(newState)
            : updateEmailVerificationStateAction(newState)
    );

    // Load the transaction again to reflect the updated verification state set by BE
    yield put(loadTransactionActions.request(transaction._id));
}

export function* verificationSaga() {
    yield takeLatest(
        [getType(requestPhoneVerificationCodeAction)],
        watchRequestVerificationCode,
        TokenDeliveryMethod.PHONE
    );
    yield takeLatest(
        [getType(requestEmailVerificationCodeAction)],
        watchRequestVerificationCode,
        TokenDeliveryMethod.EMAIL
    );
    yield takeLatest(
        [getType(submitPhoneVerificationCodeAction)],
        watchSubmitVerificationCode,
        TokenDeliveryMethod.PHONE
    );
    yield takeLatest(
        [getType(submitEmailVerificationCodeAction)],
        watchSubmitVerificationCode,
        TokenDeliveryMethod.EMAIL
    );
}
