import React, { ReactNode } from 'react';
import { Button, Row, Col } from 'antd';
import { Layout, Logo } from '../../components';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { environment } from '../../../environments/environment';

interface Props {
    logged?: boolean;
    children?: ReactNode;
    onLogout?: () => void;
    type?: 'tall' | 'default';
}

export default function Header({ type, logged, onLogout, children }: Props) {
    const { t } = useTranslation();
    function handleChangeLanguage() {
        i18next.changeLanguage(i18next.language === 'sk' ? 'cz' : 'sk');
    }
    return (
        <Layout.Header type={type}>
            <Row
                type="flex"
                justify={logged || children ? 'space-between' : 'start'}
            >
                <Col span={4}>
                    <Logo />
                </Col>
                {children && <Col span={20}>{children}</Col>}
                {logged && (
                    <Col>
                        <Button onClick={onLogout} size="default">
                            {t('logout')}
                        </Button>
                        {!environment.production && (
                            <Button
                                style={{ marginLeft: '10px' }}
                                type="primary"
                                onClick={handleChangeLanguage}
                                size="default"
                            >
                                {t('languages.select')}
                            </Button>
                        )}
                    </Col>
                )}
            </Row>
        </Layout.Header>
    );
}
