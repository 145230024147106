import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { ConsultantConfirmationForm } from 'common/forms';
import { ConsultantConfirmationFormValues } from 'common/models/Forms';

import { QuestionTitle, PageControls } from '../../../../components';
import {
    selectProtocolConsultantConfirmation,
    setProtocolConsultantConfirmationValuesAction,
    selectInvestingAgreements,
    syncInvestingAgreementsWithProtocolAction,
} from '../../../../ducks';
import { FeatureProps } from '../../../../index';

export default function ProtocolConsultantConfirmationFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch<{ id: string }>();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectProtocolConsultantConfirmation);
    const agreementValues = useSelector(selectInvestingAgreements);

    let submitForm: FormikProps<ConsultantConfirmationFormValues>['submitForm'];

    function handleBackClick() {
        history.push(
            `/form/${match!.params.id}/protocol/customer-confirmation`
        );
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(
        cb: FormikProps<ConsultantConfirmationFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(values: ConsultantConfirmationFormValues) {
        if (!isPrivileged) {
            dispatch(setProtocolConsultantConfirmationValuesAction(values));
            dispatch(syncInvestingAgreementsWithProtocolAction(values.b));
        }
        history.push(`/form/${match!.params.id}/agreement`);
    }

    return (
        <>
            <QuestionTitle>
                {t('protocolFeature.consultantConfirmation')}
            </QuestionTitle>
            <ConsultantConfirmationForm
                initialValues={{
                    a: initialValues?.a,
                    b: {
                        place: initialValues?.b?.place ?? '',
                        date: agreementValues?.dateOfSignature ?? '',
                        interviewDuration:
                            initialValues?.b?.interviewDuration ??
                            agreementValues?.interviewTime ??
                            '',
                        attendants: initialValues?.b?.attendants ?? '',
                        city:
                            initialValues?.b?.city ??
                            agreementValues?.placeOfSignature ??
                            '',
                    },
                    c: initialValues?.c,
                }}
                bindSubmit={bindSubmit}
                onSubmit={handleSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
