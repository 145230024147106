import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper } from '../../components';
import { InputField, CheckBoxField } from '../../fields';
import { BusinessPartnerInfoFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import { Row, Col } from 'antd';

interface Props {
    initialValues?: Partial<BusinessPartnerInfoFormValues>;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: BusinessPartnerInfoFormValues) => void;
}

export default function BusinessPartnerInfo({
    initialValues,
    bindSubmit,
    onSubmit,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<BusinessPartnerInfoFormValues>({
        a: Yup.string().required(t('form.required')),
        b: Yup.string().required(t('form.required')),
        c: Yup.string().required(t('form.required')),
        d: Yup.boolean().required(t('form.required')),
        e: Yup.string(),
        f: Yup.string(),
        g: Yup.string(),
        h: Yup.string(),
    });

    function handleOnSubmit(values: BusinessPartnerInfoFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({
        submitForm,
        values,
    }: FormikProps<BusinessPartnerInfoFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <Row gutter={20}>
                                <Col xs={24} md={8}>
                                    <InputField
                                        name="a"
                                        label={t(
                                            'businessPartnerInfoForm.a.label'
                                        )}
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <InputField
                                        name="b"
                                        label={t(
                                            'businessPartnerInfoForm.b.label'
                                        )}
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <InputField
                                        name="c"
                                        label={t(
                                            'businessPartnerInfoForm.c.label'
                                        )}
                                    />
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <CheckBoxField
                                name="d"
                                label={t('businessPartnerInfoForm.d.label')}
                                onChange={(isSelected, form) =>
                                    !isSelected &&
                                    form?.setValues({
                                        ...form.values,
                                        e: '',
                                        f: '',
                                        g: '',
                                        h: '',
                                    })
                                }
                            />
                            {values.d && (
                                <>
                                    <Row gutter={20}>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="e"
                                                label={t(
                                                    'businessPartnerInfoForm.e.label'
                                                )}
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="f"
                                                label={t(
                                                    'businessPartnerInfoForm.f.label'
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                    <Row gutter={20}>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="g"
                                                label={t(
                                                    'businessPartnerInfoForm.g.label'
                                                )}
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="h"
                                                label={t(
                                                    'businessPartnerInfoForm.h.label'
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a ?? '',
                b: initialValues?.b ?? '',
                c: initialValues?.c ?? '',
                d: initialValues?.d ?? false,
                e: initialValues?.e ?? '',
                f: initialValues?.f ?? '',
                g: initialValues?.g ?? '',
                h: initialValues?.h ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
