import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation, Trans } from 'react-i18next';

import { QuestionWrapper } from '../../components';
import { RadioField } from '../../fields';
import { HazardProfileFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import i18next from 'i18next';

export enum VolatilityOptions {
    Lowest = 'a',
    UpTo02Percent = 'b',
    UpTo05Percent = 'c',
    UpTo10Percent = 'd',
    UpTo15Percent = 'e',
    UpTo25Percent = 'f',
    Unlimited = 'g',
}

interface Props {
    initialValues?: Partial<HazardProfileFormValues>;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: HazardProfileFormValues) => void;
}

export default function HazardProfileForm({
    onSubmit,
    bindSubmit,
    initialValues,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<HazardProfileFormValues>({
        a: Yup.string().required(t('form.required')),
        b: Yup.string().required(t('form.required')),
        c: Yup.string().required(t('form.required')),
        d: Yup.string().required(t('form.required')),
    });

    function handleOnSubmit(values: HazardProfileFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({ submitForm }: FormikProps<HazardProfileFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                <Trans
                                    ns={I18nNameSpaces.Forms}
                                    i18nKey="hazardProfileForm.a.label"
                                >
                                    Který ze scénářů pravděpodobného vývoje
                                    hodnoty Vaší investice pro zvolený časový
                                    horizont, během, kterého nebudete své
                                    prostředky patrně potřebovat, je Vám
                                    nejbližší?
                                    <a
                                        href={
                                            i18next.language === 'sk'
                                                ? '/priloha-sk.pdf'
                                                : '/priloha-cz.pdf'
                                        }
                                        rel="noopener noreferrer"
                                        download
                                        target="_blank"
                                    >
                                        (viz příloha k Inv. Dotazníku)
                                    </a>
                                </Trans>
                            </QuestionWrapper.Title>
                            <RadioField
                                name="a"
                                type="horizontal"
                                options={[
                                    {
                                        label: t(
                                            'hazardProfileForm.a.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.a.options.b'
                                        ),
                                        value: 'b',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.a.options.c'
                                        ),
                                        value: 'c',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.a.options.d'
                                        ),
                                        value: 'd',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('hazardProfileForm.b.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="b"
                                type="vertical"
                                options={[
                                    {
                                        label: t(
                                            'hazardProfileForm.b.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.b.options.b'
                                        ),
                                        value: 'b',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.b.options.c'
                                        ),
                                        value: 'c',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.b.options.d'
                                        ),
                                        value: 'd',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('hazardProfileForm.c.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="c"
                                type="horizontal"
                                options={Object.values(VolatilityOptions).map(
                                    value => ({
                                        label: t(
                                            `hazardProfileForm.c.options.${value}`
                                        ),
                                        value,
                                    })
                                )}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('hazardProfileForm.d.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="d"
                                type="horizontal"
                                options={[
                                    {
                                        label: t(
                                            'hazardProfileForm.d.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.d.options.b'
                                        ),
                                        value: 'b',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.d.options.c'
                                        ),
                                        value: 'c',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.d.options.d'
                                        ),
                                        value: 'd',
                                    },
                                    {
                                        label: t(
                                            'hazardProfileForm.d.options.e'
                                        ),
                                        value: 'e',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a ?? '',
                b: initialValues?.b ?? '',
                c: initialValues?.c ?? '',
                d: initialValues?.d ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
