import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { HazardProfileForm } from 'common/forms';
import { HazardProfileFormValues } from 'common/models/Forms';

import { QuestionTitle, PageControls } from '../../../../components';
import {
    selectInvestingHazard,
    setInvestingHazardValuesAction,
} from '../../../../ducks';
import { FeatureProps } from '../../../../index';

export default function InvestingHazardFeature({ isPrivileged }: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch<{ id: string }>();
    const initialValues = useSelector(selectInvestingHazard);

    let submitForm: FormikProps<HazardProfileFormValues>['submitForm'];

    function handleBackClick() {
        history.goBack();
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(
        cb: FormikProps<HazardProfileFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(values: HazardProfileFormValues) {
        if (!isPrivileged) {
            dispatch(setInvestingHazardValuesAction(values));
        }
        history.push(`/form/${match!.params.id}/investing/finances`);
    }

    return (
        <>
            <QuestionTitle prefix="II.">
                {t('investingFeature.hazard')}
            </QuestionTitle>
            <HazardProfileForm
                initialValues={initialValues || undefined}
                bindSubmit={bindSubmit}
                onSubmit={handleSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
