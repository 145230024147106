import {
    ActionType,
    createAction,
    createReducer,
    getType,
} from 'typesafe-actions';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import message from 'antd/lib/message';

import { PaginationState } from 'common/components/Pagination';
import { AppState } from 'common/models/AppState';
import api from 'common/api';
import { Token, TokenState } from 'common/models/Token';
import i18n from 'common/services/i18n';
import { Transaction } from 'common/models/Transaction';
import { selectForm, selectFormTransaction } from 'features/form/ducks';

export const selectVerificationLog = (state: AppState) =>
    selectForm(state).documents.verificationLog;

export const selectVerificationLogItems = (state: AppState) =>
    selectVerificationLog(state).items;

export const selectIsVerificationLogVisible = (state: AppState) =>
    selectVerificationLog(state).isVisible;

export const selectVerificationLogPagination = (state: AppState) =>
    selectVerificationLog(state).pagination;

export const loadVerificationLogAction = createAction(
    '@@Documents/VerificationLog/LOAD'
);

export const setVerificationLogVisibleAction = createAction(
    '@@Documents/VerificationLog/SET_VISIBILITY',
    action => (value: boolean) => action(value)
);

export const setVerificationLogItemsAction = createAction(
    '@@Documents/VerificationLog/SET_ITEMS',
    action => (value: Token[]) => action(value)
);

export const setVerificationLogItemCountAction = createAction(
    '@@Documents/VerificationLog/SET_ITEM_COUNT',
    action => (value: number) => action(value)
);

export const setVerificationLogPageNumberAction = createAction(
    '@@Documents/VerificationLog/SET_PAGE_NUMBER',
    action => (value: number) => action(value)
);

export const setVerificationLogPageSizeAction = createAction(
    '@@Documents/VerificationLog/SET_PAGE_SIZE',
    action => (value: number) => action(value)
);

export const initialVerificationLogState = {
    items: [],
    isVisible: false,
    pagination: { currentPage: 1, pageSize: 5, total: 0 },
};

export interface VerificationLogState {
    items: Token[];
    isVisible: boolean;
    pagination: PaginationState;
}

type VerificationLogActions = ActionType<
    | typeof setVerificationLogItemsAction
    | typeof setVerificationLogItemCountAction
    | typeof setVerificationLogVisibleAction
    | typeof setVerificationLogPageNumberAction
    | typeof setVerificationLogPageSizeAction
>;

export default createReducer<VerificationLogState, VerificationLogActions>(
    initialVerificationLogState
)
    .handleAction(setVerificationLogVisibleAction, (state, { payload }) => ({
        ...state,
        isVisible: payload,
    }))
    .handleAction(setVerificationLogItemsAction, (state, { payload }) => ({
        ...state,
        items: payload,
    }))
    .handleAction(setVerificationLogItemCountAction, (state, { payload }) => ({
        ...state,
        pagination: { ...state.pagination, total: payload },
    }))

    .handleAction(setVerificationLogPageNumberAction, (state, { payload }) => ({
        ...state,
        pagination: { ...state.pagination, currentPage: payload },
    }))
    .handleAction(setVerificationLogPageSizeAction, (state, { payload }) => ({
        ...state,
        pagination: { ...state.pagination, currentPage: 1, pageSize: payload },
    }));

function* watchLoadVerificationLog() {
    const transaction: Transaction = yield select(selectFormTransaction);
    const pagination: PaginationState = yield select(
        selectVerificationLogPagination
    );

    const { ok, data } = yield call(api.token.find, {
        status: TokenState.USED,
        $skip: pagination.pageSize * (pagination.currentPage - 1),
        $limit: pagination.pageSize,
        'meta.transactionId': transaction._id,
    });

    if (ok && data) {
        yield put(setVerificationLogItemsAction(data.data));
        yield put(setVerificationLogItemCountAction(data.total));
    } else {
        const t = yield i18n;
        message.error(t('formsManagement.failedLoad'));
    }
}

function* watchVerificationLogChanges() {
    yield put(loadVerificationLogAction());
}

function* watchOpenVerificationLog({
    payload: isVisible,
}: ReturnType<typeof setVerificationLogVisibleAction>) {
    if (isVisible) {
        yield put(loadVerificationLogAction());
    }
}

export function* verificationLogSaga() {
    yield takeLatest(
        [getType(loadVerificationLogAction)],
        watchLoadVerificationLog
    );
    yield takeLatest(
        [
            getType(setVerificationLogPageNumberAction),
            getType(setVerificationLogPageSizeAction),
        ],
        watchVerificationLogChanges
    );
    yield takeLatest(
        [getType(setVerificationLogVisibleAction)],
        watchOpenVerificationLog
    );
}
