import cn from 'classnames';
import React, { ReactNode, ChangeEvent, useState, useEffect } from 'react';
import { Checkbox, Typography } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import useMount from 'react-use/lib/useMount';

import { Control } from '../Control';
import { Input } from '../../../components';

export interface CheckBoxOption {
    label: ReactNode;
    value: string;
    disabled?: boolean;
    custom?: boolean;
    legacy?: boolean;
}

interface Props extends CheckboxProps {
    warning?: boolean;
    label?: ReactNode;
    subLabel?: ReactNode;
    error?: ReactNode;
}

export default function EnhancedCheckbox({
    warning,
    subLabel,
    label,
    error,
    value,
    ...bag
}: Props) {
    return (
        <>
            <div className="checkbox-control">
                <div className="checkbox-control__child">
                    <Checkbox
                        checked={value}
                        className={cn({ 'ant-checkbox-warning': warning })}
                        {...bag}
                    >
                        {label}
                        {subLabel && (
                            <Typography.Paragraph className="ant-checkbox-sub-label">
                                {subLabel}
                            </Typography.Paragraph>
                        )}
                        {error && (
                            <div className="checkbox-control__error">
                                {error}
                            </div>
                        )}
                    </Checkbox>
                </div>
            </div>
        </>
    );
}

interface GroupProps {
    label?: ReactNode;
    error?: ReactNode;
    type?: 'horizontal' | 'vertical';
    value?: string[];
    options: CheckBoxOption[];
    disabled?: boolean;
    onChange?: (values: string[]) => void;
}

function EnhancedCheckboxGroup({
    label,
    error,
    type,
    options,
    disabled,
    value,
    onChange,
}: GroupProps) {
    const [values, setValues] = useState<string[]>(value || []);
    const [custom, setCustom] = useState<{ name: string; value: string }[]>([]);

    // /* eslint-disable */
    useMount(() => {
        const [customOption] = options.filter(o => o.custom);
        const normalOptions = options.filter(o => !o.custom);
        const [customValue] = values.filter(
            o => !normalOptions.map(o => o.value).includes(o)
        );
        if (customValue && customOption) {
            setCustom([
                {
                    name: customOption.value,
                    value: customValue,
                },
            ]);

            if (values.includes(customValue)) {
                setValues(
                    [...values, customOption.value].filter(
                        e => e !== customValue
                    )
                );
            }
        }
    });
    // /* eslint-enable */

    /* eslint-disable */
    useEffect(() => {
        const result = values.map(
            v =>
                (custom.find(c => c.name === v) &&
                    custom.find(c => c.name === v)!.value) ||
                v
        );
        onChange && onChange(result);
    }, [custom, values]);
    /* eslint-enable */

    function handleGroupChange(values: CheckboxValueType[]) {
        setValues(values as string[]);
    }

    function handleCustomInputChange(name: string) {
        return (e: ChangeEvent<HTMLInputElement>) => {
            setCustom([
                ...custom.filter(c => c.name !== name),
                { name, value: e.target.value },
            ]);
        };
    }

    return (
        <Control label={label} error={error}>
            <Checkbox.Group
                value={values}
                onChange={handleGroupChange}
                disabled={disabled}
                className={cn('enhanced-checkbox-group', {
                    'enhanced-checkbox-group-horizontal': type === 'horizontal',
                    'enhanced-checkbox-group-vertical': type === 'vertical',
                })}
            >
                {options
                    .filter(o => !o.legacy)
                    .map(o => (
                        <Checkbox
                            key={o.value}
                            value={o.value}
                            disabled={o.disabled}
                        >
                            {o.custom ? (
                                <Input
                                    size="default"
                                    placeholder={String(o.label)}
                                    onChange={handleCustomInputChange(o.value)}
                                    value={
                                        custom.find(c => c.name === o.value)
                                            ?.value ?? ''
                                    }
                                    disabled={disabled}
                                />
                            ) : (
                                o.label
                            )}
                        </Checkbox>
                    ))}
            </Checkbox.Group>
        </Control>
    );
}

EnhancedCheckbox.Group = EnhancedCheckboxGroup;
