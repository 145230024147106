import { message } from 'antd';
import i18n from 'common/services/i18n';

import i18next from 'i18next';
import { combineReducers } from 'redux';

import { call, put, select, spawn, take, takeLatest } from 'redux-saga/effects';
import { ActionType, createAction, createAsyncAction, createReducer, getType, } from 'typesafe-actions';
import api from '../../common/api';
import { fondHasStrategy } from '../../common/forms/FondForm/fonds';
import { InvestingExperienceOptions } from '../../common/forms/InvestingExperience';
import { AppState } from '../../common/models/AppState';
import {
    AgreementsFormValues,
    BestOfTempletonAccountFormValues,
    BusinessPartnerInfoFormValues,
    ClientConfirmationFormValues,
    ClientFormValues,
    ClientStatementFormValues,
    CompanyInfoFormValues,
    ConsultancyInitiationFormValues,
    ConsultantConfirmationFormValues,
    FinanceAnalyseFormValues,
    FinancesFormValues,
    financesValidationSchema,
    HazardProfileFormValues,
    InvestingExperienceCalcSchema,
    InvestingExperienceFormValues,
    InvestingGoalsFormValues,
    MeetingFormValues,
    RequestInfoFormValues,
} from '../../common/models/Forms';
import { Transaction, VerificationFlag } from '../../common/models/Transaction';
import { ApiClientResponse } from '../../common/services/api-client';

import documents, { DocumentsState, initialDocumentsState, signaturesSaga, } from './features/transaction/ducks';
import verification, {
    initialVerificationFormState,
    VerificationFormState,
    verificationSaga,
} from './features/verification/ducks';

// Actions
export const setInformationsCompanyInfoValuesAction = createAction(
    '@@Informations/CompanyInfo/SET',
    action => (values: CompanyInfoFormValues) => action(values)
);
export const setInformationsClient1InfoValuesAction = createAction(
    '@@Informations/Client1Info/SET',
    action => (values: ClientFormValues) => action(values)
);
export const setInformationsClient1InfoValuesPartialAction = createAction(
    '@@Informations/Client1Info/SET_PARTIAL',
    action => (values: Partial<ClientFormValues>) => action(values)
);
export const setInformationsClient2InfoValuesAction = createAction(
    '@@Informations/Client2Info/SET',
    action => (values: ClientFormValues) => action(values)
);

export const setInvestingExperienceValuesAction = createAction(
    '@@Investing/Experience/SET',
    action => (values: InvestingExperienceFormValues) => action(values)
);
export const setInvestingHazardValuesAction = createAction(
    '@@Investing/Hazard/SET',
    action => (values: HazardProfileFormValues) => action(values)
);
export const setInvestingFinancesValuesAction = createAction(
    '@@Investing/Finances/SET',
    action => (values: FinancesFormValues) => action(values)
);
export const setInvestingAnalyseValuesAction = createAction(
    '@@Investing/Analyse/SET',
    action => (values: FinanceAnalyseFormValues) => action(values)
);
export const setInvestingAgreementsValuesAction = createAction(
    '@@Investing/Agreements/SET',
    action => (values: AgreementsFormValues) => action(values)
);
export const syncInvestingAgreementsWithProtocolAction = createAction(
    '@@Investing/Agreements/SYNC_WITH_PROTOCOL',
    action => (value: ConsultantConfirmationFormValues['b']) => action(value)
);
export const setInvestingStrategyAction = createAction(
    '@@Investing/Strategy/SET',
    action => () => action()
);

export const setProtocolGoalsValuesAction = createAction(
    '@@Protocol/Goals/SET',
    action => (values: InvestingGoalsFormValues) => action(values)
);
export const setProtocolInitiationValuesAction = createAction(
    '@@Protocol/Initiation/SET',
    action => (values: ConsultancyInitiationFormValues) => action(values)
);
export const updateProtocolInitiationValuesAction = createAction(
    '@@Protocol/Initiation/UPDATE',
    action => (values: InvestingStrategy) => action(values)
);
export const setProtocolCustomerStatementValuesAction = createAction(
    '@@Protocol/CustomerStatement/SET',
    action => (values: ClientStatementFormValues) => action(values)
);
export const setProtocolCustomerConfirmationValuesAction = createAction(
    '@@Protocol/CustomerConfirmation/SET',
    action => (values: ClientConfirmationFormValues) => action(values)
);
export const setProtocolConsultantConfirmationValuesAction = createAction(
    '@@Protocol/ConsultantConfirmation/SET',
    action => (values: ConsultantConfirmationFormValues) => action(values)
);

export const setAgreementRequestInfoAction = createAction(
    '@@Agreement/RequestInfo/SET',
    action => (values: RequestInfoFormValues) => action(values)
);
export const setAgreementBusinessPartnerInfoAction = createAction(
    '@@Agreement/BusinessPartnerInfo/SET',
    action => (values: BusinessPartnerInfoFormValues) => action(values)
);
export const setAgreementMeetingAction = createAction(
    '@@Agreement/Meeting/SET',
    action => (values: MeetingFormValues) => action(values)
);

export const resetFormAction = createAction('@@Form/RESET');
export const preloadFormStateAction = createAction(
    '@@Form/PRELOAD',
    action => (form: FormState) => action(form)
);

export const setBestOfTempletonAccountInfo = createAction(
    '@@BestOfTempleton/AccountInfo/SET',
    action => (values: BestOfTempletonAccountFormValues) => action(values)
);

export const loadTransactionActions = createAsyncAction(
    '@@Form/LOAD_TRANSACTION/REQUEST',
    '@@Form/LOAD_TRANSACTION/SUCCESS',
    '@@Form/LOAD_TRANSACTION/ERROR'
)<string, Transaction, undefined>();

// Reducers
export const initialFormState: FormState = {
    verification: initialVerificationFormState,
    informations: {
        companyInfo: null,
        customerInfo1: null,
        customerInfo2: null,
    },
    investing: {
        experience: null,
        hazard: null,
        finances: null,
        analyse: null,
        agreements: null,
        strategy: null,
    },
    protocol: {
        goals: null,
        initiation: null,
        customerStatement: null,
        customerConfirmation: null,
        consultantConfirmation: null,
    },
    agreement: {
        requestInfo: null,
        businessPartnerInfo: null,
        meeting: null,
    },
    bestOfTempleton: null,
    documents: initialDocumentsState,
    transaction: null,
};

export interface FormState {
    verification: VerificationFormState;
    informations: {
        companyInfo: CompanyInfoFormValues | null;
        customerInfo1: ClientFormValues | null;
        customerInfo2: ClientFormValues | null;
    };
    investing: {
        experience: InvestingExperienceFormValues | null;
        hazard: HazardProfileFormValues | null;
        finances: FinancesFormValues | null;
        analyse: FinanceAnalyseFormValues | null;
        agreements: AgreementsFormValues | null;
        strategy: InvestingStrategy | null;
    };
    protocol: {
        goals: InvestingGoalsFormValues | null;
        initiation: ConsultancyInitiationFormValues | null;
        customerStatement: ClientStatementFormValues | null;
        customerConfirmation: ClientConfirmationFormValues | null;
        consultantConfirmation: ConsultantConfirmationFormValues | null;
    };
    agreement: {
        requestInfo: RequestInfoFormValues | null;
        businessPartnerInfo: BusinessPartnerInfoFormValues | null;
        meeting: MeetingFormValues | null;
    };
    bestOfTempleton: BestOfTempletonAccountFormValues | null;
    documents: DocumentsState;
    transaction: Transaction | null;
}

type InformationsActions = ActionType<
    | typeof setInformationsCompanyInfoValuesAction
    | typeof setInformationsClient1InfoValuesAction
    | typeof setInformationsClient1InfoValuesPartialAction
    | typeof setInformationsClient2InfoValuesAction
    | typeof resetFormAction
    | typeof preloadFormStateAction
>;
const informations = createReducer<
    FormState['informations'],
    InformationsActions
>(initialFormState.informations)
    .handleAction(
        setInformationsClient1InfoValuesPartialAction,
        (state, { payload }) => ({
            ...state,
            customerInfo1: {
                ...(state.customerInfo1 as ClientFormValues),
                ...payload,
            },
        })
    )
    .handleAction(setInformationsCompanyInfoValuesAction, (state, action) => ({
        ...state,
        companyInfo: action.payload,
    }))
    .handleAction(setInformationsClient1InfoValuesAction, (state, action) => ({
        ...state,
        customerInfo1: action.payload,
    }))
    .handleAction(setInformationsClient2InfoValuesAction, (state, action) => ({
        ...state,
        customerInfo2: action.payload,
    }))
    .handleAction(
        preloadFormStateAction,
        (state, action) => action.payload.informations
    )
    .handleAction(resetFormAction, () => initialFormState.informations);

type InvestingActions = ActionType<
    | typeof setInvestingExperienceValuesAction
    | typeof setInvestingHazardValuesAction
    | typeof setInvestingFinancesValuesAction
    | typeof setInvestingAnalyseValuesAction
    | typeof setInvestingAgreementsValuesAction
    | typeof setInvestingStrategyAction
    | typeof resetFormAction
    | typeof preloadFormStateAction
    | typeof syncInvestingAgreementsWithProtocolAction
>;
const investing = createReducer<FormState['investing'], InvestingActions>(
    initialFormState.investing
)
    .handleAction(setInvestingExperienceValuesAction, (state, action) => ({
        ...state,
        experience: action.payload,
    }))
    .handleAction(setInvestingHazardValuesAction, (state, action) => ({
        ...state,
        hazard: action.payload,
    }))
    .handleAction(setInvestingFinancesValuesAction, (state, action) => ({
        ...state,
        finances: action.payload,
    }))
    .handleAction(setInvestingAnalyseValuesAction, (state, action) => ({
        ...state,
        analyse: action.payload,
    }))
    .handleAction(setInvestingAgreementsValuesAction, (state, action) => ({
        ...state,
        agreements: action.payload,
    }))
    .handleAction(setInvestingStrategyAction, state => {
        return {
            ...state,
            strategy: calculateStrategy(state),
        };
    })
    .handleAction(resetFormAction, () => initialFormState.investing)
    .handleAction(
        preloadFormStateAction,
        (state, action) => action.payload.investing
    )
    .handleAction(
        syncInvestingAgreementsWithProtocolAction,
        (state, action) => {
            const newState = state;

            if (newState.agreements) {
                newState.agreements.dateOfSignature = action.payload.date;
                newState.agreements.interviewTime =
                    action.payload.interviewDuration;
                newState.agreements.placeOfSignature = action.payload.city;
            }

            return newState;
        }
    );

type ProtocolActions = ActionType<
    | typeof setProtocolGoalsValuesAction
    | typeof setProtocolInitiationValuesAction
    | typeof updateProtocolInitiationValuesAction
    | typeof setProtocolCustomerStatementValuesAction
    | typeof setProtocolCustomerConfirmationValuesAction
    | typeof setProtocolConsultantConfirmationValuesAction
    | typeof resetFormAction
    | typeof preloadFormStateAction
>;
const protocol = createReducer<FormState['protocol'], ProtocolActions>(
    initialFormState.protocol
)
    .handleAction(setProtocolGoalsValuesAction, (state, action) => ({
        ...state,
        goals: action.payload,
    }))
    .handleAction(setProtocolInitiationValuesAction, (state, action) => ({
        ...state,
        initiation: action.payload,
    }))
    .handleAction(updateProtocolInitiationValuesAction, (state, action) => ({
          ...state,
          initiation: updateProtocolInitiationByStrategy(state.initiation, action.payload)
      }
    ))
    .handleAction(
        setProtocolCustomerStatementValuesAction,
        (state, action) => ({
            ...state,
            customerStatement: action.payload,
        })
    )
    .handleAction(
        setProtocolCustomerConfirmationValuesAction,
        (state, action) => ({
            ...state,
            customerConfirmation: action.payload,
        })
    )
    .handleAction(
        setProtocolConsultantConfirmationValuesAction,
        (state, action) => ({
            ...state,
            consultantConfirmation: action.payload,
        })
    )
    .handleAction(resetFormAction, () => initialFormState.protocol)
    .handleAction(
        preloadFormStateAction,
        (state, action) => action.payload.protocol
    );

type AgreementActions = ActionType<
    | typeof setAgreementRequestInfoAction
    | typeof setAgreementBusinessPartnerInfoAction
    | typeof resetFormAction
    | typeof setAgreementMeetingAction
    | typeof preloadFormStateAction
>;
const agreement = createReducer<FormState['agreement'], AgreementActions>(
    initialFormState.agreement
)
    .handleAction(setAgreementRequestInfoAction, (state, action) => ({
        ...state,
        requestInfo: action.payload,
    }))
    .handleAction(setAgreementMeetingAction, (state, action) => ({
        ...state,
        meeting: action.payload,
    }))
    .handleAction(setAgreementBusinessPartnerInfoAction, (state, action) => ({
        ...state,
        businessPartnerInfo: action.payload,
    }))
    .handleAction(resetFormAction, () => initialFormState.agreement)
    .handleAction(
        preloadFormStateAction,
        (state, action) => action.payload.agreement
    );

type TransactionActions = ActionType<
    typeof resetFormAction | typeof loadTransactionActions
>;
const transaction = createReducer<FormState['transaction'], TransactionActions>(
    initialFormState.transaction
).handleAction(
    loadTransactionActions.success,
    (_state, action) => action.payload
);

type bestOfTempletonActions = ActionType<
    | typeof setBestOfTempletonAccountInfo
    | typeof preloadFormStateAction
    | typeof resetFormAction
>;

const bestOfTempleton = createReducer<
    FormState['bestOfTempleton'],
    bestOfTempletonActions
>(initialFormState.bestOfTempleton)
    .handleAction(
        setBestOfTempletonAccountInfo,
        (state, action) => action.payload
    )
    .handleAction(preloadFormStateAction, (state, action) =>
        action.payload.bestOfTempleton ? action.payload.bestOfTempleton : null
    )
    .handleAction(resetFormAction, () => initialFormState.bestOfTempleton);

export default combineReducers<FormState>({
    verification,
    transaction,
    informations,
    investing,
    protocol,
    agreement,
    bestOfTempleton,
    documents,
});

// Selectors
export function selectForm(state: AppState) {
    return state.form;
}

export function selectRouter(state: AppState) {
    return state.router;
}

export function selectFormTransaction(state: AppState) {
    return selectForm(state).transaction;
}

export function selectIsFormVerified(state: AppState) {
    const verification =
        selectFormTransaction(state)?.meta?.verification?.customer1 ?? {};

    return {
        isVerifiedPhone: verification.sms === VerificationFlag.VERIFIED,
        isVerifiedEmail: verification.email === VerificationFlag.VERIFIED,
    };
}

export function selectFormSignatures(state: AppState) {
    return selectFormTransaction(state)?.meta?.signature?.customer1?.sms ?? {};
}

// Informations
export function selectFormInformations(state: AppState) {
    return selectForm(state).informations;
}

export function selectFormInformationsDone(state: AppState) {
    return !Object.entries(selectForm(state).informations).some(
        ([key, value]) => key !== 'customerInfo2' && value === null
    );
}

export function selectInformationsCompanyInfo(state: AppState) {
    return selectFormInformations(state).companyInfo;
}

export function selectInformationsCustomerInfo1(state: AppState) {
    return selectFormInformations(state).customerInfo1;
}

export function selectInformationsCustomer1FullName(state: AppState) {
    const { firstName, lastName, title } =
        selectInformationsCustomerInfo1(state) ?? {};
    return [title, firstName, lastName].join(' ').trim();
}

export function selectInformationsCustomerInfo2(state: AppState) {
    return selectFormInformations(state).customerInfo2;
}

// Investing
export function selectFormInvesting(state: AppState) {
    return selectForm(state).investing;
}

export function selectInvestingExperience(state: AppState) {
    return selectFormInvesting(state).experience;
}

export function selectInvestingHazard(state: AppState) {
    return selectFormInvesting(state).hazard;
}

export function selectInvestingFinances(state: AppState) {
    return selectFormInvesting(state).finances;
}

export function selectInvestingAnalyse(state: AppState) {
    return selectFormInvesting(state).analyse;
}

export function selectInvestingAgreements(state: AppState) {
    return selectFormInvesting(state).agreements;
}

export function selectInvestingStrategy(state: AppState) {
    return selectFormInvesting(state).strategy;
}

export function selectInvestingDone(state: AppState) {
    return !Object.entries(selectForm(state).investing).some(
        entry => entry[1] === null
    );
}

export function selectStrategy(state: AppState) {
    let strategy = selectFormInvesting(state).strategy;

    if (!strategy) {
        strategy = calculateStrategy(selectFormInvesting(state));
    }

    return {
        name: i18next.t(`strategies.${strategy}`),
        type: strategy,
    };
}

// Protocol
export function selectFormProtocol(state: AppState) {
    return selectForm(state).protocol;
}

export function selectProtocolGoals(state: AppState) {
    return selectFormProtocol(state).goals;
}

export function selectProtocolInitiation(state: AppState) {
    return selectFormProtocol(state).initiation;
}

export function selectProtocolCustomerStatement(state: AppState) {
    return selectFormProtocol(state).customerStatement;
}

export function selectProtocolCustomerConfirmation(state: AppState) {
    return selectFormProtocol(state).customerConfirmation;
}

export function selectProtocolConsultantConfirmation(state: AppState) {
    return selectFormProtocol(state).consultantConfirmation;
}

export function selectProtocolDone(state: AppState) {
    return !Object.entries(selectForm(state).protocol).some(
        entry => entry[1] === null
    );
}

// Agreement
export function selectFormAgreement(state: AppState) {
    return selectForm(state).agreement;
}

export function selectAgreementRequestInfo(state: AppState) {
    return selectFormAgreement(state).requestInfo;
}

export function selectAgreementBusinessPartnerInfo(state: AppState) {
    return selectFormAgreement(state).businessPartnerInfo;
}

export function selectAgreementMeeting(state: AppState) {
    return selectFormAgreement(state).meeting;
}

export function selectAgreementDone(state: AppState) {
    return !Object.entries(selectForm(state).agreement).some(
        entry => entry[1] === null
    );
}

export function selectBestOfTempletonAccountInfo(state: AppState) {
    return selectForm(state).bestOfTempleton;
}

export function selectBestOfTempletonDone(state: AppState) {
    return selectForm(state).bestOfTempleton !== null;
}

export enum InvestingStrategy {
    Conservative = 'conservative',
    Defensive = 'defensive',
    Balanced = 'balanced',
    Dynamic = 'dynamic',
}

export const strategiesMap = [
    {
        type: InvestingStrategy.Conservative,
        value: 1,
    },
    {
        type: InvestingStrategy.Defensive,
        value: 2,
    },
    {
        type: InvestingStrategy.Balanced,
        value: 3,
    },
    {
        type: InvestingStrategy.Dynamic,
        value: 4,
    },
];

export enum RPs {
    RP1 = 1,
    RP2 = 2,
    RP3 = 3,
    RP4 = 4,
}

function calculateStrategy({
    finances,
    hazard,
    experience,
}: FormState['investing']): InvestingStrategy {
    if (!experience || !hazard || !finances) {
        //if cant calculate strategy return most conservative
        return InvestingStrategy.Conservative;
    }
    let pointsFromTable = (experience?.table ?? []).reduce((a, c) => {
        const rule = InvestingExperienceCalcSchema.questions[c.question];
        if (rule && rule[c.value]) {
            a = a + rule[c.value];
        }
        return a;
    }, 0);

    if (
        (experience?.table ?? []).some(
            entry =>
                entry.question === 'a' &&
                entry.value !== InvestingExperienceOptions.NoKnowledge
        )
    ) {
        const aPoints =
            InvestingExperienceCalcSchema.sub.a[experience?.a ?? ''];
        pointsFromTable += aPoints;
    }

    if (
        (experience?.table ?? []).some(
            entry =>
                (entry.question === 'b' || entry.question === 'c') &&
                entry.value !== InvestingExperienceOptions.NoKnowledge
        )
    ) {
        const bPoints =
            InvestingExperienceCalcSchema.sub.b[experience?.b?.a ?? ''];
        pointsFromTable += bPoints;
    }

    if (
        (experience?.table ?? []).some(
            entry =>
                (entry.question === 'd' || entry.question === 'e') &&
                entry.value !== InvestingExperienceOptions.NoKnowledge
        )
    ) {
        const cPoints =
            InvestingExperienceCalcSchema.sub.c[experience?.c?.a ?? ''];
        pointsFromTable += cPoints;
    }

    const wayOfEducationPoints =
        InvestingExperienceCalcSchema.wayOfEducation[
            experience?.wayOfEducation ?? ''
        ];

    if (wayOfEducationPoints) {
        pointsFromTable += wayOfEducationPoints;
    }

    const useOfServicesPoints =
        InvestingExperienceCalcSchema.useOfServices[
            experience?.useOfServices ?? ''
        ];

    if (useOfServicesPoints) {
        pointsFromTable += useOfServicesPoints;
    }

    const investingStrategy = getStrategyFromInvesting(pointsFromTable);
    const hazardStrategy = getStrategyFromHazard(hazard!);
    const financesStrategy = getStrategyFromFinances(finances!);

    const strategies = [investingStrategy, hazardStrategy, financesStrategy];

    const mappedStrategies = strategies.map(s =>
        strategiesMap.find(e => e.type === s)
    );

    const result = Math.min(...mappedStrategies.map(s => s!.value));

    return strategiesMap[result - 1].type;
}

function getStrategyFromInvesting(value: number) {
    if (value >= 0 && value <= 3) {
        return InvestingStrategy.Conservative;
    } else if (value >= 4 && value <= 8) {
        return InvestingStrategy.Defensive;
    } else if (value >= 9 && value <= 13) {
        return InvestingStrategy.Balanced;
    } else {
        return InvestingStrategy.Dynamic;
    }
}

function getStrategyFromFinances(values: FinancesFormValues) {
    const points = Object.entries(values).reduce((a, [key, value]) => {
        const rule = financesValidationSchema[key];
        if (rule && rule[value]) {
            a = a + rule[value];
        }
        return a;
    }, 0);

    if (points >= 0 && points <= 1) {
        return InvestingStrategy.Conservative;
    } else if (points >= 2 && points <= 3) {
        return InvestingStrategy.Defensive;
    } else if (points >= 4 && points <= 6) {
        return InvestingStrategy.Balanced;
    } else {
        return InvestingStrategy.Dynamic;
    }
}

function getStrategyFromHazard(values: HazardProfileFormValues) {
    function fromA(value: string) {
        switch (value) {
            case 'a':
                return RPs.RP1;
            case 'b':
                return RPs.RP2;
            case 'c':
                return RPs.RP3;
            case 'd':
                return RPs.RP4;
            default:
                return RPs.RP1;
        }
    }

    function fromD(value: string) {
        switch (value) {
            case 'a':
                return RPs.RP1;
            case 'b':
                return RPs.RP2;
            case 'c':
                return RPs.RP3;
            case 'd':
                return RPs.RP3;
            case 'e':
                return RPs.RP4;
            default:
                return RPs.RP1;
        }
    }

    function fromC(value: string) {
        switch (value) {
            case 'a':
                return RPs.RP1;
            case 'b':
                return RPs.RP1;
            case 'c':
                return RPs.RP2;
            case 'd':
                return RPs.RP3;
            case 'e':
                return RPs.RP3;
            case 'f':
                return RPs.RP4;
            case 'g':
                return RPs.RP4;
            default:
                return RPs.RP1;
        }
    }

    const aRP = fromA(values.a);
    const bRP = fromA(values.b);
    const cRP = fromC(values.c);
    const dRP = fromD(values.d);

    const result = Math.min(aRP, bRP, cRP, dRP);

    switch (result) {
        case RPs.RP1:
            return InvestingStrategy.Conservative;
        case RPs.RP2:
            return InvestingStrategy.Defensive;
        case RPs.RP3:
            return InvestingStrategy.Balanced;
        case RPs.RP4:
            return InvestingStrategy.Dynamic;
        default:
            return InvestingStrategy.Conservative;
    }
}

function updateProtocolInitiationByStrategy(
  state: FormState['protocol']['initiation'],
  strategy: InvestingStrategy
) {
    if (!state?.table) {
        return state;
    }

    const table = state.table.filter((item) =>
      fondHasStrategy(item.code, strategy))

    return { ...state, table };
}

function* watchLoadTransaction(
    action: ReturnType<typeof loadTransactionActions.request>
) {
    const resp: ApiClientResponse<Transaction> = yield call(
        api.transaction.findOne,
        action.payload
    );
    if (resp.ok) {
        yield put(preloadFormStateAction(resp.data.entries[0].meta));
        yield put(loadTransactionActions.success(resp.data));
    }
}

function* watchSetFormData() {
    const {
        agreement,
        informations,
        investing,
        protocol,
        bestOfTempleton,
    }: FormState = yield select(selectForm);
    const transaction: Transaction = yield select(selectFormTransaction);
    const resp: ApiClientResponse<Transaction> = yield call(
        api.transaction.edit,
        transaction._id,
        {
            protocol,
            informations,
            agreement,
            investing,
            bestOfTempleton,
        }
    );
    if (resp.ok) {
        yield put(preloadFormStateAction(resp.data.entries[0].meta));
        yield put(loadTransactionActions.success(resp.data));

        const t = yield i18n;
        message.success(t('formsManagement.saved'));
    }
}

function* watchSetAgreement() {
    yield take([getType(setAgreementBusinessPartnerInfoAction)]);
    yield call(watchSetFormData);
}

function* watchSetStrategy() {
    yield put(setInvestingStrategyAction());
    yield put(updateProtocolInitiationValuesAction(yield select(selectInvestingStrategy)));
    yield call(watchSetFormData);
}

export function* formSaga() {
    yield takeLatest(
        [getType(loadTransactionActions.request)],
        watchLoadTransaction
    );
    // Atomic form updates
    yield takeLatest(
        [
            getType(setInformationsCompanyInfoValuesAction),
            getType(setInformationsClient1InfoValuesAction),
            getType(setInformationsClient2InfoValuesAction),
            getType(setInformationsClient1InfoValuesPartialAction),
            getType(setInvestingAnalyseValuesAction),
            getType(setInvestingAgreementsValuesAction),

            getType(setProtocolGoalsValuesAction),
            getType(setProtocolInitiationValuesAction),
            getType(setProtocolCustomerStatementValuesAction),
            getType(setProtocolCustomerConfirmationValuesAction),
            getType(setProtocolConsultantConfirmationValuesAction),

            getType(setBestOfTempletonAccountInfo),
        ],
        watchSetFormData
    );
    // Multi-part form updates
    yield takeLatest(
        [
            getType(setInvestingExperienceValuesAction),
            getType(setInvestingHazardValuesAction),
            getType(setInvestingFinancesValuesAction),
        ],
        watchSetStrategy
    );
    yield takeLatest(
        [getType(setAgreementRequestInfoAction)],
        watchSetAgreement
    );
    // Nested form part updates
    yield spawn(verificationSaga);
    yield spawn(signaturesSaga);
}
