import { Field, FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';
import { useRegisteredRef } from 'react-register-nodes';

import { Radio } from '../../components';

interface Props {
    name: string;
    label?: ReactNode;
    options: {
        label: ReactNode;
        value: string | number | boolean;
        custom?: boolean;
    }[];
    type?: 'horizontal' | 'vertical';
    breakLabel?: boolean; //if radio has long label, so it breaks label and centers radio button itself
}

export default function RadioField({ name, ...bag }: Props) {
    const ref = useRegisteredRef(name);

    return (
        <Field name={name}>
            {({ field: { ...fieldBag }, form }: FieldProps<any>) => {
                const handleChange = (e: string | number | boolean) => {
                    form.setFieldValue(name, e);
                };

                return (
                    <div ref={ref}>
                        <Radio
                            error={getIn(form.errors, name)}
                            {...fieldBag}
                            {...bag}
                            onChange={handleChange}
                        />
                    </div>
                );
            }}
        </Field>
    );
}
