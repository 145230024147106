import React from 'react';
import { Collapse, Icon, Modal, Row, Col, Divider } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { NavLink } from 'react-router-dom';
import useUnmount from 'react-use/lib/useUnmount';

import { Button } from 'common/components';
import { SignatureForm } from 'common/forms';
import { SignatureScope, SignatureFlag } from 'common/models/Transaction';
import DocumentActions from 'features/form/components/DocumentActions';
import { DocumentSignaturesFormValues } from 'common/models/Forms';
import { TokenState } from 'common/api/token';
import { I18nNameSpaces, Languages } from 'common/services/i18n';
import { PdfRenderer } from 'common/pdf/pdf-renderer';

import {
    requestSignatureCodeAction,
    resetSignaturesStateAction,
    selectClientSignature,
    setSelectedDocumentsAction,
    UploadState,
    submitSignatureCodeAction,
    forwardDocumentsAction,
    setDocumentUploadStatusAction,
} from './ducks';

import {
    selectInformationsCustomerInfo1,
    selectStrategy,
    InvestingStrategy,
    selectIsFormVerified,
    selectFormSignatures,
} from '../../ducks';
import { useIsMobileSm } from 'common/hooks/useIsMobile';
import { setVerificationLogVisibleAction } from './features/verification-log/ducks';
import VerificationLogFeature from './features/verification-log';

export default function TransactionFeature() {
    const {
        t,
        i18n: { language },
    } = useTranslation(I18nNameSpaces.Forms);
    // const params = useParams<{ id: string }>();
    const dispatch = useDispatch();
    const appState = useStore().getState();
    const isMobile = useIsMobileSm();
    const pdfRenderer = new PdfRenderer(appState);

    const customerInfo1 = useSelector(selectInformationsCustomerInfo1);
    const strategy = useSelector(selectStrategy);
    const isConservative = strategy.type === InvestingStrategy.Conservative;
    const clientSignatures = useSelector(selectClientSignature);
    const existingSignatures = useSelector(selectFormSignatures);
    const hasAnyValidSignature = Object.keys(existingSignatures).some(
        documentId => existingSignatures[documentId] === SignatureFlag.SIGNED
    );

    const { isVerifiedEmail, isVerifiedPhone } =
        useSelector(selectIsFormVerified);
    const isFullyVerified =
        isVerifiedPhone &&
        isVerifiedEmail &&
        !!customerInfo1?.firstName &&
        !!customerInfo1?.lastName;

    useUnmount(() => {
        dispatch(resetSignaturesStateAction());
    });

    function handleRequestCode() {
        dispatch(requestSignatureCodeAction());
    }

    function handleVerifyCode(code: DocumentSignaturesFormValues['code']) {
        dispatch(submitSignatureCodeAction(code));
    }

    function handleDocumentSelect(key: SignatureScope, isSelected: boolean) {
        dispatch(setSelectedDocumentsAction({ document: key, isSelected }));
    }

    function handleShowVerificationLog() {
        dispatch(setVerificationLogVisibleAction(true));
    }

    function handleResendDocuments() {
        dispatch(setDocumentUploadStatusAction(UploadState.UPLOADING));
        dispatch(forwardDocumentsAction(Object.keys(existingSignatures) as SignatureScope[]));
    }

    function getErrorMessage() {
        if (!isFullyVerified) {
            return (
                <Trans
                    t={t}
                    i18nKey="signatureForm.readOnlyWarning"
                    components={[<NavLink to="verification" key={0} />]}
                />
            );
        }

        if (clientSignatures.status === TokenState.DELIVERY_ERROR) {
            return t([
                `signatureForm.request.${clientSignatures.httpStatus}`,
                'signatureForm.request.error',
            ]);
        }

        if (clientSignatures.status === TokenState.SUBMISSION_ERROR) {
            return clientSignatures.attemptsLeft
                ? t(`signatureForm.sign.errorAttempts`, {
                      attemptsLeft: clientSignatures.attemptsLeft,
                  })
                : t([
                      `signatureForm.sign.${clientSignatures.httpStatus}`,
                      'signatureForm.sign.error',
                  ]);
        }

        return null;
    }

    function getSuccessMessage() {
        if (clientSignatures.status === TokenState.DELIVERED) {
            return t(`signatureForm.request.success`);
        }
        if (clientSignatures.status === TokenState.VERIFIED) {
            return t(`signatureForm.sign.success`);
        }

        return null;
    }

    function renderDocumentActions(documentId: SignatureScope) {
        return (
            isFullyVerified &&
            pdfRenderer.isRenderable(documentId) && (
                <DocumentActions
                    id={documentId}
                    onChange={handleDocumentSelect}
                />
            )
        );
    }

    function renderDocumentPanel(documentId: SignatureScope) {
        return (
            <Collapse.Panel
                key={documentId}
                header={t(`signatureForm.fileTypes.${documentId}`)}
                extra={renderDocumentActions(documentId)}
            >
                {pdfRenderer.isRenderable(documentId) ? (
                    <PDFDownloadLink
                        document={pdfRenderer.renderDocument(documentId)}
                        fileName={t(`signatureForm.fileNames.${documentId}`)}
                    >
                        {({ loading }) => (
                            <Button block disabled={loading} loading={loading}>
                                {t(`signatureForm.fileTypes.${documentId}`)}
                            </Button>
                        )}
                    </PDFDownloadLink>
                ) : (
                    t('signatureForm.fileTypes.valuesMissing')
                )}
            </Collapse.Panel>
        );
    }

    return (
        <div className="transaction-feature" style={{ margin: '30px 0' }}>
            <VerificationLogFeature />
            <Modal
                centered
                destroyOnClose
                title={t('signatureForm.uploadInProgress')}
                visible={
                    clientSignatures.uploadStatus === UploadState.UPLOADING
                }
                footer={null}
                closable={false}
            >
                <p className="transaction-upload-spinner">
                    <Icon type="loading" />
                </p>
            </Modal>
            <Collapse accordion destroyInactivePanel>
                {/* Documents rendered on the client side */}
                {renderDocumentPanel(SignatureScope.CUSTOMER_INFO_1)}
                {renderDocumentPanel(SignatureScope.CUSTOMER_INFO_2)}
                {renderDocumentPanel(SignatureScope.INVESTING)}
                {renderDocumentPanel(SignatureScope.GENERAL_AGREEMENT)}
                {renderDocumentPanel(SignatureScope.PROTOCOL)}
                {renderDocumentPanel(SignatureScope.AGREEMENT)}
                {renderDocumentPanel(SignatureScope.MEETING)}

                {/* Documents rendered on the server side */}
                {/*{strategy.type !== InvestingStrategy.Conservative && (*/}
                {/*    <Collapse.Panel*/}
                {/*        key={SignatureScope.MOVENTUM}*/}
                {/*        header={t('signatureForm.fileTypes.bestOfTempleton')}*/}
                {/*        extra={renderDocumentActions(SignatureScope.MOVENTUM)}*/}
                {/*    >*/}
                {/*        {pdfRenderer.isRenderable(SignatureScope.MOVENTUM) ? (*/}
                {/*            <FileFetcher*/}
                {/*                fileType="application/pdf"*/}
                {/*                url={`/transactions/${params.id}/moventum?lang=${language}`}*/}
                {/*            >*/}
                {/*                {({ loading, blob, error }) => (*/}
                {/*                    <Button*/}
                {/*                        block*/}
                {/*                        loading={loading}*/}
                {/*                        disabled={loading || Boolean(error)}*/}
                {/*                        onClick={() =>*/}
                {/*                            saveAs(*/}
                {/*                                blob,*/}
                {/*                                t(*/}
                {/*                                    'signatureForm.fileNames.bestOfTempleton'*/}
                {/*                                )*/}
                {/*                            )*/}
                {/*                        }*/}
                {/*                    >*/}
                {/*                        {t(*/}
                {/*                            'signatureForm.fileTypes.bestOfTempleton'*/}
                {/*                        )}*/}
                {/*                    </Button>*/}
                {/*                )}*/}
                {/*            </FileFetcher>*/}
                {/*        ) : (*/}
                {/*            t('signatureForm.fileTypes.valuesMissing')*/}
                {/*        )}*/}
                {/*    </Collapse.Panel>*/}
                {/*)}*/}
            </Collapse>

            <Row style={{ marginTop: '30px' }} gutter={30}>
                <Col sm={24} lg={16}>
                    {isFullyVerified && (
                        <>
                            <p className="documents-hint">
                                {t('signatureForm.documentSelectionHint')}
                            </p>
                            <p className="documents-hint">
                                {t('signatureForm.documentForwardingHint')}
                            </p>
                        </>
                    )}
                </Col>
                <Col sm={24} lg={8}>
                    <Row className="global-actions">
                        <Col sm={24} lg={isConservative ? 24 : 12}>
                            <Button
                                block={isMobile}
                                onClick={handleShowVerificationLog}
                            >
                                {t('signatureForm.verificationLog.title')}
                            </Button>
                        </Col>
                        {/*{!isConservative && (*/}
                        {/*    <Col sm={24} lg={12}>*/}
                        {/*        <Button type="primary" block={isMobile}>*/}
                        {/*            <NavLink to="BoT">*/}
                        {/*                {t('signatureForm.createBoTAccount')}*/}
                        {/*            </NavLink>*/}
                        {/*        </Button>*/}
                        {/*    </Col>*/}
                        {/*)}*/}
                    </Row>
                </Col>
            </Row>

            <Row>
                <SignatureForm
                    signature={
                        hasAnyValidSignature
                            ? pdfRenderer.createClientSignature()
                            : null
                    }
                    readOnly={!isFullyVerified}
                    onRequestCode={handleRequestCode}
                    onVerifyCode={handleVerifyCode}
                    error={getErrorMessage()}
                    success={getSuccessMessage()}
                    loadingRequest={
                        clientSignatures.status === TokenState.REQUESTED
                    }
                    disableRequest={!clientSignatures.selectedDocuments.length}
                    loadingVerify={
                        clientSignatures.status === TokenState.SUBMITTED
                    }
                    disableVerify={[
                        TokenState.DEFAULT,
                        TokenState.DELIVERY_ERROR,
                        TokenState.REQUESTED,
                    ].includes(clientSignatures.status)}
                />

                { isFullyVerified && hasAnyValidSignature && (
                    <div style={{
                        marginTop: '16px'
                    }}>
                        <Col sm={24}>
                            <Button
                                block={true}
                                onClick={handleResendDocuments}
                            >
                                {t('signatureForm.resend.title')}
                            </Button>
                        </Col>
                    </div>
                )}
            </Row>

            <Divider />

            <Row>
                <p className="documents-hint highlighted">
                    <Trans
                        t={t}
                        i18nKey="signatureForm.identityHint"
                        components={[
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a
                                href={
                                    language === Languages.Slovak
                                        ? 'mailto:sk@phoenix-investor.com'
                                        : 'mailto:cz@phoenix-investor.com'
                                }
                                key={0}
                            />,
                        ]}
                    />
                </p>
            </Row>
        </div>
    );
}
