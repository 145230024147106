import { SelectValue } from 'antd/lib/select';
import { Field, FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';

import { Select } from '../../components';
import { SelectOption } from '../../components/Form/Select';
import { isMatchingString } from '../../utils/is-matching-string';

interface Props {
    name: string;
    options: SelectOption[];
    placeholder?: ReactNode;
    label?: ReactNode;
    disabled?: boolean;
    noFoundContent?: ReactNode;
    priorityOptions?: string[];
    showSearch?: boolean;
}

export default function SelectField({
    name,
    options,
    disabled = false,
    noFoundContent,
    ...bag
}: Props) {
    return (
        <Field name={name}>
            {({ field: { value, ...fieldBag }, form }: FieldProps<any>) => {
                function handleChange(value: SelectValue) {
                    form.setFieldValue(name, value);
                }

                function handleFilterOption(input: string, option: any) {
                    return isMatchingString(option.props.children.toString(), input);
                }

                return (
                    <Select
                        value={value ? value : undefined}
                        options={options}
                        error={getIn(form.errors, name)}
                        disabled={disabled}
                        filterOption={handleFilterOption}
                        notFoundContent={noFoundContent}
                        {...fieldBag}
                        {...bag}
                        onChange={handleChange}
                    />
                );
            }}
        </Field>
    );
}
