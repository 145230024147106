import React from 'react';
import { View } from '@react-pdf/renderer';
import {
    ClientFormValues,
    CompanyInfoFormValues,
    AgreementsFormValues,
} from '../models/Forms';

import {
    PDFParagraphBlock,
    PDFDivider,
    PDFCheckbox,
    PDFSignatureBlock,
    PDFListItem,
    PDFBrandedPageWithFooter,
} from './components';
import { useTranslation, Trans } from 'react-i18next';
import { I18nNameSpaces } from '../services/i18n';
import PdfParagraphBlock from './components/ParagraphBlock';
import i18next from 'i18next';

interface Props {
    count: number;
    clientValues: ClientFormValues;
    signature?: string;
    companyInfo: CompanyInfoFormValues;
    agreement: AgreementsFormValues;
}

export default function ClientInformationsPage({
    count,
    companyInfo,
    clientValues,
    agreement,
    signature,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.PDF);
    const { t: formT } = useTranslation(I18nNameSpaces.Forms);
    const footerTitle = t('client.title', { number: count });

    return (
        <>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <View>
                    <PDFParagraphBlock.Title size="large" bold underline blue>
                        {t('client.title', { number: count })}
                    </PDFParagraphBlock.Title>

                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock.Title bold underline>
                        {t('client.section1.title')}
                    </PDFParagraphBlock.Title>

                    <PDFParagraphBlock>
                        {t('client.section1.p1')}
                    </PDFParagraphBlock>

                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock>
                        {t('client.section1.p2')}
                    </PDFParagraphBlock>

                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock bold>
                        {t('client.section1.p3')}
                    </PDFParagraphBlock>

                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock bold>
                        {t('client.section1.p4')}
                    </PDFParagraphBlock>

                    <PDFDivider />
                    <PDFDivider />
                </View>
                <View>
                    <PDFParagraphBlock.Title bold underline>
                        {t('client.section2.title')}
                    </PDFParagraphBlock.Title>
                    <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                        <View style={{ flexGrow: 2 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section2.fields.a')}
                                value={`${companyInfo.title} ${companyInfo.firstName} ${companyInfo.lastName}`}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section2.fields.b')}
                                value={unescape(companyInfo.address)}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section2.fields.c')}
                                value={companyInfo.partnerNumber}
                            />
                            <PDFDivider />
                        </View>
                        <View style={{ flexGrow: 2 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section2.fields.d')}
                                value={companyInfo.phone}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section2.fields.e')}
                                value={companyInfo.email}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section2.fields.f')}
                                value={companyInfo.ico}
                            />
                            <PDFDivider />
                        </View>
                    </View>
                </View>
                <PDFDivider />
                <PDFDivider />
                <View>
                    <PDFParagraphBlock.Title bold underline>
                        {t('client.section3.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <PDFListItem
                        label={t('client.section3.fields.f1')}
                        value={formT(
                            `clientForm.gender.options.${clientValues.gender}`
                        )}
                    />
                    <PDFDivider />
                    <PDFListItem
                        label={t('client.section3.fields.f2')}
                        value={`${clientValues.title} ${clientValues.firstName} ${clientValues.lastName}`}
                    />
                    <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f3')}
                                value={`${clientValues.street} ${clientValues.streetNumber}`}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f4')}
                                value={clientValues.state}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f5')}
                                value={clientValues.birthday.date}
                            />
                        </View>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f12')}
                                value={clientValues.city}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f13')}
                                value={`${clientValues.phone.prefix}${clientValues.phone.number}`}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f23')}
                                value={clientValues.taxId}
                            />
                            <PDFDivider />
                        </View>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f18')}
                                value={clientValues.psc}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f19')}
                                value={clientValues.email}
                            />
                            <PDFDivider />
                        </View>
                    </View>
                    <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                        <View style={{ marginRight: '50px' }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f14')}
                                value={clientValues.birthday.city}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f6')}
                                value={clientValues.identificationNumber}
                            />
                            <PDFDivider />
                        </View>
                        <View>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f20')}
                                value={clientValues.birthday.state}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f15')}
                                value={formT(
                                    `clientForm.identification.options.${clientValues.identification}`
                                )}
                            />
                            <PDFDivider />
                        </View>
                    </View>
                    <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f7')}
                                value={clientValues.nationality}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f8')}
                                value={formT(
                                    `clientForm.status.options.${clientValues.status}`
                                )}
                            />
                            <PDFDivider />
                        </View>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f16')}
                                value={clientValues.taxResidence}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f17')}
                                value={formT(
                                    `clientForm.education.options.${clientValues.education}`
                                )}
                            />
                            <PDFDivider />
                        </View>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f21')}
                                value={clientValues.dependentChildren}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f22')}
                                value={formT(
                                    `clientForm.salary.options.${clientValues.salary}`
                                )}
                            />
                            <PDFDivider />
                        </View>
                    </View>
                    <PDFListItem
                        label={t('client.section3.fields.f9')}
                        value={clientValues.profession}
                    />
                    <PDFDivider />
                    <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                        <View style={{ marginRight: '16px' }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f10')}
                                value={clientValues.employerName}
                            />
                            <PDFDivider />
                        </View>
                        <View>
                            <PDFDivider />
                            <PDFListItem
                                label={t('client.section3.fields.f24')}
                                value={clientValues.employerId}
                            />
                            <PDFDivider />
                        </View>
                    </View>
                    <PDFDivider />
                    <PDFListItem
                        label={t('client.section3.fields.f11')}
                        value={clientValues.currency}
                    />
                    <PDFDivider />
                </View>
                <View>
                    <PDFCheckbox
                        checked={!clientValues.politicalStatus}
                        label={t('client.section4.fields.a')}
                    />
                    <PDFCheckbox
                        checked={clientValues.politicalStatus}
                        label={t('client.section4.fields.b')}
                    />
                </View>
                <View>
                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock.Title bold>
                        {t('client.section5.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                        <View style={{ flexGrow: 3 }}>
                            <PDFCheckbox
                                checked={clientValues.goal.includes('a')}
                                label={t('client.section5.fields.a')}
                            />
                            <PDFCheckbox
                                checked={clientValues.goal.includes('b')}
                                label={t('client.section5.fields.b')}
                            />
                        </View>
                        <View style={{ flexGrow: 3 }}>
                            <PDFCheckbox
                                checked={clientValues.goal.includes('d')}
                                label={t('client.section5.fields.d')}
                            />
                            <PDFCheckbox
                                checked={clientValues.goal.includes('k')}
                                label={t('client.section5.fields.k')}
                            />
                        </View>
                        <View style={{ flexGrow: 3 }}>
                            <PDFCheckbox
                                checked={clientValues.goal.includes('j')}
                                label={t('client.section5.fields.j')}
                            />
                            <PDFCheckbox
                                checked={clientValues.goal.includes('i')}
                                label={t('client.section5.fields.i')}
                            />
                        </View>
                    </View>
                    <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                        <PDFCheckbox
                            checked={clientValues.goal.some(
                                value =>
                                    ![
                                        'a',
                                        'b',
                                        'd',
                                        'g', // legacy
                                        'h', // legacy
                                        'i',
                                        'j',
                                        'k',
                                    ].includes(value)
                            )}
                            label={t('client.section5.fields.f', {
                                value: clientValues.goal.find(
                                    value =>
                                        ![
                                            'a',
                                            'b',
                                            'd',
                                            'g', // legacy
                                            'h', // legacy
                                            'i',
                                            'j',
                                            'k',
                                        ].includes(value)
                                ),
                            })}
                        />
                    </View>
                </View>
                <View>
                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock bold>
                        {t('client.section6')}
                    </PDFParagraphBlock>
                </View>
                <PDFSignatureBlock
                    date={agreement.dateOfSignature}
                    place={agreement.placeOfSignature}
                    name={`${companyInfo.title} ${companyInfo.firstName} ${
                        companyInfo.lastName
                    } ${t('signatureBlock.manager')}`}
                    signature={`${companyInfo.firstName} ${companyInfo.lastName}`}
                />
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <View>
                    <PDFParagraphBlock.Title size="large" bold underline blue>
                        {t('client.section7.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <PDFDivider />
                    <PdfParagraphBlock align="justify" lineHeight={1.2}>
                        <Trans
                            i18nKey="client.section7.p1"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                name: `${clientValues.title} ${clientValues.firstName} ${clientValues.lastName}`,
                                birthday: `${clientValues.birthday.date}, ${clientValues.birthday.city}`,
                                phone: `${clientValues.phone.prefix}${clientValues.phone.number}`,
                                street: `${clientValues.street} ${clientValues.streetNumber}`,
                                psc: clientValues.psc,
                                city: clientValues.city,
                                state: clientValues.state,
                                mail: clientValues.email,
                                partnerName: `${companyInfo.title} ${companyInfo.firstName} ${companyInfo.lastName}`,
                                partnerICO: companyInfo.ico,
                                partnerAddress: companyInfo.address,
                            }}
                        >
                            Part1
                            <PdfParagraphBlock bold />
                            Part2
                            <PdfParagraphBlock bold />
                            Part3
                            <PdfParagraphBlock bold />
                            Part4
                            <PdfParagraphBlock bold />
                            Part5
                            <PdfParagraphBlock bold />
                            Part6
                            <PdfParagraphBlock bold />
                            Part7
                            <PdfParagraphBlock bold />
                            Part8
                            <PdfParagraphBlock bold />
                            Part9
                            <PdfParagraphBlock bold />
                            Part10
                            <PdfParagraphBlock bold />
                            Part11
                            <PdfParagraphBlock bold />
                        </Trans>
                    </PdfParagraphBlock>
                    <PdfParagraphBlock lineHeight={1.3}>
                        {t('client.section7.p2')}
                    </PdfParagraphBlock>

                    {i18next.language === 'cz' ? (
                        <>
                            <PdfParagraphBlock lineHeight={1.5}>
                                {t('client.section7.p3')}
                            </PdfParagraphBlock>
                            <View>
                                <PDFCheckbox
                                    checked
                                    lineHeight={1.2}
                                    label={t('client.section7.fields.a')}
                                />
                                <PDFCheckbox
                                    checked
                                    lineHeight={1.2}
                                    label={t('client.section7.fields.b')}
                                />
                            </View>
                            <PDFDivider />
                        </>
                    ) : (
                        <PdfParagraphBlock lineHeight={1.2}>
                            {t('client.section7.p3')}
                        </PdfParagraphBlock>
                    )}

                    <PdfParagraphBlock lineHeight={1.2}>
                        {t('client.section7.p4')}
                    </PdfParagraphBlock>

                    <PdfParagraphBlock lineHeight={1.2}>
                        {i18next.language === 'sk' && (
                            <PDFCheckbox checked label="" />
                        )}
                        {t('client.section7.p5')}
                    </PdfParagraphBlock>
                </View>
                <PDFSignatureBlock
                    signature={signature}
                    date={agreement.dateOfSignature}
                    place={agreement.placeOfSignature}
                    name={`${clientValues.title} ${clientValues.firstName} ${clientValues.lastName} (Zákazník)`}
                />
            </PDFBrandedPageWithFooter>
        </>
    );
}
