import { SignatureScope } from './Transaction';

export enum TokenDeliveryMethod {
    PHONE = 'sms',
    EMAIL = 'email',
}

export enum TokenType {
    VERIFICATION = 'verification',
    SIGNATURE = 'signature',
}

export enum TokenState {
    NEW = 'new',
    USED = 'used',
    REPLACED = 'replaced',
}

export interface Token {
    _id: string;
    createdAt: string;
    updatedAt: string;
    owner: string;
    tags: TokenDeliveryMethod[];
    status: TokenState;
    type: TokenType;
    publicKey: string;
    meta: {
        transactionId: string;
        attempts: number;
        deliveryDetail: string;
        scopes?: SignatureScope[];
    };
}
