import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { QuestionWrapper, Tooltip, Typography } from 'common/components';
import { CompanyInfo } from 'common/forms';
import { CompanyInfoFormValues } from 'common/models/Forms';

import { QuestionTitle, PageControls } from '../../../../components';
import {
    selectInformationsCompanyInfo,
    setInformationsCompanyInfoValuesAction,
} from '../../../../ducks';
import { selectPartnerData } from '../../../../../auth/ducks';
import { FeatureProps } from '../../../../index';
import { Col, Icon, Row } from 'antd';

export default function CompanyInfoFeature({ isPrivileged }: FeatureProps) {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const match = useRouteMatch<{ id: string }>();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectInformationsCompanyInfo);
    const userValues = useSelector(selectPartnerData);

    let submitForm: FormikProps<CompanyInfoFormValues>['submitForm'];

    function handleBackClick() {
        history.goBack();
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(cb: FormikProps<CompanyInfoFormValues>['submitForm']) {
        submitForm = cb;
    }

    function handleSubmit(values: CompanyInfoFormValues) {
        if (!isPrivileged) {
            dispatch(setInformationsCompanyInfoValuesAction(values));
        }
        history.push(`/form/${match!.params.id}/informations/customer-info-1`);
    }

    function renderCompanyInfoTooltip() {
        return (
            i18n.language === 'cz' && (
                <Tooltip
                    title={
                        <div className="company-info-tooltip">
                            <Typography.Paragraph>
                                {t('informationsFeature.companyInfoTooltip1')}
                                <br />
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                {t('informationsFeature.companyInfoTooltip2')}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                {t('informationsFeature.companyInfoTooltip3')}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                {t('informationsFeature.companyInfoTooltip4')}
                            </Typography.Paragraph>
                        </div>
                    }
                >
                    <Icon type="info-circle" />
                </Tooltip>
            )
        );
    }

    return (
        <>
            <QuestionTitle>
                <Row justify="space-between">
                    <Col span={23}>{t('informationsFeature.companyInfo')}</Col>
                    <Col>{renderCompanyInfoTooltip()}</Col>
                </Row>
            </QuestionTitle>
            <QuestionWrapper>
                <CompanyInfo
                    initialValues={initialValues || userValues || undefined}
                    onSubmit={handleSubmit}
                    bindSubmit={bindSubmit}
                />
            </QuestionWrapper>
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
