import { Field, FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';
import { useRegisteredRef } from 'react-register-nodes';

import { Textarea } from '../../components';

interface Props {
    name: string;
    label?: ReactNode;
    placeholder?: string;
}

export default function TextareaField({ name, ...bag }: Props) {
    const ref = useRegisteredRef(name);

    return (
        <Field name={name}>
            {({ field, form: { errors } }: FieldProps<any>) => (
                <div ref={errors[name] ? ref : null}>
                    <Textarea
                        rows={4}
                        error={getIn(errors, name)}
                        {...field}
                        {...bag}
                    />
                </div>
            )}
        </Field>
    );
}
