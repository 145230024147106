import cn from 'classnames';
import React, { ReactNode } from 'react';
import { BasicProps } from 'antd/lib/layout/layout';

import { Card, Typography } from '../../components';

interface Props {
    children: ReactNode;
}

export default function QuestionWrapper({ children }: Props) {
    return (
        <div className="question-wrapper">
            <Card children={children} />
        </div>
    );
}

QuestionWrapper.Divider = ({
    children,
    type = 'default',
}: BasicProps & { type?: 'default' | 'bordered' }) => {
    return (
        <div
            className={cn('question-wrapper-divider', {
                'question-wrapper-divider-bordered': type === 'bordered',
            })}
        >
            {children}
        </div>
    );
};

QuestionWrapper.Title = ({ children }: BasicProps) => {
    return (
        <Typography.Title level={4} className="question-wrapper-title">
            {children}
        </Typography.Title>
    );
};
