import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper } from '../../components';
import { TextareaField, CheckBoxField } from '../../fields';
import { MeetingFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';

interface Props {
    initialValues?: Partial<MeetingFormValues>;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: MeetingFormValues) => void;
}

export default function MeetingForm({
    onSubmit,
    initialValues,
    bindSubmit,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<MeetingFormValues>({
        a: Yup.boolean(),
        b: Yup.boolean(),
        note: Yup.string(),
    });

    function handleOnSubmit(values: MeetingFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({
        submitForm,
    }: // values,
    FormikProps<MeetingFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="meeting-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('meetingForm.subTitle')}
                            </QuestionWrapper.Title>
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <CheckBoxField
                                name="a"
                                label={t('meetingForm.a')}
                            />
                            <CheckBoxField
                                name="b"
                                label={t('meetingForm.b')}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('meetingForm.note')}
                            </QuestionWrapper.Title>
                            <TextareaField name="note" />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a ?? false,
                b: initialValues?.b ?? false,
                note: initialValues?.note ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
