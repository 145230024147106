import React, { ReactNode, useState } from 'react';
import { Slider, Row, Col, Icon } from 'antd';
import { SliderProps, SliderValue } from 'antd/lib/slider';
import numbro from 'numbro';

import { Typography, Tooltip, Input } from '../../../components';

interface Props extends SliderProps {
    label?: ReactNode;
}

export default function EnhancedSlider({
    value: initialValue,
    onChange,
    defaultValue,
    ...props
}: Props) {
    const [value, setValue] = useState<number>(
        Number(initialValue) || Number(defaultValue) || 0
    );

    function handleChange(value: SliderValue) {
        if (typeof value === 'number') setValue(value);
    }

    function handleOnAfterChange() {
        onChange && onChange(value);
    }

    return (
        <Slider
            value={value}
            onChange={handleChange}
            onAfterChange={handleOnAfterChange}
            {...props}
        />
    );
}

interface SliderWithNumberProps {
    label: ReactNode;
    defaultValue: number;
    value?: number;
    min?: number;
    max?: number;
    step?: number;
    tooltip?: ReactNode;
    currencySymbol?: string;
    onChange?: (value: number) => void;
}

function SliderWithNumber({
    label,
    value: initialValue,
    onChange,
    tooltip,
    currencySymbol,
}: SliderWithNumberProps) {
    const [value, setValue] = useState<number>(initialValue || 0);

    function handleChange(value?: number) {
        if (typeof value === 'number') setValue(value);
        onChange && onChange(Number(value));
    }

    return (
        <div className="slider-with-number">
            <Row gutter={20} type="flex" align="middle">
                <Col xs={24} md={10}>
                    <Typography.Paragraph>{label}</Typography.Paragraph>
                </Col>
                <Col xs={24} md={6}>
                    <Input.Number value={value} onChange={handleChange} />
                </Col>
                <Col xs={20} md={6} style={{ textAlign: 'center' }}>
                    {numbro(value).formatCurrency(
                        currencySymbol && { currencySymbol }
                    )}
                </Col>
                {tooltip && (
                    <Col xs={4} md={2}>
                        <Tooltip overlay={tooltip}>
                            <Icon type="info-circle" />
                        </Tooltip>
                    </Col>
                )}
            </Row>
        </div>
    );
}

EnhancedSlider.WithNumberInput = SliderWithNumber;
