import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import useUnmount from 'react-use/lib/useUnmount';

import { QuestionWrapper } from 'common/components';
import {
    ClientEmailVerificationForm,
    ClientPhoneVerificationForm,
} from 'common/forms';
import { ClientVerificationFormPhoneValues } from 'common/models/Forms';
import {
    selectFormTransaction,
    selectIsFormVerified,
    selectInformationsCustomerInfo1,
} from 'features/form/ducks';
import { I18nNameSpaces } from 'common/services/i18n';
import {
    submitEmailVerificationCodeAction,
    submitPhoneVerificationCodeAction,
    resetClientVerificationAction,
    selectClientVerification,
    requestEmailVerificationCodeAction,
    requestPhoneVerificationCodeAction,
} from './ducks';
import { TokenState } from 'common/api/token';
import { TokenDeliveryMethod } from 'common/models/Token';
import { PageControls, QuestionTitle } from 'features/form/components';

export default function CustomerVerificationFeature() {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const history = useHistory();
    const dispatch = useDispatch();

    const initialValues = useSelector(selectInformationsCustomerInfo1);
    const transaction = useSelector(selectFormTransaction);
    const { isVerifiedEmail, isVerifiedPhone } =
        useSelector(selectIsFormVerified);
    const { email: emailState, phone: phoneState } = useSelector(
        selectClientVerification
    );

    useUnmount(() => {
        dispatch(resetClientVerificationAction());
    });

    function handleSkip() {
        history.push(`/form/${transaction!._id}/informations/company-info`);
    }

    function handleContinue() {
        history.push(`/form/${transaction!._id}/informations/company-info`);
    }

    function handleBackClick() {
        history.goBack();
    }

    async function handleRequestEmailCode(email: string) {
        dispatch(requestEmailVerificationCodeAction(email));
    }

    async function handleVerifyEmailCode(code: string) {
        dispatch(submitEmailVerificationCodeAction(code));
    }

    async function handleRequestPhoneCode(
        phone: ClientVerificationFormPhoneValues['phone']
    ) {
        dispatch(requestPhoneVerificationCodeAction(phone));
    }

    async function handleVerifyPhoneCode(code: string) {
        dispatch(submitPhoneVerificationCodeAction(code));
    }

    function getErrorByFormType(deliveryMethod: TokenDeliveryMethod) {
        const targetState =
            deliveryMethod === TokenDeliveryMethod.PHONE
                ? phoneState
                : emailState;

        if (targetState.status === TokenState.DELIVERY_ERROR) {
            return t([
                `verificationForm.${deliveryMethod}.request.${targetState.httpStatus}`,
                `verificationForm.${deliveryMethod}.request.error`,
            ]);
        }
        if (targetState.status === TokenState.SUBMISSION_ERROR) {
            return targetState.attemptsLeft
                ? t(`verificationForm.${deliveryMethod}.verify.errorAttempts`, {
                      attemptsLeft: targetState.attemptsLeft,
                  })
                : t([
                      `verificationForm.${deliveryMethod}.verify.${targetState.httpStatus}`,
                      `verificationForm.${deliveryMethod}.verify.error`,
                  ]);
        }
        return null;
    }

    function getSuccessByFormType(deliveryMethod: TokenDeliveryMethod) {
        const targetState =
            deliveryMethod === TokenDeliveryMethod.PHONE
                ? phoneState
                : emailState;
        const isVerified =
            deliveryMethod === TokenDeliveryMethod.PHONE
                ? isVerifiedPhone
                : isVerifiedEmail;

        if (targetState.status === TokenState.DELIVERED) {
            return t(`verificationForm.${deliveryMethod}.request.success`);
        }
        if (
            targetState.status === TokenState.VERIFIED ||
            (isVerified && targetState.status === TokenState.DEFAULT)
        ) {
            return t(`verificationForm.${deliveryMethod}.verify.success`);
        }
        return null;
    }

    function isVerifyDisabled(deliveryMethod: TokenDeliveryMethod) {
        const targetState =
            deliveryMethod === TokenDeliveryMethod.PHONE
                ? phoneState
                : emailState;
        const isVerified =
            deliveryMethod === TokenDeliveryMethod.PHONE
                ? isVerifiedPhone
                : isVerifiedEmail;

        return (
            (isVerified && targetState.status === TokenState.DEFAULT) ||
            [
                TokenState.DEFAULT,
                TokenState.DELIVERY_ERROR,
                TokenState.REQUESTED,
            ].includes(targetState.status)
        );
    }

    return (
        <>
            <QuestionTitle>{t('verificationForm.title')}</QuestionTitle>
            <QuestionWrapper>
                <div className="client-verification-form">
                    <QuestionWrapper.Divider>
                        <Row gutter={100}>
                            <Col lg={12}>
                                <ClientPhoneVerificationForm
                                    initialValues={initialValues || undefined}
                                    onRequestCode={handleRequestPhoneCode}
                                    onVerifyCode={handleVerifyPhoneCode}
                                    disableVerify={isVerifyDisabled(
                                        TokenDeliveryMethod.PHONE
                                    )}
                                    loadingVerify={
                                        TokenState.SUBMITTED ===
                                        phoneState.status
                                    }
                                    loadingRequest={
                                        TokenState.REQUESTED ===
                                        phoneState.status
                                    }
                                    error={getErrorByFormType(
                                        TokenDeliveryMethod.PHONE
                                    )}
                                    success={getSuccessByFormType(
                                        TokenDeliveryMethod.PHONE
                                    )}
                                />
                            </Col>
                            <Col lg={12}>
                                <ClientEmailVerificationForm
                                    initialValues={initialValues || undefined}
                                    onRequestCode={handleRequestEmailCode}
                                    onVerifyCode={handleVerifyEmailCode}
                                    disableVerify={isVerifyDisabled(
                                        TokenDeliveryMethod.EMAIL
                                    )}
                                    loadingVerify={
                                        TokenState.SUBMITTED ===
                                        emailState.status
                                    }
                                    loadingRequest={
                                        TokenState.REQUESTED ===
                                        emailState.status
                                    }
                                    error={getErrorByFormType(
                                        TokenDeliveryMethod.EMAIL
                                    )}
                                    success={getSuccessByFormType(
                                        TokenDeliveryMethod.EMAIL
                                    )}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                </div>
            </QuestionWrapper>
            <PageControls
                disableContinue={!isVerifiedPhone || !isVerifiedEmail}
                onSkip={handleSkip}
                onBack={handleBackClick}
                onContinue={handleContinue}
            />
        </>
    );
}
