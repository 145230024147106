import { Token, TokenDeliveryMethod, TokenType } from 'common/models/Token';
import { SignatureScope } from 'common/models/Transaction';
import { apiClient, ApiClientResponse } from '../services/api-client';

export enum TokenState {
    DEFAULT = 'default',
    REQUESTED = 'code-requested',
    DELIVERED = 'code-delivered',
    SUBMITTED = 'code-submitted',
    VERIFIED = 'verified',
    DELIVERY_ERROR = 'error-deliver-code',
    SUBMISSION_ERROR = 'error-submit-code',
}

export default {
    create: (
        transactionId: string,
        type: TokenType,
        deliveryMethod: TokenDeliveryMethod,
        scopes?: SignatureScope[]
    ): Promise<ApiClientResponse<void>> => {
        return apiClient.post(`/tokens/${type}/${deliveryMethod}`, {
            transactionId,
            scopes,
        });
    },
    find: (query?: {
        [key: string]: any;
    }): Promise<ApiClientResponse<Token[]>> => {
        return apiClient.get(`/tokens`, {
            params: {
                $limit: -1,
                $sort: { updatedAt: -1 },
                ...query,
            },
        });
    },
    verify: (
        transactionId: string,
        type: TokenType,
        deliveryMethod: TokenDeliveryMethod,
        deliveryDetail: string,
        code: string
    ): Promise<ApiClientResponse<{ attemptsLeft?: number }>> => {
        return apiClient.post(`/tokens/${type}/${deliveryMethod}/verify`, {
            transactionId,
            deliveryDetail,
            code,
        });
    },
};
