import { TableGroupResult } from '../components/Form/Radio';

export interface ClientVerificationFormPhoneValues {
    phone: ClientFormValues['phone'];
    code: string;
}

export interface ClientVerificationFormEmailValues {
    email: ClientFormValues['email'];
    code: string;
}

export interface DocumentSignaturesFormValues {
    code: string;
}

export interface CompanyInfoFormValues {
    gender: string;
    title: string;
    firstName: string;
    lastName: string;
    partnerNumber: string;
    ico: string;
    address: string;
    email: string;
    phone: string;
}

export interface ClientFormValues {
    gender: string;
    title: string;
    firstName: string;
    lastName: string;
    street: string;
    streetNumber: string;
    city: string;
    psc: string;
    state: string;
    birthday: {
        date: string;
        city: string;
        state: string;
    };
    phone: {
        prefix: string;
        number: string;
    };
    email: string;
    taxId: string;
    identificationNumber: string;
    identification: string;
    nationality: string;
    taxResidence: string;
    education: string;
    status: string;
    salary: string;
    dependentChildren: number;
    profession: string;
    employerName: string;
    employerId: string;
    politicalStatus: boolean;
    goal: string[];
    currency: string;
}

export interface InvestingExperienceFormValues {
    table?: TableGroupResult[];
    a: string;
    b: {
        a: string;
        b: string;
        c: string;
        d: string;
    };
    c: {
        a: string;
        b: string;
        c: string;
        d: string;
    };
    useOfServices: string;
    wayOfEducation: string;
    stockTradingLoan: string;
}

export const InvestingExperienceCalcSchema = {
    questions: {
        a: {
            moreThanTwoYears: 1,
            lessThanTwoYears: 1,
            noPracticalExperience: 0,
            noKnowledge: 0,
        },
        b: {
            moreThanTwoYears: 2,
            lessThanTwoYears: 1,
            noPracticalExperience: 1,
            noKnowledge: 0,
        },
        c: {
            moreThanTwoYears: 3,
            lessThanTwoYears: 2,
            noPracticalExperience: 1,
            noKnowledge: 0,
        },
        d: {
            moreThanTwoYears: 4,
            lessThanTwoYears: 2,
            noPracticalExperience: 1,
            noKnowledge: 0,
        },
        e: {
            moreThanTwoYears: 4,
            lessThanTwoYears: 3,
            noPracticalExperience: 2,
            noKnowledge: 0,
        },
    },
    sub: {
        a: {
            true: 0,
            false: 1,
        },
        b: {
            true: 0,
            false: 1,
        },
        c: {
            true: 0,
            false: 1,
        },
    },
    useOfServices: {
        a: 1,
        b: 2,
        c: 2,
        d: 0,
    },
    wayOfEducation: {
        a: 0,
        b: 1,
        c: 2,
        d: 2,
    },
};

export interface HazardProfileFormValues {
    a: string;
    b: string;
    c: string;
    d: string;
}

export interface FinancesFormValues {
    a: string;
    c: string;
    d: string;
    e: string;
    f: {
        a?: number;
        b?: number;
        c?: number;
        d?: number;
        e?: number;
    };
}

export const financesValidationSchema = {
    a: {
        a: 2,
        b: 1,
        c: 0,
        d: 2,
        e: 0,
        f: 2,
    },
    c: {
        a: 1,
        b: 2,
        c: 3,
        d: 3,
    },
    d: {
        a: 3,
        b: 2,
        c: 1,
        d: 0,
    },
    e: {
        a: 0,
        b: 1,
        c: 2,
        d: 2,
    },
};

export interface FinanceAnalyseFormValues {
    liquidity: number;
    capitalInvesting: number;
    monthSalary: number;
    monthExpenses: number;
    disposableResources: string;
}

export interface AgreementsFormValues {
    hazardAgreements: boolean;
    statement: {
        a: boolean;
        b: boolean;
        c: boolean;
        d: boolean;
        e: boolean;
        f: boolean;
        g: boolean;
        h: boolean;
    };
    placeOfSignature: string;
    dateOfSignature: string;
    interviewTime: string;
}

export interface InvestingGoalsFormValues {
    a: string[];
    b: string;
    d: {
        regularity: string;
        amount: number;
        regularly: number;
    };
    e: string;
    f: {
        is: boolean;
        a: string;
        b: string;
        c: string;
    };
    g: Partial<{
        a: {
            checked: boolean;
        };
        b: Record<
            string,
            | boolean
            | Partial<{
                  checked: boolean;
                  notes: string;
                  gains: string;
              }>
        >;
        c: Record<
            string,
            | boolean
            | Partial<{
                  checked: boolean;
                  notes: string;
              }>
        >;
    }>;
}

export interface ConsultancyInitiationFormValues {
    a: string;
    b: string[];
    table?: FondDataFormValues[];
    tableAltCurrency?: FondDataFormValues[];
}

export interface ClientStatementFormValues {
    a?: boolean;
    b: string[];
}

export interface ClientConfirmationFormValues {
    a: number;
    b: number;
    c: string;
    d: string;
    monthly: number;
    once: number;
}

export interface ConsultantConfirmationFormValues {
    a: {
        a?: boolean;
        b?: boolean;
        c?: boolean;
        d?: boolean;
    };
    b: {
        place: string;
        date: string;
        interviewDuration: string;
        attendants: string;
        city: string;
    };
    c: {
        place: string;
        date: string;
        interviewDuration: string;
        attendants: string;
        time: string;
        city: string;
    };
}

export interface RequestInfoFormValues {
    a: string;
    b: string;
    c: string;
}

export interface BusinessPartnerInfoFormValues {
    a: string;
    b: string;
    c: string;
    d: boolean;
    e: string;
    f: string;
    g: string;
    h: string;
}

export interface FondDataFormValues {
    code: string;
    type: 'buy' | 'sell' | 'hold' | string;
    regularAmount: number;
    oneTimeAmount: number;
    agio: number;
    note?: string;
    key?: string;
}

export interface MeetingFormValues {
    note: string;
    a: boolean;
    b: boolean;
}

export enum SelectedInvestmentCategoryOption {
    growth = 'c',
    dynamic = 'd',
    speculative = 'e',
}

export interface BestOfTempletonAccountFormValues {
    accountName: string;
    useCustomer1Address: boolean;
    address: {
        firstName: string;
        lastName: string;
        street: string;
        streetNumber: string;
        city: string;
        psc: string;
        state: string;
    };
    bankAccount: {
        accountOwnerName: string;
        IBAN: string;
        Swift: string;
        bankName: string;
        state: string;
    };
    selectedInvestmentCategory: SelectedInvestmentCategoryOption;
    experienceInSelectedCategory: string;
    avgNumberOfYearlyTransactions: number;
    avgValueOfYearlyTransactions: number;
    chosenPortfolio: string;
    plannedInvestmentDeposit: number | null;
    plannedInvestmentDepositCurrency: string;
    plannedWithdrawal: number | null;
    plannedWithdrawalCurrency: string;
    transferingFromFranklinTempleton: boolean;
    franklinTempletonDepositNumber: string;
}
