import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
    label?: ReactNode;
    error?: ReactNode;
    children?: ReactNode;
}

export const Control: FunctionComponent<Props> = ({
    label,
    error,
    children,
}: Props) => (
    <div className="control">
        <div className="control-label">{label}</div>
        <div className="control-child">{children}</div>
        {error && <div className="control-error">{error}</div>}
    </div>
);
