import React from 'react';
import ReactDOM from 'react-dom';

import Root from './root';

import './common/services/i18n';
import './root/styles/main.css';

const rootElement = document.getElementById('root');

if (rootElement && rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<Root />, rootElement);
} else {
    ReactDOM.render(<Root />, rootElement);
}
