import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { I18nNameSpaces } from '../../services/i18n';
import PdfParagraphBlock from './ParagraphBlock';
import SignatureLine from './SignatureLine';

const styles = StyleSheet.create({
    row: {
        flexGrow: 1,
        flexDirection: 'row',
    },
    block: {
        flexGrow: 1,
    },
    text: {
        flexGrow: 3,
        margin: 10,
    },
    fill1: {
        flexGrow: 2,
    },
    fill2: {
        flexGrow: 2,
    },
});

interface Props {
    date: string;
    place: string;
    name?: string;
    name2?: string;
    name3?: string;
    signature?: string;
    signature2?: string;
    signature3?: string;
    time?: string;
}

export default function SignatureBlock(props: Props) {
    const { t } = useTranslation(I18nNameSpaces.PDF);
    return (
        <View wrap={false} style={{ marginTop: 'auto' }}>
            <View style={styles.row}>
                <PdfParagraphBlock>
                    {t('signatureBlock.date')}
                </PdfParagraphBlock>
                <PdfParagraphBlock bold>{props.date}</PdfParagraphBlock>
            </View>
            {props.time && (
                <View style={styles.row}>
                    <PdfParagraphBlock>
                        {t('signatureBlock.time')}
                    </PdfParagraphBlock>
                    <PdfParagraphBlock bold>{props.time}</PdfParagraphBlock>
                </View>
            )}
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <View>
                    <View style={styles.row}>
                        <PdfParagraphBlock>
                            {t('signatureBlock.place')}
                        </PdfParagraphBlock>
                        <PdfParagraphBlock bold>
                            {props.place}
                        </PdfParagraphBlock>
                    </View>
                </View>
                <View
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    <SignatureLine
                        name={props.name}
                        signature={props.signature}
                    />
                    {props.name2 && (
                        <SignatureLine
                            name={props.name2}
                            signature={props.signature2}
                        />
                    )}
                    {props.name3 && (
                        <SignatureLine
                            name={props.name3}
                            signature={props.signature3}
                        />
                    )}
                </View>
            </View>
        </View>
    );
}
