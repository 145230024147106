import { ConfigProvider } from 'antd';
import sk from 'antd/lib/locale-provider/sk_SK';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Provider } from 'react-redux';
import { NodeManager } from 'react-register-nodes';
import { Redirect, Route, Switch } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';

import { Utils } from '../common/components';
import ProtectedRoute from '../common/guards/AuthGuard';
import AuthFeature from '../features/auth';
import FormFeature from '../features/form';
import HomeFeature from '../features/home';

import ErrorBoundary from './components/ErrorBoundary';
import { ScrollToTop } from './components/ScrollToTop';
import { WaitingComponent } from './components/WaitingComponent';

import { persistor, store } from './store/configure-store';
import { history } from './store/root-reducer';

export default function Root() {
    function renderEmpty() {
        return <Utils.Empty />;
    }

    return (
        <NodeManager>
            <ConfigProvider renderEmpty={renderEmpty} locale={sk}>
                <Provider store={store}>
                    <ErrorBoundary>
                        <PersistGate loading={<div />} persistor={persistor}>
                            <ConnectedRouter history={history}>
                                <ScrollToTop>
                                    <Switch>
                                        <Route
                                            path="/auth"
                                            component={WaitingComponent(
                                                AuthFeature
                                            )}
                                        />
                                        <ProtectedRoute
                                            path="/home"
                                            component={WaitingComponent(
                                                HomeFeature
                                            )}
                                        />
                                        <ProtectedRoute
                                            path="/form/:id"
                                            component={WaitingComponent(
                                                FormFeature
                                            )}
                                        />
                                        <Redirect path="/" to="/home" />
                                    </Switch>
                                </ScrollToTop>
                            </ConnectedRouter>
                        </PersistGate>
                    </ErrorBoundary>
                </Provider>
            </ConfigProvider>
        </NodeManager>
    );
}
