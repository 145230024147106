import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { ClientConfirmationForm } from 'common/forms';
import { ClientConfirmationFormValues } from 'common/models/Forms';

import { QuestionTitle, PageControls } from '../../../../components';
import {
    selectProtocolCustomerConfirmation,
    setProtocolCustomerConfirmationValuesAction,
} from '../../../../ducks';
import { FeatureProps } from '../../../../index';

export default function ProtocolCustomerConfirmationFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch<{ id: string }>();
    const initialValues = useSelector(selectProtocolCustomerConfirmation);

    let submitForm: FormikProps<ClientConfirmationFormValues>['submitForm'];

    function handleBackClick() {
        history.push(`/form/${match!.params.id}/protocol/customer-statement`);
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(
        cb: FormikProps<ClientConfirmationFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(values: ClientConfirmationFormValues) {
        if (!isPrivileged) {
            dispatch(setProtocolCustomerConfirmationValuesAction(values));
        }

        history.push(
            `/form/${match!.params.id}/protocol/consultant-confirmation`
        );
    }

    return (
        <>
            <QuestionTitle>
                {t('protocolFeature.customerConfirmation')}
            </QuestionTitle>
            <ClientConfirmationForm
                initialValues={initialValues || undefined}
                bindSubmit={bindSubmit}
                onSubmit={handleSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
