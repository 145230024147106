import React, { ReactNode } from 'react';
import PDFBrandedPage from './BrandedPage';
import PDFPageFooter from './PageFooter';

interface Props {
    children: ReactNode;
    footerTitle: ReactNode;
    footerContent?: ReactNode;
}

export default function PDFBrandedPageWithFooter({
    children,
    footerTitle,
    footerContent,
}: Props) {
    return (
        <PDFBrandedPage>
            {children}
            <PDFPageFooter
                title={footerTitle}
                content={footerContent ? footerContent : null}
            />
        </PDFBrandedPage>
    );
}
