import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import useUnmount from 'react-use/lib/useUnmount';

import { I18nNameSpaces } from 'common/services/i18n';
import { PasswordResetForm } from 'common/forms';
import { ResetPasswordFormValues } from 'common/models/User';
import { AppState } from 'common/models/AppState';
import { Result } from 'common/components';

import { AuthPageDescription } from '../../../../components';
import {
    requestPasswordRecoveryActions,
    ForgottenPasswordState,
    selectForgottenPasswordError,
    selectForgottenPasswordSuccess,
    resetPasswordRecoveryAction,
} from '../../ducks';

interface StateToProps {
    success: ForgottenPasswordState['success'];
    error: ForgottenPasswordState['error'];
}

interface DispatchToProps {
    requestReset: typeof requestPasswordRecoveryActions.request;
    resetRecovery: typeof resetPasswordRecoveryAction;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

function ForgottenPasswordRequestFeature({
    requestReset,
    resetRecovery,
    success,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Auth);
    const forgottenPassPage = 'forgottenPassword.request.page';

    useUnmount(() => {
        resetRecovery();
    });

    function handlePasswordResetSubmit({ email }: ResetPasswordFormValues) {
        requestReset(email);
    }

    return (
        <Row type="flex" justify="center">
            <Col xs={24} md={8}>
                {success ? (
                    <Result
                        status="success"
                        title={t(`${forgottenPassPage}.successTitle`)}
                        subTitle={t(`${forgottenPassPage}.successSubtitle`)}
                    />
                ) : (
                    <>
                        <AuthPageDescription
                            title={t(`${forgottenPassPage}.title`)}
                            description={t(`${forgottenPassPage}.description`)}
                        />
                        <PasswordResetForm
                            onSubmit={handlePasswordResetSubmit}
                        />
                    </>
                )}
            </Col>
        </Row>
    );
}

const mapDispatchToProps: DispatchToProps = {
    requestReset: requestPasswordRecoveryActions.request,
    resetRecovery: resetPasswordRecoveryAction,
};

const mapStateToProps = (state: AppState): StateToProps => ({
    error: selectForgottenPasswordError(state),
    success: selectForgottenPasswordSuccess(state),
});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ForgottenPasswordRequestFeature)
);
