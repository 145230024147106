import i18next from 'i18next';

import { apiClient, ApiClientResponse } from '../services/api-client';
import { FormState } from '../../features/form/ducks';
import { Transaction } from '../models/Transaction';

export default {
    create: (owner: string, form: FormState) => {
        return apiClient.post<Transaction>(`/transactions`, {
            type: 'questionnaire',
            time: new Date().toISOString(),
            owner,
            entries: [{ meta: form }],
        });
    },
    edit: (id: string, form: Partial<FormState>) => {
        return apiClient.patch(`/transactions/${id}`, {
            entries: [{ meta: form }],
        });
    },
    remove: (id: string) => {
        return apiClient.patch(`/transactions/${id}`, {
            status: 'deleted',
        });
    },
    find: (query?: {
        [key: string]: any;
    }): Promise<ApiClientResponse<Transaction[]>> => {
        return apiClient.get(`/transactions`, {
            params: {
                $limit: -1,
                $sort: { updatedAt: -1 },
                status: 'new',
                ...query,
            },
        });
    },
    findOne: (id: string) => {
        return apiClient.get(`/transactions/${id}`);
    },
    forwardDocuments: (
        id: string,
        documents: { blob: Blob; filename: string }[]
    ) => {
        const formData = new FormData();
        documents.forEach(document =>
            formData.append('signedDocuments', document.blob, document.filename)
        );

        return apiClient.post(`/transactions/${id}/documents`, formData, {
            params: { lang: i18next.language },
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },
};
