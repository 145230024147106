import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Row, Col, Typography } from 'antd';

import { QuestionWrapper } from '../../components';
import {
    InputField,
    RadioField,
    InputNumberField,
    SelectField,
    CheckBoxField,
} from '../../fields';
import {
    BestOfTempletonAccountFormValues,
    HazardProfileFormValues,
    InvestingExperienceFormValues,
    SelectedInvestmentCategoryOption,
} from '../../models/Forms';
import { I18nNameSpaces, Languages } from '../../services/i18n';
import { SelectOption } from 'common/components/Form/Select';
import { InvestingStrategy } from 'features/form/ducks';
import SliderWithNumberField from 'common/fields/SliderWithNumber';
import { VolatilityOptions } from '../HazardProfile';

enum MoventumPortfolios {
    Defensive = 'defensive',
    Balanced = 'balanced',
    Dynamic = 'dynamic',
    Offensive = 'offensive',
}

const CurrencyMap = {
    EUR: ' €',
    CZK: ' KČ',
};

interface Props {
    error?: boolean;
    initialValues?: Partial<BestOfTempletonAccountFormValues>;
    investingExperienceValues?: InvestingExperienceFormValues;
    hazard?: HazardProfileFormValues;
    onSubmit: (values: BestOfTempletonAccountFormValues) => void;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    strategy: { type: string; name: string };
}

export default function BestOfTempletonAccountCreationForm({
    onSubmit,
    initialValues,
    investingExperienceValues,
    hazard,
    error,
    bindSubmit,
    strategy,
}: Props) {
    const { t, i18n } = useTranslation([
        I18nNameSpaces.Forms,
        I18nNameSpaces.Common,
    ]);
    const schema = Yup.object().shape<BestOfTempletonAccountFormValues>({
        accountName: Yup.string().required(t('form.required')),
        useCustomer1Address: Yup.boolean().required(t('form.required')),
        address: Yup.object()
            .shape({
                firstName: Yup.string(),
                lastName: Yup.string(),
                street: Yup.string(),
                streetNumber: Yup.string(),
                city: Yup.string(),
                psc: Yup.string(),
                state: Yup.string(),
            })
            .when('useCustomer1Address', {
                is: false,
                then: Yup.object()
                    .shape({
                        firstName: Yup.string().required(t('form.required')),
                        lastName: Yup.string().required(t('form.required')),
                        street: Yup.string().required(t('form.required')),
                        streetNumber: Yup.string().required(t('form.required')),
                        city: Yup.string().required(t('form.required')),
                        psc: Yup.string().required(t('form.required')),
                        state: Yup.string().required(t('form.required')),
                    })
                    .required(t('form.required')),
            }),
        bankAccount: Yup.object()
            .shape({
                accountOwnerName: Yup.string().required(t('form.required')),
                IBAN: Yup.string().required(t('form.required')),
                Swift: Yup.string().required(t('form.required')),
                bankName: Yup.string().required(t('form.required')),
                state: Yup.string().required(t('form.required')),
            })
            .required('form.required'),
        selectedInvestmentCategory: Yup.mixed()
            .oneOf(Object.values(SelectedInvestmentCategoryOption))
            .required(t('form.required')),
        experienceInSelectedCategory: Yup.string().required(t('form.required')),
        avgNumberOfYearlyTransactions: Yup.number(),
        avgValueOfYearlyTransactions: Yup.number(),
        chosenPortfolio: Yup.string()
            .oneOf(Object.values(MoventumPortfolios))
            .required(t('form.required')),
        plannedInvestmentDeposit: Yup.number().nullable(),
        plannedInvestmentDepositCurrency: Yup.string(),
        plannedWithdrawal: Yup.number().nullable(),
        plannedWithdrawalCurrency: Yup.string(),
        transferingFromFranklinTempleton: Yup.boolean(),
        franklinTempletonDepositNumber: Yup.string().when(
            'transferingFromFranklinTempleton',
            {
                is: true,
                then: Yup.string().required(t('form.required')),
            }
        ),
    });

    function createPortfolioOptions(): SelectOption[] {
        // low-risk portfolios
        const options: SelectOption[] = [
            {
                value: MoventumPortfolios.Defensive,
                label: t(
                    `${I18nNameSpaces.Forms}:bestOfTempletonForm.portfolios.${MoventumPortfolios.Defensive}`
                ),
            },
            {
                value: MoventumPortfolios.Balanced,
                label: t(
                    `${I18nNameSpaces.Forms}:bestOfTempletonForm.portfolios.${MoventumPortfolios.Balanced}`
                ),
            },
        ];

        // high-risk portfolios
        if (strategy.type === InvestingStrategy.Dynamic) {
            options.push({
                value: MoventumPortfolios.Dynamic,
                label: t(
                    `${I18nNameSpaces.Forms}:bestOfTempletonForm.portfolios.${MoventumPortfolios.Dynamic}`
                ),
            });

            if (hazard?.c === VolatilityOptions.Unlimited) {
                options.push({
                    value: MoventumPortfolios.Offensive,
                    label: t(
                        `${I18nNameSpaces.Forms}:bestOfTempletonForm.portfolios.${MoventumPortfolios.Offensive}`
                    ),
                });
            }
        }
        return options;
    }

    function handleOnSubmit(values: BestOfTempletonAccountFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({
        submitForm,
        values,
    }: FormikProps<BestOfTempletonAccountFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <Form>
                <QuestionWrapper.Divider>
                    <InputField
                        name="accountName"
                        label={t('bestOfTempletonForm.accountName.label')}
                        placeholder={t(
                            'bestOfTempletonForm.accountName.placeholder'
                        )}
                    />
                </QuestionWrapper.Divider>
                <QuestionWrapper.Divider>
                    <QuestionWrapper.Title>
                        {t('bestOfTempletonForm.address.title')}
                    </QuestionWrapper.Title>
                    <RadioField
                        name="useCustomer1Address"
                        type="horizontal"
                        options={[
                            {
                                label: t(
                                    'bestOfTempletonForm.useCustomer1Address.options.useCustomer1'
                                ),
                                value: true,
                            },
                            {
                                label: t(
                                    'bestOfTempletonForm.useCustomer1Address.options.useOther'
                                ),
                                value: false,
                            },
                        ]}
                        label={t(
                            'bestOfTempletonForm.useCustomer1Address.label'
                        )}
                    />
                    {!values.useCustomer1Address && (
                        <>
                            <Row gutter={20}>
                                <Col xs={24} md={12}>
                                    <InputField
                                        name="address.firstName"
                                        label={t(
                                            'bestOfTempletonForm.address.firstName.label'
                                        )}
                                        placeholder={t(
                                            'bestOfTempletonForm.address.firstName.placeholder'
                                        )}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <InputField
                                        name="address.lastName"
                                        label={t(
                                            'bestOfTempletonForm.address.lastName.label'
                                        )}
                                        placeholder={t(
                                            'bestOfTempletonForm.address.lastName.placeholder'
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={16}>
                                    <InputField
                                        name="address.street"
                                        label={t(
                                            'bestOfTempletonForm.address.street.label'
                                        )}
                                        placeholder={t(
                                            'bestOfTempletonForm.address.street.placeholder'
                                        )}
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <InputField
                                        name="address.streetNumber"
                                        label={t(
                                            'bestOfTempletonForm.address.streetNumber.label'
                                        )}
                                        placeholder={t(
                                            'bestOfTempletonForm.address.streetNumber.placeholder'
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={6}>
                                    <InputField
                                        name="address.psc"
                                        label={t(
                                            'bestOfTempletonForm.address.psc.label'
                                        )}
                                        placeholder={t(
                                            'bestOfTempletonForm.address.psc.placeholder'
                                        )}
                                    />
                                </Col>
                                <Col xs={24} md={10}>
                                    <InputField
                                        name="address.city"
                                        label={t(
                                            'bestOfTempletonForm.address.city.label'
                                        )}
                                        placeholder={t(
                                            'bestOfTempletonForm.address.city.placeholder'
                                        )}
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <InputField
                                        name="address.state"
                                        label={t(
                                            'bestOfTempletonForm.address.state.label'
                                        )}
                                        placeholder={t(
                                            'bestOfTempletonForm.address.state.placeholder'
                                        )}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </QuestionWrapper.Divider>
                <QuestionWrapper.Divider>
                    <QuestionWrapper.Title>
                        {t('bestOfTempletonForm.bankAccount.title')}
                    </QuestionWrapper.Title>
                    <InputField
                        name="bankAccount.accountOwnerName"
                        label={t(
                            'bestOfTempletonForm.bankAccount.accountOwnerName.label'
                        )}
                        placeholder={t(
                            'bestOfTempletonForm.bankAccount.accountOwnerName.placeholder'
                        )}
                    />
                    <Row gutter={20}>
                        <Col xs={24} md={16}>
                            <InputField
                                name="bankAccount.IBAN"
                                label={t(
                                    'bestOfTempletonForm.bankAccount.IBAN.label'
                                )}
                                placeholder={t(
                                    'bestOfTempletonForm.bankAccount.IBAN.placeholder'
                                )}
                            />
                        </Col>
                        <Col xs={24} md={8}>
                            <InputField
                                name="bankAccount.Swift"
                                label={t(
                                    'bestOfTempletonForm.bankAccount.Swift.label'
                                )}
                                placeholder={t(
                                    'bestOfTempletonForm.bankAccount.Swift.placeholder'
                                )}
                            />
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col xs={24} md={12}>
                            <InputField
                                name="bankAccount.bankName"
                                label={t(
                                    'bestOfTempletonForm.bankAccount.bankName.label'
                                )}
                                placeholder={t(
                                    'bestOfTempletonForm.bankAccount.bankName.placeholder'
                                )}
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <InputField
                                name="bankAccount.state"
                                label={t(
                                    'bestOfTempletonForm.bankAccount.state.label'
                                )}
                                placeholder={t(
                                    'bestOfTempletonForm.bankAccount.state.placeholder'
                                )}
                            />
                        </Col>
                    </Row>
                </QuestionWrapper.Divider>
                <QuestionWrapper.Divider>
                    <QuestionWrapper.Title>
                        {t(
                            'bestOfTempletonForm.experienceInSelectedCategory.title'
                        )}
                    </QuestionWrapper.Title>
                    <RadioField
                        breakLabel
                        name="selectedInvestmentCategory"
                        type="vertical"
                        options={[
                            {
                                label: t(
                                    `bestOfTempletonForm.selectedInvestmentCategory.options.${SelectedInvestmentCategoryOption.growth}`
                                ),
                                value: SelectedInvestmentCategoryOption.growth,
                            },
                            {
                                label: t(
                                    `bestOfTempletonForm.selectedInvestmentCategory.options.${SelectedInvestmentCategoryOption.dynamic}`
                                ),
                                value: SelectedInvestmentCategoryOption.dynamic,
                            },
                            {
                                label: t(
                                    `bestOfTempletonForm.selectedInvestmentCategory.options.${SelectedInvestmentCategoryOption.speculative}`
                                ),
                                value:
                                    SelectedInvestmentCategoryOption.speculative,
                            },
                        ]}
                        label={t(
                            'bestOfTempletonForm.selectedInvestmentCategory.label'
                        )}
                    />
                    <RadioField
                        name="experienceInSelectedCategory"
                        type="vertical"
                        options={[
                            {
                                label: t(
                                    'bestOfTempletonForm.experienceInSelectedCategory.options.lt1'
                                ),
                                value: 'lt1',
                            },
                            {
                                label: t(
                                    'bestOfTempletonForm.experienceInSelectedCategory.options.lt5'
                                ),
                                value: 'lt5',
                            },
                            {
                                label: t(
                                    'bestOfTempletonForm.experienceInSelectedCategory.options.gt5'
                                ),
                                value: 'gt5',
                            },
                        ]}
                        label={t(
                            'bestOfTempletonForm.experienceInSelectedCategory.label'
                        )}
                    />
                </QuestionWrapper.Divider>
                <QuestionWrapper.Divider>
                    <QuestionWrapper.Title>
                        {t(
                            'bestOfTempletonForm.avgNumberOfYearlyTransactions.title'
                        )}
                    </QuestionWrapper.Title>
                    <Row gutter={20}>
                        <Col xs={24} md={12}>
                            <InputNumberField
                                name="avgNumberOfYearlyTransactions"
                                label={t(
                                    'bestOfTempletonForm.avgNumberOfYearlyTransactions.label'
                                )}
                                precision={0}
                                disabled
                            />
                        </Col>
                        <Col xs={24} md={12}>
                            <InputNumberField
                                name="avgValueOfYearlyTransactions"
                                label={t(
                                    'bestOfTempletonForm.avgValueOfYearlyTransactions.label'
                                )}
                                disabled
                            />
                        </Col>
                    </Row>
                </QuestionWrapper.Divider>
                <QuestionWrapper.Divider>
                    <QuestionWrapper.Title>
                        {t('bestOfTempletonForm.chosenPortfolio.title')}
                    </QuestionWrapper.Title>
                    <SelectField
                        name="chosenPortfolio"
                        options={createPortfolioOptions()}
                        label={t('bestOfTempletonForm.chosenPortfolio.label')}
                    />
                </QuestionWrapper.Divider>
                <QuestionWrapper.Divider>
                    <QuestionWrapper.Title>
                        {t(
                            'bestOfTempletonForm.plannedInvestmentDeposit.title'
                        )}
                    </QuestionWrapper.Title>
                    <Row type="flex" style={{ alignItems: 'center' }}>
                        <Col span={4}>
                            {i18n.language === Languages.Czech && (
                                <SelectField
                                    name="plannedInvestmentDepositCurrency"
                                    options={[
                                        { label: 'CZK', value: 'CZK' },
                                        { label: 'EUR', value: 'EUR' },
                                    ]}
                                    label={t('bestOfTempletonForm.currency')}
                                />
                            )}
                        </Col>
                        <Col span={20} style={{ marginTop: '12px' }}>
                            <SliderWithNumberField
                                name="plannedInvestmentDeposit"
                                label={t(
                                    'bestOfTempletonForm.plannedInvestmentDeposit.label'
                                )}
                                tooltip={t(
                                    'bestOfTempletonForm.plannedInvestmentDeposit.tooltip'
                                )}
                                currencySymbol={
                                    CurrencyMap[
                                        values.plannedInvestmentDepositCurrency
                                    ]
                                }
                                defaultValue={0}
                            />
                        </Col>
                    </Row>
                </QuestionWrapper.Divider>
                <QuestionWrapper.Divider>
                    <QuestionWrapper.Title>
                        {t('bestOfTempletonForm.plannedWithdrawal.title')}
                    </QuestionWrapper.Title>
                    <Row type="flex" style={{ alignItems: 'center' }}>
                        <Col span={4}>
                            {i18n.language === Languages.Czech && (
                                <SelectField
                                    name="plannedWithdrawalCurrency"
                                    options={[
                                        { label: 'CZK', value: 'CZK' },
                                        { label: 'EUR', value: 'EUR' },
                                    ]}
                                    label={t('bestOfTempletonForm.currency')}
                                />
                            )}
                        </Col>
                        <Col span={20} style={{ marginTop: '12px' }}>
                            <SliderWithNumberField
                                name="plannedWithdrawal"
                                label={t(
                                    'bestOfTempletonForm.plannedWithdrawal.label'
                                )}
                                tooltip={t(
                                    'bestOfTempletonForm.plannedWithdrawal.tooltip'
                                )}
                                currencySymbol={
                                    CurrencyMap[
                                        values.plannedWithdrawalCurrency
                                    ]
                                }
                                defaultValue={0}
                            />
                        </Col>
                    </Row>
                </QuestionWrapper.Divider>
                <QuestionWrapper.Divider>
                    <CheckBoxField
                        name="transferingFromFranklinTempleton"
                        label={t(
                            'bestOfTempletonForm.transferingFromFranklinTempleton.label'
                        )}
                    />
                    {values.transferingFromFranklinTempleton && (
                        <InputField
                            name="franklinTempletonDepositNumber"
                            label={t(
                                'bestOfTempletonForm.franklinTempletonDepositNumber.label'
                            )}
                        />
                    )}
                </QuestionWrapper.Divider>
                {error && (
                    <Typography.Paragraph type="danger">
                        {t('clientForm.serverError')}
                    </Typography.Paragraph>
                )}
            </Form>
        );
    }

    function getAvgNumberOfYearlyTransactions() {
        let b = investingExperienceValues?.b?.c ?? '0';
        let c = investingExperienceValues?.c?.c ?? '0';

        // the value can still be empty string
        if (b.length === 0) {
            b = '0';
        }

        if (c.length === 0) {
            c = '0';
        }

        return parseInt(b) + parseInt(c);
    }

    function getAvgValueOfYearlyTransactions() {
        let b = investingExperienceValues?.b?.d ?? '0';
        let c = investingExperienceValues?.c?.d ?? '0';

        // the value can still be empty string
        if (b.length === 0) {
            b = '0';
        }

        if (c.length === 0) {
            c = '0';
        }

        return parseFloat(b) + parseFloat(c);
    }

    return (
        <Formik
            initialValues={{
                accountName: initialValues?.accountName ?? '',
                useCustomer1Address: initialValues?.useCustomer1Address ?? true,
                address: {
                    firstName: initialValues?.address?.firstName ?? '',
                    lastName: initialValues?.address?.lastName ?? '',
                    street: initialValues?.address?.street ?? '',
                    streetNumber: initialValues?.address?.streetNumber ?? '',
                    city: initialValues?.address?.city ?? '',
                    psc: initialValues?.address?.psc ?? '',
                    state: initialValues?.address?.state ?? '',
                },
                bankAccount: {
                    accountOwnerName:
                        initialValues?.bankAccount?.accountOwnerName ?? '',
                    IBAN: initialValues?.bankAccount?.IBAN ?? '',
                    Swift: initialValues?.bankAccount?.Swift ?? '',
                    bankName: initialValues?.bankAccount?.bankName ?? '',
                    state: initialValues?.bankAccount?.state ?? '',
                },
                selectedInvestmentCategory:
                    initialValues?.selectedInvestmentCategory ??
                    (strategy.type === InvestingStrategy.Dynamic
                        ? SelectedInvestmentCategoryOption.dynamic
                        : SelectedInvestmentCategoryOption.growth),
                experienceInSelectedCategory:
                    initialValues?.experienceInSelectedCategory ?? 'lt1',
                avgNumberOfYearlyTransactions: getAvgNumberOfYearlyTransactions(),
                avgValueOfYearlyTransactions: getAvgValueOfYearlyTransactions(),
                chosenPortfolio: initialValues?.chosenPortfolio ?? '',
                plannedInvestmentDeposit:
                    initialValues?.plannedInvestmentDeposit ?? null,
                plannedInvestmentDepositCurrency:
                    initialValues?.plannedInvestmentDepositCurrency ??
                    (i18n.language === Languages.Czech ? 'CZK' : 'EUR'),
                plannedWithdrawal: initialValues?.plannedWithdrawal ?? null,
                plannedWithdrawalCurrency:
                    initialValues?.plannedWithdrawalCurrency ??
                    (i18n.language === Languages.Czech ? 'CZK' : 'EUR'),
                transferingFromFranklinTempleton:
                    initialValues?.transferingFromFranklinTempleton ?? false,
                franklinTempletonDepositNumber:
                    initialValues?.franklinTempletonDepositNumber ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
            enableReinitialize
        />
    );
}
