import React from 'react';
import { Row, Col, Divider, Modal } from 'antd';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import useMount from 'react-use/lib/useMount';
import { useTranslation } from 'react-i18next';

import api from 'common/api';
import { Button, FormsList, SearchBox } from 'common/components';

import { selectUserProfile } from '../../../auth/ducks';
import { initialFormState } from '../../../form/ducks';
import {
    loadFormsListAction,
    selectFormsListItems,
    selectFormsListPagination,
    selectIsFormsListLoading,
    setFormsListPageNumberAction,
    setFormsListPageSizeAction,
    setFormsListSearchQueryAction,
} from './ducks';

export default function FormsFeature() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const user = useSelector(selectUserProfile);
    const transactions = useSelector(selectFormsListItems);
    const pagination = useSelector(selectFormsListPagination);
    const loading = useSelector(selectIsFormsListLoading);

    useMount(() => {
        dispatch(setFormsListSearchQueryAction(''));
    });

    function handleBackClick() {
        history.push('/home');
    }

    function handleCreateNew() {
        if (user) {
            api.transaction.create(user.id, initialFormState).then(resp => {
                if (resp.data) {
                    history.push(`/form/${resp.data._id}`);
                }
            });
        }
    }

    function handleEdit(id: string) {
        return () => {
            const transaction = transactions.find(t => t.id === id);
            if (transaction) {
                history.push(`/form/${transaction.id}`);
            }
        };
    }

    function handleRemove(id: string) {
        return () => {
            Modal.confirm({
                title: t('formsManagement.removeConfirmMessage.title'),
                content: t('formsManagement.removeConfirmMessage.description'),
                okType: 'danger',
                onOk: () => {
                    api.transaction
                        .remove(id)
                        .then(resp => {
                            if (resp.data) {
                                dispatch(loadFormsListAction());
                            } else {
                                Modal.error({ title: 'Error' });
                            }
                        })
                        .catch(() => {
                            Modal.error({ title: 'Error' });
                        });
                },
            });
        };
    }

    function handlePageChange(page: number) {
        dispatch(setFormsListPageNumberAction(page));
    }

    function handlePageSizeChange(current: number, size: number) {
        dispatch(setFormsListPageSizeAction(size));
    }

    function handleSearchSubmit(query: string) {
        dispatch(setFormsListSearchQueryAction(query));
    }

    return (
        <>
            <Row style={{ margin: '30px 0' }}>
                <Col sm={24} lg={18}>
                    <SearchBox
                        onSearchClicked={handleSearchSubmit}
                        buttonText={t('formsManagement.search')}
                        placeholder={t('formsManagement.searchPlaceholder')}
                    />
                </Col>
                <Col sm={24} lg={6}>
                    <Row type="flex" justify="end" gutter={15}>
                        <Col>
                            <Button onClick={handleBackClick}>
                                {t('formsManagement.back')}
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={handleCreateNew} type="primary">
                                {t('formsManagement.createNew')}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Divider />

            <FormsList
                loading={loading}
                items={transactions}
                totalItems={pagination.total}
                currentPage={pagination.currentPage}
                pageSize={pagination.pageSize}
                renderActions={item => [
                    <Button
                        onClick={handleEdit(item.id)}
                        type="primary"
                        size="default"
                    >
                        {t('formsManagement.edit')}
                    </Button>,
                    <Button
                        onClick={handleRemove(item.id)}
                        type="danger"
                        size="default"
                    >
                        {t('formsManagement.remove')}
                    </Button>,
                ]}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </>
    );
}
