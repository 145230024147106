import React, { ReactNode } from 'react';
import { Control } from '../Control';
import { Field, FieldProps, getIn } from 'formik';

interface Props {
    name: string;
    label?: ReactNode;
    children: ReactNode;
}

export default function GroupErrorWrapper({ name, label, children }: Props) {
    return (
        <Field name={name}>
            {({ form: { errors } }: FieldProps) => {
                const fieldErrors = getIn(errors, name);

                return (
                    <Control
                        label={label}
                        // groups can have nested errors
                        error={
                            typeof fieldErrors === 'object'
                                ? undefined
                                : fieldErrors
                        }
                    >
                        {children}
                    </Control>
                );
            }}
        </Field>
    );
}
