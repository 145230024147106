import React, { useState } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper, Radio } from '../../components';
import { I18nNameSpaces } from '../../services/i18n';
import {
    Formik,
    Form,
    FormikProps,
    validateYupSchema,
    yupToFormErrors,
} from 'formik';
import { RadioField, InputField } from '../../fields';
import { Col, Row } from 'antd';
import { InvestingExperienceFormValues } from '../../models/Forms';
import { TableGroupResult } from '../../components/Form/Radio';

interface Props {
    onSubmit: (
        values: InvestingExperienceFormValues,
        groupResults: TableGroupResult[]
    ) => void;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    initialValues?: InvestingExperienceFormValues;
}

export enum InvestingExperienceOptions {
    MoreThanTwoYears = 'moreThanTwoYears',
    LessThanTwoYears = 'lessThanTwoYears',
    NoPracticalExperience = 'noPracticalExperience',
    NoKnowledge = 'noKnowledge',
}

export default function InvestingExperienceForm({
    onSubmit,
    bindSubmit,
    initialValues,
}: Props) {
    const [groupResults, setGroupResults] = useState<TableGroupResult[]>([]);
    const { t } = useTranslation(I18nNameSpaces.Forms);

    function handleChange(values: TableGroupResult[]) {
        setGroupResults(values);
    }

    function handleOnSubmit(values: InvestingExperienceFormValues) {
        onSubmit(values, groupResults);
    }

    function validate(values: InvestingExperienceFormValues) {
        function groupResultValidator(keys: string[]) {
            return (
                groupResults: TableGroupResult[],
                schema: Yup.StringSchema
            ) => {
                return groupResults.some(
                    r =>
                        keys.includes(r.question) &&
                        r.value !== InvestingExperienceOptions.NoKnowledge
                )
                    ? schema.required(t('form.required'))
                    : schema.notRequired();
            };
        }

        const schema = Yup.object().shape<InvestingExperienceFormValues>({
            a: Yup.string().when('$groupResults', groupResultValidator(['a'])),
            b: Yup.object()
                .default(null)
                .nullable()
                .when('$groupResults', groupResultValidator(['b', 'c']))
                .shape({
                    a: Yup.string().when(
                        '$groupResults',
                        groupResultValidator(['b', 'c'])
                    ),
                    b: Yup.string().when(
                        '$groupResults',
                        groupResultValidator(['b', 'c'])
                    ),
                    c: Yup.string().when(
                        '$groupResults',
                        groupResultValidator(['b', 'c'])
                    ),
                    d: Yup.string().when(
                        '$groupResults',
                        groupResultValidator(['b', 'c'])
                    ),
                }),
            c: Yup.object()
                .default(null)
                .nullable()
                .when('$groupResults', groupResultValidator(['c', 'd']))
                .shape({
                    a: Yup.string().when(
                        '$groupResults',
                        groupResultValidator(['c', 'd'])
                    ),
                    b: Yup.string().when(
                        '$groupResults',
                        groupResultValidator(['c', 'd'])
                    ),
                    c: Yup.string().when(
                        '$groupResults',
                        groupResultValidator(['c', 'd'])
                    ),
                    d: Yup.string().when(
                        '$groupResults',
                        groupResultValidator(['c', 'd'])
                    ),
                }),
            useOfServices: Yup.string().required(t('form.required')),
            wayOfEducation: Yup.string().required(t('form.required')),
            stockTradingLoan: Yup.string().required(t('form.required')),
        });

        try {
            validateYupSchema<InvestingExperienceFormValues>(
                values,
                schema,
                true,
                { groupResults }
            );
        } catch (err) {
            return yupToFormErrors(err);
        }

        return {};
    }

    function renderForm(formik: FormikProps<InvestingExperienceFormValues>) {
        if (bindSubmit) {
            bindSubmit(formik.submitForm);
        }
        return (
            <Form>
                {groupResults.some(
                    r =>
                        r.question === 'a' &&
                        r.value !== InvestingExperienceOptions.NoKnowledge
                ) && (
                    <QuestionWrapper key="subquestion1">
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t(
                                    'investingExperienceForm.hasInvestingKnowledgeQsts.a.label'
                                )}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="a"
                                type="horizontal"
                                options={[
                                    {
                                        label: t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.a.options.positive'
                                        ),
                                        value: true,
                                    },
                                    {
                                        label: t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.a.options.negative'
                                        ),
                                        value: false,
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                )}
                {groupResults.some(
                    r =>
                        (r.question === 'b' || r.question === 'c') &&
                        r.value !== InvestingExperienceOptions.NoKnowledge
                ) && (
                    <QuestionWrapper key="subquestion2">
                        <QuestionWrapper.Divider>
                            <Row>
                                <Col>
                                    <QuestionWrapper.Title>
                                        {t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.b.label'
                                        )}
                                    </QuestionWrapper.Title>
                                    <RadioField
                                        name="b.a"
                                        type="horizontal"
                                        options={[
                                            {
                                                label: t(
                                                    'investingExperienceForm.hasInvestingKnowledgeQsts.b.options.positive'
                                                ),
                                                value: true,
                                            },
                                            {
                                                label: t(
                                                    'investingExperienceForm.hasInvestingKnowledgeQsts.b.options.negative'
                                                ),
                                                value: false,
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QuestionWrapper.Title>
                                        {t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.a.label'
                                        )}
                                    </QuestionWrapper.Title>
                                    <RadioField
                                        name="b.b"
                                        type="horizontal"
                                        options={[
                                            {
                                                label: t(
                                                    'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.a.options.often'
                                                ),
                                                value: true,
                                            },
                                            {
                                                label: t(
                                                    'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.a.options.notOften'
                                                ),
                                                value: false,
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={8}>
                                    <QuestionWrapper.Title>
                                        {t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.b.label'
                                        )}
                                    </QuestionWrapper.Title>
                                    <InputField
                                        name="b.c"
                                        placeholder={t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.b.placeholder'
                                        )}
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <QuestionWrapper.Title>
                                        {t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.c.label'
                                        )}
                                    </QuestionWrapper.Title>
                                    <InputField
                                        name="b.d"
                                        placeholder={t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.c.placeholder'
                                        )}
                                    />
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                )}
                {groupResults.some(
                    r =>
                        (r.question === 'd' || r.question === 'e') &&
                        r.value !== InvestingExperienceOptions.NoKnowledge
                ) && (
                    <QuestionWrapper key="subQuestion3">
                        <QuestionWrapper.Divider>
                            <Row>
                                <Col>
                                    <QuestionWrapper.Title>
                                        {t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.c.label'
                                        )}
                                    </QuestionWrapper.Title>
                                    <RadioField
                                        name="c.a"
                                        type="horizontal"
                                        options={[
                                            {
                                                label: t(
                                                    'investingExperienceForm.hasInvestingKnowledgeQsts.c.options.positive'
                                                ),
                                                value: 'true',
                                            },
                                            {
                                                label: t(
                                                    'investingExperienceForm.hasInvestingKnowledgeQsts.c.options.negative'
                                                ),
                                                value: 'false',
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <QuestionWrapper.Title>
                                        {t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.c.questions.a.label'
                                        )}
                                    </QuestionWrapper.Title>
                                    <RadioField
                                        name="c.b"
                                        type="horizontal"
                                        options={[
                                            {
                                                label: t(
                                                    'investingExperienceForm.hasInvestingKnowledgeQsts.c.questions.a.options.often'
                                                ),
                                                value: 'often',
                                            },
                                            {
                                                label: t(
                                                    'investingExperienceForm.hasInvestingKnowledgeQsts.c.questions.a.options.notOften'
                                                ),
                                                value: 'notOften',
                                            },
                                        ]}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={20}>
                                <Col xs={24} md={8}>
                                    <QuestionWrapper.Title>
                                        {t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.c.questions.b.label'
                                        )}
                                    </QuestionWrapper.Title>
                                    <InputField
                                        name="c.c"
                                        placeholder={t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.c.questions.b.placeholder'
                                        )}
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <QuestionWrapper.Title>
                                        {t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.c.questions.c.label'
                                        )}
                                    </QuestionWrapper.Title>
                                    <InputField
                                        name="c.d"
                                        placeholder={t(
                                            'investingExperienceForm.hasInvestingKnowledgeQsts.c.questions.c.placeholder'
                                        )}
                                    />
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                )}
                <QuestionWrapper>
                    <QuestionWrapper.Divider>
                        <Row>
                            <Col>
                                <QuestionWrapper.Title>
                                    {t(
                                        'investingExperienceForm.useOfServices.label'
                                    )}
                                </QuestionWrapper.Title>
                                <RadioField
                                    name="useOfServices"
                                    type="horizontal"
                                    options={[
                                        {
                                            label: t(
                                                'investingExperienceForm.useOfServices.options.a'
                                            ),
                                            value: 'a',
                                        },
                                        {
                                            label: t(
                                                'investingExperienceForm.useOfServices.options.b'
                                            ),
                                            value: 'b',
                                        },
                                        {
                                            label: t(
                                                'investingExperienceForm.useOfServices.options.c'
                                            ),
                                            value: 'c',
                                        },
                                        {
                                            label: t(
                                                'investingExperienceForm.useOfServices.options.d'
                                            ),
                                            value: 'd',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                </QuestionWrapper>
                <QuestionWrapper>
                    <QuestionWrapper.Divider>
                        <Row>
                            <Col>
                                <QuestionWrapper.Title>
                                    {t(
                                        'investingExperienceForm.wayOfEducation.label'
                                    )}
                                </QuestionWrapper.Title>
                                <RadioField
                                    name="wayOfEducation"
                                    type="vertical"
                                    options={[
                                        {
                                            label: t(
                                                'investingExperienceForm.wayOfEducation.options.a'
                                            ),
                                            value: 'a',
                                        },
                                        {
                                            label: t(
                                                'investingExperienceForm.wayOfEducation.options.b'
                                            ),
                                            value: 'b',
                                        },
                                        {
                                            label: t(
                                                'investingExperienceForm.wayOfEducation.options.c'
                                            ),
                                            value: 'c',
                                        },
                                        {
                                            label: t(
                                                'investingExperienceForm.wayOfEducation.options.d'
                                            ),
                                            value: 'd',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                </QuestionWrapper>
                <QuestionWrapper>
                    <QuestionWrapper.Divider>
                        <Row>
                            <Col>
                                <QuestionWrapper.Title>
                                    {t(
                                        'investingExperienceForm.stockTradingLoan.label'
                                    )}
                                </QuestionWrapper.Title>
                                <RadioField
                                    name="stockTradingLoan"
                                    type="vertical"
                                    options={[
                                        {
                                            label: t(
                                                'investingExperienceForm.stockTradingLoan.options.a'
                                            ),
                                            value: 'a',
                                        },
                                        {
                                            label: t(
                                                'investingExperienceForm.stockTradingLoan.options.b'
                                            ),
                                            value: 'b',
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                </QuestionWrapper>
            </Form>
        );
    }

    return (
        <>
            <QuestionWrapper>
                <QuestionWrapper.Divider>
                    <QuestionWrapper.Title>
                        {t('investingExperienceForm.title')}
                    </QuestionWrapper.Title>
                    <Radio.TableGroup
                        initialValues={
                            initialValues && initialValues.table
                                ? initialValues.table
                                : [
                                      {
                                          question: 'a',
                                          value:
                                              InvestingExperienceOptions.NoKnowledge,
                                      },
                                      {
                                          question: 'b',
                                          value:
                                              InvestingExperienceOptions.NoKnowledge,
                                      },
                                      {
                                          question: 'c',
                                          value:
                                              InvestingExperienceOptions.NoKnowledge,
                                      },
                                      {
                                          question: 'd',
                                          value:
                                              InvestingExperienceOptions.NoKnowledge,
                                      },
                                      {
                                          question: 'e',
                                          value:
                                              InvestingExperienceOptions.NoKnowledge,
                                      },
                                  ]
                        }
                        options={[
                            {
                                label: t(
                                    'investingExperienceForm.options.moreThanTwoYears'
                                ),
                                value:
                                    InvestingExperienceOptions.MoreThanTwoYears,
                            },
                            {
                                label: t(
                                    'investingExperienceForm.options.lessThanTwoYears'
                                ),
                                value:
                                    InvestingExperienceOptions.LessThanTwoYears,
                            },
                            {
                                label: t(
                                    'investingExperienceForm.options.noPracticalExperience'
                                ),
                                value:
                                    InvestingExperienceOptions.NoPracticalExperience,
                            },
                            {
                                label: t(
                                    'investingExperienceForm.options.noKnowledge'
                                ),
                                value: InvestingExperienceOptions.NoKnowledge,
                            },
                        ]}
                        questions={[
                            {
                                label: t('investingExperienceForm.questions.a'),
                                value: 'a',
                            },
                            {
                                label: t('investingExperienceForm.questions.b'),
                                value: 'b',
                            },
                            {
                                label: t('investingExperienceForm.questions.c'),
                                value: 'c',
                            },
                            {
                                label: t('investingExperienceForm.questions.d'),
                                value: 'd',
                            },
                            {
                                label: t('investingExperienceForm.questions.e'),
                                value: 'e',
                            },
                        ]}
                        onChange={handleChange}
                    />
                </QuestionWrapper.Divider>
            </QuestionWrapper>
            <Formik
                initialValues={{
                    a: initialValues?.a ?? '',
                    b: initialValues?.b ?? {
                        a: initialValues?.b?.a ?? '',
                        b: initialValues?.b?.b ?? '',
                        c: initialValues?.b?.c ?? '',
                        d: initialValues?.b?.d ?? '',
                    },
                    c: initialValues?.c ?? {
                        a: initialValues?.b?.a ?? '',
                        b: initialValues?.b?.b ?? '',
                        c: initialValues?.b?.c ?? '',
                        d: initialValues?.b?.d ?? '',
                    },
                    useOfServices: initialValues?.useOfServices ?? '',
                    wayOfEducation: initialValues?.wayOfEducation ?? '',
                    stockTradingLoan: initialValues?.stockTradingLoan ?? '',
                }}
                onSubmit={handleOnSubmit}
                render={renderForm}
                validate={validate}
                validateOnBlur={false}
                validateOnChange={false}
            />
        </>
    );
}
