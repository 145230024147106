import React from 'react';
import { pdf, Document, Font } from '@react-pdf/renderer';
import i18n from 'common/services/i18n';

import { AppState } from 'common/models/AppState';
import {
    selectInformationsCustomerInfo1,
    selectInformationsCustomerInfo2,
    selectInformationsCompanyInfo,
    selectProtocolConsultantConfirmation,
    selectInvestingAnalyse,
    selectInvestingHazard,
    selectInvestingExperience,
    selectStrategy,
    selectProtocolCustomerConfirmation,
    selectProtocolGoals,
    selectAgreementBusinessPartnerInfo,
    selectAgreementRequestInfo,
    selectInvestingFinances,
    selectProtocolInitiation,
    selectInvestingAgreements,
    selectAgreementMeeting,
    selectProtocolCustomerStatement,
    selectBestOfTempletonDone,
    selectFormSignatures,
    InvestingStrategy,
} from 'features/form/ducks';

import {
    ClientFormValues,
    CompanyInfoFormValues,
    ConsultantConfirmationFormValues,
    FinanceAnalyseFormValues,
    HazardProfileFormValues,
    InvestingExperienceFormValues,
    ClientConfirmationFormValues,
    InvestingGoalsFormValues,
    BusinessPartnerInfoFormValues,
    RequestInfoFormValues,
    FinancesFormValues,
    ConsultancyInitiationFormValues,
    AgreementsFormValues,
    MeetingFormValues,
    ClientStatementFormValues,
} from 'common/models/Forms';
import {
    ClientInformationsPage,
    InvestingFormPage,
    GeneralAgreementPage,
    ProtocolPage,
    AgreementPage,
    MeetingPage,
} from 'common/pdf';
import { SignatureScope, SignatureFlag } from 'common/models/Transaction';

// Register and load fonts them immediately to avoid race condition: https://github.com/diegomura/react-pdf/issues/1494
Font.register({
    family: 'HindRegular',
    src: '/fonts/hind/hind-regular.ttf',
    fontStyle: 'normal',
    fontWeight: 400,
});
Font.load({
    fontFamily: 'HindRegular',
    fontStyle: 'normal',
    fontWeight: 400,
});

Font.register({
    family: 'HindSemiBold',
    src: '/fonts/hind/hind-semibold.ttf',
    fontStyle: 'normal',
    fontWeight: 700,
});
Font.load({
    fontFamily: 'HindSemiBold',
    fontStyle: 'normal',
    fontWeight: 700,
});

Font.register({
    family: 'RobotoLightItalic',
    src: '/fonts/roboto/roboto-light-italic.ttf',
    fontStyle: 'normal',
    fontWeight: 400,
});
Font.load({
    fontFamily: 'RobotoLightItalic',
    fontStyle: 'normal',
    fontWeight: 400,
});

Font.registerHyphenationCallback(word => [word]);

export class PdfRenderer {
    // Information
    customerInfo1: ClientFormValues | null;
    customerInfo2: ClientFormValues | null;
    companyInfo: CompanyInfoFormValues | null;

    // Protocol
    goals: InvestingGoalsFormValues | null;
    initiation: ConsultancyInitiationFormValues | null;
    clientStatement: ClientStatementFormValues | null;
    clientConfirmation: ClientConfirmationFormValues | null;
    consultantConfirmation: ConsultantConfirmationFormValues | null;

    // Investing
    strategy: { name: string; type: InvestingStrategy };
    analyse: FinanceAnalyseFormValues | null;
    hazard: HazardProfileFormValues | null;
    investingExperience: InvestingExperienceFormValues | null;
    finances: FinancesFormValues | null;
    investingAgreement: AgreementsFormValues | null;

    // Agreement
    businessInfo: BusinessPartnerInfoFormValues | null;
    requestInfo: RequestInfoFormValues | null;
    meeting: MeetingFormValues | null;

    // Misc
    bot: boolean | null;
    existingSignatures: { [value in SignatureScope]?: SignatureFlag };

    constructor(state: AppState) {
        this.customerInfo1 = selectInformationsCustomerInfo1(state);
        this.customerInfo2 = selectInformationsCustomerInfo2(state);
        this.companyInfo = selectInformationsCompanyInfo(state);

        this.goals = selectProtocolGoals(state);
        this.initiation = selectProtocolInitiation(state);
        this.clientStatement = selectProtocolCustomerStatement(state);
        this.clientConfirmation = selectProtocolCustomerConfirmation(state);
        this.consultantConfirmation =
            selectProtocolConsultantConfirmation(state);

        this.strategy = selectStrategy(state);
        this.analyse = selectInvestingAnalyse(state);
        this.hazard = selectInvestingHazard(state);
        this.investingExperience = selectInvestingExperience(state);
        this.finances = selectInvestingFinances(state);
        this.investingAgreement = selectInvestingAgreements(state);

        this.businessInfo = selectAgreementBusinessPartnerInfo(state);
        this.requestInfo = selectAgreementRequestInfo(state);
        this.meeting = selectAgreementMeeting(state);

        this.bot = selectBestOfTempletonDone(state);
        this.existingSignatures = selectFormSignatures(state);
    }

    // Circumvents race condition in @react-pdf when rendering multiple PDF's with custom fonts simultaneously
    // https://github.com/diegomura/react-pdf/issues/310
    // Should be resolved as of @react-pdf v2.X, the retry policy is kept only as an extra precaution
    _tryGenerateBlob = async (documentId: SignatureScope) => {
        const document = this.renderDocument(documentId);
        let blob;
        let retryAttempts = 0;

        do {
            try {
                blob = await pdf(document).toBlob();
            } catch (error) {
                // Render failed - let's try again after a short sleep
                console.warn(`Retrying to generate blob for '${documentId}'..`);
                await new Promise(r => setTimeout(r, 250));
                retryAttempts++;
            }
        } while (!blob && retryAttempts < 3);

        if (blob) {
            return blob;
        } else {
            throw new Error(`Gave up on generating blob for '${documentId}'`);
        }
    };

    createClientSignature = (documentId?: SignatureScope) => {
        const signature = `${this.customerInfo1?.firstName ?? ''} ${
            this.customerInfo1?.lastName ?? ''
        }`;

        if (!documentId) {
            return signature;
        }

        return this.existingSignatures[documentId] === SignatureFlag.SIGNED
            ? signature
            : undefined;
    };

    isRenderable = (documentId: SignatureScope) => {
        switch (documentId) {
            case SignatureScope.CUSTOMER_INFO_1:
                return (
                    this.customerInfo1 &&
                    this.companyInfo &&
                    this.investingAgreement
                );
            case SignatureScope.CUSTOMER_INFO_2:
                return (
                    this.customerInfo2 &&
                    this.companyInfo &&
                    this.investingAgreement
                );
            case SignatureScope.INVESTING:
                return (
                    this.analyse &&
                    this.investingExperience &&
                    this.customerInfo1 &&
                    this.companyInfo &&
                    this.investingAgreement &&
                    this.hazard &&
                    this.investingExperience &&
                    this.strategy &&
                    this.finances
                );
            case SignatureScope.GENERAL_AGREEMENT:
                return (
                    this.investingAgreement &&
                    this.customerInfo1 &&
                    this.companyInfo
                );
            case SignatureScope.PROTOCOL:
                return (
                    this.consultantConfirmation &&
                    this.clientConfirmation &&
                    this.customerInfo1 &&
                    this.companyInfo &&
                    this.goals &&
                    this.hazard &&
                    this.initiation &&
                    this.requestInfo &&
                    this.investingAgreement &&
                    this.clientStatement
                );
            case SignatureScope.AGREEMENT:
                return (
                    this.consultantConfirmation &&
                    this.businessInfo &&
                    this.requestInfo &&
                    this.customerInfo1 &&
                    this.goals &&
                    this.clientConfirmation &&
                    this.companyInfo
                );
            case SignatureScope.MEETING:
                return (
                    this.consultantConfirmation &&
                    this.customerInfo1 &&
                    this.clientConfirmation &&
                    this.companyInfo &&
                    this.meeting
                );
            case SignatureScope.MOVENTUM:
                return (
                    this.consultantConfirmation &&
                    this.customerInfo1 &&
                    this.clientConfirmation &&
                    this.companyInfo &&
                    this.finances &&
                    this.hazard &&
                    this.investingExperience &&
                    this.meeting &&
                    this.goals &&
                    this.analyse &&
                    this.bot
                );
            default:
                return false;
        }
    };

    renderDocument = (documentId: SignatureScope) => {
        switch (documentId) {
            case SignatureScope.CUSTOMER_INFO_1:
                return (
                    <Document>
                        <ClientInformationsPage
                            agreement={this.investingAgreement!}
                            clientValues={this.customerInfo1!}
                            companyInfo={this.companyInfo!}
                            signature={this.createClientSignature(
                                SignatureScope.CUSTOMER_INFO_1
                            )}
                            count={1}
                        />
                    </Document>
                );
            case SignatureScope.CUSTOMER_INFO_2:
                return (
                    <Document>
                        <ClientInformationsPage
                            agreement={this.investingAgreement!}
                            clientValues={this.customerInfo2!}
                            companyInfo={this.companyInfo!}
                            signature={this.createClientSignature(
                                SignatureScope.CUSTOMER_INFO_2
                            )}
                            count={2}
                        />
                    </Document>
                );
            case SignatureScope.INVESTING:
                return (
                    <Document>
                        <InvestingFormPage
                            finances={this.finances!}
                            analyse={this.analyse!}
                            clientValues1={this.customerInfo1!}
                            clientValues2={this.customerInfo2!}
                            companyInfo={this.companyInfo!}
                            agreement={this.investingAgreement!}
                            hazard={this.hazard!}
                            investing={this.investingExperience!}
                            strategy={this.strategy}
                            client1Signature={this.createClientSignature(
                                SignatureScope.INVESTING
                            )}
                        />
                    </Document>
                );
            case SignatureScope.GENERAL_AGREEMENT:
                return (
                    <Document>
                        <GeneralAgreementPage
                            agreement={this.consultantConfirmation?.c!}
                            client2={this.customerInfo2!}
                            companyInfo={this.companyInfo!}
                            client1={this.customerInfo1!}
                            client1Signature={this.createClientSignature(
                                SignatureScope.GENERAL_AGREEMENT
                            )}
                        />
                    </Document>
                );
            case SignatureScope.PROTOCOL:
                return (
                    <Document>
                        <ProtocolPage
                            clientStatement={this.clientStatement!}
                            initiation={this.initiation!}
                            clientConfirmation={this.clientConfirmation!}
                            clientValues1={this.customerInfo1!}
                            clientValues2={this.customerInfo2!}
                            companyInfo={this.companyInfo!}
                            consultantConfirmation={
                                this.consultantConfirmation!
                            }
                            goals={this.goals!}
                            hazard={this.hazard!}
                            agreement={this.investingAgreement!}
                            client1Signature={this.createClientSignature(
                                SignatureScope.PROTOCOL
                            )}
                        />
                    </Document>
                );
            case SignatureScope.AGREEMENT:
                return (
                    <Document>
                        <AgreementPage
                            companyInfo={this.companyInfo!}
                            clientConfirmation={this.clientConfirmation!}
                            goals={this.goals!}
                            confirmation={this.consultantConfirmation!}
                            businessInfo={this.businessInfo!}
                            requestInfo={this.requestInfo!}
                            client1={this.customerInfo1!}
                            client2={this.customerInfo2!}
                            client1Signature={this.createClientSignature(
                                SignatureScope.AGREEMENT
                            )}
                        />
                    </Document>
                );
            case SignatureScope.MEETING:
                return (
                    <Document>
                        <MeetingPage
                            clientValues1={this.customerInfo1!}
                            clientValues2={this.customerInfo2 || undefined}
                            companyInfo={this.companyInfo!}
                            consultantConfirmation={
                                this.consultantConfirmation!
                            }
                            meeting={this.meeting!}
                            client1Signature={this.createClientSignature(
                                SignatureScope.MEETING
                            )}
                        />
                    </Document>
                );
            default:
                return <Document />;
        }
    };

    createBlobs = (documentIds: SignatureScope[]) => {
        return new Promise((resolve, reject) => {
            // Timeout ensures the UI won't freeze immediately (e.g. allow a loader animation to start)
            // since the main thread will be blocked by the @react-pdf engine afterwards
            setTimeout(async () => {
                try {
                    const renderableDocuments = documentIds.filter(
                        documentId =>
                            documentId !== SignatureScope.MOVENTUM && // generated on BE
                            this.isRenderable(documentId)
                    );

                    const t = await i18n;
                    const fileNames = renderableDocuments.map(documentId =>
                        t(`forms:signatureForm.fileNames.${documentId}`)
                    );
                    const blobPromises = renderableDocuments.map(documentId =>
                        this._tryGenerateBlob(documentId)
                    );
                    const binaryDocuments = (
                        await Promise.all(blobPromises)
                    ).map((blob, index) => ({
                        filename: fileNames[index],
                        blob,
                    }));

                    resolve(binaryDocuments);
                } catch (err) {
                    console.error(err);
                    reject(err);
                }
            }, 0);
        });
    };
}
