import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { ConsultancyInitiationForm } from 'common/forms';
import { ConsultancyInitiationFormValues } from 'common/models/Forms';

import { QuestionTitle, PageControls } from '../../../../components';
import {
    selectProtocolInitiation,
    setProtocolInitiationValuesAction,
    selectStrategy,
    selectInvestingHazard,
} from '../../../../ducks';
import { FeatureProps } from '../../../../index';

export default function ProtocolInitiationFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectProtocolInitiation);
    const strategy = useSelector(selectStrategy);
    const hazard = useSelector(selectInvestingHazard);
    const match = useRouteMatch<{ id: string }>();

    let submitForm: FormikProps<ConsultancyInitiationFormValues>['submitForm'];

    function handleBackClick() {
        history.push(`/form/${match!.params.id}/protocol/goals`);
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(
        cb: FormikProps<ConsultancyInitiationFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(values: ConsultancyInitiationFormValues) {
        if (!isPrivileged) {
            dispatch(setProtocolInitiationValuesAction(values));
        }
        history.push(`/form/${match!.params.id}/protocol/customer-statement`);
    }

    return (
        <>
            <QuestionTitle>{t('protocolFeature.initiation')}</QuestionTitle>
            <ConsultancyInitiationForm
                initialValues={initialValues || undefined}
                strategy={strategy}
                hazard={hazard}
                bindSubmit={bindSubmit}
                onSubmit={handleSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
