import React from 'react';
import { Layout } from 'antd';
import cn from 'classnames';
import Container from '../Container';
import { BasicProps } from 'antd/lib/layout/layout';

interface Props extends BasicProps {
    type?: 'default' | 'tall';
}

export default function EnhancedHeader({ children, type }: Props) {
    return (
        <Layout.Header
            className={cn({ 'ant-layout-header-tall': type === 'tall' })}
        >
            <Container>{children}</Container>
        </Layout.Header>
    );
}
