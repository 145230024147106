import React, { ReactNode, Fragment } from 'react';

import { Field, FieldProps, FormikValues } from 'formik';
import { InputField, ObjectCheckboxField } from '../index';
import { QuestionWrapper } from 'common/components';
import { Row } from 'antd';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { I18nNameSpaces } from '../../services/i18n';
import { InvestingGoalsFormValues } from '../../models/Forms';
import { Control } from '../../components/Form/Control';

type CheckboxTreeInput = {
    label: string;
    name: string;
};

export type CheckboxTreeOption = {
    label: string;
    value: string;
    children?: CheckboxTreeOption[];
    inputs?: CheckboxTreeInput[];
};

interface CheckboxTreeProps {
    options: CheckboxTreeOption[];
    values: InvestingGoalsFormValues['g'];
    name: string;
}

interface Props {
    options: CheckboxTreeOption[];
    name: string;
    label?: ReactNode;
    error?: ReactNode;
}

// TODO: decouple from formik & create separate component
// TODO: generalize to recursively render any checkbox & label tree structure
const CheckboxTree = ({ options, values, name }: CheckboxTreeProps) => {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const isChecked = (values: FormikValues, path: string) =>
        get(values, `${path}.checked`);

    return (
        <>
            {options.map(option => (
                <Fragment key={`${name}.${option.value}`}>
                    <ObjectCheckboxField
                        name={`${name}.${option.value}`}
                        label={t(option.label)}
                    />
                    {option.children &&
                        isChecked(values, `${name}.${option.value}`) && (
                            <QuestionWrapper.Divider>
                                {option.children.map(child => {
                                    const prefixedValue = `${name}.${option.value}.${child.value}`;

                                    return (
                                        <Row
                                            key={prefixedValue}
                                            type="flex"
                                            justify="space-between"
                                            align="middle"
                                        >
                                            <ObjectCheckboxField
                                                name={prefixedValue}
                                                label={t(child.label)}
                                            />
                                            {child.inputs &&
                                                isChecked(
                                                    values,
                                                    prefixedValue
                                                ) && (
                                                    <>
                                                        {child.inputs.map(
                                                            input => (
                                                                <InputField
                                                                    key={`${prefixedValue}.${input.name}`}
                                                                    name={`${prefixedValue}.${input.name}`}
                                                                    label={t(
                                                                        input.label
                                                                    )}
                                                                    size="small"
                                                                />
                                                            )
                                                        )}
                                                    </>
                                                )}
                                        </Row>
                                    );
                                })}
                            </QuestionWrapper.Divider>
                        )}
                </Fragment>
            ))}
        </>
    );
};

export default function CheckboxTreeField({ options, name }: Props) {
    return (
        <Field name={name}>
            {({ form: { values, errors } }: FieldProps) => {
                let error = get(errors, name);

                // filter out nested errors
                if (typeof error !== 'string') {
                    error = undefined;
                }

                return (
                    <Control error={error}>
                        <CheckboxTree
                            options={options}
                            values={values}
                            name={name}
                        />
                    </Control>
                );
            }}
        </Field>
    );
}
