import { message } from 'antd';

import api from 'common/api';
import { PaginationState } from 'common/components/Pagination';
import { AppState } from 'common/models/AppState';
import { Transaction, VerificationFlag, VersionFlag, } from 'common/models/Transaction';
import i18n from 'common/services/i18n';
import { FormState } from 'features/form/ducks';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { ActionType, createAction, createReducer, getType, } from 'typesafe-actions';

export interface FormsListState {
    items: FormsListItem[];
    pagination: PaginationState;
    searchQuery: string;
    loading: boolean;
}

export type FormsListItem = FormState & {
    id: string;
    createdAt: string;
    updatedAt: string;
    isVerified: boolean;
    isOutdated: boolean;
};

export const selectHome = (state: AppState) => state.home;

export const selectFormsListItems = (state: AppState) =>
    selectHome(state).formsList.items;

export const selectFormsListPagination = (state: AppState) =>
    selectHome(state).formsList.pagination;

export const selectFormsSearchQuery = (state: AppState) =>
    selectHome(state).formsList.searchQuery;

export const selectIsFormsListLoading = (state: AppState) =>
    selectHome(state).formsList.loading;

export const loadFormsListAction = createAction(
    '@@Home/FormsList/LOAD',
    action => (query?: { [key: string]: any }) => action(query)
);

export const setFormsListLoadingAction = createAction(
    '@@Home/FormsList/SET_IS_LOADING',
    action => (value: boolean) => action(value)
);

export const setFormsItemsAction = createAction(
    '@@Home/FormsList/SET_ITEMS',
    action => (values: Transaction[]) => action(values)
);

export const setFormsListSearchQueryAction = createAction(
    '@@Home/FormsList/SET_SEARCH_QUERY',
    action => (value: string) => action(value)
);

export const setFormsListPageNumberAction = createAction(
    '@@Home/FormsList/SET_PAGE_NUMBER',
    action => (value: number) => action(value)
);

export const setFormsListPageSizeAction = createAction(
    '@@Home/FormsList/SET_PAGE_SIZE',
    action => (value: number) => action(value)
);

export const setFormsListItemCountAction = createAction(
    '@@Home/FormsList/SET_ITEM_COUNT',
    action => (value: number) => action(value)
);

type FormsActions = ActionType<
    | typeof setFormsItemsAction
    | typeof setFormsListPageNumberAction
    | typeof setFormsListPageSizeAction
    | typeof setFormsListItemCountAction
    | typeof setFormsListSearchQueryAction
    | typeof setFormsListLoadingAction
>;

const initialFormsOverviewState = {
    items: [],
    pagination: { currentPage: 1, pageSize: 10, total: 0 },
    searchQuery: '',
    loading: false,
};

export default createReducer<FormsListState, FormsActions>(
    initialFormsOverviewState
)
    .handleAction(setFormsItemsAction, (state, { payload }) => ({
        ...state,
        items: payload.map((t: Transaction) => ({
            id: t._id,
            createdAt: t.createdAt,
            updatedAt: t.updatedAt,
            ownerId: t.owner,
            ...t.entries[0].meta,
            isOutdated: t.meta.version === VersionFlag.OUTDATED,
            isVerified:
                t?.meta?.verification?.customer1?.sms ===
                    VerificationFlag.VERIFIED &&
                t?.meta?.verification?.customer1?.email ===
                    VerificationFlag.VERIFIED,
        })),
    }))
    .handleAction(setFormsListPageNumberAction, (state, { payload }) => ({
        ...state,
        pagination: { ...state.pagination, currentPage: payload },
    }))
    .handleAction(setFormsListPageSizeAction, (state, { payload }) => ({
        ...state,
        pagination: { ...state.pagination, currentPage: 1, pageSize: payload },
    }))
    .handleAction(setFormsListItemCountAction, (state, { payload }) => ({
        ...state,
        pagination: { ...state.pagination, total: payload },
    }))
    .handleAction(setFormsListSearchQueryAction, (state, { payload }) => ({
        ...state,
        searchQuery: payload,
    }))
    .handleAction(setFormsListLoadingAction, (state, { payload }) => ({
        ...state,
        loading: payload,
    }));

function* watchLoadFormsList() {
    yield put(setFormsListLoadingAction(true));

    const pagination = yield select(selectFormsListPagination);
    const searchQuery = yield select(selectFormsSearchQuery);
    const { ok, data } = yield call(api.transaction.find, {
        $limit: pagination.pageSize,
        $skip: pagination.pageSize * (pagination.currentPage - 1),
        ...(searchQuery ? { $text: { $search: searchQuery } } : {}),
    });

    if (ok && data) {
        yield put(setFormsItemsAction(data.data));
        yield put(setFormsListItemCountAction(data.total));
    } else {
        const t = yield i18n;
        message.error(t('formsManagement.failedLoad'));
    }

    yield put(setFormsListLoadingAction(false));
}

function* watchFormsListChanges() {
    yield put(loadFormsListAction());
}

export function* formsListSaga() {
    yield takeLatest(getType(loadFormsListAction), watchLoadFormsList);
    yield takeLatest(
        [
            getType(setFormsListPageNumberAction),
            getType(setFormsListPageSizeAction),
            getType(setFormsListSearchQueryAction),
        ],
        watchFormsListChanges
    );
}
