import { Field, FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Col, Input, Row } from 'antd';
import { Control } from '../../components/Form/Control';

interface Props {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    enterButton?: string | ReactNode;
    onSearch?: (phone: { prefix: string; number: string }) => void;
}

export default function PhoneInputField({
    name,
    label,
    placeholder,
    enterButton,
    onSearch,
    ...rest
}: Props) {
    return (
        <Field name={name}>
            {({ field, form: { errors, setFieldValue } }: FieldProps) => {
                const error = getIn(errors, name);

                return (
                    <Control
                        label={label}
                        error={error ? error.number || error.prefix : null}
                    >
                        <Row>
                            <Col xs={enterButton ? 24 : 12} md={7}>
                                <PhoneInput
                                    preferredCountries={['cz', 'sk', 'at']}
                                    inputProps={{
                                        disabled: true,
                                        name: `${name}.prefix`,
                                    }}
                                    inputStyle={{
                                        width: '100%',
                                        height: '41px',
                                    }}
                                    onChange={val =>
                                        setFieldValue(`${name}.prefix`, val)
                                    }
                                    placeholder=""
                                    value={field.value.prefix}
                                />
                            </Col>
                            {enterButton ? (
                                <Col xs={24} md={17}>
                                    <Input.Search
                                        type="text"
                                        name={`${name}.number`}
                                        onChange={field.onChange}
                                        placeholder={placeholder}
                                        value={field.value.number}
                                        enterButton={enterButton}
                                        onSearch={() =>
                                            onSearch &&
                                            onSearch({
                                                prefix: field.value.prefix,
                                                number: field.value.number,
                                            })
                                        }
                                        {...rest}
                                    />
                                </Col>
                            ) : (
                                <Col xs={12} md={17}>
                                    <Input
                                        type="text"
                                        name={`${name}.number`}
                                        onChange={field.onChange}
                                        placeholder={placeholder}
                                        value={field.value.number}
                                        {...rest}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Control>
                );
            }}
        </Field>
    );
}
