import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router';

import ExperienceFeature from './features/experience';
import HazardFeature from './features/hazard';
import FinancesFeature from './features/finances';
import AnalyseFeature from './features/analyse';
import AgreementsFeature from './features/agreements';
import PDFFeature from './features/pdf';
import { AppState } from '../../../../common/models/AppState';
import { selectIsPrivileged } from '../../../auth/ducks';
import { connect } from 'react-redux';

interface StateToProps {
    isPrivileged: boolean;
}

function InvestingFeature({ isPrivileged }: StateToProps) {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match!.path}/experience`}
                render={props => (
                    <ExperienceFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Route
                path={`${match!.path}/hazard`}
                render={props => (
                    <HazardFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Route
                path={`${match!.path}/finances`}
                render={props => (
                    <FinancesFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Route
                path={`${match!.path}/analyse`}
                render={props => (
                    <AnalyseFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Route
                path={`${match!.path}/agreements`}
                render={props => (
                    <AgreementsFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Route path={`${match!.path}/pdf`} component={PDFFeature} />
            <Redirect
                path={`${match!.path}/`}
                to={`${match!.path}/experience`}
            />
        </Switch>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    isPrivileged: selectIsPrivileged(state),
});

export default connect(mapStateToProps)(InvestingFeature);
