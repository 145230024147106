export const environment = {
    production: false,
    protocol: 'https',
    host_sk: 'api.phoenix.touch4dev.net',
    host_cz: 'api.phoenix.touch4dev.net',
    basicAuthToken: 'cGhvZW5peDprdkxEU1k2MlVlbUxhNUpz',
    debug: true,
    pdf_version_sk: 'V-01-2021',
    pdf_version_cz: 'V-01-2022',
};
