import React, { ReactNode } from 'react';
import { StyleSheet, Page, Image } from '@react-pdf/renderer';

export const pageStyles = StyleSheet.create({
    page: {
        fontFamily: 'HindRegular',
        letterSpacing: 0.2,
        lineHeight: 1.6,
        width: '100%',
        padding: 35,
        paddingBottom: 54, // 35 + (19 for footer)
        minHeight: '100%',
    },
    pageBackground: {
        position: 'absolute',
        height: '70%',
        width: '50%',
        top: '18%',
        left: '25%',
    },
    footer: {
        position: 'absolute',
        padding: '0 35 35 35',
        left: 0,
        bottom: 0,
        right: 0,
    },
});

interface Props {
    children: ReactNode;
}

export default function PDFBrandedPage({ children }: Props) {
    return (
        <Page wrap size="A4" style={pageStyles.page}>
            <Image
                fixed
                src="/pdf/page-background.png"
                style={pageStyles.pageBackground}
            />
            {children}
        </Page>
    );
}
