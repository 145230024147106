import React, { ReactNode } from 'react';
import numbro from 'numbro';

import { View } from '@react-pdf/renderer';
import { PDFParagraphBlock } from './index';
import { FondDataFormValues } from '../../models/Forms';
import { useTranslation } from 'react-i18next';
import { I18nNameSpaces } from '../../services/i18n';

interface CellProps {
    value: ReactNode;
    width?: string;
}

const TableCell = ({ value, width = '12.5%' }: CellProps) => (
    <View
        style={{
            width,
            borderRight: '1 solid black',
            borderBottom: '1 solid black',
            justifyContent: 'center',
            padding: 2,
        }}
    >
        <PDFParagraphBlock align="center">{value}</PDFParagraphBlock>
    </View>
);

interface Props {
    data: FondDataFormValues[];
    currency: string;
}

export default function PDFFondTable({ data, currency }: Props) {
    const { t } = useTranslation(I18nNameSpaces.PDF);
    const { t: cmT } = useTranslation();

    return (
        <>
            <View
                style={{
                    border: '1 solid black',
                    borderRight: 0,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    borderBottom: 0,
                }}
            >
                <TableCell value={cmT('fondTable.columns.code')} width="25%" />
                <TableCell value={cmT('fondTable.columns.buy')} width="8.3%" />
                <TableCell value={cmT('fondTable.columns.sell')} width="8.3%" />
                <TableCell value={cmT('fondTable.columns.hold')} width="8.3%" />
                <TableCell
                    value={`${cmT(
                        'fondTable.columns.regularAmount'
                    )} ${currency}`}
                />
                <TableCell
                    value={`${cmT(
                        'fondTable.columns.oneTimeAmount'
                    )} ${currency}`}
                />
                <TableCell value={cmT('fondTable.columns.agio')} />
                <TableCell value={cmT('fondTable.columns.note')} />
                {data.map((p, i) => (
                    <View
                        key={i}
                        style={{ width: '100%', flexDirection: 'row' }}
                    >
                        <TableCell value={`${i + 1}. ${p.code}`} width="25%" />
                        <TableCell
                            value={p.type === 'buy' ? 'x' : '-'}
                            width="8.3%"
                        />
                        <TableCell
                            value={p.type === 'sell' ? 'x' : '-'}
                            width="8.3%"
                        />
                        <TableCell
                            value={p.type === 'hold' ? 'x' : '-'}
                            width="8.3%"
                        />
                        <TableCell
                            value={numbro(p.regularAmount).formatCurrency()}
                        />
                        <TableCell
                            value={numbro(p.oneTimeAmount).formatCurrency()}
                        />
                        <TableCell value={p.agio} />
                        <TableCell value={p.note || ''} />
                    </View>
                ))}
            </View>
            <View
                style={{
                    border: '1 solid black',
                    padding: 2,
                    borderTop: 0,
                }}
            >
                <PDFParagraphBlock lineHeight={1.3}>
                    {t('protocol.section6')}
                </PDFParagraphBlock>
            </View>
        </>
    );
}
