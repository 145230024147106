import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextBackend from 'i18next-xhr-backend';
import moment from 'moment';
import numbro from 'numbro';
import { initReactI18next } from 'react-i18next';

import { environment } from '../../environments/environment';

import 'moment/locale/cs';
import 'moment/locale/sk';

const numbroLanguages = require('numbro/dist/languages.min.js');

export enum I18nNameSpaces {
    Common = 'common',
    Auth = 'auth',
    Forms = 'forms',
    PDF = 'pdf',
    Fonds = 'fonds',
}

export enum Languages {
    Czech = 'cz',
    Slovak = 'sk',
}

interface LanguagesConfig {
    [name: string]: {
        resourceLocale: string;
        momentLocale: string;
        numbroLocale: string;
    };
}

export const LANGUAGE_CURRENCY_MAP = {
    [Languages.Czech]: 'CZK',
    [Languages.Slovak]: 'EUR',
};

export const LANGUAGES: LanguagesConfig = {
    [Languages.Czech]: {
        resourceLocale: 'cz',
        momentLocale: 'cs',
        numbroLocale: 'cs-CZ',
    },
    [Languages.Slovak]: {
        resourceLocale: 'sk',
        momentLocale: 'sk',
        numbroLocale: 'sk-SK',
    },
};

// Register slovak locale
numbro.registerLanguage({
    ...numbroLanguages[LANGUAGES[Languages.Czech].numbroLocale],
    currency: {
        symbol: ' Kč',
        position: 'postfix',
        code: 'czk',
    },
    currencyFormat: {
        thousandSeparated: true,
    },
});

numbro.registerLanguage({
    ...numbroLanguages[LANGUAGES[Languages.Slovak].numbroLocale],
    currency: {
        symbol: '€',
        position: 'postfix',
        code: 'eur',
    },
    currencyFormat: {
        thousandSeparated: true,
    },
});

// see: https://www.i18next.com/overview/configuration-options
const i18nInstance = i18next
    .use(i18nextBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        ns: Object.values(I18nNameSpaces),
        defaultNS: I18nNameSpaces.Common,
        nsSeparator: ':',
        returnEmptyString: true,
        detection: {
            order: ['cookie', 'navigator'],
            lookupCookie: 'phoenix-lang',
            caches: ['cookie'],
            cookieMinutes: 525600,
        },
        initImmediate: false,
        whitelist: [Languages.Czech, Languages.Slovak],
        fallbackLng: Languages.Czech,
        lng: window.location.host.endsWith('.sk')
            ? Languages.Slovak
            : Languages.Czech,
        react: {
            useSuspense: false,
        },
        interpolation: { escapeValue: false },
    });

if (environment.debug) {
    (window as any).moment = moment;
    (window as any).numbro = numbro;
    (window as any).i18n = i18next;
}

i18next.on('languageChanged', lng => {
    moment.locale(LANGUAGES[lng].momentLocale);
    numbro.setLanguage(LANGUAGES[lng].numbroLocale);
});

export default i18nInstance;
