import React, { ReactNode } from 'react';
import { Avatar, Empty, Icon, List, Row, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { FormsListItem } from 'features/home/features/forms/ducks';
import { Pagination, Spinner } from 'common/components';

interface Props {
    items: FormsListItem[];
    totalItems?: number;
    currentPage?: number;
    loading?: boolean;
    displayOwner?: boolean;
    pageSize?: number;
    renderActions: (item: FormsListItem) => ReactNode[];
    onPageChange: (page: number, pageSize?: number) => void;
    onPageSizeChange: (current: number, size: number) => void;
}

export default function FormsList({
    items,
    totalItems,
    currentPage,
    loading,
    displayOwner,
    pageSize,
    renderActions,
    onPageChange,
    onPageSizeChange,
}: Props) {
    const { t } = useTranslation();

    function getTitle(item: FormsListItem) {
        const { firstName, lastName, email } =
            item?.informations?.customerInfo1 ?? {};

        return (
            <>
                {`${firstName} ${lastName} ${email}`}
                {item.isOutdated && (
                    <>
                        {' '}
                        <Tooltip
                            title={t('formsManagement.outdatedForm')}
                            placement="top"
                        >
                            <Icon
                                type="exclamation"
                                className="forms-list-icon-outdated"
                            />
                        </Tooltip>
                    </>
                )}
                {item.isVerified && (
                    <>
                        {' '}
                        <Tooltip
                            title={t('formsManagement.verifiedForm')}
                            placement="top"
                        >
                            <Icon
                                type="check"
                                className="forms-list-icon-verified"
                            />
                        </Tooltip>
                    </>
                )}
            </>
        );
    }

    function getDescription(item: FormsListItem) {
        const createdAt = `${t('formsManagement.createdAt')} ${moment(
            item.createdAt
        ).fromNow()}`;

        const updatedAt = `${t('formsManagement.updatedAt')} ${moment(
            item.updatedAt
        ).fromNow()}`;

        return [
            createdAt,
            updatedAt,
            ...(displayOwner
                ? [
                      `${t('formsManagement.advisor')} ${
                          item.informations?.companyInfo?.firstName
                      } ${item.informations?.companyInfo?.lastName}`,
                  ]
                : []),
        ].join(', ');
    }

    function renderFormsList() {
        return items.length > 0 ? (
            <>
                <Row>
                    <List
                        className="forms-list"
                        loading={false}
                        itemLayout="horizontal"
                        dataSource={items}
                        renderItem={item => (
                            <List.Item actions={renderActions(item)}>
                                <List.Item.Meta
                                    avatar={<Avatar icon="form" />}
                                    title={getTitle(item)}
                                    description={getDescription(item)}
                                />
                            </List.Item>
                        )}
                    />
                </Row>
                <Row type="flex" justify="center">
                    <Pagination
                        total={totalItems}
                        current={currentPage}
                        pageSize={pageSize}
                        onChange={onPageChange}
                        onShowSizeChange={onPageSizeChange}
                    />
                </Row>
            </>
        ) : (
            <Empty description={t('formsManagement.empty')} />
        );
    }

    return loading ? (
        <Spinner title={t('formsManagement.spinner')} />
    ) : (
        renderFormsList()
    );
}
