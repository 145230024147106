import { combineReducers } from 'redux';
import { fork, all, takeLatest, put, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { message } from 'antd';

import {
    createReducer,
    ActionType,
    createAsyncAction,
    getType,
} from 'typesafe-actions';

import api from 'common/api';
import { AppState } from 'common/models/AppState';
import { UserProfile } from 'common/models/User';
import { ApiClientResponse } from 'common/services/api-client';

import login, {
    LoginState,
    loginSaga,
    loginActions,
} from './features/login/ducks';
import registration, { registrationSaga } from './features/registration/ducks';
import { RegistrationState } from './features/registration/ducks';
import forgottenPassword, {
    ForgottenPasswordState,
    forgottenPasswordSaga,
} from './features/forgotten-password/ducks';
import { CompanyInfoFormValues } from '../../common/models/Forms';

// Actions
export const logoutActions = createAsyncAction(
    '@@Auth/LOGOUT/REQUEST',
    '@@Auth/LOGOUT/SUCCESS',
    '@@Auth/LOGOUT/FAILURE'
)<undefined, undefined, undefined>();

export const updateProfileActions = createAsyncAction(
    '@@Auth/UpdateProfile/REQUEST',
    '@@Auth/UpdateProfile/SUCCESS',
    '@@Auth/UpdateProfile/FAILURE'
)<Partial<UserProfile>, UserProfile, undefined>();

export const checkUserActions = createAsyncAction(
    '@@Auth/CheckUser/REQUEST',
    '@@Auth/CheckUser/SUCCESS',
    '@@Auth/CheckUser/FAILURE'
)<undefined, undefined, undefined>();

// Reducers
export interface AuthState {
    login: LoginState;
    registration: RegistrationState;
    forgottenPassword: ForgottenPasswordState;
    profile: UserProfile | null;
    logged: boolean;
}

const profile = createReducer<
    AuthState['profile'],
    ActionType<
        typeof loginActions | typeof logoutActions | typeof updateProfileActions
    >
>(null)
    .handleAction(loginActions.success, (_state, action) => action.payload)
    .handleAction(
        updateProfileActions.success,
        (_state, action) => action.payload
    )
    .handleAction(logoutActions.success, () => null);

const logged = createReducer<AuthState['logged']>(false)
    .handleAction(logoutActions.success, () => false)
    .handleAction(loginActions.success, () => true);

export default combineReducers<AuthState>({
    login,
    profile,
    logged,
    registration,
    forgottenPassword,
});

// Selectors
export function selectAuth(state: AppState) {
    return state.auth;
}

export function selectIsLogged(state: AppState) {
    return selectAuth(state).logged;
}

export function selectUserProfile(state: AppState) {
    return selectAuth(state).profile;
}

export function selectIsPrivileged(state: AppState) {
    return selectAuth(state).profile?.roles?.privileged ?? false;
}

export function selectPartnerData(
    state: AppState
): CompanyInfoFormValues | null {
    const user = selectUserProfile(state);
    return user
        ? {
              address: `${user?.street ?? ''}, ${user?.city ?? ''}`,
              email: user?.email ?? '',
              firstName: user?.firstName ?? '',
              gender: user?.meta?.gender ?? '',
              ico: user?.meta?.ico ?? '',
              lastName: user?.lastName ?? '',
              partnerNumber: user?.meta?.idcard ?? '',
              phone: user?.phone ?? '',
              title: user?.title ?? '',
          }
        : null;
}

// Sagas
function* watchUpdateProfile(
    action: ReturnType<typeof updateProfileActions.request>
): SagaIterator {
    const resp: ApiClientResponse<UserProfile> = yield call(
        api.user.updateProfile,
        action.payload
    );

    if (resp.ok) {
        yield put(updateProfileActions.success(resp.data));
        message.success('Profile sucessfully changed');
    } else {
        yield put(updateProfileActions.failure());
    }
}

function* watchCheckUser(): SagaIterator {
    const resp: ApiClientResponse<UserProfile> = yield call(api.user.profile);

    if (!resp.ok) {
        yield put(logoutActions.success());
    } else {
        yield put(loginActions.success(resp.data));
    }
}

function* watchLogout(): SagaIterator {
    const resp = yield call(api.user.logout);

    if (resp.ok) {
        yield put(logoutActions.success());
    } else {
        yield put(logoutActions.failure());
    }
}

export function* authSaga() {
    yield takeLatest(getType(updateProfileActions.request), watchUpdateProfile);
    yield takeLatest(getType(logoutActions.request), watchLogout);
    yield takeLatest(getType(checkUserActions.request), watchCheckUser);
    yield all([
        fork(loginSaga),
        fork(registrationSaga),
        fork(forgottenPasswordSaga),
    ]);
}
