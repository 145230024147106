import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper } from '../../components';
import { RadioField, TextareaField, InputNumberField } from '../../fields';
import { ClientConfirmationFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import { Row, Col } from 'antd';
import SliderWithNumberField from '../../fields/SliderWithNumber';

interface Props {
    initialValues?: Partial<ClientConfirmationFormValues>;
    bindSubmit?: (
        cb: FormikProps<ClientConfirmationFormValues>['submitForm']
    ) => void;
    onSubmit: (values: ClientConfirmationFormValues) => void;
}

export default function ClientConfirmationForm({
    onSubmit,
    initialValues,
    bindSubmit,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<ClientConfirmationFormValues>({
        a: Yup.number().required(t('form.required')),
        b: Yup.number().required(t('form.required')),
        c: Yup.string().required(t('form.required')),
        d: Yup.string(),
        once: Yup.number(),
        monthly: Yup.number(),
    });

    function handleOnSubmit(values: ClientConfirmationFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({
        submitForm,
        values,
    }: FormikProps<ClientConfirmationFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider type="bordered">
                            <SliderWithNumberField
                                defaultValue={0}
                                min={0}
                                name="a"
                                label={t('clientConfirmationForm.a.label')}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider type="bordered">
                            <SliderWithNumberField
                                defaultValue={0}
                                min={0}
                                name="b"
                                label={t('clientConfirmationForm.b.label')}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('clientConfirmationForm.c.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="c"
                                type="vertical"
                                options={[
                                    {
                                        label: t(
                                            'clientConfirmationForm.c.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'clientConfirmationForm.c.options.b'
                                        ),
                                        value: 'b',
                                    },
                                    {
                                        label: t(
                                            'clientConfirmationForm.c.options.c'
                                        ),
                                        value: 'c',
                                    },
                                ]}
                            />
                            {values.c === 'a' && (
                                <>
                                    <Row gutter={20}>
                                        <Col xs={24} md={8}>
                                            <InputNumberField
                                                label={t(
                                                    'clientConfirmationForm.c.sub.once'
                                                )}
                                                name="once"
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {values.c === 'b' && (
                                <>
                                    <Row gutter={20}>
                                        <Col xs={24} md={8}>
                                            <InputNumberField
                                                label={t(
                                                    'clientConfirmationForm.c.sub.once'
                                                )}
                                                name="once"
                                            />
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <InputNumberField
                                                label={t(
                                                    'clientConfirmationForm.c.sub.monthly'
                                                )}
                                                name="monthly"
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {values.c === 'c' && (
                                <>
                                    <Row gutter={20}>
                                        <Col xs={24} md={8}>
                                            <InputNumberField
                                                label={t(
                                                    'clientConfirmationForm.c.sub.monthly'
                                                )}
                                                name="monthly"
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('clientConfirmationForm.d.label')}
                            </QuestionWrapper.Title>
                            <TextareaField name="d" />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a ?? 0,
                b: initialValues?.b ?? 0,
                c: initialValues?.c ?? '',
                d: initialValues?.d ?? '',
                once: initialValues?.once ?? 0,
                monthly: initialValues?.monthly ?? 0,
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
