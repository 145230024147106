import React, { ReactNode } from 'react';
import { Typography } from 'common/components';

interface Props {
    children: ReactNode;
    prefix?: string;
}

export default function QuestionTitle({ children, prefix }: Props) {
    return (
        <div className="question-title">
            <Typography.Title level={2}>
                {prefix && (
                    <span className="question-title-prefix">{prefix}</span>
                )}
                {children}
            </Typography.Title>
        </div>
    );
}
