import { Layout } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';
import React from 'react';

import Container from './Container';
import Header from './Header';

const EnhancedLayout = (props: BasicProps) => <Layout {...props} />;

EnhancedLayout.Header = Header;
EnhancedLayout.Footer = Layout.Footer;
EnhancedLayout.Content = Layout.Content;
EnhancedLayout.Container = Container;

export default EnhancedLayout;
