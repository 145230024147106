import React, { ReactNode } from 'react';
import { PDFParagraphBlock } from './index';

interface Props {
    label: ReactNode;
    value: ReactNode;
    bold?: boolean;
}

export default function PDFListItem({ label, value, bold = true }: Props) {
    return (
        <PDFParagraphBlock>
            <PDFParagraphBlock>{label}</PDFParagraphBlock>
            <PDFParagraphBlock bold={bold}>{value}</PDFParagraphBlock>
        </PDFParagraphBlock>
    );
}
