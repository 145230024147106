import React from 'react';
import { Trans } from 'react-i18next';

export default function Empty() {
    return (
        <div className="container">
            <div className="ant-row">
                <h1 className="ant-col-24">
                    <Trans i18nKey="unexpectedError.message" />
                </h1>
                <p>
                    <a href="/">
                        <Trans i18nKey="unexpectedError.back" />
                    </a>
                </p>
            </div>
        </div>
    );
}
