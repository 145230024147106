import React from 'react';
import { connect } from 'react-redux';
import { RouteProps, Redirect, Route } from 'react-router';

import { AppState } from '../models/AppState';
import { selectIsLogged } from '../../features/auth/ducks';

interface StateToProps {
    isLogged: boolean;
}

type Props = StateToProps & RouteProps;

function PrivateRoute<T>({ component: Component, isLogged, ...rest }: Props) {
    const C = Component as any;
    return (
        <Route
            {...rest}
            render={props =>
                isLogged ? (
                    <C {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/auth/login',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
}

export default connect((state: AppState) => ({
    isLogged: selectIsLogged(state),
}))(PrivateRoute);
