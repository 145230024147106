import React from 'react';
import { Divider } from 'antd';
import { useHistory } from 'react-router';
import useMount from 'react-use/lib/useMount';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Button, FormsList, SearchBox } from 'common/components';
import {
    selectFormsListItems,
    selectFormsListPagination,
    selectIsFormsListLoading,
    setFormsListPageNumberAction,
    setFormsListPageSizeAction,
    setFormsListSearchQueryAction,
} from '../forms/ducks';

export default function AdminFormsFeature() {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const transactions = useSelector(selectFormsListItems);
    const pagination = useSelector(selectFormsListPagination);
    const loading = useSelector(selectIsFormsListLoading);

    useMount(() => {
        dispatch(setFormsListSearchQueryAction(''));
    });

    function handleShowDetails(id: string) {
        history.push(`/form/${id}`);
    }

    function handleSearchSubmit(query: string) {
        dispatch(setFormsListSearchQueryAction(query));
    }

    function handlePageChange(page: number) {
        dispatch(setFormsListPageNumberAction(page));
    }

    function handlePageSizeChange(current: number, size: number) {
        dispatch(setFormsListPageSizeAction(size));
    }

    return (
        <>
            <div style={{ margin: '30px 0' }}>
                <SearchBox
                    onSearchClicked={handleSearchSubmit}
                    buttonText={t('formsManagement.search')}
                    placeholder={t('formsManagement.searchPlaceholder')}
                />
            </div>
            <Divider />
            <FormsList
                displayOwner
                items={transactions}
                totalItems={pagination.total}
                currentPage={pagination.currentPage}
                pageSize={pagination.pageSize}
                loading={loading}
                renderActions={item => [
                    <Button
                        onClick={() => handleShowDetails(item.id)}
                        type="primary"
                        size="default"
                    >
                        {t('formsManagement.details')}
                    </Button>,
                ]}
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
            />
        </>
    );
}
