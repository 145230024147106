import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';

import { Button } from '../../components';
import { InputField } from '../../fields';
import { RegistrationFormValues } from '../../models/User';
import { Row, Col } from 'antd';

interface Props extends WithTranslation {
    onSubmit: (values: RegistrationFormValues) => void;
    initialValues?: Partial<RegistrationFormValues>;
}

function RegistrationForm({ onSubmit, t }: Props) {
    const schema = Yup.object<RegistrationFormValues>({
        oldPassword: Yup.string()
            .email(t('form.emailRequired'))
            .required(t('form.required')),
        password: Yup.string()
            .required(t('form.required'))
            .matches(
                new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})'),
                t('form.passwordInvalid')
            ),
        passwordAgain: Yup.string()
            .required(t('form.required'))
            .oneOf([Yup.ref('password'), null], t('form.passwordsMustMatch')),
    });

    function handleOnSubmit({ oldPassword, password }: RegistrationFormValues) {
        onSubmit && onSubmit({ oldPassword, password });
    }

    function renderForm() {
        return (
            <div className="registration-form">
                <Form>
                    <InputField
                        name="oldPassword"
                        type="password"
                        size="large"
                        label={t('registrationForm.oldPassword.label')}
                        placeholder={t(
                            'registrationForm.oldPassword.placeholder'
                        )}
                    />
                    <InputField
                        name="password"
                        type="password"
                        size="large"
                        label={t('registrationForm.password.label')}
                        placeholder={t('registrationForm.password.placeholder')}
                    />
                    <InputField
                        name="passwordAgain"
                        type="password"
                        size="large"
                        label={t('registrationForm.passwordAgain.label')}
                        placeholder={t(
                            'registrationForm.passwordAgain.placeholder'
                        )}
                    />
                    <Row
                        type="flex"
                        justify="center"
                        style={{ marginTop: '30px' }}
                    >
                        <Col>
                            <Button htmlType="submit" type="primary">
                                {t('registrationForm.submit')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

    return (
        <Formik<RegistrationFormValues>
            initialValues={{
                oldPassword: '',
                password: '',
                passwordAgain: '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}

export default withTranslation()(RegistrationForm);
