// Example
import { apiClient } from '../services/api-client';
import {
    LoginFormValues,
    RegistrationFormValues,
    UserProfile,
    ProfileFormValues,
} from '../models/User';

export default {
    logout: () => {
        return apiClient.post<UserProfile>(`/users/logout`);
    },
    login: (values: LoginFormValues) => {
        return apiClient.post<UserProfile>(`/users/login`, values);
    },
    profile: () => {
        return apiClient.get<UserProfile>('/users/profile');
    },
    updateProfile: (values: Partial<UserProfile>) => {
        return apiClient.post(`/users/profile`, values);
    },
    register: (auth: RegistrationFormValues, profile: ProfileFormValues) => {
        return apiClient.post<UserProfile>(`/users/registration`, {
            ...auth,
            ...profile,
            meta: {
                registration: {
                    channel: 'eshop',
                    locale: 'cz',
                },
            },
        });
    },
    activateRegistration: (token: string) => {
        return apiClient.get('/users/activation', {
            params: {
                token,
            },
        });
    },
    passwordReset: (email: string, locale: string, recoveryUrl: string) => {
        return apiClient.post(`/users/password/reset`, {
            email,
            locale,
            recoveryUrl,
        });
    },
    forgotPassword: (email: string, recoveryUrl: string, locale = 'cz') => {
        return apiClient.post('/users/password/reset', {
            recoveryUrl,
            email,
            locale,
        });
    },
    changePassword: (token: string, password: string, locale = 'cz') => {
        return apiClient.post('/users/recovery', {
            token,
            password,
            locale,
        });
    },
    updatePassword: (password: string) => {
        return apiClient.post('/users/profile', {
            password,
        });
    },
    find: (id: string) => {
        return apiClient.get('/users/find', {
            params: {
                id,
            },
        });
    },
};
