import { Field, FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';
import { useRegisteredRef } from 'react-register-nodes';
import isNumeric from 'antd/lib/_util/isNumeric';

import { Input } from '../../components';
import { InputNumberProps } from 'antd/lib/input-number';

interface Props {
    name: string;
    label?: ReactNode;
    size?: InputNumberProps['size'];
    min?: InputNumberProps['min'];
    max?: InputNumberProps['max'];
    precision?: number;
    isCurrency?: boolean;
    disabled?: boolean;
}

export default function InputField({
    name,
    size,
    precision = 2,
    ...bag
}: Props) {
    const ref = useRegisteredRef(name);

    return (
        <Field name={name}>
            {({
                field: { value, ...field },
                form: { errors, setFieldValue },
            }: FieldProps) => {
                function handleChange(v?: number) {
                    setFieldValue(name, isNumeric(v) ? v : 0);
                }
                return (
                    <div ref={errors[name] ? ref : null}>
                        <Input.Number
                            precision={precision}
                            error={getIn(errors, name)}
                            size={size}
                            defaultValue={value}
                            {...field}
                            {...bag}
                            onChange={handleChange}
                        />
                    </div>
                );
            }}
        </Field>
    );
}
