import React, { Component, ReactNode } from 'react';
import api from '../../common/api';
import { connect } from 'react-redux';

import { AppState } from '../../common/models/AppState';
import { selectUserProfile } from '../../features/auth/ducks';
import { UserProfile } from '../../common/models/User';
import { Utils } from '../../common/components';

interface State {
    hasError: boolean;
}

interface StateToProps {
    children: ReactNode;
    profile: UserProfile | null;
}

class ErrorBoundary extends Component<StateToProps, State> {
    state = {
        hasError: false,
    };

    componentDidCatch(e: Error) {
        if (process.env.NODE_ENV !== 'development') {
            api.journal.postError(e, { userId: this.props.profile?.id ?? 'Unknown' });
        }
        this.setState({ hasError: true });
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        return hasError ? <Utils.Error /> : children;
    }
}

export default connect((state: AppState) => ({
    profile: selectUserProfile(state),
}))(ErrorBoundary);
