import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import { InvestingExperienceForm } from 'common/forms';

import { Typography } from 'common/components';
import { QuestionTitle, PageControls } from '../../../../components';
import { InvestingExperienceFormValues } from '../../../../../../common/models/Forms';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import {
    setInvestingExperienceValuesAction,
    selectInvestingExperience,
} from '../../../../ducks';
import { TableGroupResult } from 'common/components/Form/Radio';
import { FeatureProps } from '../../../../index';

export default function InvestingExperienceFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectInvestingExperience);
    const match = useRouteMatch<{ id: string }>();

    let submitForm: FormikProps<InvestingExperienceFormValues>['submitForm'];

    function handleBackClick() {
        history.goBack();
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(
        cb: FormikProps<InvestingExperienceFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(
        values: InvestingExperienceFormValues,
        table: TableGroupResult[]
    ) {
        if (!isPrivileged) {
            dispatch(setInvestingExperienceValuesAction({ ...values, table }));
        }
        history.push(`/form/${match!.params.id}/investing/hazard`);
    }
    return (
        <>
            <QuestionTitle prefix="I.">
                {t('investingFeature.experience.title')}
            </QuestionTitle>
            <Typography.Paragraph style={{ marginBottom: '50px' }}>
                {t('investingFeature.experience.content')}
            </Typography.Paragraph>
            <InvestingExperienceForm
                initialValues={initialValues || undefined}
                bindSubmit={bindSubmit}
                onSubmit={handleSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
