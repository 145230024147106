import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import { AppState } from '../../common/models/AppState';
import auth from '../../features/auth/ducks';
import form from '../../features/form/ducks';
import home from '../../features/home/ducks';

export const history = createBrowserHistory();

export default combineReducers<AppState>({
    router: connectRouter(history),
    auth,
    form,
    home,
});

export function selectRouter(state: AppState) {
    return state.router;
}
