import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { Icon } from 'antd';
import * as Yup from 'yup';

import { I18nNameSpaces } from 'common/services/i18n';
import { ClientVerificationFormEmailValues } from 'common/models/Forms';
import { InputSearchField } from 'common/fields';
import { ResultMessage } from 'common/components';

interface Props {
    success?: string | null;
    error?: string | null;
    loadingRequest?: boolean;
    loadingVerify?: boolean;
    initialValues?: Partial<ClientVerificationFormEmailValues>;
    disableVerify?: boolean;

    onRequestCode: (email: string) => void;
    onVerifyCode: (code: string) => void;
}

export default function ClientVerificationForm({
    success,
    error,
    loadingRequest,
    loadingVerify,
    initialValues,
    disableVerify,

    onRequestCode,
    onVerifyCode,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);

    const emailSchema = Yup.object().shape<
        Pick<ClientVerificationFormEmailValues, 'email'>
    >({
        email: Yup.string().required(t('form.required')),
    });

    const codeSchema = Yup.object().shape<
        Pick<ClientVerificationFormEmailValues, 'code'>
    >({
        code: Yup.string().required(t('form.required')),
    });

    function renderCodeRequestForm({
        submitForm,
    }: FormikProps<Pick<ClientVerificationFormEmailValues, 'email'>>) {
        return (
            <Form>
                <InputSearchField
                    name="email"
                    label={t('clientForm.email.label')}
                    defaultValue={initialValues?.email ?? ''}
                    placeholder={t('clientForm.email.placeholder')}
                    enterButton={
                        loadingRequest ? (
                            <Icon type="loading" />
                        ) : (
                            t('verificationForm.email.request.submit')
                        )
                    }
                    onSearch={submitForm}
                    disabled={loadingRequest}
                />
            </Form>
        );
    }

    function renderCodeVerifyForm({
        submitForm,
    }: FormikProps<Pick<ClientVerificationFormEmailValues, 'code'>>) {
        return (
            <Form>
                <InputSearchField
                    name="code"
                    label={t('verificationForm.email.code.label')}
                    placeholder={t('verificationForm.email.code.placeholder')}
                    autoComplete="off"
                    enterButton={
                        loadingVerify ? (
                            <Icon type="loading" />
                        ) : (
                            t('verificationForm.email.verify.submit')
                        )
                    }
                    onSearch={submitForm}
                    disabled={loadingVerify || disableVerify}
                />
            </Form>
        );
    }

    return (
        <div className="client-verification-form">
            <Formik
                initialValues={{
                    email: initialValues?.email ?? '',
                }}
                onSubmit={({ email }) => onRequestCode(email)}
                render={renderCodeRequestForm}
                validationSchema={emailSchema}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
            />
            <Formik
                initialValues={{
                    code: '',
                }}
                onSubmit={({ code }) => onVerifyCode(code)}
                render={renderCodeVerifyForm}
                validationSchema={codeSchema}
                validateOnBlur={false}
                validateOnChange={false}
            />
            {!!error && <ResultMessage type="error">{error}</ResultMessage>}
            {!!success && !error && (
                <ResultMessage type="success">{success}</ResultMessage>
            )}
        </div>
    );
}
