import { Field, FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';
import { useRegisteredRef } from 'react-register-nodes';

import { Input } from '../../components';
import { InputProps } from 'antd/lib/input';

interface Props {
    name: string;
    label?: ReactNode;
    placeholder?: string;
    type?: 'password' | 'text';
    size?: InputProps['size'];
}

export default function InputField({ name, type, size, ...bag }: Props) {
    const ref = useRegisteredRef(name);

    return (
        <Field name={name}>
            {({ field, form: { errors } }: FieldProps<any>) => (
                <div ref={getIn(errors, name) ? ref : null}>
                    {type === 'password' ? (
                        <Input.Password
                            error={getIn(errors, name)}
                            size={size}
                            {...field}
                            {...bag}
                        />
                    ) : (
                        <Input
                            type={type}
                            error={getIn(errors, name)}
                            size={size}
                            {...field}
                            {...bag}
                        />
                    )}
                </div>
            )}
        </Field>
    );
}
