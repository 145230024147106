import { message } from 'antd';

import api from 'common/api';
import { TokenState } from 'common/api/token';
import { AppState } from 'common/models/AppState';
import { DocumentSignaturesFormValues } from 'common/models/Forms';
import { TokenDeliveryMethod, TokenType } from 'common/models/Token';
import { SignatureScope, Transaction } from 'common/models/Transaction';
import { PdfRenderer } from 'common/pdf/pdf-renderer';
import i18n from 'common/services/i18n';
import {
    loadTransactionActions,
    selectForm,
    selectFormTransaction,
    selectInformationsCustomerInfo1,
} from 'features/form/ducks';
import { TFunction } from 'i18next';
import { combineReducers } from 'redux';
import { call, delay, put, select, spawn, take, takeLatest, } from 'redux-saga/effects';
import { ActionType, createAction, createReducer, getType, } from 'typesafe-actions';
import { UserProfile } from '../../../../common/models/User';
import { selectUserProfile } from '../../../auth/ducks';

import verificationLog, {
    initialVerificationLogState,
    verificationLogSaga,
    VerificationLogState,
} from './features/verification-log/ducks';

export function selectClientSignature(state: AppState) {
    return selectForm(state).documents.signatures;
}

export function selectDocumentsToBeSigned(state: AppState) {
    return selectClientSignature(state).selectedDocuments;
}

export const resetSignaturesStateAction = createAction(
    '@@Documents/Signatures/RESET'
);

export const requestSignatureCodeAction = createAction(
    '@@Documents/Signatures/REQUEST_CODE'
);

export const submitSignatureCodeAction = createAction(
    '@@Documents/Signatures/SUBMIT_CODE',
    action => (values: DocumentSignaturesFormValues['code']) => action(values)
);

export const setSelectedDocumentsAction = createAction(
    '@@Documents/Signatures/SET_SELECTED_DOCUMENTS',
    action => (values: { document: SignatureScope; isSelected: boolean }) =>
        action(values)
);

export const forwardDocumentsAction = createAction(
    '@@Documents/Signatures/FORWARD_SIGNED',
    action => (values: SignatureScope[]) => action(values)
);

export const setDocumentUploadStatusAction = createAction(
    '@@Documents/Signatures/SET_UPLOAD_STATUS',
    action => (values: UploadState) => action(values)
);

const changeSignaturesStateAction = createAction(
    '@@Documents/Signatures/CHANGE_STATE',
    action => (values: TokenState) => action(values)
);

const updateSignaturesStateAction = createAction(
    '@@Documents/Signatures/UPDATE_STATE',
    action => (values: Partial<SignaturesState>) => action(values)
);

export const initialSignaturesFormState = {
    selectedDocuments: [],
    status: TokenState.DEFAULT,
};

export enum UploadState {
    UPLOADING = 'uploading',
    SUCCESS = 'success',
    FAILED = 'failed',
}

export interface SignaturesState {
    selectedDocuments: SignatureScope[];
    uploadStatus?: UploadState;
    status: TokenState;
    attemptsLeft?: number;
    httpStatus?: number;
}

type DocumentsFormActions = ActionType<
    | typeof setSelectedDocumentsAction
    | typeof requestSignatureCodeAction
    | typeof submitSignatureCodeAction
    | typeof changeSignaturesStateAction
    | typeof updateSignaturesStateAction
    | typeof resetSignaturesStateAction
    | typeof setDocumentUploadStatusAction
>;

const signatures = createReducer<SignaturesState, DocumentsFormActions>(
    initialSignaturesFormState
)
    .handleAction(
        setSelectedDocumentsAction,
        (state, { payload: { isSelected, document } }) => ({
            ...state,
            selectedDocuments: isSelected
                ? [...state.selectedDocuments, document]
                : state.selectedDocuments.filter(
                      selectedDocument => selectedDocument !== document
                  ),
        })
    )
    .handleAction(updateSignaturesStateAction, (state, { payload }) => ({
        ...state,
        ...payload,
    }))
    .handleAction(changeSignaturesStateAction, (state, { payload }) => ({
        selectedDocuments: state.selectedDocuments,
        status: payload,
    }))
    .handleAction(requestSignatureCodeAction, state => ({
        ...state,
        status: TokenState.REQUESTED,
    }))
    .handleAction(submitSignatureCodeAction, state => ({
        ...state,
        status: TokenState.SUBMITTED,
    }))
    .handleAction(setDocumentUploadStatusAction, (state, { payload }) => ({
        ...state,
        uploadStatus: payload,
    }))
    .handleAction(resetSignaturesStateAction, () => initialSignaturesFormState);

export interface DocumentsState {
    signatures: SignaturesState;
    verificationLog: VerificationLogState;
}

export const initialDocumentsState = {
    signatures: initialSignaturesFormState,
    verificationLog: initialVerificationLogState,
};

export default combineReducers<DocumentsState>({
    signatures,
    verificationLog,
});

function* watchRequestSignatureCode() {
    // Prevent accidental double-clicks submissions
    yield delay(250);

    const transaction: Transaction = yield select(selectFormTransaction);
    const signatureScopes: SignatureScope[] = yield select(
        selectDocumentsToBeSigned
    );

    const { ok, status } = yield call(
        api.token.create,
        transaction._id,
        TokenType.SIGNATURE,
        TokenDeliveryMethod.PHONE,
        signatureScopes
    );

    yield put(
        updateSignaturesStateAction({
            status: ok ? TokenState.DELIVERED : TokenState.DELIVERY_ERROR,
            httpStatus: status,
        })
    );
}

function* watchSubmitSignatureCode({
    payload,
}: ReturnType<typeof submitSignatureCodeAction>) {
    const transaction: Transaction = yield select(selectFormTransaction);
    const { phone } = yield select(selectInformationsCustomerInfo1);
    const selectedDocuments: SignatureScope[] = yield select(
        selectDocumentsToBeSigned
    );

    const { ok, status, response } = yield call(
        api.token.verify,
        transaction._id,
        TokenType.SIGNATURE,
        TokenDeliveryMethod.PHONE,
        `${phone.prefix}${phone.number}`,
        payload
    );

    yield put(
        updateSignaturesStateAction({
            status: ok ? TokenState.VERIFIED : TokenState.SUBMISSION_ERROR,
            httpStatus: status,
            attemptsLeft: response?.data?.attemptsLeft,
            ...(ok && { selectedDocuments: [] }),
        })
    );

    // Load the transaction again to reflect the updated signatures state set by BE
    yield put(loadTransactionActions.request(transaction._id));
    yield take([
        loadTransactionActions.success,
        loadTransactionActions.failure,
    ]);

    if (ok) {
        yield put(setDocumentUploadStatusAction(UploadState.UPLOADING));
        yield put(forwardDocumentsAction(selectedDocuments));
    }
}

function* watchForwardSignedDocuments({
    payload: documentIds,
}: ReturnType<typeof forwardDocumentsAction>) {
    const t: TFunction = yield i18n;

    try {
        const state: AppState = yield select();
        const renderer = new PdfRenderer(state);
        const documents: { filename: string; blob: Blob }[] = yield call(
            renderer.createBlobs,
            documentIds
        );
        const transaction: Transaction = yield select(selectFormTransaction);
        const { ok } = yield call(
            api.transaction.forwardDocuments,
            transaction._id,
            documents
        );

        ok
            ? message.success(t('forms:signatureForm.uploadSuccess'))
            : message.error(t('forms:signatureForm.uploadFailure'));

        yield put(
            setDocumentUploadStatusAction(
                ok ? UploadState.SUCCESS : UploadState.FAILED
            )
        );
    } catch (error) {
        const user: UserProfile = yield select(selectUserProfile);
        api.journal.postError(error, { userId: user.id })
        yield put(setDocumentUploadStatusAction(UploadState.FAILED));
        message.error(t('forms:signatureForm.uploadFailure'));
    }
}

export function* signaturesSaga() {
    yield takeLatest(
        [getType(requestSignatureCodeAction)],
        watchRequestSignatureCode
    );
    yield takeLatest(
        [getType(submitSignatureCodeAction)],
        watchSubmitSignatureCode
    );
    yield takeLatest(
        [getType(forwardDocumentsAction)],
        watchForwardSignedDocuments
    );
    yield spawn(verificationLogSaga);
}
