import React, { useRef } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'antd';

import { Typography, QuestionWrapper } from '../../components';
import { InputField, RadioField } from '../../fields';
import { CompanyInfoFormValues } from '../../models/Forms';
import { useTranslation } from 'react-i18next';
import { I18nNameSpaces } from '../../services/i18n';

interface Props {
    error?: boolean;
    initialValues?: Partial<CompanyInfoFormValues>;
    bindSubmit?: (cb: FormikProps<CompanyInfoFormValues>['submitForm']) => void;
    onSubmit: (values: CompanyInfoFormValues) => void;
}

export default function CompanyInfoForm({
    onSubmit,
    initialValues,
    error,
    bindSubmit,
}: Props) {
    const formRef = useRef();
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<CompanyInfoFormValues>({
        gender: Yup.string().required(t('form.required')),
        firstName: Yup.string().required(t('form.required')),
        lastName: Yup.string().required(t('form.required')),
        partnerNumber: Yup.string().required(t('form.required')),
        ico: Yup.string().required(t('form.required')),
        address: Yup.string().required(t('form.required')),
        email: Yup.string().required(t('form.required')),
        phone: Yup.string().required(t('form.required')),
        title: Yup.string(),
    });

    function handleOnSubmit(values: CompanyInfoFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({ submitForm }: FormikProps<CompanyInfoFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <QuestionWrapper.Divider>
                    <Form>
                        <Row gutter={20}>
                            <Col>
                                <RadioField
                                    name="gender"
                                    type="horizontal"
                                    options={[
                                        {
                                            label: t(
                                                'companyForm.gender.options.mr'
                                            ),
                                            value: 'mr',
                                        },
                                    ]}
                                    label={t('companyForm.gender.label')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="title"
                                    label={t('companyForm.title.label')}
                                    placeholder={t(
                                        'companyForm.title.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="firstName"
                                    label={t('companyForm.firstName.label')}
                                    placeholder={t(
                                        'companyForm.firstName.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="lastName"
                                    label={t('companyForm.lastName.label')}
                                    placeholder={t(
                                        'companyForm.lastName.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="partnerNumber"
                                    label={t('companyForm.partnerNumber.label')}
                                    placeholder={t(
                                        'companyForm.partnerNumber.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="ico"
                                    label={t('companyForm.ico.label')}
                                    placeholder={t(
                                        'companyForm.ico.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="address"
                                    label={t('companyForm.address.label')}
                                    placeholder={t(
                                        'companyForm.address.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="email"
                                    label={t('companyForm.email.label')}
                                    placeholder={t(
                                        'companyForm.email.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="phone"
                                    label={t('companyForm.phone.label')}
                                    placeholder={t(
                                        'companyForm.phone.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                        {error && (
                            <Typography.Paragraph type="danger">
                                {t('companyForm.serverError')}
                            </Typography.Paragraph>
                        )}
                    </Form>
                </QuestionWrapper.Divider>
            </div>
        );
    }

    return (
        <Formik
            ref={formRef as any}
            initialValues={{
                // The 'gender' radio button is only a visual hint with hardcoded value
                // It could be removed from the form data model, but customer has requested hiding/disabling it instead
                gender: 'mr',
                firstName: initialValues?.firstName ?? '',
                lastName: initialValues?.lastName ?? '',
                partnerNumber: initialValues?.partnerNumber ?? '',
                ico: initialValues?.ico ?? '',
                address: initialValues?.address ?? '',
                email: initialValues?.email ?? '',
                phone: initialValues?.phone ?? '',
                title: initialValues?.title ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
