import React, { ReactNode } from 'react';
import { Typography } from 'antd';

interface Props {
    title: ReactNode;
    description?: ReactNode;
}

export default function PageDescription({ title, description }: Props) {
    return (
        <div className="auth-page-description">
            <Typography.Title level={1}>{title}</Typography.Title>
            {description && (
                <Typography.Paragraph>{description}</Typography.Paragraph>
            )}
        </div>
    );
}
