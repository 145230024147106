import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { Typography, QuestionWrapper } from '../../components';
import {
    InputField,
    RadioField,
    InputNumberField,
    CheckBoxGroupField,
} from '../../fields';
import { ClientFormValues } from '../../models/Forms';
import { I18nNameSpaces, Languages } from '../../services/i18n';
import PhoneInputField from '../../fields/PhoneInput';

interface Props {
    error?: boolean;
    initialValues?: Partial<ClientFormValues>;
    onSubmit: (values: ClientFormValues) => void;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
}

export default function ClientForm({
    onSubmit,
    initialValues,
    error,
    bindSubmit,
}: Props) {
    const {
        t,
        i18n: { language },
    } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<ClientFormValues>({
        gender: Yup.string().required(t('form.required')),
        firstName: Yup.string().required(t('form.required')),
        lastName: Yup.string().required(t('form.required')),
        email: Yup.string().required(t('form.required')),
        phone: Yup.object().shape({
            prefix: Yup.string().required(t('form.required')),
            number: Yup.string().required(t('form.required')),
        }),
        street: Yup.string().required(t('form.required')),
        streetNumber: Yup.string().required(t('form.required')),
        state: Yup.string().required(t('form.required')),
        city: Yup.string().required(t('form.required')),
        psc: Yup.string().required(t('form.required')),
        title: Yup.string(),
        birthday: Yup.object()
            .shape({
                date: Yup.string().required(t('form.required')),
                city: Yup.string().required(t('form.required')),
                state: Yup.string().required(t('form.required')),
            })
            .required(),
        taxId: Yup.string().required(t('form.required')),
        identification: Yup.string().required(t('form.required')),
        identificationNumber: Yup.string().required(t('form.required')),
        nationality: Yup.string().required(t('form.required')),
        taxResidence: Yup.string().required(t('form.required')),
        education: Yup.string().required(t('form.required')),
        status: Yup.string().required(t('form.required')),
        salary: Yup.string().required(t('form.required')),
        dependentChildren: Yup.number().required(t('form.required')),
        profession: Yup.string().required(t('form.required')),
        employerName: Yup.string().required(t('form.required')),
        employerId: Yup.string().required(t('form.required')),
        politicalStatus: Yup.boolean().required(t('form.required')),
        goal: Yup.array(Yup.string()).test(
            'Required subset test',
            t('form.required'),
            (array: string[]) => {
                const requiredItems = ['a', 'b', 'g', 'd', 'h', 'i'];
                return array.some(item => requiredItems.includes(item));
            }
        ),
        currency: Yup.string().required(t('form.required')),
    });

    function handleOnSubmit(values: ClientFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({ submitForm, values }: FormikProps<ClientFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper.Divider>
                        <Row gutter={20}>
                            <Col>
                                <RadioField
                                    name="gender"
                                    type="horizontal"
                                    options={[
                                        {
                                            label: t(
                                                'clientForm.gender.options.mr'
                                            ),
                                            value: 'mr',
                                        },
                                        {
                                            label: t(
                                                'clientForm.gender.options.mrs'
                                            ),
                                            value: 'mrs',
                                        },
                                    ]}
                                    label={t('clientForm.gender.label')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="title"
                                    label={t('clientForm.title.label')}
                                    placeholder={t(
                                        'clientForm.title.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="firstName"
                                    label={t('clientForm.firstName.label')}
                                    placeholder={t(
                                        'clientForm.firstName.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="lastName"
                                    label={t('clientForm.lastName.label')}
                                    placeholder={t(
                                        'clientForm.lastName.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="city"
                                    label={t('clientForm.city.label')}
                                    placeholder={t(
                                        'clientForm.city.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="street"
                                    label={t('clientForm.street.label')}
                                    placeholder={t(
                                        'clientForm.street.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={4}>
                                <InputField
                                    name="streetNumber"
                                    label={t('clientForm.streetName.label')}
                                    placeholder={t(
                                        'clientForm.streetName.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={4}>
                                <InputField
                                    name="psc"
                                    label={t('clientForm.psc.label')}
                                    placeholder={t(
                                        'clientForm.psc.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="state"
                                    label={t('clientForm.state.label')}
                                    placeholder={t(
                                        'clientForm.state.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <PhoneInputField
                                    name={'phone'}
                                    label={t('clientForm.phone.label')}
                                    placeholder={t(
                                        'clientForm.phone.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="email"
                                    label={t('clientForm.email.label')}
                                    placeholder={t(
                                        'clientForm.email.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row gutter={20} />
                    </QuestionWrapper.Divider>
                    <QuestionWrapper.Divider>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="birthday.date"
                                    label={t('clientForm.birthday.date.label')}
                                    placeholder={t(
                                        'clientForm.birthday.date.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="birthday.city"
                                    label={t('clientForm.birthday.city.label')}
                                    placeholder={t(
                                        'clientForm.birthday.city.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="birthday.state"
                                    label={t('clientForm.birthday.state.label')}
                                    placeholder={t(
                                        'clientForm.birthday.state.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="taxId"
                                    label={t('clientForm.taxId.label')}
                                    placeholder={t(
                                        'clientForm.taxId.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                    <QuestionWrapper.Divider>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <Row>
                                    <Col xs={24}>
                                        <InputField
                                            name="identificationNumber"
                                            label={t(
                                                'clientForm.identificationNumber.label'
                                            )}
                                            placeholder={t(
                                                'clientForm.identificationNumber.placeholder'
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24}>
                                        <InputField
                                            name="nationality"
                                            label={t(
                                                'clientForm.nationality.label'
                                            )}
                                            placeholder={t(
                                                'clientForm.nationality.placeholder'
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} md={16}>
                                <Row>
                                    <Col xs={24}>
                                        <RadioField
                                            name="identification"
                                            type="horizontal"
                                            options={[
                                                {
                                                    label: t(
                                                        'clientForm.identification.options.PERSONAL_ID'
                                                    ),
                                                    value: 'PERSONAL_ID',
                                                },
                                                {
                                                    label: t(
                                                        'clientForm.identification.options.PASSPORT'
                                                    ),
                                                    value: 'PASSPORT',
                                                },
                                            ]}
                                            label={t(
                                                'clientForm.identification.label'
                                            )}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={24} md={12}>
                                        <InputField
                                            name="taxResidence"
                                            label={t(
                                                'clientForm.taxResidence.label'
                                            )}
                                            placeholder={t(
                                                'clientForm.taxResidence.placeholder'
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                    <QuestionWrapper.Divider>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <RadioField
                                    name="education"
                                    type="vertical"
                                    options={[
                                        {
                                            label: t(
                                                'clientForm.education.options.elementary'
                                            ),
                                            value: 'elementary',
                                        },
                                        {
                                            label: t(
                                                'clientForm.education.options.highSchool'
                                            ),
                                            value: 'highSchool',
                                        },
                                        {
                                            label: t(
                                                'clientForm.education.options.maturity'
                                            ),
                                            value: 'maturity',
                                        },
                                        {
                                            label: t(
                                                'clientForm.education.options.university'
                                            ),
                                            value: 'university',
                                        },
                                    ]}
                                    label={t('clientForm.education.label')}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <RadioField
                                    name="status"
                                    type="vertical"
                                    options={[
                                        {
                                            label: t(
                                                'clientForm.status.options.single'
                                            ),
                                            value: 'single',
                                        },
                                        {
                                            label: t(
                                                'clientForm.status.options.married'
                                            ),
                                            value: 'married',
                                        },
                                        {
                                            label: t(
                                                'clientForm.status.options.widow'
                                            ),
                                            value: 'widow',
                                        },
                                        {
                                            label: t(
                                                'clientForm.status.options.divorced'
                                            ),
                                            value: 'divorced',
                                        },
                                    ]}
                                    label={t('clientForm.status.label')}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <RadioField
                                    name="salary"
                                    type="vertical"
                                    options={[
                                        {
                                            label: t(
                                                'clientForm.salary.options.freelancer'
                                            ),
                                            value: 'freelancer',
                                        },
                                        {
                                            label: t(
                                                'clientForm.salary.options.employed'
                                            ),
                                            value: 'employed',
                                        },
                                        {
                                            label: t(
                                                'clientForm.salary.options.notEmployed'
                                            ),
                                            value: 'notEmployed',
                                        },
                                        {
                                            label: t(
                                                'clientForm.salary.options.senior'
                                            ),
                                            value: 'senior',
                                        },
                                    ]}
                                    label={t('clientForm.salary.label')}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                    <QuestionWrapper.Divider>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <InputNumberField
                                    name="dependentChildren"
                                    min={0}
                                    precision={0}
                                    label={t(
                                        'clientForm.dependentChildren.label'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="profession"
                                    label={t(
                                        `clientForm.profession.label.${
                                            values.salary || 'employed'
                                        }`
                                    )}
                                    placeholder={t(
                                        'clientForm.profession.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="employerName"
                                    label={t('clientForm.employerName.label')}
                                    placeholder={t(
                                        'clientForm.employerName.placeholder'
                                    )}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="employerId"
                                    label={t('clientForm.employerId.label')}
                                    placeholder={t(
                                        'clientForm.employerId.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                    <QuestionWrapper.Divider>
                        <Row>
                            <Col xs={24}>
                                <RadioField
                                    name="politicalStatus"
                                    type="vertical"
                                    options={[
                                        {
                                            label: t(
                                                'clientForm.politicalStatus.options.positive'
                                            ),
                                            value: false,
                                        },
                                        {
                                            label: t(
                                                'clientForm.politicalStatus.options.negative'
                                            ),
                                            value: true,
                                        },
                                    ]}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                    <QuestionWrapper.Divider>
                        <Row gutter={20}>
                            <Col xs={24} md={8}>
                                <CheckBoxGroupField
                                    name="goal"
                                    type="vertical"
                                    options={[
                                        ...['a', 'b', 'j', 'd', 'k', 'i'].map(
                                            value => ({
                                                label: t(
                                                    `clientForm.goal.options.${value}`
                                                ),
                                                value,
                                            })
                                        ),
                                        {
                                            label: t(
                                                'clientForm.goal.options.f'
                                            ),
                                            value: 'f',
                                            custom: true,
                                        },
                                        {
                                            label: null,
                                            value: 'g', // wealth conservation
                                            legacy: true,
                                        },
                                        {
                                            label: null,
                                            value: 'h', // regular income
                                            legacy: true,
                                        },
                                    ]}
                                    label={t('clientForm.goal.label')}
                                />
                            </Col>
                            <Col xs={24} md={8}>
                                <InputField
                                    name="currency"
                                    label={t('clientForm.currency.label')}
                                    placeholder={t(
                                        'clientForm.currency.placeholder'
                                    )}
                                />
                            </Col>
                        </Row>
                    </QuestionWrapper.Divider>
                    {error && (
                        <Typography.Paragraph type="danger">
                            {t('clientForm.serverError')}
                        </Typography.Paragraph>
                    )}
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                gender: initialValues?.gender ?? '',
                firstName: initialValues?.firstName ?? '',
                lastName: initialValues?.lastName ?? '',
                email: initialValues?.email ?? '',
                phone: initialValues?.phone ?? {
                    number: '',
                    prefix: language === Languages.Czech ? '420' : '421',
                },
                street: initialValues?.street ?? '',
                streetNumber: initialValues?.streetNumber ?? '',
                state: initialValues?.state ?? '',
                city: initialValues?.city ?? '',
                psc: initialValues?.psc ?? '',
                title: initialValues?.title ?? '',
                birthday: {
                    date: initialValues?.birthday?.date ?? '',
                    city: initialValues?.birthday?.city ?? '',
                    state: initialValues?.birthday?.state ?? '',
                },
                taxId: initialValues?.taxId ?? '',
                identification: initialValues?.identification ?? '',
                identificationNumber: initialValues?.identificationNumber ?? '',
                dependentChildren: initialValues?.dependentChildren ?? 0,
                nationality: initialValues?.nationality ?? '',
                taxResidence: initialValues?.taxResidence ?? '',
                education: initialValues?.education ?? '',
                status: initialValues?.status ?? '',
                salary: initialValues?.salary ?? '',
                profession: initialValues?.profession ?? '',
                employerName: initialValues?.employerName ?? '',
                employerId: initialValues?.employerId ?? '',
                politicalStatus: initialValues?.politicalStatus ?? false,
                goal: initialValues?.goal ?? [],
                currency: initialValues?.currency ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
