import React, { ReactNode } from 'react';
import { Steps } from 'antd';
import { StepsProps } from 'antd/lib/steps';

interface Props extends StepsProps {
    children: ReactNode;
}

export default function EnhancedSteps(props: Props) {
    return <Steps {...props} />;
}

EnhancedSteps.Step = Steps.Step;
