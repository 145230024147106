import React from 'react';

import {
    ConsultantConfirmationFormValues,
    RequestInfoFormValues,
    BusinessPartnerInfoFormValues,
    ClientFormValues,
    ClientConfirmationFormValues,
    InvestingGoalsFormValues,
    CompanyInfoFormValues,
} from '../models/Forms';

import {
    PDFBrandedPageWithFooter,
    PDFParagraphBlock,
    PDFSignatureBlock,
    PDFDivider,
} from './components';
import { View, Text } from '@react-pdf/renderer';
import { useTranslation, Trans } from 'react-i18next';
import { I18nNameSpaces } from '../services/i18n';
import numbro from 'numbro';

interface Props {
    confirmation: ConsultantConfirmationFormValues;
    clientConfirmation: ClientConfirmationFormValues;
    goals: InvestingGoalsFormValues;
    requestInfo: RequestInfoFormValues;
    businessInfo: BusinessPartnerInfoFormValues;
    client1?: ClientFormValues;
    client1Signature?: string;
    client2?: ClientFormValues;
    companyInfo: CompanyInfoFormValues;
}

export default function AgreementPage({
    requestInfo,
    client1,
    client1Signature,
    client2,
    companyInfo,
    businessInfo,
    confirmation,
    goals,
    clientConfirmation,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.PDF);
    const footerTitle = (
        <PDFParagraphBlock>{t('agreement.title')}</PDFParagraphBlock>
    );
    return (
        <PDFBrandedPageWithFooter footerTitle={footerTitle}>
            <View>
                <PDFParagraphBlock.Title size="large" bold underline blue>
                    {t('agreement.title')}
                </PDFParagraphBlock.Title>
                <PDFDivider />
                <PDFDivider />
                <PDFParagraphBlock.Title bold blue size="medium">
                    {t('agreement.section1.title')}
                </PDFParagraphBlock.Title>
                <PDFDivider />
                <View style={{ flexDirection: 'row' }}>
                    <PDFParagraphBlock>
                        {t('agreement.section1.q1')}
                    </PDFParagraphBlock>
                    <PDFParagraphBlock bold>{requestInfo.a}</PDFParagraphBlock>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexDirection: 'row', minWidth: '23%' }}>
                        <PDFParagraphBlock>
                            {t('agreement.section1.q2')}
                        </PDFParagraphBlock>
                        <PDFParagraphBlock bold>
                            {requestInfo.b}
                        </PDFParagraphBlock>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <PDFParagraphBlock>
                            {t('agreement.section1.q3')}
                        </PDFParagraphBlock>
                        <PDFParagraphBlock bold>
                            {requestInfo.c}
                        </PDFParagraphBlock>
                    </View>
                </View>
                <PDFDivider />
                <PDFDivider />
                <PDFParagraphBlock.Title bold blue size="medium">
                    {t('agreement.section2.title')}
                </PDFParagraphBlock.Title>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexGrow: 2 }}>
                        <PDFParagraphBlock.Title bold>
                            {t('agreement.section2.q1.a')}
                        </PDFParagraphBlock.Title>
                        <PDFDivider />
                        <View style={{ flexDirection: 'row' }}>
                            <PDFParagraphBlock>
                                {t('agreement.section2.q1.b')}
                            </PDFParagraphBlock>
                            <PDFParagraphBlock bold>
                                {client1
                                    ? ` ${client1.title} ${client1.firstName} ${client1.lastName}`
                                    : ''}
                            </PDFParagraphBlock>
                        </View>
                    </View>
                    <View style={{ flexGrow: 2 }}>
                        <PDFParagraphBlock.Title bold>
                            {t('agreement.section2.q2.a')}
                        </PDFParagraphBlock.Title>
                        <PDFDivider />
                        <View style={{ flexDirection: 'row' }}>
                            <PDFParagraphBlock>
                                {t('agreement.section2.q2.b')}
                            </PDFParagraphBlock>
                            <PDFParagraphBlock bold>
                                {client2
                                    ? ` ${client2.title} ${client2.firstName} ${client2.lastName}`
                                    : ''}
                            </PDFParagraphBlock>
                        </View>
                    </View>
                </View>
            </View>
            <PDFDivider />
            <PDFDivider />
            <PDFParagraphBlock.Title bold blue size="medium">
                {t('agreement.section3.title')}
            </PDFParagraphBlock.Title>
            <PDFDivider />
            <PDFParagraphBlock>
                <Trans
                    i18nKey="agreement.section3.p"
                    ns={I18nNameSpaces.PDF}
                    values={{
                        investingMonthly: numbro(
                            goals?.d?.regularly ?? 0
                        ).formatCurrency(),
                        investingOnce: numbro(
                            goals?.d?.amount ?? 0
                        ).formatCurrency(),
                        provisionOnce: numbro(
                            clientConfirmation?.once ?? 0
                        ).formatCurrency(),
                        provisionMonthly: numbro(
                            clientConfirmation?.monthly ?? 0
                        ).formatCurrency(),
                    }}
                >
                    <Text style={{ fontFamily: 'HindSemiBold' }} />
                    Text
                    <Text style={{ fontFamily: 'HindSemiBold' }} />
                    <Text style={{ fontFamily: 'HindSemiBold' }} />
                    <Text style={{ fontFamily: 'HindSemiBold' }} />
                    Text
                    <Text style={{ fontFamily: 'HindSemiBold' }} />
                    Text
                    <Text style={{ fontFamily: 'HindSemiBold' }} />
                    Text
                    <Text style={{ fontFamily: 'HindSemiBold' }} />
                    Text
                </Trans>
            </PDFParagraphBlock>
            <PDFDivider />
            <PDFDivider />
            <PDFSignatureBlock
                signature={client1Signature}
                name={
                    client1
                        ? `${client1.title} ${client1.firstName} ${client1.lastName} (Zákazník 1)`
                        : undefined
                }
                name2={
                    client2
                        ? `${client2.title} ${client2.firstName} ${client2.lastName} (Zákazník 2)`
                        : undefined
                }
                date={confirmation.c.date}
                place={confirmation.c.city}
                time={confirmation.c.interviewDuration}
            />
            <PDFDivider />
            <PDFDivider />
            <PDFDivider />
            <PDFParagraphBlock.Title bold blue size="medium">
                {t('agreement.section4.title')}
            </PDFParagraphBlock.Title>
            <View style={{ flexDirection: 'row' }}>
                <View style={{ flexGrow: 2 }}>
                    <PDFParagraphBlock.Title bold>
                        {t('agreement.section4.a.title')}
                    </PDFParagraphBlock.Title>
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="agreement.section4.a.q1"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                value: `${companyInfo.title} ${companyInfo.firstName} ${companyInfo.lastName}`,
                            }}
                        >
                            Text
                            <Text style={{ fontFamily: 'HindSemiBold' }} />
                        </Trans>
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="agreement.section4.a.q2"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                value: companyInfo.partnerNumber,
                            }}
                        >
                            Text
                            <Text style={{ fontFamily: 'HindSemiBold' }} />
                        </Trans>
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="agreement.section4.a.q3"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                value: companyInfo.email,
                            }}
                        >
                            Text
                            <Text style={{ fontFamily: 'HindSemiBold' }} />
                        </Trans>
                    </PDFParagraphBlock>
                </View>
                <View style={{ flexGrow: 2 }}>
                    <PDFParagraphBlock.Title bold>
                        {t('agreement.section4.b.title')}
                    </PDFParagraphBlock.Title>
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="agreement.section4.b.q1"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                value: `${businessInfo.e} ${businessInfo.f} `,
                            }}
                        >
                            Text
                            <Text style={{ fontFamily: 'HindSemiBold' }} />
                        </Trans>
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="agreement.section4.b.q2"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                value: businessInfo.g,
                            }}
                        >
                            Text
                            <Text style={{ fontFamily: 'HindSemiBold' }} />
                        </Trans>
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="agreement.section4.b.q3"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                value: businessInfo.h,
                            }}
                        >
                            Text
                            <Text style={{ fontFamily: 'HindSemiBold' }} />
                        </Trans>
                    </PDFParagraphBlock>
                </View>
            </View>
            <PDFDivider />
            <PDFDivider />
            <View wrap={false} style={{ border: '1 solid black', padding: 2 }}>
                <PDFParagraphBlock.Title bold blue size="medium">
                    {t('agreement.section5.title')}
                </PDFParagraphBlock.Title>
                <PDFParagraphBlock>
                    {t('agreement.section5.p1')}
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p2"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p3"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p4"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p5"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p6"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p7"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p8"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p9"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p10"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="agreement.section5.p11"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            value: businessInfo.h,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Text
                    </Trans>
                </PDFParagraphBlock>
            </View>
            <PDFParagraphBlock>
                <Trans
                    i18nKey="agreement.section5.p12"
                    ns={I18nNameSpaces.PDF}
                    values={{
                        value: businessInfo.h,
                    }}
                />
            </PDFParagraphBlock>
        </PDFBrandedPageWithFooter>
    );
}
