import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    withRouter,
    RouteComponentProps,
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';

import { Layout, Header } from 'common/components';
import { AppState } from 'common/models/AppState';

import {
    AuthState,
    selectIsLogged,
    logoutActions,
    checkUserActions,
    selectUserProfile,
} from '../auth/ducks';
import { selectForm, FormState } from '../form/ducks';

import FormsFeature from './features/forms';
import MenuFeature from './features/menu';
import AdminFormsFeature from './features/adminForms';

interface StateToProps {
    logged: AuthState['logged'];
    profile: AuthState['profile'];
    form: FormState;
}

interface DispatchToProps {
    logout: typeof logoutActions.request;
    checkUser: typeof checkUserActions.request;
}

type Props = StateToProps &
    DispatchToProps &
    WithTranslation &
    RouteComponentProps;

class HomeFeature extends Component<Props> {
    componentDidMount() {
        this.props.checkUser();
    }

    render() {
        const { logged, logout, match } = this.props;
        return (
            <Layout>
                <Header logged={logged} onLogout={logout} />
                <Layout.Content>
                    <Layout.Container>
                        <Switch>
                            <Route
                                path={`${match.path}/menu`}
                                component={MenuFeature}
                            />
                            <Route
                                path={`${match.path}/forms`}
                                component={
                                    this.props.profile?.roles?.privileged
                                        ? AdminFormsFeature
                                        : FormsFeature
                                }
                            />
                            <Redirect path="/" to={`${match.path}/menu`} />
                        </Switch>
                    </Layout.Container>
                </Layout.Content>
            </Layout>
        );
    }
}

const mapStateToProps = (state: AppState): StateToProps => ({
    logged: selectIsLogged(state),
    profile: selectUserProfile(state),
    form: selectForm(state),
});

const mapDispatchToProps: DispatchToProps = {
    logout: logoutActions.request,
    checkUser: checkUserActions.request,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HomeFeature))
);
