import { apiClient } from '../services/api-client';

export default {
    postError: ({ message: errorMessage, name, stack }: Error, options?: { message?: string, userId?: string }) => {
        const { message = errorMessage, userId } = options ?? {};
        return apiClient.post('/journal', {
            tags: ['frontend'],
            subject: 'Frontend error occurred',
            level: 'error',
            type: 'system',
            message,
            meta: {
                error: {
                    url: window.location.href,
                    name: name,
                    stack: stack,
                    userId
                },
            },
        });
    },
};
