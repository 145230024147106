import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { View, Text } from '@react-pdf/renderer';
import numbro from 'numbro';

import {
    ConsultantConfirmationFormValues,
    CompanyInfoFormValues,
    ClientFormValues,
    HazardProfileFormValues,
    InvestingGoalsFormValues,
    ClientConfirmationFormValues,
    ConsultancyInitiationFormValues,
    ClientStatementFormValues,
    AgreementsFormValues,
} from '../models/Forms';

import {
    PDFParagraphBlock,
    PDFDivider,
    PDFCheckbox,
    PDFSignatureBlock,
    PDFBrandedPageWithFooter,
    PDFBasicQuestion,
    PDFListItem,
    PDFFondTable,
} from './components';

import { I18nNameSpaces, LANGUAGE_CURRENCY_MAP } from '../services/i18n';
import PDFCheckboxTree from './components/CheckboxTree';
import { economicBackgroundCheckboxTree } from '../forms/InvestingGoalsForm/templates';

interface Props {
    clientValues1: ClientFormValues;
    client1Signature?: string;
    clientValues2: ClientFormValues;
    companyInfo: CompanyInfoFormValues;
    goals: InvestingGoalsFormValues;
    hazard: HazardProfileFormValues;
    consultantConfirmation: ConsultantConfirmationFormValues;
    clientConfirmation: ClientConfirmationFormValues;
    clientStatement: ClientStatementFormValues;
    initiation: ConsultancyInitiationFormValues;
    agreement: AgreementsFormValues;
}

export default function ProtocolPage({
    companyInfo,
    consultantConfirmation,
    clientValues1,
    client1Signature,
    clientValues2,
    hazard,
    clientConfirmation,
    initiation,
    clientStatement,
    goals,
    agreement,
}: Props) {
    const { t: formT } = useTranslation(I18nNameSpaces.Forms);
    const {
        t,
        i18n: { language },
    } = useTranslation(I18nNameSpaces.PDF);
    const footerTitle = (
        <PDFParagraphBlock>{t('protocol.title')}</PDFParagraphBlock>
    );

    const who =
        initiation.a === 'a'
            ? t('protocol.section5.p5.options.a')
            : t('protocol.section5.p5.options.b');

    return (
        <>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <View>
                    <PDFParagraphBlock.Title size="large" bold underline blue>
                        {t('protocol.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock.Title bold>
                        {t('protocol.section1.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexGrow: 2 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('protocol.section1.fields.a')}
                                value={`${companyInfo.title} ${companyInfo.firstName} ${companyInfo.lastName}`}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('protocol.section1.fields.b')}
                                value={companyInfo.address}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('protocol.section1.fields.c')}
                                value={companyInfo.partnerNumber}
                            />
                            <PDFDivider />
                        </View>
                        <View style={{ flexGrow: 2 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('protocol.section1.fields.d')}
                                value={companyInfo.phone}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('protocol.section1.fields.e')}
                                value={companyInfo.email}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('protocol.section1.fields.f')}
                                value={companyInfo.ico}
                            />
                            <PDFDivider />
                        </View>
                    </View>
                </View>
                <PDFDivider />
                <PDFParagraphBlock bold>
                    {t('protocol.p1', { date: agreement.dateOfSignature })}
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock>{t('protocol.p2')}</PDFParagraphBlock>
                <PDFDivider />
                <PDFParagraphBlock bold>
                    {t('protocol.section2')}
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexGrow: 2 }}>
                        <PDFParagraphBlock.Title
                            size="medium"
                            bold
                            underline
                            blue
                        >
                            {t('protocol.userBox.title', { number: 1 })}
                        </PDFParagraphBlock.Title>
                        <PDFListItem
                            label={t('protocol.userBox.fields.a')}
                            value={formT(
                                `clientForm.gender.options.${clientValues1.gender}`
                            )}
                        />
                        <PDFListItem
                            label={t('protocol.userBox.fields.b')}
                            value={`${clientValues1.title} ${clientValues1.firstName} ${clientValues1.lastName}`}
                        />
                        <PDFParagraphBlock bold>
                            {t('protocol.userBox.fields.addressTitle')}
                        </PDFParagraphBlock>
                        <PDFListItem
                            label={t('protocol.userBox.fields.c')}
                            value={`${clientValues1.street} ${clientValues1.streetNumber}`}
                        />
                        <View
                            style={{
                                flexDirection: 'row',
                                height: 'auto',
                            }}
                        >
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('protocol.userBox.fields.d')}
                                    value={clientValues1.city}
                                />
                            </View>
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('protocol.userBox.fields.e')}
                                    value={clientValues1.psc}
                                />
                            </View>
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('protocol.userBox.fields.f')}
                                    value={clientValues1.state}
                                />
                            </View>
                        </View>
                        <PDFDivider />
                        <PDFListItem
                            label={t('meeting.userBox.fields.g')}
                            value={clientValues1?.birthday?.date}
                        />
                    </View>
                    <View style={{ flexGrow: 2 }}>
                        <PDFParagraphBlock.Title
                            size="medium"
                            bold
                            underline
                            blue
                        >
                            {t('protocol.userBox.title', { number: 2 })}
                        </PDFParagraphBlock.Title>
                        <PDFListItem
                            label={t('protocol.userBox.fields.a')}
                            value={
                                clientValues2
                                    ? formT(
                                          `clientForm.gender.options.${clientValues2.gender}`
                                      )
                                    : ''
                            }
                        />
                        <PDFListItem
                            label={t('protocol.userBox.fields.b')}
                            value={
                                clientValues2
                                    ? `${clientValues2.title} ${clientValues2.firstName} ${clientValues2.lastName}`
                                    : ''
                            }
                        />
                        <PDFParagraphBlock bold>
                            {t('protocol.userBox.fields.addressTitle')}
                        </PDFParagraphBlock>
                        <PDFListItem
                            label={t('protocol.userBox.fields.c')}
                            value={`${clientValues2?.street ?? ''} ${
                                clientValues2?.streetNumber ?? ''
                            }`}
                        />
                        <View
                            style={{
                                flexDirection: 'row',
                                height: 'auto',
                            }}
                        >
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('protocol.userBox.fields.d')}
                                    value={clientValues2?.city ?? ''}
                                />
                            </View>
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('protocol.userBox.fields.e')}
                                    value={clientValues2?.psc ?? ''}
                                />
                            </View>
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('protocol.userBox.fields.f')}
                                    value={clientValues2?.state ?? ''}
                                />
                            </View>
                        </View>
                        <PDFDivider />
                        <PDFListItem
                            label={t('meeting.userBox.fields.g')}
                            value={clientValues2?.birthday?.date}
                        />
                    </View>
                </View>
                <PDFDivider />
                <PDFDivider />
                <PDFParagraphBlock bold>
                    {t('protocol.section3.title')}
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <PDFBasicQuestion
                    id="IV."
                    label={t('protocol.section3.q1')}
                    value={formT(`hazardProfileForm.d.options.${hazard.d}`)}
                />
                <PDFDivider />
                <PDFDivider />
                <PDFBasicQuestion
                    id="V."
                    label={t('protocol.section3.q2.title')}
                    value=""
                />
                <PDFDivider />
                <PDFDivider />
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexGrow: 2 }}>
                        <PDFCheckbox
                            checked={clientValues1.goal.includes('a')}
                            label={t('protocol.section3.q2.options.a')}
                        />
                        <PDFCheckbox
                            checked={clientValues1.goal.includes('b')}
                            label={t('protocol.section3.q2.options.b')}
                        />
                    </View>
                    <View style={{ flexGrow: 2 }}>
                        <PDFCheckbox
                            checked={clientValues1.goal.includes('d')}
                            label={t('protocol.section3.q2.options.d')}
                        />
                        <PDFCheckbox
                            checked={clientValues1.goal.includes('k')}
                            label={t('protocol.section3.q2.options.k')}
                        />
                    </View>
                    <View style={{ flexGrow: 2 }}>
                        <PDFCheckbox
                            checked={clientValues1.goal.includes('j')}
                            label={t('protocol.section3.q2.options.j')}
                        />
                        <PDFCheckbox
                            checked={clientValues1.goal.includes('i')}
                            label={t('protocol.section3.q2.options.i')}
                        />
                    </View>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <PDFCheckbox
                        checked={clientValues1.goal.some(
                            e =>
                                ![
                                    'a',
                                    'b',
                                    'd',
                                    'f',
                                    'g', // legacy
                                    'h', // legacy
                                    'i',
                                    'j',
                                    'k',
                                ].includes(e)
                        )}
                        label={t('protocol.section3.q2.options.f', {
                            value: clientValues1.goal.find(
                                e =>
                                    ![
                                        'a',
                                        'b',
                                        'd',
                                        'f',
                                        'g', // legacy
                                        'h', // legacy
                                        'i',
                                        'j',
                                        'k',
                                    ].includes(e)
                            ),
                        })}
                    />
                </View>
                <PDFDivider />
                <PDFDivider />
                <PDFParagraphBlock bold>
                    {t('protocol.section3.q3')}
                    {goals.b ? ` ${goals.b}` : ''}
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <PDFDivider />
                <PDFDivider />
                <PDFBasicQuestion
                    id="VI."
                    label={t('protocol.section4.title')}
                    value=""
                />
                <PDFDivider />
                <PDFDivider />
                <PDFCheckboxTree
                    treeTemplate={economicBackgroundCheckboxTree}
                    answers={goals.g}
                />
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="protocol.section5.p1"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            monthly: numbro(goals.d.regularly).formatCurrency(),
                            once: numbro(goals.d.amount).formatCurrency(),
                        }}
                    >
                        Má být investováno
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        cca.
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        a
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        cca.
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <PDFCheckbox
                    label={t('protocol.section5.p2.a')}
                    checked={goals.e === 'a'}
                />
                <PDFCheckbox
                    checked={goals.e === 'b'}
                    label={t('protocol.section5.p2.b')}
                />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="protocol.section5.p3"
                        ns={I18nNameSpaces.PDF}
                    >
                        Part1
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Part1
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Part1
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                        Part1
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <PDFParagraphBlock bold>
                    <Trans
                        i18nKey="protocol.section5.p4"
                        ns={I18nNameSpaces.PDF}
                    >
                        Part1
                        <Text style={{ fontFamily: 'HindRegular' }} />
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="protocol.section5.p5.base"
                        ns={I18nNameSpaces.PDF}
                        values={{
                            who,
                        }}
                    >
                        Part1
                        <Text style={{ fontFamily: 'HindSemiBold' }} />
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                    <PDFCheckbox
                        checked={initiation.b.includes('a')}
                        label={t('protocol.section5.p6.a')}
                    />
                    <PDFCheckbox
                        checked={initiation.b.includes('b')}
                        label={t('protocol.section5.p6.b')}
                    />
                    <PDFCheckbox
                        checked={initiation.b.includes('c')}
                        label={t('protocol.section5.p6.c')}
                    />
                    <PDFCheckbox
                        checked={initiation.b.includes('d')}
                        label={t('protocol.section5.p6.d')}
                    />
                    <PDFCheckbox
                        checked={initiation.b.includes('e')}
                        label={t('protocol.section5.p6.e')}
                    />
                    <PDFCheckbox
                        checked={
                            !!initiation.b.find(
                                e => !['a', 'b', 'c', 'd', 'e'].includes(e)
                            )
                        }
                        label={t('protocol.section5.p6.f', {
                            value: initiation.b.find(
                                e => !['a', 'b', 'c', 'd', 'e'].includes(e)
                            ),
                        })}
                    />
                </View>
                <PDFDivider />
                <PDFDivider />
                <PDFFondTable
                    data={initiation?.table ?? []}
                    currency={LANGUAGE_CURRENCY_MAP[language]}
                />
                <PDFDivider />
                <PDFDivider />
                <PDFFondTable
                    data={initiation?.tableAltCurrency ?? []}
                    currency={LANGUAGE_CURRENCY_MAP[language]}
                />
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <PDFParagraphBlock bold blue size="medium">
                    {t('protocol.section7.title')}
                </PDFParagraphBlock>
                <View
                    wrap={false}
                    style={{
                        border: '1 solid black',
                        padding: '5 20 5 5',
                        lineHeight: 1.3,
                    }}
                >
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.a')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.b')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.c')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.d')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.e')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.f')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.g')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.h')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.i')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.j')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.k')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.l')}
                    />
                    <PDFCheckbox
                        checked
                        label={t('protocol.section7.options.m')}
                    />
                    <PDFCheckbox
                        checked
                        label={
                            <Trans
                                i18nKey={'protocol.section7.options.o'}
                                ns={I18nNameSpaces.PDF}
                                values={{
                                    value: clientStatement.b
                                        .map(e =>
                                            ['a', 'b', 'c'].includes(e)
                                                ? formT(
                                                      `clientStatementForm.b.options.${e}`
                                                  )
                                                : e
                                        )
                                        .join(', '),
                                }}
                            >
                                Part1
                                <Text style={{ fontFamily: 'HindSemiBold' }} />
                            </Trans>
                        }
                    />
                </View>
                <PDFDivider />
                <PDFDivider />
                <View wrap={false}>
                    <PDFParagraphBlock bold blue size="medium">
                        {t('protocol.section8.title')}
                    </PDFParagraphBlock>
                    <View
                        style={{
                            border: '1 solid black',
                            padding: '5 20 5 5',
                            // lineHeight: 1.4,
                        }}
                    >
                        <PDFParagraphBlock>
                            {t('protocol.section8.p1')}
                        </PDFParagraphBlock>
                        <PDFDivider />
                        <PDFParagraphBlock bold>
                            <Trans
                                i18nKey="protocol.section8.p2"
                                ns={I18nNameSpaces.PDF}
                                values={{
                                    reguraly: numbro(
                                        clientConfirmation.a
                                    ).formatCurrency(),
                                    once: numbro(
                                        clientConfirmation.b
                                    ).formatCurrency(),
                                }}
                            />
                        </PDFParagraphBlock>
                        <PDFDivider />
                        <PDFParagraphBlock>
                            {t('protocol.section8.p3')}
                        </PDFParagraphBlock>
                        <PDFDivider />
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flexGrow: 2 }}>
                                <PDFCheckbox
                                    checked={clientConfirmation.c === 'a'}
                                    label={
                                        <Trans
                                            i18nKey="protocol.section8.q1.a"
                                            ns={I18nNameSpaces.PDF}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: 'HindSemiBold',
                                                }}
                                            />
                                        </Trans>
                                    }
                                />
                            </View>
                            <View style={{ flexGrow: 2 }}>
                                <PDFCheckbox
                                    checked={clientConfirmation.c === 'c'}
                                    label={
                                        <Trans
                                            i18nKey="protocol.section8.q1.c"
                                            ns={I18nNameSpaces.PDF}
                                            values={{
                                                monthly: numbro(
                                                    clientConfirmation.monthly
                                                ).formatCurrency(),
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    fontFamily: 'HindSemiBold',
                                                }}
                                            />
                                        </Trans>
                                    }
                                />
                            </View>
                        </View>
                        <PDFCheckbox
                            checked={clientConfirmation.c === 'b'}
                            label={
                                <Trans
                                    i18nKey="protocol.section8.q1.b"
                                    ns={I18nNameSpaces.PDF}
                                    values={{
                                        reguraly: numbro(
                                            clientConfirmation.monthly
                                        ).formatCurrency(),
                                        once: numbro(
                                            clientConfirmation.once
                                        ).formatCurrency(),
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontFamily: 'HindSemiBold',
                                        }}
                                    />
                                    Part2
                                    <Text
                                        style={{
                                            fontFamily: 'HindSemiBold',
                                        }}
                                    />
                                    Part3
                                    <Text
                                        style={{
                                            fontFamily: 'HindSemiBold',
                                        }}
                                    />
                                </Trans>
                            }
                        />
                        <PDFDivider />
                        {clientConfirmation.d ? (
                            <PDFParagraphBlock>
                                {t('protocol.section8.q1.note', {
                                    note: clientConfirmation.d,
                                })}
                            </PDFParagraphBlock>
                        ) : null}
                        <PDFDivider />
                        <PDFParagraphBlock>
                            {t('protocol.section8.p4')}
                        </PDFParagraphBlock>
                        <View>
                            <PDFSignatureBlock
                                date={String(consultantConfirmation.c.date)}
                                signature={client1Signature}
                                name={
                                    clientValues1
                                        ? `${clientValues1.title} ${clientValues1.firstName} ${clientValues1.lastName} (Zákazník 1)`
                                        : undefined
                                }
                                name2={
                                    clientValues2
                                        ? `${clientValues2.title} ${clientValues2.firstName} ${clientValues2.lastName} (Zákazník 2)`
                                        : undefined
                                }
                                place={consultantConfirmation.c.city}
                                time={
                                    consultantConfirmation.c.interviewDuration
                                }
                            />
                        </View>
                    </View>
                </View>
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <PDFParagraphBlock bold blue size="medium">
                    {t('protocol.section9.title')}
                </PDFParagraphBlock>
                <View
                    style={{
                        border: '1 solid black',
                        padding: '5 20 5 5',
                        lineHeight: 1.4,
                    }}
                >
                    <PDFParagraphBlock>
                        {t('protocol.section9.p1')}
                    </PDFParagraphBlock>
                    <PDFDivider />
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="protocol.section9.p2"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                date: agreement.dateOfSignature,
                                time: consultantConfirmation.b
                                    .interviewDuration,
                                place: consultantConfirmation.b.city,
                                where: ['a', 'b'].includes(
                                    consultantConfirmation.b.place
                                )
                                    ? String(
                                          formT(
                                              `consultantConfirmationForm.b.questions.place.${consultantConfirmation.b.place}`
                                          )
                                      )
                                    : consultantConfirmation.b.place,
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            Part 2
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            Part 3
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            Part 4
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                        </Trans>
                    </PDFParagraphBlock>
                    <PDFDivider />
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="protocol.section9.p3"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                value: consultantConfirmation.b.attendants,
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                        </Trans>
                    </PDFParagraphBlock>
                    <PDFDivider />
                    <PDFCheckbox checked label={t('protocol.section9.q1.a')} />
                    <PDFCheckbox checked label={t('protocol.section9.q1.b')} />
                    <PDFCheckbox checked label={t('protocol.section9.q1.c')} />
                    <PDFCheckbox checked label={t('protocol.section9.q1.d')} />
                    <PDFDivider />
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="protocol.section9.p4"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                date: consultantConfirmation.c.date,
                                place: consultantConfirmation.c.city,
                                where: ['a', 'b'].includes(
                                    consultantConfirmation.c.place
                                )
                                    ? String(
                                          formT(
                                              `consultantConfirmationForm.c.questions.place.${consultantConfirmation.c.place}`
                                          )
                                      )
                                    : consultantConfirmation.c.place,
                                duration:
                                    consultantConfirmation.c.interviewDuration,
                                time: consultantConfirmation.c.time,
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            Part 2
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            Part 3
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            Part 3
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                            Part 3
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                        </Trans>
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        <Trans
                            i18nKey="protocol.section9.p3"
                            ns={I18nNameSpaces.PDF}
                            values={{
                                value: consultantConfirmation.c.attendants,
                            }}
                        >
                            <Text
                                style={{
                                    fontFamily: 'HindSemiBold',
                                }}
                            />
                        </Trans>
                    </PDFParagraphBlock>
                    <PDFDivider />
                    <PDFSignatureBlock
                        date={String(consultantConfirmation.c.date)}
                        signature={`${companyInfo.firstName} ${companyInfo.lastName}`}
                        name={
                            companyInfo
                                ? `${companyInfo.title} ${
                                      companyInfo.firstName
                                  } ${companyInfo.lastName} ${t(
                                      'signatureBlock.manager'
                                  )}`
                                : undefined
                        }
                        place={consultantConfirmation.c.city}
                    />
                </View>
                <PDFDivider />
                <PDFDivider />
                <View>
                    <PDFParagraphBlock bold>
                        {t('protocol.section10.title')}
                    </PDFParagraphBlock>
                    <PDFParagraphBlock bold>
                        {t('protocol.section10.p1.title')}
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        {t('protocol.section10.p1.p')}
                    </PDFParagraphBlock>

                    <PDFParagraphBlock bold>
                        {t('protocol.section10.p2.title')}
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        {t('protocol.section10.p2.p')}
                    </PDFParagraphBlock>

                    <PDFParagraphBlock bold>
                        {t('protocol.section10.p3.title')}
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        {t('protocol.section10.p3.p')}
                    </PDFParagraphBlock>

                    <PDFParagraphBlock bold>
                        {t('protocol.section10.p4.title')}
                    </PDFParagraphBlock>
                    <PDFParagraphBlock>
                        {t('protocol.section10.p4.p')}
                    </PDFParagraphBlock>
                </View>
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <PDFParagraphBlock bold>
                    {t('protocol.section10.p5.title')}
                </PDFParagraphBlock>
                <PDFParagraphBlock>
                    <Trans
                        i18nKey="protocol.section10.p5.p"
                        ns={I18nNameSpaces.PDF}
                    >
                        <Text
                            style={{
                                fontFamily: 'HindSemiBold',
                                // fontStyle: 'underline',
                            }}
                        />
                        Part 2
                        <Text
                            style={{
                                fontFamily: 'HindSemiBold',
                            }}
                        />
                        Part 2
                        <Text
                            style={{
                                fontFamily: 'HindSemiBold',
                            }}
                        />
                        Part 2
                        <Text
                            style={{
                                fontFamily: 'HindSemiBold',
                            }}
                        />
                        Part 2
                    </Trans>
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
            </PDFBrandedPageWithFooter>
        </>
    );
}
