import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Route, RouteComponentProps, Redirect, Switch } from 'react-router';
import { connect } from 'react-redux';

import { Layout, Header } from 'common/components';

import LoginFeature from './features/login';
import RegistrationFeature from './features/registration';
import ForgottenPasswordFeature from './features/forgotten-password';

interface DispatchToProps {}

interface StateToProps {}

type Props = DispatchToProps &
    StateToProps &
    WithTranslation &
    RouteComponentProps<{ id: string }>;

function AuthFeature({ match }: Props) {
    return (
        <Layout>
            <Header />
            <Layout.Content>
                <Layout.Container>
                    <Switch>
                        <Route
                            path={`${match.path}/login`}
                            component={LoginFeature}
                        />
                        <Route
                            path={`${match.path}/registration`}
                            component={RegistrationFeature}
                        />
                        <Route
                            path={`${match.path}/forgotten-password`}
                            component={ForgottenPasswordFeature}
                        />
                        <Redirect
                            path={`${match.path}/`}
                            to={`${match.path}/login`}
                        />
                    </Switch>
                </Layout.Container>
            </Layout.Content>
        </Layout>
    );
}

const mapStateToProps = (): StateToProps => ({});

const mapDispatchToProps: DispatchToProps = {};

export default withTranslation()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AuthFeature)
);
