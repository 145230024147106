import React, { Fragment } from 'react';
import { Row, Col } from 'antd';
import { WithTranslation, withTranslation } from 'react-i18next';
import useUnmount from 'react-use/lib/useUnmount';
import { connect, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps, Redirect } from 'react-router';

import { LoginForm, PasswordEditForm } from 'common/forms';
import { LoginFormValues, PasswordEditFormValues } from 'common/models/User';
import { AppState } from 'common/models/AppState';
import { I18nNameSpaces } from 'common/services/i18n';

import { AuthPageDescription } from '../../components';
import { loginActions, selectLoginError, loginResetAction } from './ducks';
import { selectIsLogged, selectUserProfile, AuthState } from '../../ducks';
import api from '../../../../common/api';

interface DispatchToProps {
    login: typeof loginActions.request;
    reset: typeof loginResetAction;
}

interface StateToProps {
    isLogged: boolean;
    error: boolean;
    profile: AuthState['profile'];
}

type Props = RouteComponentProps &
    StateToProps &
    DispatchToProps &
    WithTranslation;

function LoginFeature(props: Props) {
    const dispatch = useDispatch();

    useUnmount(() => {
        props.reset();
    });

    function handleLoginSubmit(values: LoginFormValues) {
        props.login(values);
    }

    function handleForgottenPasswordClick() {
        props.history.push('/auth/forgotten-password');
    }

    function handlePasswordEdit(values: PasswordEditFormValues) {
        api.user.updatePassword(values.password).then(() => {
            if (props.profile && values.password) {
                dispatch(
                    loginActions.request({
                        email: props.profile.email,
                        password: values.password,
                    })
                );
            }
        });
    }

    return (
        <Fragment>
            {props?.profile?.stats?.login === 1 ? (
                <Row type="flex" justify="center">
                    <Col xs={24} md={8}>
                        <AuthPageDescription
                            title={props.t('registration.page.title', {
                                name: props?.profile?.firstName ?? '',
                            })}
                        />
                        <PasswordEditForm onSubmit={handlePasswordEdit} />
                    </Col>
                </Row>
            ) : (
                <>
                    {props.isLogged && <Redirect to="/" />}
                    <Row type="flex" justify="center">
                        <Col xs={24} md={8}>
                            <AuthPageDescription
                                title={props.t('login.page.title')}
                            />
                            <LoginForm
                                onSubmit={handleLoginSubmit}
                                onForgotPassword={handleForgottenPasswordClick}
                                error={props.error}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </Fragment>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    isLogged: selectIsLogged(state),
    error: selectLoginError(state),
    profile: selectUserProfile(state),
});

const mapDispatchToProps: DispatchToProps = {
    login: loginActions.request,
    reset: loginResetAction,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(withTranslation(I18nNameSpaces.Auth)(LoginFeature))
);
