import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FormikProps } from 'formik';

import { AnalyseConclusion } from 'common/components';
import { RequestInfoForm, BusinessPartnerInfoForm } from 'common/forms';
import { I18nNameSpaces } from 'common/services/i18n';

import {
    selectProtocolCustomerConfirmation,
    selectProtocolGoals,
    selectInformationsCompanyInfo,
    selectProtocolCustomerStatement,
    setAgreementBusinessPartnerInfoAction,
    setAgreementRequestInfoAction,
    selectAgreementRequestInfo,
    selectAgreementBusinessPartnerInfo,
} from '../../../../ducks';
import {
    RequestInfoFormValues,
    BusinessPartnerInfoFormValues,
} from 'common/models/Forms';
import { QuestionTitle, PageControls } from '../../../../components';
import { FeatureProps } from '../../../../index';

export default function AgreementSummaryFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch<{ id: string }>();

    const customerStatement = useSelector(selectProtocolCustomerStatement);
    const confirmationValues = useSelector(selectProtocolCustomerConfirmation);
    const goalsValues = useSelector(selectProtocolGoals);
    const companyInfo = useSelector(selectInformationsCompanyInfo);

    const agreementRequestValues = useSelector(selectAgreementRequestInfo);
    const agreementBPartnerValues = useSelector(
        selectAgreementBusinessPartnerInfo
    );

    let submitRequestInfo: FormikProps<any>['submitForm'];

    function bindRequestSubmit(cb: FormikProps<any>['submitForm']) {
        submitRequestInfo = cb;
    }

    let submitBusinessPartnerInfo: FormikProps<any>['submitForm'];

    function bindBusinessPartnerInfoSubmit(cb: FormikProps<any>['submitForm']) {
        submitBusinessPartnerInfo = cb;
    }

    function handleBackClick() {
        history.push(`/form/${match!.params.id}/agreement/meeting`);
    }

    function handleContinueClick() {
        submitRequestInfo();
        submitBusinessPartnerInfo();
    }

    function handleRequestSubmit(values: RequestInfoFormValues) {
        if (!isPrivileged) {
            dispatch(setAgreementRequestInfoAction(values));
        }
        if (agreementBPartnerValues) {
            history.push(`/form/${match!.params.id}/transaction`);
        }
    }

    function handleBusinessInfoSubmit(values: BusinessPartnerInfoFormValues) {
        if (!isPrivileged) {
            dispatch(setAgreementBusinessPartnerInfoAction(values));
        }

        if (agreementRequestValues) {
            history.push(`/form/${match!.params.id}/transaction`);
        }
    }

    return (
        <>
            <QuestionTitle>{t('agreementFeature.requestInfo')}</QuestionTitle>
            <RequestInfoForm
                bindSubmit={bindRequestSubmit}
                initialValues={{
                    a: (customerStatement?.b ?? [])
                        .map(a =>
                            t(`clientStatementForm.b.options.${a}`, {
                                ns: I18nNameSpaces.Forms,
                            })
                        )
                        .join(','),
                    ...agreementRequestValues,
                }}
                onSubmit={handleRequestSubmit}
            />
            <QuestionTitle>
                {t('agreementFeature.businessPartnerInfoForm')}
            </QuestionTitle>
            <BusinessPartnerInfoForm
                bindSubmit={bindBusinessPartnerInfoSubmit}
                initialValues={{
                    a: companyInfo!.firstName,
                    b: companyInfo!.lastName,
                    c: companyInfo!.partnerNumber,
                    ...agreementBPartnerValues,
                }}
                onSubmit={handleBusinessInfoSubmit}
            />
            <QuestionTitle>{t('agreementFeature.conclusion')}</QuestionTitle>
            <AnalyseConclusion
                oneTimeProvision={confirmationValues?.once ?? 0}
                monthlyProvision={confirmationValues?.monthly ?? 0}
                monthlyInvestingPlan={goalsValues?.d?.regularly ?? 0}
                oneTimeInvesting={goalsValues?.d?.amount ?? 0}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
