import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { Row, Col, Icon } from 'antd';
import numbro from 'numbro';

import { Typography, QuestionWrapper, Tooltip } from '../../components';
import {
    RadioField,
    TextareaField,
    CheckBoxField,
    CheckBoxGroupField,
    InputNumberField,
    InputField,
    CheckboxTreeField,
} from '../../fields';
import {
    InvestingGoalsFormValues,
    HazardProfileFormValues,
} from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import { economicBackgroundCheckboxTree } from './templates';

interface Props {
    initialValues?: Partial<InvestingGoalsFormValues>;
    hazard?: HazardProfileFormValues;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: InvestingGoalsFormValues) => void;
}

export default function InvestingGoalsForm({
    onSubmit,
    initialValues,
    bindSubmit,
    hazard,
}: Props) {
    const { t, i18n } = useTranslation(I18nNameSpaces.Forms);

    const dependentStringField = Yup.mixed().when('checked', {
        is: true,
        then: Yup.string().required(t('form.required')),
        otherwise: Yup.string().notRequired(),
    });

    const schema = Yup.object().shape<InvestingGoalsFormValues>({
        a: Yup.array(Yup.string()),
        b: Yup.string(),
        d: Yup.object().shape({
            amount: Yup.number().required(t('form.required')),
            regularity: Yup.string().required(t('form.required')),
            regularly: Yup.number().required(t('form.required')),
        }),
        e: Yup.string().required(t('form.required')),
        f: Yup.object().shape({
            is: Yup.boolean(),
            a: Yup.string(),
            b: Yup.string(),
            c: Yup.string(),
        }),
        g: Yup.object()
            .shape({
                a: Yup.object().shape({
                    checked: Yup.boolean(),
                }),
                b: Yup.object().shape({
                    checked: Yup.boolean(),
                    a: Yup.object().shape({
                        checked: Yup.boolean(),
                        gains: dependentStringField,
                        notes: dependentStringField,
                    }),
                    b: Yup.object().shape({
                        checked: Yup.boolean(),
                        gains: dependentStringField,
                        notes: dependentStringField,
                    }),
                    c: Yup.object().shape({
                        checked: Yup.boolean(),
                        gains: dependentStringField,
                        notes: dependentStringField,
                    }),
                    d: Yup.object().shape({
                        checked: Yup.boolean(),
                        gains: dependentStringField,
                        notes: dependentStringField,
                    }),
                }),
                c: Yup.object().shape({
                    checked: Yup.boolean(),
                    a: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                    b: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                    c: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                    d: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                    e: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                    f: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                    g: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                    h: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                    i: Yup.object().shape({
                        checked: Yup.boolean(),
                        notes: dependentStringField,
                    }),
                }),
            })
            .test(
                'At least one checked test',
                t('form.required'),
                ({ a, b, c }) => a.checked || b.checked || c.checked
            ),
    });

    function handleOnSubmit(values: InvestingGoalsFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderFinanceBackgroundTooltip() {
        return (
            i18n.language === 'cz' && (
                <Tooltip
                    title={
                        <div className="finance-background-tooltip__content">
                            <Typography.Paragraph>
                                {t('investingGoalsForm.g.labelTooltip1')}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                {t('investingGoalsForm.g.labelTooltip2')}
                            </Typography.Paragraph>
                            <Typography.Paragraph>
                                <Trans
                                    t={t}
                                    i18nKey="investingGoalsForm.g.labelTooltip3"
                                    components={[
                                        <Typography.Text underline key={0} />,
                                    ]}
                                />
                            </Typography.Paragraph>
                        </div>
                    }
                >
                    <Icon
                        className="finance-background-tooltip"
                        type="info-circle"
                    />
                </Tooltip>
            )
        );
    }

    function renderForm({
        submitForm,
        values,
    }: FormikProps<InvestingGoalsFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('investingGoalsForm.timeHorizont')} -{' '}
                                {t(
                                    `hazardProfileForm.d.options.${
                                        hazard?.d ?? ''
                                    }`
                                )}
                            </QuestionWrapper.Title>
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('investingGoalsForm.a.label')}
                            </QuestionWrapper.Title>
                            <CheckBoxGroupField
                                name="a"
                                type="vertical"
                                disabled
                                options={[
                                    ...['a', 'b', 'j', 'd', 'k', 'i'].map(
                                        value => ({
                                            label: t(
                                                `investingGoalsForm.a.options.${value}`
                                            ),
                                            value,
                                        })
                                    ),
                                    {
                                        label: t(
                                            'investingGoalsForm.a.options.f'
                                        ),
                                        value: 'f',
                                        custom: true,
                                    },
                                    {
                                        label: null,
                                        value: 'g', // wealth conservation
                                        legacy: true,
                                    },
                                    {
                                        label: null,
                                        value: 'h', // regular income
                                        legacy: true,
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('investingGoalsForm.b.label')}
                            </QuestionWrapper.Title>
                            <TextareaField name="b" />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('investingGoalsForm.g.label')}
                                {renderFinanceBackgroundTooltip()}
                            </QuestionWrapper.Title>
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <CheckboxTreeField
                                name="g"
                                options={economicBackgroundCheckboxTree}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('investingGoalsForm.d.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="d.regularity"
                                type="horizontal"
                                options={[
                                    {
                                        label: t(
                                            'investingGoalsForm.d.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'investingGoalsForm.d.options.b'
                                        ),
                                        value: 'b',
                                    },
                                ]}
                            />
                            <Row type="flex" align="middle" gutter={30}>
                                <Col xs={24} md={12}>
                                    <InputNumberField
                                        label={t(
                                            'investingGoalsForm.d.regularly'
                                        )}
                                        name="d.regularly"
                                        min={0}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Typography.Text>
                                        {numbro(
                                            values.d.regularly
                                        ).formatCurrency()}
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} md={12}>
                                    <InputNumberField
                                        label={t('investingGoalsForm.d.amount')}
                                        name="d.amount"
                                        min={0}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <Typography.Text>
                                        {numbro(
                                            values.d.amount
                                        ).formatCurrency()}
                                    </Typography.Text>
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <RadioField
                                name="e"
                                type="vertical"
                                options={[
                                    {
                                        label: t(
                                            'investingGoalsForm.e.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'investingGoalsForm.e.options.b'
                                        ),
                                        value: 'b',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <CheckBoxField
                                name="f.is"
                                label={t('investingGoalsForm.f.label')}
                            />
                            {values.f.is && (
                                <>
                                    <CheckBoxGroupField
                                        label={t(
                                            'investingGoalsForm.f.sub.a.label'
                                        )}
                                        name="f.a"
                                        type="vertical"
                                        options={[
                                            {
                                                label: t(
                                                    'investingGoalsForm.f.sub.a.options.a'
                                                ),
                                                value: 'a',
                                            },
                                            {
                                                label: t(
                                                    'investingGoalsForm.f.sub.a.options.b'
                                                ),
                                                value: 'b',
                                            },
                                            {
                                                label: t(
                                                    'investingGoalsForm.f.sub.a.options.c'
                                                ),
                                                value: 'c',
                                            },
                                            {
                                                label: t(
                                                    'investingGoalsForm.f.sub.a.options.d'
                                                ),
                                                value: 'd',
                                            },
                                        ]}
                                    />
                                    <Typography.Paragraph>
                                        {t('investingGoalsForm.f.info.p1')}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        {t('investingGoalsForm.f.info.p2a')}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        {t('investingGoalsForm.f.info.p2b')}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        {t('investingGoalsForm.f.info.p2c')}
                                    </Typography.Paragraph>
                                    <Typography.Paragraph>
                                        {t('investingGoalsForm.f.info.p2d')}
                                    </Typography.Paragraph>
                                    <Row gutter={20}>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="f.b"
                                                label={t(
                                                    'investingGoalsForm.f.sub.b.label'
                                                )}
                                            />
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <InputField
                                                name="f.c"
                                                label={t(
                                                    'investingGoalsForm.f.sub.c.label'
                                                )}
                                            />
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a ?? [],
                b: initialValues?.b ?? '',
                d: {
                    amount: initialValues?.d?.amount ?? 0,
                    regularity: initialValues?.d?.regularity ?? '',
                    regularly: initialValues?.d?.regularly ?? 0,
                },
                e: initialValues?.e ?? '',
                f: initialValues?.f ?? {
                    is: false,
                    a: '',
                    b: '',
                    c: '',
                },
                g: initialValues?.g ?? {},
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
