import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { ClientStatementForm } from 'common/forms';
import { ClientStatementFormValues } from 'common/models/Forms';

import { QuestionTitle, PageControls } from '../../../../components';
import {
    selectProtocolCustomerStatement,
    setProtocolCustomerStatementValuesAction,
} from '../../../../ducks';
import { FeatureProps } from '../../../../index';

export default function ProtocolCustomerStatementFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const match = useRouteMatch<{ id: string }>();
    const initialValues = useSelector(selectProtocolCustomerStatement);

    let submitForm: FormikProps<ClientStatementFormValues>['submitForm'];

    function handleBackClick() {
        history.push(`/form/${match!.params.id}/protocol/initiation`);
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(
        cb: FormikProps<ClientStatementFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(values: ClientStatementFormValues) {
        if (!isPrivileged) {
            dispatch(setProtocolCustomerStatementValuesAction(values));
        }
        history.push(
            `/form/${match!.params.id}/protocol/customer-confirmation`
        );
    }

    return (
        <>
            <QuestionTitle>
                {t('protocolFeature.customerStatement')}
            </QuestionTitle>
            <ClientStatementForm
                initialValues={initialValues || undefined}
                bindSubmit={bindSubmit}
                onSubmit={handleSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
