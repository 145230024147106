import React, { ReactNode } from 'react';
import cn from 'classnames';
import { Input, InputNumber } from 'antd';
import { InputProps, SearchProps } from 'antd/lib/input';
import { InputNumberProps } from 'antd/lib/input-number';
import numbro from 'numbro';

import { Control } from '../Control';

interface PropsStringInput extends InputProps {
    label?: ReactNode;
    error?: ReactNode;
}

function EnhancedInput({
    type,
    label,
    error,
    addonAfter,
    ...bag
}: PropsStringInput) {
    return (
        <Control label={label} error={error}>
            <Input
                type={type}
                addonBefore={addonAfter}
                className={cn({
                    'ant-input-warning': error,
                })}
                {...bag}
            />
        </Control>
    );
}

EnhancedInput.Password = ({
    type,
    label,
    error,
    addonAfter,
    ...bag
}: PropsStringInput) => {
    return (
        <Control label={label} error={error}>
            <Input.Password
                type={type}
                addonBefore={addonAfter}
                className={cn({
                    'ant-input-warning': error,
                })}
                {...bag}
            />
        </Control>
    );
};

interface PropsStringInputSearch extends SearchProps {
    label?: ReactNode;
    error?: ReactNode;
}

EnhancedInput.Search = ({
    type,
    label,
    error,
    ...bag
}: PropsStringInputSearch) => {
    return (
        <Control label={label} error={error}>
            <Input.Search
                type={type}
                className={cn({
                    'ant-input-warning': error,
                })}
                {...bag}
            />
        </Control>
    );
};

interface PropsNumberInput extends InputNumberProps {
    label?: ReactNode;
    error?: ReactNode;
    isCurrency?: boolean;
}

EnhancedInput.Number = ({
    type,
    label,
    isCurrency,
    error,
    ...bag
}: PropsNumberInput) => {
    return (
        <Control label={label} error={error}>
            <InputNumber
                type={type}
                className={cn({
                    'ant-input-warning': error,
                })}
                formatter={
                    isCurrency
                        ? value =>
                              `${value} ${
                                  numbro.languageData().currency.symbol
                              }`
                        : undefined
                }
                parser={
                    isCurrency
                        ? value => {
                              if (value && typeof value === 'string') {
                                  const splitValue = value.split(' ');
                                  if (splitValue.length > 1) {
                                      return splitValue[0];
                                  } else {
                                      return '';
                                  }
                              } else return value ? value.toString() : '';
                          }
                        : undefined
                }
                {...bag}
            />
        </Control>
    );
};

export default EnhancedInput;
