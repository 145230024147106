import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Field, FieldProps, FormikProps, getIn } from 'formik';
import React, { ReactNode } from 'react';

import { CheckBox } from '../../components';

interface Props {
    name: string;
    label: ReactNode;
    subLabel?: ReactNode;
    onChange?: (value: boolean, form?: FormikProps<any>) => void;
}

export default ({ name, onChange, ...bag }: Props) => (
    <Field name={name}>
        {({ field: { value, ...fieldBag }, form }: FieldProps<any>) => {
            const handleChange = (e: CheckboxChangeEvent) => {
                onChange?.(e.target.checked, form);
                form.setFieldValue(name, e.target.checked);
            };
            return (
                <CheckBox
                    checked={value}
                    error={getIn(form.errors, name)}
                    value={Boolean(value)}
                    {...fieldBag}
                    {...bag}
                    onChange={handleChange}
                />
            );
        }}
    </Field>
);
