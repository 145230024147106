import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';

import { CheckBox } from '../../components/Form';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { set } from 'lodash';

interface ObjectCheckboxProps {
    name: string;
    label: ReactNode;
}

const ObjectCheckboxField = ({ name, label }: ObjectCheckboxProps) => (
    <Field name={name}>
        {({ field: { value }, form }: FieldProps<any>) => {
            const handleChange = (e: CheckboxChangeEvent) => {
                const newValues = set(form.values, name, {
                    checked: e.target.checked,
                });
                form.setValues(newValues);
            };

            return (
                <CheckBox
                    checked={value && value.checked}
                    value={value}
                    label={label}
                    onChange={handleChange}
                />
            );
        }}
    </Field>
);

export default ObjectCheckboxField;
