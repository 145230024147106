import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    divider: {
        width: '100%',
        height: '2px',
        marginBottom: '4px',
    },
});

export default function PDFDivider({ border = false }: { border?: boolean }) {
    return <View style={{ ...styles.divider, height: border ? '2px' : 0 }} />;
}
