import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProps } from 'formik';

import { AgreementsForm } from 'common/forms';
import { Typography } from 'common/components';

import { QuestionTitle, PageControls } from '../../../../components';
import {
    setInvestingAgreementsValuesAction,
    selectInvestingAgreements,
    selectStrategy,
} from '../../../../ducks';
import { AgreementsFormValues } from 'common/models/Forms';
import { FeatureProps } from '../../../../index';

export default function InvestingAgreementsFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectInvestingAgreements);
    const strategy = useSelector(selectStrategy);
    const match = useRouteMatch<{ id: string }>();

    let submitForm: FormikProps<AgreementsFormValues>['submitForm'];

    function handleBackClick() {
        history.goBack();
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(cb: FormikProps<AgreementsFormValues>['submitForm']) {
        submitForm = cb;
    }

    function handleSubmit(values: AgreementsFormValues) {
        if (!isPrivileged) {
            dispatch(setInvestingAgreementsValuesAction(values));
        }
        history.push(`/form/${match!.params.id}/investing/pdf`);
    }

    return (
        <>
            <Typography.Title style={{ marginTop: '100px' }} level={1}>
                {strategy ? strategy.name : t('strategies.undefined')}
            </Typography.Title>
            <QuestionTitle>{t('investingFeature.agreements')}</QuestionTitle>
            <AgreementsForm
                onSubmit={handleSubmit}
                bindSubmit={bindSubmit}
                initialValues={initialValues || undefined}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
