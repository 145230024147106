import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { I18nNameSpaces } from 'common/services/i18n';

import {
    selectIsVerificationLogVisible,
    selectVerificationLogItems,
    selectVerificationLogPagination,
    setVerificationLogPageNumberAction,
    setVerificationLogPageSizeAction,
    setVerificationLogVisibleAction,
} from './ducks';
import moment from 'moment';
import { Token, TokenDeliveryMethod, TokenType } from 'common/models/Token';

export default function VerificationLogFeature() {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const dispatch = useDispatch();

    const isVisible = useSelector(selectIsVerificationLogVisible);
    const pagination = useSelector(selectVerificationLogPagination);
    const tokens = useSelector(selectVerificationLogItems);

    const columns = [
        {
            title: t('signatureForm.verificationLog.tokenType.title'),
            width: '30%',
            dataIndex: 'type',
            render: (text: string, item: Token) =>
                t(`signatureForm.verificationLog.tokenType.${item.type}`),
        },
        {
            title: t('signatureForm.verificationLog.deliveryDetail'),
            width: '30%',
            dataIndex: 'meta.deliveryDetail',
        },
        {
            title: t('signatureForm.verificationLog.updatedAtDate'),
            width: '20%',
            dataIndex: 'updatedAtDate',
            render: (text: string, item: Token) =>
                moment(item.updatedAt).format('L'),
        },
        {
            title: t('signatureForm.verificationLog.updatedAtTime'),
            width: '20%',
            dataIndex: 'updatedAtTime',
            render: (text: string, item: Token) =>
                moment(item.updatedAt).format('HH:mm:ss'),
        },
    ];

    function handlePageChange(page: number) {
        dispatch(setVerificationLogPageNumberAction(page));
    }

    function handlePageSizeChange(current: number, size: number) {
        dispatch(setVerificationLogPageSizeAction(size));
    }

    function handleCancel() {
        dispatch(setVerificationLogVisibleAction(false));
    }

    function renderExpandedRow(item: Token) {
        let content = '-';

        if (item.type === TokenType.SIGNATURE) {
            content = (item.meta.scopes ?? [])
                .map(scope => t(`signatureForm.fileTypes.${scope}`))
                .join(', ');
        } else if (item.type === TokenType.VERIFICATION) {
            const knownTokenTags = Object.values(TokenDeliveryMethod);
            content = item.tags
                .filter(tag => knownTokenTags.includes(tag))
                .map(tag => t(`signatureForm.verificationLog.tokenTags.${tag}`))
                .join(', ');
        }

        return <span className="row-expanded">{content}</span>;
    }

    return (
        <Modal
            width={750}
            className="verification-log-feature"
            title={
                <Typography.Text strong>
                    {t('signatureForm.verificationLog.title')}
                </Typography.Text>
            }
            centered
            visible={isVisible}
            footer={null}
            destroyOnClose
            closable={true}
            onCancel={handleCancel}
        >
            <Table
                expandRowByClick
                expandedRowRender={renderExpandedRow}
                rowKey={item => item._id}
                columns={columns}
                dataSource={tokens}
                scroll={{ y: 270 }}
                pagination={{
                    pageSize: pagination.pageSize,
                    current: pagination.currentPage,
                    total: pagination.total,
                    showSizeChanger: true,
                    onShowSizeChange: handlePageSizeChange,
                    onChange: handlePageChange,
                    pageSizeOptions: ['5', '10', '20'],
                }}
            />
        </Modal>
    );
}
