import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper } from '../../components';
import { CheckBoxField, CheckBoxGroupField } from '../../fields';
import { ClientStatementFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';

interface Props {
    initialValues?: Partial<ClientStatementFormValues>;
    onSubmit: (values: ClientStatementFormValues) => void;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
}

export default function ClientStatementForm({
    onSubmit,
    initialValues,
    bindSubmit,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<ClientStatementFormValues>({
        a: Yup.boolean().required(t('form.required')),
        b: Yup.array(Yup.string()).required(t('form.required')),
    });

    function handleOnSubmit(values: ClientStatementFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({
        submitForm,
    }: FormikProps<ClientStatementFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <CheckBoxField
                                name="a"
                                label={t('clientStatementForm.a.label')}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('clientStatementForm.b.label')}
                            </QuestionWrapper.Title>
                            <CheckBoxGroupField
                                name="b"
                                type="vertical"
                                options={[
                                    {
                                        label: t(
                                            'clientStatementForm.b.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'clientStatementForm.b.options.b'
                                        ),
                                        value: 'b',
                                    },
                                    {
                                        label: t(
                                            'clientStatementForm.b.options.c'
                                        ),
                                        value: 'c',
                                    },
                                    {
                                        label: t(
                                            'clientStatementForm.b.options.d'
                                        ),
                                        value: 'd',

                                        custom: true,
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a,
                b: initialValues?.b ?? [],
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
