import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import {
    QuestionWrapper,
    NewFondsTable,
    HeldFondsTable,
} from '../../components';
import { RadioField, CheckBoxGroupField } from '../../fields';
import {
    ConsultancyInitiationFormValues,
    HazardProfileFormValues,
} from '../../models/Forms';
import { I18nNameSpaces, LANGUAGE_CURRENCY_MAP } from '../../services/i18n';

interface Props {
    initialValues?: Partial<ConsultancyInitiationFormValues>;
    strategy: { name: string; type: string };
    hazard: HazardProfileFormValues | null;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: ConsultancyInitiationFormValues) => void;
}

export default function ConsultancyInitiationForm({
    initialValues,
    strategy,
    hazard,
    bindSubmit,
    onSubmit,
}: Props) {
    const {
        t,
        i18n: { language },
    } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<ConsultancyInitiationFormValues>({
        a: Yup.string().required(t('form.required')),
        b: Yup.array(Yup.string().required(t('form.required'))),
    });

    function handleOnSubmit(values: ConsultancyInitiationFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({
        submitForm,
        setFieldValue,
        values,
    }: FormikProps<ConsultancyInitiationFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('consultancyInitiationForm.a.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="a"
                                type="horizontal"
                                options={[
                                    {
                                        label: t(
                                            'consultancyInitiationForm.a.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'consultancyInitiationForm.a.options.b'
                                        ),
                                        value: 'b',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('consultancyInitiationForm.b.label')}
                            </QuestionWrapper.Title>
                            <CheckBoxGroupField
                                name="b"
                                type="vertical"
                                options={[
                                    {
                                        label: t(
                                            'consultancyInitiationForm.b.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'consultancyInitiationForm.b.options.b'
                                        ),
                                        value: 'b',
                                    },
                                    {
                                        label: t(
                                            'consultancyInitiationForm.b.options.c'
                                        ),
                                        value: 'c',
                                    },
                                    {
                                        label: t(
                                            'consultancyInitiationForm.b.options.d'
                                        ),
                                        value: 'd',
                                    },
                                    {
                                        label: t(
                                            'consultancyInitiationForm.b.options.e'
                                        ),
                                        value: 'e',
                                    },
                                    {
                                        label: t(
                                            'consultancyInitiationForm.b.options.f'
                                        ),
                                        value: 'f',
                                        custom: true,
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <NewFondsTable
                                strategy={strategy}
                                hazard={hazard}
                                initialData={values.table}
                                onChange={v => setFieldValue('table', v)}
                                currency={LANGUAGE_CURRENCY_MAP[language]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <HeldFondsTable
                                initialData={values.tableAltCurrency}
                                onChange={v =>
                                    setFieldValue('tableAltCurrency', v)
                                }
                                currency={LANGUAGE_CURRENCY_MAP[language]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a ?? '',
                b: initialValues?.b ?? [],
                table: initialValues?.table ?? [],
                tableAltCurrency: initialValues?.tableAltCurrency ?? [],
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
