import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router';

import { QuestionWrapper } from 'common/components';
import { ClientForm } from 'common/forms';

import { QuestionTitle, PageControls } from '../../../../components';
import { ClientFormValues } from '../../../../../../common/models/Forms';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import {
    selectInformationsCustomerInfo2,
    setInformationsClient2InfoValuesAction,
} from '../../../../ducks';
import { FeatureProps } from '../../../../index';

export default function CustomerInfo2Feature({ isPrivileged }: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectInformationsCustomerInfo2);
    const match = useRouteMatch<{ id: string }>();

    let submitForm: FormikProps<ClientFormValues>['submitForm'];

    function handleSkip() {
        history.push(`/form/${match!.params.id}/investing`);
    }

    function handleBackClick() {
        history.goBack();
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(cb: FormikProps<ClientFormValues>['submitForm']) {
        submitForm = cb;
    }

    function handleSubmit(values: ClientFormValues) {
        if (!isPrivileged) {
            dispatch(setInformationsClient2InfoValuesAction(values));
        }
        history.push(`/form/${match!.params.id}/investing`);
    }

    return (
        <>
            <QuestionTitle>{t('informationsFeature.clientTwo')}</QuestionTitle>
            <QuestionWrapper>
                <ClientForm
                    initialValues={initialValues || undefined}
                    onSubmit={handleSubmit}
                    bindSubmit={bindSubmit}
                />
            </QuestionWrapper>
            <PageControls
                onSkip={handleSkip}
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
