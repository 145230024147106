import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Button } from '../../components';
import { InputField } from '../../fields';
import { ResetPasswordFormValues } from '../../models/User';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

interface Props extends WithTranslation {
    onSubmit: (values: ResetPasswordFormValues) => void;
    initialValues?: Partial<ResetPasswordFormValues>;
}

function ResetPasswordForm({ onSubmit, initialValues, t }: Props) {
    const schema = Yup.object<ResetPasswordFormValues>().shape<ResetPasswordFormValues>(
        {
            email: Yup.string()
                .email(t('form.emailRequired'))
                .required(t('form.required')),
        }
    );

    function handleOnSubmit(values: ResetPasswordFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm(formikBag: FormikProps<ResetPasswordFormValues>) {
        return (
            <div className="login-form">
                <Form>
                    <InputField
                        name="email"
                        label={t('resetPasswordForm.email.label')}
                        placeholder={t('resetPasswordForm.email.placeholder')}
                        size="large"
                    />
                    <Row
                        type="flex"
                        justify="center"
                        style={{ marginTop: '30px' }}
                    >
                        <Col>
                            <Button
                                htmlType="submit"
                                type="primary"
                                disabled={formikBag.isSubmitting}
                            >
                                {t('resetPasswordForm.submit')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{ email: initialValues?.email ?? '' }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}

export default withTranslation()(ResetPasswordForm);
