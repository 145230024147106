import React from 'react';
import { Icon } from 'antd';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';

import { Tooltip } from 'common/components';
import { SignatureFlag, SignatureScope } from 'common/models/Transaction';
import { selectClientSignature } from 'features/form/features/transaction/ducks';
import { selectFormSignatures } from 'features/form/ducks';
import { I18nNameSpaces } from 'common/services/i18n';

interface Props {
    id: SignatureScope;
    isSelected?: boolean;
    onChange: (key: SignatureScope, isSelected: boolean) => void;
}

export default function DocumentActions({ id, onChange }: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const formSignatures = useSelector(selectFormSignatures);
    const clientSignatures = useSelector(selectClientSignature);

    return (
        <div
            className="document-actions"
            onClick={e => {
                e.stopPropagation();
            }}
        >
            {formSignatures[id] === SignatureFlag.SIGNED ? (
                <Tooltip
                    className="signature-tooltip"
                    title={t('signatureForm.signed')}
                >
                    <Icon type="file-protect" />
                </Tooltip>
            ) : (
                <Checkbox
                    checked={clientSignatures.selectedDocuments.includes(id)}
                    onChange={e => {
                        onChange(id, e.target.checked);
                    }}
                />
            )}
        </div>
    );
}
