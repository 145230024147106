import axios, { AxiosResponse } from 'axios';
import qs from 'qs';

import { environment } from '../../environments/environment';

export interface ApiClientResponse<T> extends AxiosResponse<T> {
    ok: boolean;
    response?: AxiosResponse<T>;
}

export const apiClient = axios.create({
    responseType: 'json',
    baseURL: `${environment.protocol}://${
        window.location.host.endsWith('.sk')
            ? environment.host_sk
            : environment.host_cz
    }`,
    withCredentials: true,
    paramsSerializer: params => {
        return qs.stringify(params);
    },
});

apiClient.interceptors.request.use(config => {
    config.headers.common[
        'Authorization'
    ] = `Basic ${environment.basicAuthToken}`;
    config.headers.common['Cache-Control'] = 'no-cache';
    return config;
});

apiClient.interceptors.response.use(
    response => normalizeSuccessResponse(response),
    error => normalizeErrorResponse(error)
);

function normalizeSuccessResponse<T>(
    response: AxiosResponse<T>
): ApiClientResponse<T> {
    return {
        ...response,
        ok: true,
    };
}

function normalizeErrorResponse(error: any) {
    return {
        ...error,
        status: error?.response?.status,
        ok: false,
    };
}
