import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';

import { Button } from '../../components';
import { InputField } from '../../fields';
import { PasswordEditFormValues } from '../../models/User';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

interface Props extends WithTranslation {
    onSubmit: (values: PasswordEditFormValues) => void;
}

function PasswordEditForm({ onSubmit, t }: Props) {
    const schema = Yup.object<PasswordEditFormValues>().shape<
        PasswordEditFormValues
    >({
        password: Yup.string().required(t('form.required')),
        passwordAgain: Yup.string()
            .required(t('form.required'))
            .oneOf([Yup.ref('password'), null], t('form.mustMatch')),
    });

    function handleOnSubmit(values: PasswordEditFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm(formikBag: FormikProps<PasswordEditFormValues>) {
        return (
            <div className="password-edit-form">
                <Form>
                    <InputField
                        name="password"
                        type="password"
                        size="large"
                        label={t('passwordEdit.password.label')}
                        placeholder={t('passwordEdit.password.placeholder')}
                    />
                    <InputField
                        name="passwordAgain"
                        type="password"
                        size="large"
                        label={t('passwordEdit.passwordAgain.label')}
                        placeholder={t(
                            'passwordEdit.passwordAgain.placeholder'
                        )}
                    />
                    <Row
                        type="flex"
                        justify="center"
                        style={{ marginTop: '30px' }}
                    >
                        <Col>
                            <Button
                                disabled={formikBag.isSubmitting}
                                htmlType="submit"
                                type="primary"
                            >
                                {t('passwordEdit.submit')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{ oldPassword: '', password: '', passwordAgain: '' }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}

export default withTranslation()(PasswordEditForm);
