import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { useTranslation, Trans } from 'react-i18next';
import {
    ClientFormValues,
    CompanyInfoFormValues,
    ConsultantConfirmationFormValues,
} from '../models/Forms';

import {
    PDFParagraphBlock,
    PDFDivider,
    PDFSignatureBlock,
    PDFBrandedPageWithFooter,
} from './components';

import { I18nNameSpaces } from '../services/i18n';

interface Props {
    client1: ClientFormValues;
    client1Signature?: string;
    client2?: ClientFormValues;
    companyInfo: CompanyInfoFormValues;
    agreement: ConsultantConfirmationFormValues['c'];
}

export default function ClientInformationsPage({
    client1,
    client1Signature,
    client2,
    companyInfo,
    agreement,
}: Props) {
    const { t, i18n } = useTranslation(I18nNameSpaces.PDF);
    const footerTitle = (
        <PDFParagraphBlock>{t('generalAgreement.p1.title')}</PDFParagraphBlock>
    );
    return (
        <>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <View>
                    <PDFParagraphBlock.Title bold>
                        {t('generalAgreement.title')}
                    </PDFParagraphBlock.Title>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <View style={{ width: '48%' }}>
                            <PDFParagraphBlock.Title bold>
                                {t('generalAgreement.p1.title')}
                            </PDFParagraphBlock.Title>
                            <PDFParagraphBlock lineHeight={1.4}>
                                <Trans
                                    i18nKey="generalAgreement.p1.p"
                                    ns={I18nNameSpaces.PDF}
                                >
                                    Part 1
                                    <Text
                                        style={{ fontFamily: 'HindSemiBold' }}
                                    />
                                    Part 2
                                    <Text
                                        style={{ fontFamily: 'HindSemiBold' }}
                                    />
                                    Part 4
                                    <Text
                                        style={{ fontFamily: 'HindSemiBold' }}
                                    />
                                    Part 5
                                </Trans>
                            </PDFParagraphBlock>
                            <PDFDivider />
                            <PDFParagraphBlock.Title bold>
                                {t('generalAgreement.p2.title')}
                            </PDFParagraphBlock.Title>
                            <PDFParagraphBlock lineHeight={1.4}>
                                {t('generalAgreement.p2.p')}
                            </PDFParagraphBlock>
                        </View>
                        <View style={{ width: '48%' }}>
                            <PDFParagraphBlock lineHeight={1.4}>
                                <Trans
                                    i18nKey="generalAgreement.p2.p2"
                                    ns={I18nNameSpaces.PDF}
                                />
                            </PDFParagraphBlock>
                            <PDFDivider />
                            <PDFParagraphBlock.Title bold>
                                {t('generalAgreement.p3.title')}
                            </PDFParagraphBlock.Title>
                            <PDFParagraphBlock lineHeight={1.4}>
                                {t('generalAgreement.p3.p')}
                            </PDFParagraphBlock>
                        </View>
                    </View>
                </View>
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <View>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <View style={{ width: '48%' }}>
                            <PDFParagraphBlock lineHeight={1.3}>
                                {t('generalAgreement.p3.p2')}
                            </PDFParagraphBlock>
                        </View>
                        <View style={{ width: '48%' }}>
                            <PDFParagraphBlock lineHeight={1.3}>
                                {t('generalAgreement.p3.p3')}
                            </PDFParagraphBlock>
                            <Image
                                src={`/pdf/investment-graph-${
                                    i18n.language === 'sk' ? 'sk' : 'cz'
                                }.png`}
                                style={{
                                    width: '100%',
                                    marginVertical: '10px',
                                }}
                            />
                            <PDFDivider />
                            <PDFParagraphBlock.Title bold>
                                {t('generalAgreement.p4.title')}
                            </PDFParagraphBlock.Title>
                            <PDFParagraphBlock lineHeight={1.3}>
                                <Trans
                                    i18nKey="generalAgreement.p4.p"
                                    ns={I18nNameSpaces.PDF}
                                />
                            </PDFParagraphBlock>
                        </View>
                    </View>
                </View>
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <View>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <View style={{ width: '48%' }}>
                            <PDFParagraphBlock lineHeight={1.3}>
                                {t('generalAgreement.p4.p2')}
                            </PDFParagraphBlock>
                            <PDFDivider />
                            <PDFParagraphBlock.Title bold>
                                {t('generalAgreement.p5.title')}
                            </PDFParagraphBlock.Title>
                            <PDFParagraphBlock lineHeight={1.3}>
                                {t('generalAgreement.p5.p')}
                            </PDFParagraphBlock>
                        </View>
                        <View style={{ width: '48%' }}>
                            <PDFParagraphBlock lineHeight={1.3}>
                                {t('generalAgreement.p5.p2')}
                            </PDFParagraphBlock>
                            <PDFDivider />
                            <PDFParagraphBlock.Title bold>
                                {t('generalAgreement.p6.title')}
                            </PDFParagraphBlock.Title>
                            <PDFParagraphBlock lineHeight={1.3}>
                                {t('generalAgreement.p6.p')}
                            </PDFParagraphBlock>
                        </View>
                    </View>
                </View>
                <PDFDivider />
                <PDFDivider />
                <PDFDivider />
                <PDFDivider />
                <PDFDivider />

                <View
                    wrap={false}
                    style={{
                        border: '1 solid black',
                        padding: 5,
                    }}
                >
                    <PDFParagraphBlock>
                        {t('generalAgreement.box.title')}
                    </PDFParagraphBlock>

                    <PDFDivider />
                    <PDFDivider />
                    <PDFDivider />
                    <PDFSignatureBlock
                        signature={client1Signature}
                        name={
                            client1
                                ? `${client1.title} ${client1.firstName} ${client1.lastName} (Zákazník 1)`
                                : undefined
                        }
                        name2={
                            client2
                                ? `${client2.title} ${client2.firstName} ${client2.lastName} (Zákazník 2)`
                                : undefined
                        }
                        signature3={`${companyInfo.firstName} ${companyInfo.lastName}`}
                        name3={
                            companyInfo
                                ? `${companyInfo.title} ${
                                      companyInfo.firstName
                                  } ${companyInfo.lastName} ${t(
                                      'signatureBlock.manager'
                                  )}`
                                : undefined
                        }
                        date={agreement.date}
                        place={agreement.city}
                        time={agreement.interviewDuration}
                    />
                </View>
            </PDFBrandedPageWithFooter>
        </>
    );
}
