import React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

export default function EnhancedButton({
    size = 'large',
    ...rest
}: ButtonProps) {
    return <Button size={size} {...rest} />;
}
