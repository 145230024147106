import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { GroupErrorWrapper, QuestionWrapper } from '../../components';
import { RadioField } from '../../fields';
import { FinancesFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import InputField from '../../fields/InputNumber';
import { Col, Row } from 'antd';

interface Props {
    initialValues?: Partial<FinancesFormValues>;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: FinancesFormValues) => void;
}

export default function FinancesProfileForm({
    onSubmit,
    bindSubmit,
    initialValues,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<FinancesFormValues>({
        a: Yup.string().required(t('form.required')),
        c: Yup.string().required(t('form.required')),
        d: Yup.string().required(t('form.required')),
        e: Yup.string().required(t('form.required')),
        f: Yup.object()
            .required()
            .default({})
            .shape({
                a: Yup.number().required(t('form.required')),
                b: Yup.number().required(t('form.required')),
                c: Yup.number().required(t('form.required')),
                d: Yup.number().required(t('form.required')),
                e: Yup.number().required(t('form.required')),
            })
            .test(
                '100% sum test',
                t('financesForm.f.sumError'),
                ({ a, b, c, d, e }) => a + b + c + d + e === 100
            ),
    });

    function handleOnSubmit(values: FinancesFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({ submitForm }: FormikProps<FinancesFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('financesForm.a.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="a"
                                type="vertical"
                                options={[
                                    {
                                        label: t('financesForm.a.options.a'),
                                        value: 'a',
                                    },
                                    {
                                        label: t('financesForm.a.options.b'),
                                        value: 'b',
                                    },
                                    {
                                        label: t('financesForm.a.options.c'),
                                        value: 'c',
                                    },
                                    {
                                        label: t('financesForm.a.options.d'),
                                        value: 'd',
                                    },
                                    {
                                        label: t('financesForm.a.options.f'),
                                        value: 'f',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('financesForm.c.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="c"
                                type="vertical"
                                options={[
                                    {
                                        label: t('financesForm.c.options.a'),
                                        value: 'a',
                                    },
                                    {
                                        label: t('financesForm.c.options.b'),
                                        value: 'b',
                                    },
                                    {
                                        label: t('financesForm.c.options.c'),
                                        value: 'c',
                                    },
                                    {
                                        label: t('financesForm.c.options.d'),
                                        value: 'd',
                                    },
                                    {
                                        label: t('financesForm.c.options.e'),
                                        value: 'e',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('financesForm.e.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="e"
                                type="vertical"
                                options={[
                                    {
                                        label: t('financesForm.e.options.a'),
                                        value: 'a',
                                    },
                                    {
                                        label: t('financesForm.e.options.b'),
                                        value: 'b',
                                    },
                                    {
                                        label: t('financesForm.e.options.c'),
                                        value: 'c',
                                    },
                                    {
                                        label: t('financesForm.e.options.d'),
                                        value: 'd',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('financesForm.d.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="d"
                                type="horizontal"
                                options={[
                                    {
                                        label: t('financesForm.d.options.a'),
                                        value: 'a',
                                    },
                                    {
                                        label: t('financesForm.d.options.b'),
                                        value: 'b',
                                    },
                                    {
                                        label: t('financesForm.d.options.c'),
                                        value: 'c',
                                    },
                                    {
                                        label: t('financesForm.d.options.d'),
                                        value: 'd',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('financesForm.f.label')}
                            </QuestionWrapper.Title>
                            <GroupErrorWrapper name="f">
                                <Row type="flex" align="middle">
                                    <Col xs={2}>
                                        <InputField size="small" name="f.a" />
                                    </Col>
                                    <Col>{t('financesForm.f.options.a')}</Col>
                                </Row>
                                <Row type="flex" align="middle">
                                    <Col xs={2}>
                                        <InputField size="small" name="f.b" />
                                    </Col>
                                    <Col>{t('financesForm.f.options.b')}</Col>
                                </Row>
                                <Row type="flex" align="middle">
                                    <Col xs={2}>
                                        <InputField size="small" name="f.c" />
                                    </Col>
                                    <Col>{t('financesForm.f.options.c')}</Col>
                                </Row>
                                <Row type="flex" align="middle">
                                    <Col xs={2}>
                                        <InputField size="small" name="f.d" />
                                    </Col>
                                    <Col>{t('financesForm.f.options.d')}</Col>
                                </Row>
                                <Row type="flex" align="middle">
                                    <Col xs={2}>
                                        <InputField size="small" name="f.e" />
                                    </Col>
                                    <Col>{t('financesForm.f.options.e')}</Col>
                                </Row>
                            </GroupErrorWrapper>
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a ?? '',
                c: initialValues?.c ?? '',
                d: initialValues?.d ?? '',
                e: initialValues?.e ?? '',
                f: {
                    a: initialValues?.f?.a,
                    b: initialValues?.f?.b,
                    c: initialValues?.f?.c,
                    d: initialValues?.f?.d,
                    e: initialValues?.f?.e,
                },
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
