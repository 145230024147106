import { Field, FieldProps } from 'formik';
import React, { ReactNode } from 'react';
import { useRegisteredRef } from 'react-register-nodes';

import { Slider } from '../../components';

interface Props {
    name: string;
    label: ReactNode;
    min?: number;
    max?: number;
    defaultValue: number;
    tooltip?: ReactNode;
    currencySymbol?: string;
    type?: 'default' | 'bordered';
}

export default function SliderWithNumberField({ name, ...bag }: Props) {
    const ref = useRegisteredRef(name);

    return (
        <Field name={name}>
            {({
                field: { value },
                form: { errors, setFieldValue },
            }: FieldProps) => {
                function handleChange(value: number) {
                    setFieldValue(name, value);
                }

                return (
                    <div ref={errors[name] ? ref : null}>
                        <Slider.WithNumberInput
                            value={value}
                            onChange={handleChange}
                            {...bag}
                        />
                    </div>
                );
            }}
        </Field>
    );
}
