import React, { ReactNode } from 'react';
import { View, Image } from '@react-pdf/renderer';
import { PDFParagraphBlock } from '.';

interface Props {
    checked: boolean;
    label: ReactNode;
    lineHeight?: number;
}

export default function PDFCheckbox({ label, checked, lineHeight }: Props) {
    return (
        <View
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
            }}
        >
            <View>
                {checked ? (
                    <Image
                        style={{
                            width: 8,
                            height: 8,
                        }}
                        src="/checkbox-checked.png"
                    />
                ) : (
                    <Image
                        style={{
                            width: 8,
                            height: 8,
                        }}
                        src="/checkbox.png"
                    />
                )}
            </View>
            <View style={{ marginLeft: 3 }}>
                <PDFParagraphBlock lineHeight={lineHeight}>
                    {label}{' '}
                </PDFParagraphBlock>
            </View>
        </View>
    );
}
