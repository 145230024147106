import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProps } from 'formik';

import { InvestingGoalsForm } from 'common/forms';
import { InvestingGoalsFormValues } from 'common/models/Forms';

import { QuestionTitle, PageControls } from '../../../../components';
import {
    selectProtocolGoals,
    setProtocolGoalsValuesAction,
    selectInformationsCustomerInfo1,
    selectInvestingHazard,
} from '../../../../ducks';
import { FeatureProps } from '../../../../index';

export default function ProtocolGoalsFeature({ isPrivileged }: FeatureProps) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectProtocolGoals);
    const customerValues = useSelector(selectInformationsCustomerInfo1);
    const hazardValues = useSelector(selectInvestingHazard);
    const match = useRouteMatch<{ id: string }>();

    let submitForm: FormikProps<InvestingGoalsFormValues>['submitForm'];

    function handleBackClick() {
        history.goBack();
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(
        cb: FormikProps<InvestingGoalsFormValues>['submitForm']
    ) {
        submitForm = cb;
    }

    function handleSubmit(values: InvestingGoalsFormValues) {
        if (!isPrivileged) {
            dispatch(setProtocolGoalsValuesAction(values));
        }
        history.push(`/form/${match!.params.id}/protocol/initiation`);
    }

    return (
        <>
            <QuestionTitle>{t('protocolFeature.goals')}</QuestionTitle>
            <InvestingGoalsForm
                hazard={hazardValues || undefined}
                initialValues={{
                    ...initialValues,
                    a: customerValues!.goal,
                }}
                bindSubmit={bindSubmit}
                onSubmit={handleSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
