import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../resources/images/logo.png';

export default function Logo() {
    return (
        <Link to="/">
            <img className="logo" src={logo} alt="logo" />
        </Link>
    );
}
