import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router';

import CompanyInfoFeature from './features/company-info';
import CustomerInfo1Feature from './features/customer-info-1';
import CustomerInfo2Feature from './features/customer-info-2';
import { AppState } from '../../../../common/models/AppState';
import { selectIsPrivileged } from '../../../auth/ducks';
import { connect } from 'react-redux';

interface StateToProps {
    isPrivileged: boolean;
}

function InformationsFeature({ isPrivileged }: StateToProps) {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match!.path}/company-info`}
                render={props => (
                    <CompanyInfoFeature
                        {...props}
                        isPrivileged={isPrivileged}
                    />
                )}
            />
            <Route
                path={`${match!.path}/customer-info-1`}
                render={props => (
                    <CustomerInfo1Feature
                        {...props}
                        isPrivileged={isPrivileged}
                    />
                )}
            />
            <Route
                path={`${match!.path}/customer-info-2`}
                render={props => (
                    <CustomerInfo2Feature
                        {...props}
                        isPrivileged={isPrivileged}
                    />
                )}
            />
            <Redirect
                path={`${match!.path}/`}
                to={`${match!.path}/company-info`}
            />
        </Switch>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    isPrivileged: selectIsPrivileged(state),
});

export default connect(mapStateToProps)(InformationsFeature);
