import React from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { useSelector, useDispatch } from 'react-redux';

import { MeetingForm } from 'common/forms';
import { MeetingFormValues } from 'common/models/Forms';

import { PageControls, QuestionTitle } from '../../../../components';
import {
    selectAgreementMeeting,
    setAgreementMeetingAction,
} from '../../../../ducks';
import { I18nNameSpaces } from '../../../../../../common/services/i18n';
import { FeatureProps } from '../../../../index';

export default function AgreementMeetingFeature({
    isPrivileged,
}: FeatureProps) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const history = useHistory();
    const match = useRouteMatch<{ id: string }>();
    const dispatch = useDispatch();
    const initialValues = useSelector(selectAgreementMeeting);

    let submitForm: FormikProps<MeetingFormValues>['submitForm'];

    function handleBackClick() {
        history.push(
            `/form/${match!.params.id}/protocol/consultant-confirmation`
        );
    }

    function handleContinueClick() {
        submitForm();
    }

    function bindSubmit(cb: FormikProps<MeetingFormValues>['submitForm']) {
        submitForm = cb;
    }

    function handleSubmit(values: MeetingFormValues) {
        if (!isPrivileged) {
            dispatch(setAgreementMeetingAction(values));
        }
        history.push(`/form/${match!.params.id}/agreement/summary`);
    }

    return (
        <>
            <QuestionTitle>{t('meetingForm.title')}</QuestionTitle>
            <MeetingForm
                initialValues={initialValues || undefined}
                onSubmit={handleSubmit}
                bindSubmit={bindSubmit}
            />
            <PageControls
                onBack={handleBackClick}
                onContinue={handleContinueClick}
            />
        </>
    );
}
