import React, { ReactNode } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { View, Text } from '@react-pdf/renderer';
import {
    ClientFormValues,
    CompanyInfoFormValues,
    InvestingExperienceFormValues,
    HazardProfileFormValues,
    FinanceAnalyseFormValues,
    FinancesFormValues,
    AgreementsFormValues,
} from '../models/Forms';

import {
    PDFParagraphBlock,
    PDFDivider,
    PDFCheckbox,
    PDFBrandedPageWithFooter,
    PDFSignatureBlock,
    PDFListItem,
    PDFBasicQuestion,
} from './components';
import { I18nNameSpaces } from '../services/i18n';
import numbro from 'numbro';
import PdfParagraphBlock from './components/ParagraphBlock';

interface Props {
    clientValues1: ClientFormValues;
    client1Signature?: string;
    clientValues2?: ClientFormValues;
    investing: InvestingExperienceFormValues;
    hazard: HazardProfileFormValues;
    analyse: FinanceAnalyseFormValues;
    companyInfo: CompanyInfoFormValues;
    strategy: { name: string; type: string };
    finances: FinancesFormValues;
    agreement: AgreementsFormValues;
}

export default function InvestingFormPage({
    companyInfo,
    clientValues1,
    clientValues2,
    investing,
    hazard,
    finances,
    analyse,
    strategy,
    agreement,
    client1Signature,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.PDF);
    const { t: formT } = useTranslation(I18nNameSpaces.Forms);
    const footerTitle = (
        <PDFParagraphBlock>{t('investing.title')}</PDFParagraphBlock>
    );

    function getReserve(values: FinanceAnalyseFormValues) {
        if (
            clientValues1!.salary === 'employed' ||
            clientValues1!.salary === 'freelancer'
        ) {
            return numbro(values.monthExpenses * 12).formatCurrency();
        }

        return numbro(values.liquidity * 0.4).formatCurrency();
    }

    function renderHazard(title: ReactNode, content: ReactNode) {
        return (
            <>
                <PDFCheckbox
                    checked
                    label={
                        <Text
                            style={{
                                fontFamily: 'HindSemiBold',
                                position: 'relative',
                                left: 5,
                            }}
                        >
                            {title}
                        </Text>
                    }
                />
                <PDFParagraphBlock lineHeight={1.4}>
                    {content}
                </PDFParagraphBlock>
            </>
        );
    }

    const extractLeadingNumbers = (str: string) => {
        const numbers = /^[0-9]+/.exec(str);
        return numbers ? numbers[0] : undefined;
    };

    return (
        <>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <View>
                    <PDFParagraphBlock.Title size="large" bold underline blue>
                        {t('investing.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock lineHeight={1.3}>
                        {t('investing.p1')}
                    </PDFParagraphBlock>
                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock bold>
                        {t('investing.section1.title')}
                    </PDFParagraphBlock>
                    <PDFDivider />
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ flexGrow: 2 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('investing.section1.fields.a')}
                                value={`${companyInfo.title} ${companyInfo.firstName} ${companyInfo.lastName}`}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('investing.section1.fields.b')}
                                value={companyInfo.address}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('investing.section1.fields.c')}
                                value={companyInfo.partnerNumber}
                            />
                            <PDFDivider />
                        </View>
                        <View style={{ flexGrow: 2 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={t('investing.section1.fields.d')}
                                value={companyInfo.phone}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('investing.section1.fields.e')}
                                value={companyInfo.email}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={t('investing.section1.fields.f')}
                                value={companyInfo.ico}
                            />
                            <PDFDivider />
                        </View>
                    </View>
                </View>
                <PDFDivider />
                <PDFDivider />
                <PDFParagraphBlock lineHeight={1.3}>
                    {t('investing.p2')}
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <PDFParagraphBlock bold>
                    {t('investing.section2')}
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flexGrow: 2 }}>
                        <PDFParagraphBlock.Title
                            size="medium"
                            bold
                            underline
                            blue
                        >
                            {t('investing.userBox.title', { number: 1 })}
                        </PDFParagraphBlock.Title>
                        <PDFListItem
                            label={t('investing.userBox.fields.a')}
                            value={formT(
                                `clientForm.gender.options.${clientValues1.gender}`
                            )}
                        />
                        <PDFListItem
                            label={t('investing.userBox.fields.b')}
                            value={`${clientValues1.title} ${clientValues1.firstName} ${clientValues1.lastName}`}
                        />
                        <PDFParagraphBlock bold>
                            {t('investing.userBox.fields.addressTitle')}
                        </PDFParagraphBlock>
                        <PDFListItem
                            label={t('investing.userBox.fields.c')}
                            value={`${clientValues1.street} ${clientValues1.streetNumber}`}
                        />
                        <View
                            style={{
                                flexDirection: 'row',
                                height: 'auto',
                            }}
                        >
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('investing.userBox.fields.d')}
                                    value={clientValues1.city}
                                />
                            </View>
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('investing.userBox.fields.e')}
                                    value={clientValues1.psc}
                                />
                            </View>
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('investing.userBox.fields.f')}
                                    value={clientValues1.state}
                                />
                            </View>
                        </View>
                        <PDFListItem
                            label={t('investing.userBox.fields.g')}
                            value={clientValues1.birthday.date}
                        />
                    </View>
                    <View style={{ flexGrow: 2 }}>
                        <PDFParagraphBlock.Title
                            size="medium"
                            bold
                            underline
                            blue
                        >
                            {t('investing.userBox.title', { number: 2 })}
                        </PDFParagraphBlock.Title>
                        <PDFListItem
                            label={t('investing.userBox.fields.a')}
                            value={
                                clientValues2
                                    ? formT(
                                          `clientForm.gender.options.${
                                              clientValues2?.gender ?? ''
                                          }`
                                      )
                                    : ''
                            }
                        />
                        <PDFListItem
                            label={t('investing.userBox.fields.b')}
                            value={
                                clientValues2
                                    ? `${clientValues2.title} ${clientValues2.firstName} ${clientValues2.lastName}`
                                    : ''
                            }
                        />
                        <PDFParagraphBlock bold>
                            {t('investing.userBox.fields.addressTitle')}
                        </PDFParagraphBlock>
                        <PDFListItem
                            label={t('investing.userBox.fields.c')}
                            value={`${clientValues2?.street ?? ''} ${
                                clientValues2?.streetNumber ?? ''
                            }`}
                        />
                        <View
                            style={{
                                flexDirection: 'row',
                                height: 'auto',
                            }}
                        >
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('investing.userBox.fields.d')}
                                    value={clientValues2?.city ?? ''}
                                />
                            </View>
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('investing.userBox.fields.e')}
                                    value={clientValues2?.psc ?? ''}
                                />
                            </View>
                            <View style={{ flexGrow: 3 }}>
                                <PDFListItem
                                    label={t('investing.userBox.fields.f')}
                                    value={clientValues2?.state ?? ''}
                                />
                            </View>
                        </View>
                        <PDFListItem
                            label={t('investing.userBox.fields.g')}
                            value={clientValues2?.birthday.date ?? ''}
                        />
                    </View>
                </View>
                <View>
                    <PDFDivider />
                    <PDFDivider />

                    <PDFBasicQuestion
                        id="I."
                        label={
                            <PDFParagraphBlock.Title bold underline>
                                {t('investing.question1.title')}
                            </PDFParagraphBlock.Title>
                        }
                        value=""
                    />
                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock.Title bold>
                        {t('investing.question1.qTitle')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <PDFBasicQuestion
                        id="a)"
                        label={t('investing.question1.q1')}
                        value={formT(
                            `investingExperienceForm.options.${
                                (investing?.table ?? []).find(
                                    q => q.question === 'a'
                                )?.value ?? ''
                            }`
                        )}
                    />
                    <PDFBasicQuestion
                        id="b)"
                        label={t('investing.question1.q2')}
                        value={formT(
                            `investingExperienceForm.options.${
                                (investing?.table ?? []).find(
                                    q => q.question === 'b'
                                )?.value ?? ''
                            }`
                        )}
                    />
                    <PDFBasicQuestion
                        id="c)"
                        label={t('investing.question1.q3')}
                        value={formT(
                            `investingExperienceForm.options.${
                                (investing?.table ?? []).find(
                                    q => q.question === 'c'
                                )?.value ?? ''
                            }`
                        )}
                    />
                    <PDFBasicQuestion
                        id="d)"
                        label={t('investing.question1.q4')}
                        value={formT(
                            `investingExperienceForm.options.${
                                (investing?.table ?? []).find(
                                    q => q.question === 'd'
                                )?.value ?? ''
                            }`
                        )}
                    />
                    <PDFBasicQuestion
                        id="e)"
                        label={t('investing.question1.q5')}
                        value={formT(
                            `investingExperienceForm.options.${
                                (investing?.table ?? []).find(
                                    q => q.question === 'e'
                                )?.value ?? ''
                            }`
                        )}
                    />
                    <PDFDivider />
                    <PDFDivider />
                    <PDFParagraphBlock.Title bold>
                        {t('investing.question1.sub.title')}
                    </PDFParagraphBlock.Title>
                    <PDFDivider />
                    <PDFBasicQuestion
                        id="A."
                        label={t('investing.question1.sub.a')}
                        value={String(
                            investing.a
                                ? formT(
                                      'investingExperienceForm.hasInvestingKnowledgeQsts.a.options.positive'
                                  )
                                : formT(
                                      'investingExperienceForm.hasInvestingKnowledgeQsts.a.options.negative'
                                  )
                        )}
                    />
                    <PDFBasicQuestion
                        id="B."
                        label={t('investing.question1.sub.b.main')}
                        value={String(
                            investing.b.a
                                ? formT(
                                      'investingExperienceForm.hasInvestingKnowledgeQsts.a.options.positive'
                                  )
                                : formT(
                                      'investingExperienceForm.hasInvestingKnowledgeQsts.a.options.negative'
                                  )
                        )}
                    >
                        <View style={{ flexDirection: 'row' }}>
                            <PDFParagraphBlock bold>
                                {t('investing.question1.sub.b.b2')}
                            </PDFParagraphBlock>
                            <PDFParagraphBlock>
                                {String(
                                    investing.b.b
                                        ? formT(
                                              'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.a.options.often'
                                          )
                                        : formT(
                                              'investingExperienceForm.hasInvestingKnowledgeQsts.b.questions.a.options.notOften'
                                          )
                                )}
                            </PDFParagraphBlock>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <PDFParagraphBlock bold>
                                {t('investing.question1.sub.b.b3')}
                            </PDFParagraphBlock>
                            <PDFParagraphBlock>
                                {String(investing.b.c)}
                            </PDFParagraphBlock>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <PDFParagraphBlock bold>
                                {t('investing.question1.sub.b.b4')}
                            </PDFParagraphBlock>
                            <PDFParagraphBlock>
                                {numbro(
                                    extractLeadingNumbers(
                                        String(investing?.b?.d ?? 0)
                                    )
                                ).formatCurrency()}
                            </PDFParagraphBlock>
                        </View>
                    </PDFBasicQuestion>
                    <PDFDivider />
                    <PDFBasicQuestion
                        id="C."
                        label={t('investing.question1.sub.c.main')}
                        value={String(
                            investing.c.a
                                ? formT(
                                      'investingExperienceForm.hasInvestingKnowledgeQsts.b.options.positive'
                                  )
                                : formT(
                                      'investingExperienceForm.hasInvestingKnowledgeQsts.b.options.negative'
                                  )
                        )}
                    >
                        <View style={{ flexDirection: 'row' }}>
                            <PDFParagraphBlock bold>
                                {t('investing.question1.sub.c.c2')}
                            </PDFParagraphBlock>
                            <PDFParagraphBlock>
                                {String(
                                    investing.c.b
                                        ? formT(
                                              `investingExperienceForm.hasInvestingKnowledgeQsts.c.questions.a.options.${investing.c.b}`
                                          )
                                        : ''
                                )}
                            </PDFParagraphBlock>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <PDFParagraphBlock bold>
                                {t('investing.question1.sub.c.c3')}
                            </PDFParagraphBlock>
                            <PDFParagraphBlock>
                                {String(investing.c.c)}
                            </PDFParagraphBlock>
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <PDFParagraphBlock bold>
                                {t('investing.question1.sub.c.c4')}
                            </PDFParagraphBlock>
                            <PDFParagraphBlock>
                                {numbro(
                                    extractLeadingNumbers(
                                        String(investing?.c?.d ?? 0)
                                    )
                                ).formatCurrency()}
                            </PDFParagraphBlock>
                        </View>
                    </PDFBasicQuestion>
                </View>
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <PDFBasicQuestion
                    id="2."
                    label={t('investing.question1.q6')}
                    value={String(
                        formT(
                            `investingExperienceForm.useOfServices.options.${investing.useOfServices}`
                        )
                    )}
                />
                <PDFDivider />
                <PDFDivider />
                <PDFBasicQuestion
                    id="3."
                    label={t('investing.question1.q7')}
                    value={String(
                        formT(
                            `investingExperienceForm.wayOfEducation.options.${investing.wayOfEducation}`
                        )
                    )}
                />
                <PDFDivider />
                <PDFDivider />
                <PDFBasicQuestion
                    id="4."
                    label={t('investing.question1.q8')}
                    value={String(
                        formT(
                            `investingExperienceForm.stockTradingLoan.options.${investing.stockTradingLoan}`
                        )
                    )}
                />
                <PDFDivider />
                <PDFDivider />
                <PDFBasicQuestion
                    id="II."
                    label={
                        <PDFParagraphBlock.Title bold underline>
                            {t('investing.question2.title')}
                        </PDFParagraphBlock.Title>
                    }
                    value=""
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="1)."
                    label={t('investing.question2.q1')}
                    value={String(
                        formT(`hazardProfileForm.a.options.${hazard.a}`)
                    )}
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="2)"
                    label={
                        <Trans
                            i18nKey="investing.question2.q2"
                            ns={I18nNameSpaces.PDF}
                        >
                            Part1
                            <Text style={{ fontFamily: 'HindRegular' }} />
                        </Trans>
                    }
                    value={String(
                        formT(`hazardProfileForm.b.options.${hazard.b}`)
                    )}
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="3)"
                    label={t('investing.question2.q3')}
                    value={String(
                        formT(`hazardProfileForm.c.options.${hazard.c}`)
                    )}
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="4)"
                    label={t('investing.question2.q4')}
                    value={String(
                        formT(`hazardProfileForm.d.options.${hazard.d}`)
                    )}
                />
                <PDFDivider />
                <PDFDivider />
                <PDFDivider />
                <PDFDivider />
                {/* Hazard profile */}
                <PDFBasicQuestion
                    id="III."
                    label={
                        <PDFParagraphBlock.Title bold underline>
                            {t('investing.question3.title')}
                        </PDFParagraphBlock.Title>
                    }
                    value=""
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="1)"
                    label={t('investing.question3.q1')}
                    value={String(
                        formT(`financesForm.a.options.${finances.a}`)
                    )}
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="2)"
                    label={
                        <Trans
                            i18nKey="investing.question3.q2"
                            ns={I18nNameSpaces.PDF}
                        >
                            Part1
                            <Text style={{ fontFamily: 'HindRegular' }} />
                        </Trans>
                    }
                    value={String(
                        formT(`financesForm.e.options.${finances.e}`)
                    )}
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="3)"
                    label={t('investing.question3.q3')}
                    value={String(
                        formT(`financesForm.c.options.${finances.c}`)
                    )}
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="4)"
                    label={
                        <Trans
                            i18nKey="investing.question3.q4"
                            ns={I18nNameSpaces.PDF}
                        >
                            Part1
                            <Text style={{ fontFamily: 'HindRegular' }} />
                            Part3
                        </Trans>
                    }
                    value={String(
                        formT(`financesForm.d.options.${finances.d}`)
                    )}
                />
                <PDFDivider />
                <PDFBasicQuestion
                    id="5)"
                    label={String(t('investing.question3.q5'))}
                    value=""
                />
                <PDFDivider />
                <View>
                    <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={finances?.f?.a}
                                value={String(
                                    formT('financesForm.f.options.a')
                                )}
                                bold={false}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={finances?.f?.b}
                                value={String(
                                    formT('financesForm.f.options.b')
                                )}
                                bold={false}
                            />
                            <PDFDivider />
                        </View>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={finances?.f?.c}
                                value={String(
                                    formT('financesForm.f.options.c')
                                )}
                                bold={false}
                            />
                            <PDFDivider />
                            <PDFListItem
                                label={finances?.f?.d}
                                value={String(
                                    formT('financesForm.f.options.d')
                                )}
                                bold={false}
                            />
                            <PDFDivider />
                        </View>
                        <View style={{ flexGrow: 3 }}>
                            <PDFDivider />
                            <PDFListItem
                                label={finances?.f?.e}
                                value={String(
                                    formT('financesForm.f.options.e')
                                )}
                                bold={false}
                            />
                            <PDFDivider />
                        </View>
                    </View>
                    <PDFDivider />
                    {/* Finance Analyse */}
                    <PDFParagraphBlock.Title bold>
                        {t('investing.analyse.title')}
                    </PDFParagraphBlock.Title>
                    <View wrap={false} style={{ border: '1 solid black' }}>
                        <PDFParagraphBlock>
                            <Trans
                                ns={I18nNameSpaces.PDF}
                                i18nKey="investing.analyse.a"
                                values={{
                                    value: numbro(
                                        analyse.liquidity
                                    ).formatCurrency(),
                                }}
                            >
                                <Text style={{ fontFamily: 'HindSemiBold' }} />{' '}
                                Part2
                            </Trans>
                        </PDFParagraphBlock>
                        <PDFDivider />
                        <PDFParagraphBlock>
                            <Trans
                                ns={I18nNameSpaces.PDF}
                                i18nKey="investing.analyse.b"
                                values={{
                                    value: numbro(
                                        analyse.capitalInvesting
                                    ).formatCurrency(),
                                }}
                            >
                                <Text style={{ fontFamily: 'HindSemiBold' }} />{' '}
                                Part2
                            </Trans>
                        </PDFParagraphBlock>
                        <PDFDivider />
                        <PDFParagraphBlock>
                            <Trans
                                ns={I18nNameSpaces.PDF}
                                i18nKey="investing.analyse.c"
                                values={{
                                    value: numbro(
                                        analyse.monthSalary
                                    ).formatCurrency(),
                                }}
                            >
                                <Text style={{ fontFamily: 'HindSemiBold' }} />{' '}
                                Part2
                            </Trans>
                        </PDFParagraphBlock>
                        <PDFDivider />
                        <PDFDivider />
                        <PDFParagraphBlock>
                            <Trans
                                ns={I18nNameSpaces.PDF}
                                i18nKey="investing.analyse.d"
                                values={{
                                    value: numbro(
                                        analyse.monthExpenses
                                    ).formatCurrency(),
                                }}
                            >
                                <Text style={{ fontFamily: 'HindSemiBold' }} />{' '}
                                Part2
                            </Trans>
                        </PDFParagraphBlock>
                        <PDFDivider />
                        <PDFParagraphBlock bold>
                            <Trans
                                ns={I18nNameSpaces.PDF}
                                i18nKey="investing.analyse.e"
                                values={{ value: getReserve(analyse) }}
                            />
                        </PDFParagraphBlock>
                        <PDFParagraphBlock>
                            <Trans
                                ns={I18nNameSpaces.PDF}
                                i18nKey="investing.analyse.f"
                                values={{
                                    value: numbro(
                                        Math.max(
                                            0,
                                            analyse.monthSalary -
                                                analyse.monthExpenses
                                        )
                                    ).formatCurrency(),
                                }}
                            >
                                Part1
                                <Text style={{ fontFamily: 'HindSemiBold' }} />
                            </Trans>
                        </PDFParagraphBlock>
                        <PDFDivider />
                        <PDFDivider />
                    </View>
                    <PDFDivider />
                    <PDFBasicQuestion
                        id="6)"
                        label={t('investing.question3.q6')}
                        value={String(
                            formT(
                                `financeAnalyse.disposableResources.options.${analyse.disposableResources}`
                            )
                        )}
                    />
                </View>
            </PDFBrandedPageWithFooter>
            {/* Results */}
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <PDFParagraphBlock.Title bold underline blue size="medium">
                    {t('investing.result.title')}
                </PDFParagraphBlock.Title>
                <PDFDivider />
                <PdfParagraphBlock>
                    <Trans
                        ns={I18nNameSpaces.PDF}
                        i18nKey="investing.result.p"
                        values={{
                            strategy: strategy.name,
                        }}
                    >
                        <Text style={{ fontFamily: 'HindSemiBold' }} /> Part2
                    </Trans>
                </PdfParagraphBlock>
                {/* Hazards */}
                <PDFDivider />
                <PDFParagraphBlock.Title bold underline>
                    {t('investing.hazards.title')}
                </PDFParagraphBlock.Title>
                <PDFDivider />
                <PDFParagraphBlock>
                    {t('investing.hazards.p')}
                </PDFParagraphBlock>
                <PDFDivider />
                <PDFDivider />
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <View style={{ width: '48%' }}>
                        {renderHazard(
                            t('investing.hazards.h1.title'),
                            t('investing.hazards.h1.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h2.title'),
                            t('investing.hazards.h2.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h3.title'),
                            t('investing.hazards.h3.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h4.title'),
                            t('investing.hazards.h4.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h13.title'),
                            t('investing.hazards.h13.p')
                        )}
                    </View>
                    <View style={{ width: '48%' }}>
                        {renderHazard(
                            t('investing.hazards.h7.title'),
                            t('investing.hazards.h7.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h8.title'),
                            t('investing.hazards.h8.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h9.title'),
                            t('investing.hazards.h9.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h10.title'),
                            t('investing.hazards.h10.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h11.title'),
                            t('investing.hazards.h11.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h12.title'),
                            t('investing.hazards.h12.p')
                        )}
                        <PDFDivider />
                        {renderHazard(
                            t('investing.hazards.h6.title'),
                            t('investing.hazards.h6.p')
                        )}
                    </View>
                </View>
            </PDFBrandedPageWithFooter>
            <PDFBrandedPageWithFooter footerTitle={footerTitle}>
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    <View style={{ width: '48%' }}>
                        {renderHazard(
                            t('investing.hazards.h5.title'),
                            t('investing.hazards.h5.p')
                        )}
                        <PDFDivider />
                    </View>
                    <View style={{ width: '48%' }}>
                        {renderHazard(
                            t('investing.hazards.h14.title'),
                            t('investing.hazards.h14.p')
                        )}
                        <PDFDivider />
                    </View>
                </View>
                <PDFDivider />
                <PDFParagraphBlock bold>
                    {t('investing.hazards.info')}
                </PDFParagraphBlock>
                {/* Client confirmation */}
                <View>
                    <PDFDivider />
                    <PDFDivider />
                </View>
                {/* Finance Analyse */}
                <View wrap={false}>
                    <PDFParagraphBlock.Title bold blue>
                        {t('investing.clientStatement.title')}
                    </PDFParagraphBlock.Title>
                    <View
                        style={{ border: '1 solid black', padding: '8 20 8 8' }}
                    >
                        <PDFCheckbox
                            checked
                            label={t('investing.clientStatement.items.a')}
                        />
                        <PDFDivider />
                        <PDFCheckbox
                            checked
                            label={t('investing.clientStatement.items.b')}
                        />
                        <PDFDivider />
                        <PDFCheckbox
                            checked
                            label={t('investing.clientStatement.items.d')}
                        />
                        <PDFDivider />
                        <PDFCheckbox
                            checked
                            label={t('investing.clientStatement.items.e')}
                        />
                        <PDFDivider />
                        <PDFCheckbox
                            checked
                            label={t('investing.clientStatement.items.f')}
                        />
                        <PDFDivider />
                        <PDFCheckbox
                            checked
                            label={t('investing.clientStatement.items.g')}
                        />
                        <PDFDivider />
                        <PDFCheckbox
                            checked
                            label={t('investing.clientStatement.items.h')}
                        />
                        <PDFDivider />
                        <PDFDivider />
                        <PDFDivider />
                        <PDFDivider />

                        <View style={{ paddingRight: 20 }}>
                            <PDFSignatureBlock
                                signature={client1Signature}
                                name={
                                    clientValues1
                                        ? `${clientValues1.title} ${clientValues1.firstName} ${clientValues1.lastName} (Zákazník 1)`
                                        : undefined
                                }
                                name2={
                                    clientValues2
                                        ? `${clientValues2.title} ${clientValues2.firstName} ${clientValues2.lastName} (Zákazník 2)`
                                        : undefined
                                }
                                signature3={`${companyInfo.firstName} ${companyInfo.lastName}`}
                                name3={
                                    companyInfo
                                        ? `${companyInfo.title} ${
                                              companyInfo.firstName
                                          } ${companyInfo.lastName} ${t(
                                              'signatureBlock.manager'
                                          )}`
                                        : undefined
                                }
                                date={agreement.dateOfSignature}
                                place={agreement.placeOfSignature}
                                time={agreement.interviewTime}
                            />
                        </View>
                    </View>
                </View>
            </PDFBrandedPageWithFooter>
        </>
    );
}
