import { Field, FieldProps, getIn } from 'formik';
import React, { ReactNode } from 'react';

import { CheckBox, CheckBoxOption } from '../../components';

interface Props {
    name: string;
    label?: ReactNode;
    type?: 'horizontal' | 'vertical';
    options: CheckBoxOption[];
    disabled?: boolean;
}

export default ({ name, ...rest }: Props) => (
    <Field name={name}>
        {({ field: { value }, form }: FieldProps<any>) => {
            const handleChange = (values: string[]) => {
                form.setFieldValue(name, values);
            };

            return (
                <CheckBox.Group
                    error={getIn(form.errors, name)}
                    value={value || []}
                    onChange={handleChange}
                    {...rest}
                />
            );
        }}
    </Field>
);
