import React from 'react';
import { Pagination } from 'antd';
import { useTranslation } from 'react-i18next';
import { PaginationProps } from 'antd/lib/pagination';

export interface PaginationState {
    currentPage: number;
    pageSize: number;
    total: number;
}

export default function EnhancedPagination(props: PaginationProps) {
    const { t } = useTranslation();

    return (
        <Pagination
            defaultPageSize={10}
            showSizeChanger
            showTotal={(total, range) =>
                t('formsManagement.paginationRange', {
                    start: range[0],
                    end: range[1],
                    total,
                })
            }
            {...props}
        />
    );
}
