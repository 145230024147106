import React, { ReactNode } from 'react';
import { View, Text } from '@react-pdf/renderer';
import PdfParagraphBlock from './ParagraphBlock';
import { useTranslation } from 'react-i18next';
import { I18nNameSpaces } from 'common/services/i18n';
import { environment } from 'environments/environment';
import { pageStyles } from './BrandedPage';

interface Props {
    title: ReactNode;
    content?: ReactNode;
    version?: any;
}

export default function PDFPageFooter({ title, content }: Props) {
    const { t, i18n } = useTranslation(I18nNameSpaces.PDF);

    return (
        <View fixed style={pageStyles.footer}>
            {content}
            <View
                style={{
                    flexGrow: 1,
                    flexDirection: 'row',
                    backgroundColor: '#c0c0c0',
                    padding: '1px 2px',
                    marginTop: '4px',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <View style={{ marginRight: 'auto', width: '40%' }}>
                    <PdfParagraphBlock>
                        {i18n.language === 'sk'
                            ? environment.pdf_version_sk
                            : environment.pdf_version_cz}
                    </PdfParagraphBlock>
                </View>
                <View
                    style={{
                        width: '20%',
                    }}
                >
                    <Text
                        fixed
                        style={{ textAlign: 'center', fontSize: 8 }}
                        render={({ pageNumber, totalPages }) =>
                            `${t('page')} ${pageNumber}/${totalPages}`
                        }
                    />
                </View>
                <View
                    style={{
                        alignItems: 'flex-end',
                        marginLeft: 'auto',
                        width: '40%',
                    }}
                >
                    <PdfParagraphBlock>{title}</PdfParagraphBlock>
                </View>
            </View>
        </View>
    );
}
