import React from 'react';
import { Trans } from 'react-i18next';

export default function Empty() {
    return (
        <div>
            <Trans i18nKey="empty" />
        </div>
    );
}
