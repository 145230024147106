import { call, put, takeLatest } from 'redux-saga/effects';

import {
    createAsyncAction,
    createAction,
    createReducer,
    getType,
    ActionType,
} from 'typesafe-actions';

import api from 'common/api';
import { AppState } from 'common/models/AppState';
import { LoginFormValues, UserProfile } from 'common/models/User';

import { selectAuth } from '../../ducks';
import { ApiClientResponse } from '../../../../common/services/api-client';

// Actions
export const loginActions = createAsyncAction(
    '@@Login/REQUEST',
    '@@Login/SUCCESS',
    '@@Login/FAILURE'
)<LoginFormValues, UserProfile, undefined>();

export const loginResetAction = createAction('@@Login/RESET');

// Reducers
export interface LoginState {
    error: boolean;
    loading: boolean;
    success: boolean;
}

const initialState: LoginState = {
    error: false,
    loading: false,
    success: false,
} as const;

type LoginActions = ActionType<typeof loginActions | typeof loginResetAction>;
export default createReducer<LoginState, LoginActions>(initialState)
    .handleAction(loginActions.failure, state => ({
        ...state,
        error: true,
    }))
    .handleAction(loginActions.success, state => ({
        ...state,
        success: true,
    }))
    .handleAction(loginResetAction, () => initialState);

// Selectors
export function selectLogin(state: AppState) {
    return selectAuth(state).login;
}

export function selectLoginError(state: AppState) {
    return selectLogin(state).error;
}

// Sagas
function* watchLoginRequest(action: ReturnType<typeof loginActions.request>) {
    const resp: ApiClientResponse<UserProfile> = yield call(
        api.user.login,
        action.payload as LoginFormValues
    );

    if (resp.ok) {
        yield put(loginActions.success(resp.data));
    } else {
        yield put(loginActions.failure());
    }
}

export function* loginSaga() {
    yield takeLatest(getType(loginActions.request), watchLoginRequest);
}
