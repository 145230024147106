import React, { useEffect, useState } from 'react';
import { WithTranslation, useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import useUnmount from 'react-use/lib/useUnmount';
import { Row, Col } from 'antd';

import { RegistrationForm } from 'common/forms';
import { AppState } from 'common/models/AppState';
import { RegistrationFormValues, ProfileFormValues } from 'common/models/User';
import { I18nNameSpaces } from 'common/services/i18n';

import { AuthPageDescription } from '../../components';
import {
    registrationActions,
    registrationResetAction,
    selectRegistrationError,
    selectRegistrationSuccess,
} from './ducks';
import { selectIsLogged } from '../../ducks';

interface DispatchToProps {
    register: typeof registrationActions.request;
    reset: typeof registrationResetAction;
}

interface StateToProps {
    isLogged: boolean;
    error: boolean;
    success: boolean;
}

type Props = StateToProps &
    DispatchToProps &
    RouteComponentProps &
    WithTranslation;

function RegistrationFeature({ history, success, location, reset }: Props) {
    const { t } = useTranslation(I18nNameSpaces.Auth);
    const [registration, setRegistration] = useState<{
        authStep: RegistrationFormValues | null;
        profileStep: ProfileFormValues | null;
    }>({ authStep: null, profileStep: null });

    useEffect(() => {
        if (success && location.pathname === '/auth/registration') {
            history.push('/auth/login');
        }
    }, [success, history, location]);

    useUnmount(() => {
        reset();
    });

    function handleRegisterSubmit(values: RegistrationFormValues) {
        setRegistration({ ...registration, authStep: values });
    }

    return (
        <Row type="flex" justify="center">
            <Col xs={24} md={8}>
                <AuthPageDescription
                    title={t('registration.page.title')}
                    description={t('registration.page.description')}
                />
                <RegistrationForm onSubmit={handleRegisterSubmit} />
            </Col>
        </Row>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    isLogged: selectIsLogged(state),
    error: selectRegistrationError(state),
    success: selectRegistrationSuccess(state),
});

const mapDispatchToProps: DispatchToProps = {
    register: registrationActions.request,
    reset: registrationResetAction,
};

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(RegistrationFeature)
);
