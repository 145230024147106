import React, { ReactNode } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

interface Props {
    children?: ReactNode;
    bold?: boolean;
    underline?: boolean;
    blue?: boolean;
    size?: 'regular' | 'medium' | 'large';
    align?: 'justify' | 'center' | 'left' | 'right';
    lineHeight?: number;
    style?: Style;
}

export default function PdfParagraphBlock({
    children,
    bold,
    underline,
    blue,
    size = 'regular',
    align = 'justify',
    lineHeight,
    style = {},
}: Props) {
    return (
        <Text
            style={{
                fontFamily: bold ? 'HindSemiBold' : 'HindRegular',
                textDecoration: underline ? 'underline' : 'none',
                color: blue ? '#4472c4' : 'black',
                fontSize:
                    size === 'regular'
                        ? 8
                        : size === 'medium'
                        ? 9
                        : size === 'large'
                        ? 12
                        : 8,
                textAlign: align,
                lineHeight,
                ...style,
            }}
        >
            {children}
        </Text>
    );
}

PdfParagraphBlock.Title = ({
    children,
    bold,
    underline,
    blue,
    size,
}: Props) => {
    return (
        <Text
            style={{
                fontFamily: bold ? 'HindSemiBold' : 'HindRegular',
                textDecoration: underline ? 'underline' : 'none',
                color: blue ? '#4472c4' : 'black',
                fontSize:
                    size === 'regular'
                        ? 8
                        : size === 'medium'
                        ? 9
                        : size === 'large'
                        ? 12
                        : 8,
            }}
        >
            {children}
        </Text>
    );
};

PdfParagraphBlock.Wrapper = ({ children }: { children: ReactNode }) => {
    return <View style={{ marginBottom: '10px' }}>{children}</View>;
};
