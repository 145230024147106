import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Typography } from '../../components';
import { InputField } from '../../fields';
import { LoginFormValues } from '../../models/User';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

interface Props {
    error?: boolean;
    initialValues?: Partial<LoginFormValues>;
    onSubmit: (values: LoginFormValues) => void;
    onForgotPassword?: () => void;
}

export default function LoginForm({
    onForgotPassword,
    onSubmit,
    initialValues,
    error,
}: Props) {
    const { t } = useTranslation();
    const schema = Yup.object<LoginFormValues>().shape<LoginFormValues>({
        email: Yup.string()
            .email(t('form.emailRequired'))
            .required(t('form.required')),
        password: Yup.string().required(t('form.required')),
    });

    function handleOnSubmit(values: LoginFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm() {
        return (
            <div className="login-form">
                <Form>
                    <InputField
                        name="email"
                        label={t('loginForm.email.label')}
                        placeholder={t('loginForm.email.placeholder')}
                        size="large"
                    />
                    <InputField
                        name="password"
                        type="password"
                        label={t('loginForm.password.label')}
                        size="large"
                        placeholder={t('loginForm.password.placeholder')}
                    />
                    {error && (
                        <Typography.Paragraph type="danger">
                            {t('loginForm.serverError')}
                        </Typography.Paragraph>
                    )}
                    <div
                        onClick={onForgotPassword}
                        className="login-form-forgotten-password"
                    >
                        <Typography.Paragraph underline>
                            {t('loginForm.forgotPasswordLink')}
                        </Typography.Paragraph>
                    </div>
                    <Row type="flex" justify="center">
                        <Col>
                            <Button htmlType="submit" type="primary">
                                {t('loginForm.submit')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{ email: initialValues?.email ?? '', password: '' }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
