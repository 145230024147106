import React, { ReactNode } from 'react';
import { Card, Typography } from '../../components';
import { Col, Row } from 'antd';
import { BasicProps } from 'antd/lib/layout/layout';

interface Props {
    title: ReactNode;
    icon: ReactNode;
    onClick?: () => void;
}

export default function FeatureLink({ title, icon, onClick }: Props) {
    return (
        <div className="feature-link" onClick={onClick}>
            <Card>
                {icon}
                <Typography.Title level={2}>{title}</Typography.Title>
            </Card>
        </div>
    );
}

FeatureLink.Grid = function ({ children }: BasicProps) {
    return (
        <Row type="flex" justify="center" className="feature-link-grid">
            <Col>{children}</Col>
        </Row>
    );
};
