import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router';

import MeetingFeature from './features/meeting';
import SummaryFeature from './features/summary';
import { AppState } from '../../../../common/models/AppState';
import { selectIsPrivileged } from '../../../auth/ducks';
import { connect } from 'react-redux';

interface StateToProps {
    isPrivileged: boolean;
}

function AgreementsFeature({ isPrivileged }: StateToProps) {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match!.path}/meeting`}
                render={props => (
                    <MeetingFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Route
                path={`${match!.path}/summary`}
                render={props => (
                    <SummaryFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Redirect path={`${match!.path}/`} to={`${match!.path}/meeting`} />
        </Switch>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    isPrivileged: selectIsPrivileged(state),
});

export default connect(mapStateToProps)(AgreementsFeature);
