import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper, InfoBlock } from '../../components';
import { FinanceAnalyseFormValues, ClientFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import SliderWithNumberField from '../../fields/SliderWithNumber';
import { Row, Col } from 'antd';
import numbro from 'numbro';
import { RadioField } from '../../fields';

interface Props {
    initialValues?: Partial<FinanceAnalyseFormValues>;
    customerValues?: ClientFormValues;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: FinanceAnalyseFormValues) => void;
}

export default function FinancesAnalyseForm({
    onSubmit,
    initialValues,
    bindSubmit,
    customerValues,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<FinanceAnalyseFormValues>({
        capitalInvesting: Yup.number().required(t('form.required')),
        liquidity: Yup.number().required(t('form.required')),
        monthExpenses: Yup.number().required(t('form.required')),
        monthSalary: Yup.number().required(t('form.required')),
        disposableResources: Yup.string().required(t('form.required')),
    });

    function handleOnSubmit(values: FinanceAnalyseFormValues) {
        onSubmit && onSubmit(values);
    }

    function getReserve(values: FinanceAnalyseFormValues) {
        if (customerValues) {
            if (
                customerValues.salary === 'employed' ||
                customerValues.salary === 'freelancer'
            ) {
                return numbro(values.monthExpenses * 12).formatCurrency();
            }

            return numbro(values.liquidity * 0.4).formatCurrency();
        }

        return '';
    }

    function renderForm({
        submitForm,
        values,
    }: FormikProps<FinanceAnalyseFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider type="bordered">
                            <SliderWithNumberField
                                name="liquidity"
                                label={t('financeAnalyse.liquidity.label')}
                                tooltip={t('financeAnalyse.liquidity.info')}
                                defaultValue={0}
                                min={0}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider type="bordered">
                            <SliderWithNumberField
                                name="capitalInvesting"
                                label={t(
                                    'financeAnalyse.capitalInvesting.label'
                                )}
                                tooltip={t(
                                    'financeAnalyse.capitalInvesting.info'
                                )}
                                defaultValue={0}
                                min={0}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider type="bordered">
                            <SliderWithNumberField
                                name="monthSalary"
                                label={t('financeAnalyse.monthSalary.label')}
                                tooltip={t('financeAnalyse.monthSalary.info')}
                                defaultValue={0}
                                min={0}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider type="bordered">
                            <SliderWithNumberField
                                name="monthExpenses"
                                label={t('financeAnalyse.monthExpenses.label')}
                                tooltip={t('financeAnalyse.monthExpenses.info')}
                                defaultValue={0}
                                min={0}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <Row type="flex" gutter={30}>
                                <Col xs={24} md={12}>
                                    <InfoBlock
                                        title={t(
                                            'financeAnalyse.results.disposableBalance'
                                        )}
                                        value={numbro(
                                            Math.max(
                                                0,
                                                values.monthSalary -
                                                    values.monthExpenses
                                            )
                                        ).formatCurrency()}
                                    />
                                </Col>
                                <Col xs={24} md={12}>
                                    <InfoBlock
                                        title={t(
                                            'financeAnalyse.results.recommendedReserve'
                                        )}
                                        value={getReserve(values)}
                                    />
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('financeAnalyse.disposableResources.label')}
                            </QuestionWrapper.Title>
                            <RadioField
                                name="disposableResources"
                                type="vertical"
                                options={[
                                    {
                                        label: t(
                                            'financeAnalyse.disposableResources.options.a'
                                        ),
                                        value: 'a',
                                    },
                                    {
                                        label: t(
                                            'financeAnalyse.disposableResources.options.b'
                                        ),
                                        value: 'b',
                                    },
                                    {
                                        label: t(
                                            'financeAnalyse.disposableResources.options.c'
                                        ),
                                        value: 'c',
                                    },
                                    {
                                        label: t(
                                            'financeAnalyse.disposableResources.options.d'
                                        ),
                                        value: 'd',
                                    },
                                    {
                                        label: t(
                                            'financeAnalyse.disposableResources.options.e'
                                        ),
                                        value: 'e',
                                    },
                                ]}
                            />
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                liquidity: initialValues?.liquidity ?? 0,
                capitalInvesting: initialValues?.capitalInvesting ?? 0,
                monthExpenses: initialValues?.monthExpenses ?? 0,
                monthSalary: initialValues?.monthSalary ?? 0,
                disposableResources: initialValues?.disposableResources ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
