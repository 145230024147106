import { combineReducers } from 'redux';
import { spawn } from 'redux-saga/effects';
import formsList, { formsListSaga, FormsListState } from './features/forms/ducks';

export interface HomeState {
    formsList: FormsListState;
}

export default combineReducers<HomeState>({
    formsList
});

export function* homeSaga() {
    yield spawn(formsListSaga);
}

