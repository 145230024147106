import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper } from '../../components';
import { InputField } from '../../fields';
import { RequestInfoFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import { Row, Col } from 'antd';

interface Props {
    initialValues?: Partial<RequestInfoFormValues>;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: RequestInfoFormValues) => void;
}

export default function RequestInfoForm({
    onSubmit,
    bindSubmit,
    initialValues,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<RequestInfoFormValues>({
        a: Yup.string().required(t('form.required')),
        b: Yup.string(),
        c: Yup.string(),
    });

    function handleOnSubmit(values: RequestInfoFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({ submitForm }: FormikProps<RequestInfoFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <Row gutter={20}>
                                <Col xs={24} md={8}>
                                    <InputField
                                        name="a"
                                        label={t('requestInfoForm.a.label')}
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <InputField
                                        name="b"
                                        label={t('requestInfoForm.b.label')}
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <InputField
                                        name="c"
                                        label={t('requestInfoForm.c.label')}
                                    />
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                a: initialValues?.a ?? '',
                b: initialValues?.b ?? '',
                c: initialValues?.c ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
