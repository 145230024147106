import React, { ReactNode } from 'react';
import { View } from '@react-pdf/renderer';
import { PDFParagraphBlock } from './index';

interface Props {
    id: ReactNode;
    label: ReactNode;
    value: ReactNode;
    children?: ReactNode;
}

export default function PDFBasicQuestion({
    id,
    label,
    value,
    children,
}: Props) {
    return (
        <View style={{ flexDirection: 'row' }}>
            <View>
                <PDFParagraphBlock.Title bold>{id}</PDFParagraphBlock.Title>
            </View>
            <View style={{ paddingLeft: 10 }}>
                <PDFParagraphBlock.Title bold>{label}</PDFParagraphBlock.Title>
                <View>
                    <PDFParagraphBlock>{value}</PDFParagraphBlock>
                </View>
                {children}
            </View>
        </View>
    );
}
