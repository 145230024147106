import React from 'react';
import { Text, View } from '@react-pdf/renderer';

interface Props {
    name?: string;
    signature?: string;
}

export default function SignatureLine(props: Props) {
    return (
        <>
            <Text
                style={{
                    fontSize: 9,
                    color: 'darkblue',
                    fontFamily: 'RobotoLightItalic',
                    textTransform: 'uppercase',
                    marginBottom: -2,
                }}
            >
                {`${props.signature ?? ' '}`}
            </Text>
            <View
                style={{
                    fontSize: 8,
                    fontFamily: 'HindSemiBold',
                    minWidth: 75,
                    padding: '1 0 5 0',
                    borderTop: '1 dotted black',
                    // White/transparent border with a radius as a fix for a PDF visual artifact:
                    // https://stackoverflow.com/questions/46318491
                    borderTopLeftRadius: 1,
                    borderLeft: '1 solid white',
                }}
            >
                <Text>{props.name}</Text>
            </View>
        </>
    );
}
