import { default as EmptyComponent } from './Empty';
import { default as ErrorComponent } from './Error';
import { default as LoadingComponent } from './Loading';
import { default as LockBodyScroll } from './LockScroll';

export default {
    Error: ErrorComponent,
    Empty: EmptyComponent,
    Loading: LoadingComponent,
    LockBodyScroll,
};
