import React, { ReactNode, useState, useEffect, ChangeEvent } from 'react';
import cn from 'classnames';
import { Radio, Row, Col } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';

import { Control } from '../Control';
import { Typography, Input } from '../../../components';
import useMount from 'react-use/lib/useMount';

interface Option {
    custom?: boolean;
    label: ReactNode;
    value: string | number | boolean;
}

interface Props {
    label?: ReactNode;
    error?: ReactNode;
    type?: 'horizontal' | 'vertical';
    className?: string;
    disabled?: boolean;
    breakLabel?: boolean;
    options: Option[];
    onChange?: (value: string | number | boolean) => void;
    value?: string;
}

export default function EnhancedRadio({
    options = [],
    type = 'vertical',
    error,
    label,
    className,
    onChange,
    value,
    breakLabel = false,
    ...bag
}: Props) {
    const [custom, setCustom] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState<
        string | number | boolean
    >('');

    useMount(() => {
        // skip if field is uninitialized
        if (value === '') return;

        if (options.some(o => o.value === value)) {
            setSelectedValue(value !== undefined ? value : '');
        } else {
            const customRadioButton = options.find(o => o.custom);

            if (customRadioButton) {
                setSelectedValue(customRadioButton.value);
                setCustom(value || '');
            }
        }
    });

    useEffect(() => {
        if (
            selectedValue !== '' &&
            options.find(o => o.value === selectedValue)!.custom
        ) {
            onChange && onChange(custom);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [custom]);

    function handleCustomInputChange(e: ChangeEvent<HTMLInputElement>) {
        setCustom(e.target.value);
    }

    function handleChange(e: RadioChangeEvent) {
        const nextSelectedOption = options.find(
            o => e.target.value === o.value
        );

        setSelectedValue(nextSelectedOption!.value);
        onChange &&
            onChange(
                nextSelectedOption!.custom ? custom : nextSelectedOption!.value
            );
    }

    return (
        <Control label={label} error={error}>
            <Radio.Group
                className={cn(className, {
                    'ant-radio-group-horizontal': type === 'horizontal',
                    'ant-radio-group-vertical': type === 'vertical',
                    'break-label': breakLabel,
                })}
                onChange={handleChange}
                value={selectedValue}
                {...bag}
            >
                {options.map(o => (
                    <Radio key={o.value.toString()} value={o.value}>
                        {o.custom ? (
                            <Input
                                onChange={handleCustomInputChange}
                                value={custom}
                                placeholder={String(o.label)}
                            />
                        ) : (
                            o.label
                        )}
                    </Radio>
                ))}
            </Radio.Group>
        </Control>
    );
}
export type TableGroupResult = {
    question: string;
    value: string;
};
interface TableGroupProps {
    questions: { label: ReactNode; value: string }[];
    options: {
        label: ReactNode;
        value: string;
    }[];
    initialValues?: TableGroupResult[];
    onChange: (values: TableGroupResult[]) => void;
}

function RadioTableGroup({
    questions,
    options,
    onChange,
    initialValues,
}: TableGroupProps) {
    const [values, setValues] = useState<TableGroupResult[]>(
        initialValues || []
    );

    useEffect(() => {
        onChange(values);
    });

    function handleRadioChange(question: string) {
        return (e: RadioChangeEvent) => {
            setValues([
                ...values.filter(v => v.question !== question),
                { question, value: e.target.value },
            ]);
        };
    }

    return (
        <div className="table-radio-group">
            <Row type="flex">
                <Col offset={6} span={18}>
                    <Row type="flex">
                        {options.map((o, index) => (
                            <Col
                                key={index}
                                span={24 / options.length}
                                className="table-radio-group-option-label"
                            >
                                <Typography.Paragraph>
                                    {o.label}
                                </Typography.Paragraph>
                            </Col>
                        ))}
                    </Row>
                </Col>
            </Row>
            {questions.map(q => (
                <Row
                    key={q.value}
                    type="flex"
                    className="table-radio-group-row"
                >
                    <Col span={6}>
                        <Typography.Paragraph>{q.label}</Typography.Paragraph>
                    </Col>
                    <Col span={18}>
                        <Radio.Group
                            defaultValue={
                                values.find(v => v.question === q.value) &&
                                values.find(v => v.question === q.value)!.value
                            }
                            onChange={handleRadioChange(q.value)}
                        >
                            <Row type="flex" style={{ height: '100%' }}>
                                {options.map((o, index) => (
                                    <Col
                                        key={index}
                                        span={24 / options.length}
                                        className="table-radio-group-option-wrap"
                                    >
                                        <Radio value={o.value} />
                                    </Col>
                                ))}
                            </Row>
                        </Radio.Group>
                    </Col>
                </Row>
            ))}
        </div>
    );
}

EnhancedRadio.TableGroup = RadioTableGroup;
