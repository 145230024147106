import qs from 'qs';
import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';

import { I18nNameSpaces } from 'common/services/i18n';
import { PasswordEditFormValues } from 'common/models/User';
import { PasswordEditForm } from 'common/forms';

import { AuthPageDescription } from '../../../../components';
import { changePasswordActions } from '../../ducks';

interface StateToProps {}

interface DispatchToProps {
    changePassword: typeof changePasswordActions.request;
}

type Props = RouteComponentProps & DispatchToProps & StateToProps;

function ForgottenPasswordRecoveryFeature({ changePassword }: Props) {
    const { t } = useTranslation(I18nNameSpaces.Auth);
    const recoveryPage = 'forgottenPassword.recovery.page';

    function handleSubmit({ password }: PasswordEditFormValues) {
        const { token } = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        });

        changePassword({ password, token: token as string });
    }

    return (
        <Row type="flex" justify="center">
            <Col xs={24} md={8}>
                <AuthPageDescription
                    title={t(`${recoveryPage}.title`)}
                    description={t(`${recoveryPage}.description`)}
                />
                <PasswordEditForm onSubmit={handleSubmit} />
            </Col>
        </Row>
    );
}

const mapDispatchToProps: DispatchToProps = {
    changePassword: changePasswordActions.request,
};

const mapStateToProps = (): StateToProps => ({});

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ForgottenPasswordRecoveryFeature)
);
