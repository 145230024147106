import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router';

import GoalsFeature from './features/goals';
import InitiationFeature from './features/initiation';
import CustomerStatementFeature from './features/customer-statement';
import CustomerConfirmationFeature from './features/customer-confirmation';
import ConsultantConfirmationFeature from './features/consultant-confirmation';
import { AppState } from '../../../../common/models/AppState';
import { selectIsPrivileged } from '../../../auth/ducks';
import { connect } from 'react-redux';

interface StateToProps {
    isPrivileged: boolean;
}

function ProtocolFeature({ isPrivileged }: StateToProps) {
    const match = useRouteMatch();

    return (
        <Switch>
            <Route
                path={`${match!.path}/goals`}
                render={props => (
                    <GoalsFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Route
                path={`${match!.path}/initiation`}
                render={props => (
                    <InitiationFeature {...props} isPrivileged={isPrivileged} />
                )}
            />
            <Route
                path={`${match!.path}/customer-statement`}
                render={props => (
                    <CustomerStatementFeature
                        {...props}
                        isPrivileged={isPrivileged}
                    />
                )}
            />
            <Route
                path={`${match!.path}/customer-confirmation`}
                render={props => (
                    <CustomerConfirmationFeature
                        {...props}
                        isPrivileged={isPrivileged}
                    />
                )}
            />
            <Route
                path={`${match!.path}/consultant-confirmation`}
                render={props => (
                    <ConsultantConfirmationFeature
                        {...props}
                        isPrivileged={isPrivileged}
                    />
                )}
            />
            <Redirect path={`${match!.path}/`} to={`${match!.path}/goals`} />
        </Switch>
    );
}

const mapStateToProps = (state: AppState): StateToProps => ({
    isPrivileged: selectIsPrivileged(state),
});

export default connect(mapStateToProps)(ProtocolFeature);
