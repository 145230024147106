import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { QuestionWrapper } from '../../components';
import { AgreementsFormValues } from '../../models/Forms';
import { I18nNameSpaces } from '../../services/i18n';
import { CheckBoxField, InputField } from '../../fields';
import { Row, Col } from 'antd';

interface Props {
    initialValues?: Partial<AgreementsFormValues>;
    bindSubmit?: (cb: FormikProps<any>['submitForm']) => void;
    onSubmit: (values: AgreementsFormValues) => void;
}

export default function AgreementsForm({
    onSubmit,
    initialValues,
    bindSubmit,
}: Props) {
    const { t } = useTranslation(I18nNameSpaces.Forms);
    const schema = Yup.object().shape<AgreementsFormValues>({
        hazardAgreements: Yup.boolean().oneOf([true], t('form.required')),
        statement: Yup.object()
            .required()
            .default(null)
            .nullable()
            .shape({
                a: Yup.boolean().oneOf([true], t('form.required')),
                b: Yup.boolean().oneOf([true], t('form.required')),
                c: Yup.boolean().oneOf([true], t('form.required')),
                d: Yup.boolean().oneOf([true], t('form.required')),
                e: Yup.boolean().oneOf([true], t('form.required')),
                f: Yup.boolean().oneOf([true], t('form.required')),
                g: Yup.boolean().oneOf([true], t('form.required')),
                h: Yup.boolean().oneOf([true], t('form.required')),
            }),
        dateOfSignature: Yup.string().required(t('form.required')),
        placeOfSignature: Yup.string().required(t('form.required')),
        interviewTime: Yup.string().required(t('form.required')),
    });

    function handleOnSubmit(values: AgreementsFormValues) {
        onSubmit && onSubmit(values);
    }

    function renderForm({ submitForm }: FormikProps<AgreementsFormValues>) {
        if (bindSubmit) {
            bindSubmit(submitForm);
        }
        return (
            <div className="company-form">
                <Form>
                    <QuestionWrapper>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('agreementsForm.hazardsAgreements.title')}
                            </QuestionWrapper.Title>
                            <CheckBoxField
                                name="hazardAgreements"
                                label={t(
                                    'agreementsForm.hazardsAgreements.label'
                                )}
                                subLabel={t(
                                    'agreementsForm.hazardsAgreements.subLabel'
                                )}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <QuestionWrapper.Title>
                                {t('agreementsForm.statement.title')}
                            </QuestionWrapper.Title>
                            <CheckBoxField
                                name="statement.a"
                                label={t(
                                    'agreementsForm.statement.questions.a'
                                )}
                            />
                            <CheckBoxField
                                name="statement.b"
                                label={t(
                                    'agreementsForm.statement.questions.b'
                                )}
                            />
                            <CheckBoxField
                                name="statement.c"
                                label={t(
                                    'agreementsForm.statement.questions.c'
                                )}
                            />
                            <CheckBoxField
                                name="statement.d"
                                label={t(
                                    'agreementsForm.statement.questions.d'
                                )}
                            />
                            <CheckBoxField
                                name="statement.e"
                                label={t(
                                    'agreementsForm.statement.questions.e'
                                )}
                            />
                            <CheckBoxField
                                name="statement.f"
                                label={t(
                                    'agreementsForm.statement.questions.f'
                                )}
                            />
                            <CheckBoxField
                                name="statement.g"
                                label={t(
                                    'agreementsForm.statement.questions.g'
                                )}
                            />
                            <CheckBoxField
                                name="statement.h"
                                label={t(
                                    'agreementsForm.statement.questions.h'
                                )}
                            />
                        </QuestionWrapper.Divider>
                        <QuestionWrapper.Divider>
                            <Row gutter={20}>
                                <Col xs={24} md={8}>
                                    <InputField
                                        label={t(
                                            'agreementsForm.placeOfSignature.label'
                                        )}
                                        name="placeOfSignature"
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <InputField
                                        label={t(
                                            'agreementsForm.dateOfSignature.label'
                                        )}
                                        name="dateOfSignature"
                                    />
                                </Col>
                                <Col xs={24} md={8}>
                                    <InputField
                                        label={t(
                                            'agreementsForm.interviewTime.label'
                                        )}
                                        name="interviewTime"
                                    />
                                </Col>
                            </Row>
                        </QuestionWrapper.Divider>
                    </QuestionWrapper>
                </Form>
            </div>
        );
    }

    return (
        <Formik
            initialValues={{
                hazardAgreements: initialValues?.hazardAgreements ?? false,
                statement: {
                    a: initialValues?.statement?.a ?? false,
                    b: initialValues?.statement?.b ?? false,
                    c: initialValues?.statement?.c ?? false,
                    d: initialValues?.statement?.d ?? false,
                    e: initialValues?.statement?.e ?? false,
                    f: initialValues?.statement?.f ?? false,
                    g: initialValues?.statement?.g ?? false,
                    h: initialValues?.statement?.h ?? false,
                },
                dateOfSignature: initialValues?.dateOfSignature ?? '',
                interviewTime: initialValues?.interviewTime ?? '',
                placeOfSignature: initialValues?.placeOfSignature ?? '',
            }}
            onSubmit={handleOnSubmit}
            render={renderForm}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
        />
    );
}
